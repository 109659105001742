import {Injectable} from '@angular/core';
import {HeaderTitleService} from "../header/header-title.service";

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  constructor(private headerService: HeaderTitleService) {
  }

  /*
    public breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

    setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
      this.breadcrumbs$.next(breadcrumbs);
    }

    getBreadcrumbs() {
      return this.breadcrumbs$.asObservable();
    } */

  breadcrumbs: { label: string; route: string }[] = [];

  setBreadcrumbs(breadcrumbs: { label: string; route: string }[]): void {
    this.breadcrumbs = breadcrumbs;
    ["vTesting jobs", "vECU packages", "All Simulations", "Remote Testbench"].forEach((label, index) =>
      this.headerService.navigationItems[index].active = this.breadcrumbs[0].label == label);
  }

  findIndexByLabel(label: string): number {
    return this.breadcrumbs.findIndex((crumb) => crumb.label === label);
  }

  // Function to remove elements from the array above the specified index
  removeAboveIndex(index: number): void {
    if (index !== -1) {
      this.breadcrumbs.splice(index + 1);
    }
  }
}
