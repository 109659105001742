import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {VirtualRemoteTestBenchVtestingJobService} from "../../../../services/remote-testbench-vtesting-job.service";
import {Format, ValidationService, ValidationStatus} from "../../../../../services/validation/validation.service";
import {CapabilitiesService} from "../../../../../services/capabilities/capabilities.service";
import {NotificationService} from "../../../../../services/notification/notification.service";
import {VecuFiles} from "../../../../interfaces/vecu-files";
import {Subject} from "rxjs";
import {Actions} from "../../remote-testbench-job-flow-test/remote-testbench-job-flow-test.component";
import {StarcDomain} from '@vtp/vtpcfg-client-ts';

enum FormGroup {
  Odxd = "Odxd",
  Candela = "Candela",
  EcuExtract = "EcuExtract",
  Network = "Network"
}

@Component({
  selector: 'app-remote-testbench-job-brop',
  // standalone: true,
  // imports: [],
  templateUrl: './remote-testbench-job-brop.component.html',
  styleUrl: './remote-testbench-job-brop.component.css'
})

export class VirtualRemoteTestBenchJobBropComponentSettingsComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() index!: number;
  @Input() actionTransmitter!: Subject<{ action: number, option: any }>;

  subscription$: any;

  files: VecuFiles = {ecu_extract: [], odx_d: [], candela: []};
  testGroups: string[] = [];
  networkNames: string[] = [];
  starcDomains: StarcDomain[] = Object.values(StarcDomain);

  selectedOdxdFile: string = '';
  selectedCandelaFile: string = '';
  selectedEcuExtractFile: string = '';

  showNetworkConfiguration: boolean = false;
  showOdxdConfiguration: boolean = false;
  showCandelaConfiguration: boolean = false;
  showEcuExtractConfiguration: boolean = false;

  protected readonly Format = Format;
  protected readonly FormGroup = FormGroup;
  protected readonly ValidationStatus = ValidationStatus;

  constructor(
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService,
    public vTestingJobService: VirtualRemoteTestBenchVtestingJobService,
    public validationService: ValidationService
  ) {
  }

  ngOnInit() {
    this.validationService.addExtraFields(FormGroup);
    this.initActionTransmitter();
    this.resetFiles();
    if (this.vTestingJobService.simulationBundles[this.id].package.id) {
      this.getEcuNetworks()
    }
    if (this.vTestingJobService.vtestingJobDefinitions[this.index].test_object.package_release_id) {
      this.getFiles(this.vTestingJobService.vtestingJobDefinitions[this.index].test_object.package_release_id!);
    }
    if (this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files!.odx_d)
      this.selectedOdxdFile = this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files!.odx_d!;
    if (this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files!.candela)
      this.selectedCandelaFile = this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files!.candela!;
    if (this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files!.ecu_extract)
      this.selectedEcuExtractFile = this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files!.ecu_extract!;

    this.getSpecificTestType()
  }

  initActionTransmitter() {
    this.subscription$ = this.actionTransmitter.subscribe(data => {
      if (data.action == Actions.TestTypeChange) {
        this.getSpecificTestType();
      }
      if (data.action == Actions.PackageChange) {
        this.getEcuNetworks();
        this.resetFiles();
      }
      if (data.action == Actions.ReleaseChange) {
        this.getFiles(data.option);
      }
    });
  }

  ngOnDestroy() {
    // this.validationService.removeFields(FormGroup); bug fix for vTpl-11
    this.subscription$.complete();
  }

  getSpecificTestType(): void {
    this.testGroups = [];
    this.vTestingJobService.simulationBundles[this.id].test_environment.configuration!.test_groups! = [];
    this.resetTestType();
    if (!this.vTestingJobService.simulationBundles[this.id].test_environment.test_type) {
      return;
    }
    this.capabilitiesService.getSpecificTestType(
      this.vTestingJobService.getBaseData().basic_configuration?.model_series!,
      this.vTestingJobService.simulationBundles[this.id].test_environment.test_type!
    ).subscribe({
      next: (response) => {
        this.showNetworkConfiguration = !!response.data.configurations.find((config: any) => config.type === 'network');
        this.showOdxdConfiguration = !!response.data.files.find((config: any) => config.file_type === 'odx-d');
        this.showCandelaConfiguration = !!response.data.files.find((config: any) => config.file_type === 'candela');
        this.showEcuExtractConfiguration = !!response.data.files.find((config: any) => config.file_type === 'ecu-extract');
        this.testGroups = response.data.configurations.find((config: any) => config.type === 'test_groups')?.value || [];

        this.validationService.setInputFieldTouched(FormGroup.Network, this.vTestingJobService.getToggleStatus() || !this.showNetworkConfiguration);
        this.validationService.setInputFieldTouched(FormGroup.Odxd, this.vTestingJobService.getToggleStatus() || !this.showOdxdConfiguration);
        this.validationService.setInputFieldTouched(FormGroup.Candela, this.vTestingJobService.getToggleStatus() || !this.showCandelaConfiguration);
        this.validationService.setInputFieldTouched(FormGroup.EcuExtract, this.vTestingJobService.getToggleStatus() || !this.showEcuExtractConfiguration);

        if (!this.showNetworkConfiguration)
          this.vTestingJobService.simulationBundles[this.id].test_environment.configuration!.networks![0].name = '';
        if (!this.showOdxdConfiguration)
          this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files!.odx_d = '';
        if (!this.showCandelaConfiguration)
          this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files!.candela = '';
        if (!this.showEcuExtractConfiguration)
          this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files!.ecu_extract = '';
        this.vTestingJobService.skeleton[2] = false;
        if (!this.vTestingJobService.simulationBundles[this.id].test_environment.configuration!.test_groups!.length) {
          this.vTestingJobService.simulationBundles[this.id].test_environment.configuration!.test_groups! = this.testGroups;
        }
      },
      error: (error) => this.notificationService.handleError(error),
    });
  }

  getEcuNetworks(): void {
    this.networkNames = [];
    this.showNetworkConfiguration = true;
    this.validationService.setInputFieldLoading(FormGroup.Network, true);

    this.capabilitiesService.getPackageOfModelSeries(
      this.vTestingJobService.getBaseData().basic_configuration?.model_series!,
      this.vTestingJobService.simulationBundles[this.id].package.id
    ).subscribe({
      next: (response) => {
        this.capabilitiesService.getEcuOfTopologyInModelSeries(
          this.vTestingJobService.getBaseData().basic_configuration?.model_series!,
          response.data.application_instances.diagnostic_name,
          true
        ).subscribe({
          next: (response) => response.data.networks.forEach((networks: any) => this.networkNames.push(networks.name)),
          complete: () => this.validationService.setInputFieldLoading(FormGroup.Network, false),
          error: (error) => this.notificationService.handleError(error),
        });
      },
      error: (error) => this.notificationService.handleError(error)
    });
  }

  getFiles(releaseId: string): void {
    [FormGroup.Odxd, FormGroup.Candela, FormGroup.EcuExtract]
      .forEach((fileType) => this.validationService.setInputFieldLoading(fileType, true));
    this.capabilitiesService.getSpecificPackageRelease(releaseId).subscribe({
      next: (response) => {
        this.files = {ecu_extract: [], odx_d: [], candela: []};
        response.data.application_instances.manifests.forEach((manifest: any) =>
          manifest.file_type === 'ecu-extract' ? this.files.ecu_extract.push(manifest.file_name) :
            manifest.file_type === 'odx-d' ? this.files.odx_d?.push(manifest.file_name) : this.files.candela?.push(manifest.file_name)
        );
      },
      complete: () => {
        [FormGroup.Odxd, FormGroup.Candela, FormGroup.EcuExtract]
          .forEach((fileType) => this.validationService.setInputFieldLoading(fileType, false));
      },
      error: (error) => this.notificationService.handleError(error),
    });
  }

  resetTestType(): void {
    this.showNetworkConfiguration = false;
    this.showOdxdConfiguration = false;
    this.showCandelaConfiguration = false;
    this.showEcuExtractConfiguration = false;
  }

  resetFiles(): void {
    this.files = {ecu_extract: [], odx_d: [], candela: []};
    this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files = {
      ecu_extract: undefined,
      odx_d: undefined,
      candela: undefined,
      ncd: undefined
    };
  }

  ecuExtractChange(event: any): void {
    this.selectedEcuExtractFile = event.detail.value ? typeof event.detail.value == 'string' ? event.detail.value : event.detail.value.value : '';
    this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files!.ecu_extract = this.selectedEcuExtractFile
  }

  candelaChange(event: any): void {
    this.selectedCandelaFile = event.detail.value ? typeof event.detail.value == 'string' ? event.detail.value : event.detail.value.value : '';
    this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files!.candela = this.selectedCandelaFile
  }

  odxdChange(event: any): void {
    this.selectedOdxdFile = event.detail.value ? typeof event.detail.value == 'string' ? event.detail.value : event.detail.value.value : '';
    this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.files!.odx_d = this.selectedOdxdFile
  }

  testGroupChange(event: any): void {
    if (event && event.detail.value) {
      this.vTestingJobService.simulationBundles[this.id].test_environment.configuration!.test_groups = [];
      event.detail.value.forEach((group: any) =>
        this.vTestingJobService.simulationBundles[this.id].test_environment.configuration!.test_groups!.push(group.value));
    }
  }

  networkChange(event: any): void {
    this.vTestingJobService.simulationBundles[this.id].test_environment.configuration!.networks![0].name =
      event.detail.value ? typeof event.detail.value == 'string' ? event.detail.value : event.detail.value.value : '';
  }

  onStarcIdInputChange(event: any): void {
    this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration!.starc_testset_id = ''
    event.detail.value;
  }

  domainChange(event: any): void {
    this.vTestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration!.starc_domain = event
      .detail.value
      ? event.target.value
      : undefined;
  }
}
