<ag-grid-angular
  class="ag-theme-wb spacing-top-xs"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [loadingOverlayComponent]="loadingOverlayComponent"
  [loadingOverlayComponentParams]="loadingOverlayComponentParams"
  [masterDetail]="true"
  [detailCellRendererParams]="detailCellRendererParams"
  (firstDataRendered)="onFirstDataRendered($event)"
  (gridReady)="onGridReady($event)"
  [enableCellChangeFlash]="true"
  [isRowMaster]="isRowMaster"
  [getRowHeight]="getRowHeight"
  domLayout="autoHeight"
  [pagination]="true"
  [paginationPageSize]="10"
  unSortIcon
  suppressRowClickSelection
  suppressPaginationPanel
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"

>
</ag-grid-angular>
<wb-pagination
  *ngIf="getTotalPages() > 1"
  [currentPage]="currentPage.value"
  [pages]="getTotalPages()"
  (wbchange)="setGridPage($event)"
></wb-pagination>
