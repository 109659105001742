import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {CellClickedEvent, ColDef, ICellRendererParams, ValueGetterParams,} from 'ag-grid-community';
import {forkJoin, Subscription} from 'rxjs';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {HeaderTitleService} from 'src/app/services/header/header-title.service';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {SimulationBuilderService} from 'src/app/simulation-builder/services/simulation-builder.service';
import * as moment from 'moment';

@Component({
  selector: 'app-co-simulation-bundle-detail',
  templateUrl: './co-simulation-bundle-detail.component.html',
  styleUrl: './co-simulation-bundle-detail.component.css',
})
export class CoSimulationBundleDetailComponent implements OnDestroy {
  CoSimId: string | null = '';
  title: any;
  description: string = '';
  primaryButtonText: string = '';

  rowData?: any[] = [];
  loadData: boolean = true;

  constructor(
    private headerService: HeaderTitleService,
    public simulationBuilderService: SimulationBuilderService,
    private capabilitiesService: CapabilitiesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbsService,
    private notificationService: NotificationService
  ) {
    this.routerSubscription$ = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  selectedTabId!: string;
  subscription!: Subscription;
  routerSubscription$!: Subscription;

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    suppressMovable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
  };

  public columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 3,
      cellRenderer: (params: ICellRendererParams) => {
        return `<wb-link variant="standalone" (click)="">${params.value}</wb-link>`;
      },
      onCellClicked: (event: CellClickedEvent) => this.onCellClicked(event),
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      flex: 2,
      valueGetter: this.ValueGetter,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
    },
    {
      field: 'model_series',
      headerName: 'Model series',
      flex: 2,
    },
    {
      field: 'release_information.count',
      headerName: 'Number of releases',
      flex: 2,
      comparator: (valueA, valueB) => valueA - valueB,
    },
    {
      field: 'release_information.latest.name',
      headerName: 'Newest release',
      flex: 2,
    },
  ];

  onCellClicked(event: CellClickedEvent): void {
    this.simulationBuilderService.simulationId = '';
    this.simulationBuilderService.simulationOverview = undefined;
    this.simulationBuilderService.simulationId = event.data.id;
    this.simulationBuilderService.simulationOverview = event.data;
    this.showSimulation(
      this.simulationBuilderService.simulationId!,
      event.data.type
    );
  }

  showSimulation(id: string, type: string): void {
    this.router.navigate([`simulation/${id}/overview`]);
  }

  ValueGetter(params: ValueGetterParams) {
    return moment(params.data.created_at)
      .utc(true)
      .format('DD-MM-YYYY HH:mm:ss');
  }

  ngOnDestroy() {
    this.breadcrumbService.breadcrumbs.pop();
    this.headerService.setTabs([]);
    this.subscription.unsubscribe();
    this.routerSubscription$.unsubscribe();
  }

  init() {
    this.headerService.setTabs([
      {label: 'Information', id: 'info'},
      {label: 'Permissions', id: 'permissions'},
    ]);
    if (this.activatedRoute.snapshot.queryParamMap.get('tab')) {
      this.headerService.setSelectedTab({
        label: '',
        id: this.activatedRoute.snapshot.queryParamMap.get('tab')!,
      });
    }
    this.subscription = this.headerService.selectedTab$.subscribe(
      (tab) => (this.selectedTabId = tab)
    );
    this.CoSimId = this.activatedRoute.snapshot.paramMap.get('id');
    this.capabilitiesService
      .getCoSimulationBundle(this.activatedRoute.snapshot.paramMap.get('id')!)
      .subscribe({
        next: (response: any) => {
          this.simulationBuilderService.simulationOverview = response.data;
          // this.simulationBuilderService.modelSeries = response.data.model_series[0].name;
        },
        complete: () => {
          this.prepareOverview();
        },
        error: (error: any) => {
          this.notificationService.handleError(error);
          this.router.navigate(['/simulations']);
        },
      });
  }

  prepareOverview(): void {
    let observables: any[] = [];

    this.simulationBuilderService.simulationOverview.simulation_bundle_ids.forEach(
      (simBunId: string) => {
        observables.push(
          this.capabilitiesService.getSimulationBundleById(simBunId)
        );
      }
    );

    forkJoin(observables).subscribe({
      next: (responses) => {
        responses.forEach((response) => {
          this.rowData?.push(response.data);
        });
      },
      complete: () => {
        this.loadData = false;
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
    this.title = this.simulationBuilderService.simulationOverview.name!;
    this.description =
      'Co-simulation | ' +
      this.simulationBuilderService.simulationOverview?.package?.model_series[0]
        .name;

    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    this.headerService.primaryButtonText = this.primaryButtonText;
    this.headerService.clearSecondaryClickFunction();
    this.headerService.secondaryClick$.subscribe(() =>
      this.router.navigate(['/simulations'])
    );
    const breadcrumb = {
      label: this.simulationBuilderService.simulationOverview.name!,
      route:
        '/co-simulation/' +
        this.simulationBuilderService.simulationOverview.id +
        '/overview',
    };
    if (this.breadcrumbService.breadcrumbs.length == 0) {
      this.breadcrumbService.breadcrumbs.push({
        label: 'Simulations',
        route: '/simulations',
      });
    }
    if (this.breadcrumbService.findIndexByLabel(breadcrumb.label) > -1) {
      this.breadcrumbService.removeAboveIndex(
        this.breadcrumbService.findIndexByLabel(breadcrumb.label)
      );
    } else {
      this.breadcrumbService.breadcrumbs.push(breadcrumb);
    }

    let tab = this.activatedRoute.snapshot.queryParamMap.get('tab');

    if (tab) {
      this.breadcrumbService.breadcrumbs.push({
        label: (tab == 'permissions' ? 'Permissions' : 'Information'),
        route: ''
      });
    } else {
      this.breadcrumbService.breadcrumbs.push({
        label: 'Information',
        route: ''
      });
    }
  }
}
