import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notify: boolean = false;
  notifyWarning: boolean = false;
  notifySuccess: boolean = false;
  notifyTestDefWarning: boolean = false;
  notificationMessage: string = '';
  notificationWarningMessage: string[] = [];
  notificationSuccesMessage: string[] = [];

  constructor() {}

  handleError(err: any): void {
    const traceId = err.headers.getAll('x-amzn-trace-id')[0].split('=')[1];
    const message1 = err.error.errors ? err.error.errors.join('.') : '';
    const message2 = err.error.message ? err.error.message : '';
    const message3 = err.error.Message ? err.error.Message : '';
    let message = `${message3}. ${message2}. ${message1}`
    if(message.length > 100){
      message = message.substring(0,100) + '...'
    }
    this.notify = true;
    this.notificationMessage = `Error: ${message}. You can provide this trace ID in your support ticket: ${traceId}. To contact support use: vTesting@mercedes-benz.com`;
    /*     this.notificationWarningMessage = `${warningmessage}`; */
  }
}
