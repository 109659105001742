<wb-grid [fullWidth]="true">
  <wb-grid-row style="padding-top: 24px">
    <wb-grid-col mq6="5">
      <wb-heading size="xs" class="spacing-xs">Enter base data</wb-heading>
    </wb-grid-col>
    <wb-grid-col mq6="5">
      <wb-heading size="xs" class="spacing-bot-24">
        Continuous testing
      </wb-heading>
    </wb-grid-col>
    <wb-grid-col mq6="5">
      <wb-input
        as="input"
        [(ngModel)]="vTestingJobService.base_data.name"
        name="name"
        ngDefaultControl
        inputmode="text"
        class="spacing-xs"
        required
        #name="ngModel"
        [error]="
          validationService.isFieldInvalid(
            FormGroup.Name,
            name,
            vTestingJobService.base_data.name,
            Format.Name
          )
        "
      >
        <span slot="label">vTesting name*</span>
        <div slot="message">
          <app-validation-notification
            name="Job name"
            [type]="Format.None"
            *ngIf="
              validationService.getFormStatus(
                FormGroup.Name,
                ValidationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                FormGroup.Name,
                ValidationStatus.Touched
              )
            "
          >
          </app-validation-notification>
          <app-validation-notification
            name="Job name"
            [type]="Format.Name"
            *ngIf="
              validationService.getFormStatus(
                FormGroup.Name,
                ValidationStatus.Length
              ) &&
              validationService.getFormStatus(
                FormGroup.Name,
                ValidationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-input>
    </wb-grid-col>
    <wb-grid-col mq6="5">
      <wb-toggle
        name="ct-mode"
        [checked]="vTestingJobService.ToggleStatus"
        (wbchange)="showToolTip($event)"
        style="margin-top: 15px"
      >
        <wb-text size="m">
          {{ controlHint }}
        </wb-text>
      </wb-toggle>
    </wb-grid-col>
    <wb-grid-col mq6="5">
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="
            vTestingJobService.base_data.basic_configuration.model_series
          "
          [disabled]="editMode"
          name="modelSeries"
          ngDefaultControl
          required
          #modelSeries="ngModel"
          [error]="
            validationService.isComboBoxInValid(
              FormGroup.ModelSeries,
              modelSeries,
              vTestingJobService.base_data.basic_configuration.model_series,
              getModelSeriesOptions()
            )
          "
          (wbcomboboxinput)="modelSeriesChange($event)"
        >
          <label slot="label">Model series*</label>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No model series selected"
          no-items-found-text="No model series found"
          (wbcomboboxlistitemselectionchange)="modelSeriesChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let line of vehicleLines?.items"
            [value]="line.name"
          >
            {{ line.name }}
          </wb-combobox-list-item>
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="model series"
            [type]="Format.Pick"
            *ngIf="
              validationService.getFormStatus(
                FormGroup.ModelSeries,
                ValidationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                FormGroup.ModelSeries,
                ValidationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
    </wb-grid-col>
    <wb-grid-col mq6="5">
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="releaseCondition"
          [disabled]="!vTestingJobService.getToggleStatus()"
          name="type"
          ngDefaultControl
          required
          #ReleaseCondition="ngModel"
          [error]="
          this.vTestingJobService.getToggleStatus() ?
            validationService.isComboBoxInValid(
              FormGroup.ReleaseCondition,
              ReleaseCondition,
              releaseCondition,
              releaseConditions
            ) && vTestingJobService.getToggleStatus() : false
          "
          (wbcomboboxinput)="releaseConditionChange($event)"
        >
          <label slot="label">Release condition*</label>
        </wb-combobox-input>
        <wb-combobox-list
          (wbcomboboxlistitemselectionchange)="releaseConditionChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let releaseCondition of releaseConditions"
            [value]="releaseCondition"
          >
            {{ releaseCondition }}
          </wb-combobox-list-item>
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="release condition"
            [type]="Format.Pick"
            *ngIf="
              validationService.getFormStatus(
                FormGroup.ReleaseCondition,
                ValidationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                FormGroup.ReleaseCondition,
                ValidationStatus.Touched
              ) &&
              vTestingJobService.getToggleStatus()
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
    </wb-grid-col>
    <wb-grid-col mq6="5">
      <wb-input
        as="input"
        class="spacing-xs"
        [(ngModel)]="vTestingJobService.base_data.further_notes"
        inputmode="text"
        name="furtherNotes"
        ngDefaultControl
      >
        <span slot="label">Further notes</span>
      </wb-input>
    </wb-grid-col>
    <wb-grid-col mq6="5">
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="releaseConditionOperator"
          [disabled]="!vTestingJobService.getToggleStatus()"
          name="operator"
          ngDefaultControl
          required
          #ReleaseOperator="ngModel"
          [error]="
            this.vTestingJobService.getToggleStatus() ?
            validationService.isComboBoxInValid(
              FormGroup.ReleaseOperator,
              ReleaseOperator,
              releaseConditionOperator,
              releaseConditionOperators
            ) && vTestingJobService.getToggleStatus() : false
          "
          (wbcomboboxinput)="releaseOperatorChange($event)"
        >
          <label slot="label">Release condition operator*</label>
        </wb-combobox-input>
        <wb-combobox-list
          (wbcomboboxlistitemselectionchange)="releaseOperatorChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let releaseConditionOperator of releaseConditionOperators"
            [value]="releaseConditionOperator"
          >
            {{ releaseConditionOperator }}
          </wb-combobox-list-item>
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="release operator"
            [type]="Format.Pick"
            *ngIf="
              validationService.getFormStatus(
                FormGroup.ReleaseOperator,
                ValidationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                FormGroup.ReleaseOperator,
                ValidationStatus.Touched
              ) &&
              vTestingJobService.getToggleStatus()
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
