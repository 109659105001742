import { environment } from './src/environments/environment';

export const authConfig = {
  loginUrl: 'https://ssoalpha.dvb.corpinter.net/v1/auth',
  clientId: environment.authConfig.clientId,
  redirectUri: environment.authConfig.redirectUri,
  issuer: 'https://ssoalpha.dvb.corpinter.net/v1',
  requestAccessToken: true,
  tokenEndpoint: 'https://ssoalpha.dvb.corpinter.net/v1/token',
  scope: `groups openid profile email audience:server:client_id:${environment.authConfig.vtpcapClientId} audience:server:client_id:${environment.authConfig.vtpcfgClientId}`,
  requireHttps: false,
};
