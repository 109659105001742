import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,} from '@angular/core';
import {GetVTestingJobRunDto} from '@vtp/vtpcfg-client-ts';
import {
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  IDetailCellRendererParams,
  IsRowMaster,
  RowHeightParams,
} from 'ag-grid-community';
import {
  CustomLoadingOverlayComponent
} from 'src/app/components/custom-grids/custom-loading-overlay/custom-loading-overlay.component';
import {GetVtestingJobRunJob} from '../../../test-job/interfaces/VtestingJobRun/getVtestingJobRunJob';
import {ActivatedRoute} from '@angular/router';
import {ConfigurationService} from '../../../services/configuration/configuration.service';
import {NotificationService} from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-custom-nested-grid',
  templateUrl: './custom-nested-grid.component.html',
  styleUrl: './custom-nested-grid.component.css',
})
export class CustomNestedGridComponent implements OnChanges {
  @Output() GridReady = new EventEmitter<any>();
  @Input() rowData?: any[];
  @Input() columnDefs?: ColDef[];
  @Input() defaultColDef?: ColDef;
  @Input() detailCellRendererParams?: IDetailCellRendererParams<
    GetVTestingJobRunDto,
    GetVtestingJobRunJob
  >;
  testjobId: any;

  //expanden nde
  constructor(
    private activedRoute: ActivatedRoute,
    private configurationService: ConfigurationService,
    private notificationService: NotificationService
  ) {
  }

  public gridApi?: GridApi;
  currentPage: { value: number } = {value: 1};
  pageSize: { value: number } = {value: 10};
  loadingOverlayComponent: any = CustomLoadingOverlayComponent;
  loadingOverlayComponentParams: Object = {
    loadingMessage: 'Loading...',
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.setRowData();
    }
  }

  setRowData() {
    if (this.rowData && this.gridApi) {
      this.gridApi.setRowData(this.rowData as any);
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.GridReady.emit(this.gridApi);
    this.checkGridApi();
  }

  checkGridApi(): void {
    if (!this.gridApi) {
      return;
    }
  }

  public overlayNoRowsTemplate = '<wb-text> Nothing to show</wb-text>';

  setGridPage(event: Event): void {
    const customEvent = event as CustomEvent;
    this.gridApi?.paginationGoToPage(customEvent.detail - 1);
    this.currentPage.value = customEvent.detail;
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    // arbitrarily expand a row for presentational purposes
    setTimeout(() => {
      let runningNode = false;

      params.api.forEachNode((node) => {
        if (node.data.status == "RUNNING") {
          node.setExpanded(true);
          runningNode = true;
        }
      });
      !runningNode && params.api.getDisplayedRowAtIndex(0)!.setExpanded(true);
    }, 0);
  }

  isRowMaster: IsRowMaster = (dataItem: any) => {
    return dataItem ? dataItem.test_definition_runs.length > 0 : false;
  };

  getRowHeight: (params: RowHeightParams) => number | undefined | null = (
    params: RowHeightParams
  ) => {
    if (params.node && params.node.detail) {
      const offset = 35;
      const sizes = params.api.getSizesForCurrentTheme() || {};
      const allDetailRowHeight =
        params.data.test_definition_runs.length * sizes.rowHeight;

      return allDetailRowHeight + (sizes.headerHeight || 0) + offset;
    } else return 50;
  };

  getTotalPages(): number {
    return Math.ceil((this.rowData?.length || 0) / this.pageSize.value);
  }
}
