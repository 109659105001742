<div *ngIf="simulationBuilderService.refinementSpinner">
  <wb-spinner class="centered-element" theme="light" style="width: 128px; height: 128px"></wb-spinner>
</div>
<wb-grid [fullWidth]="true" *ngIf="!simulationBuilderService.refinementSpinner">
  <wb-grid-row class="spacing-top-s">
    <wb-grid-col mq6="12">
      <app-custom-grid
        [rowData]="simulationBuilderService.simulatedEcus"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
      >
      </app-custom-grid>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
