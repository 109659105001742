<wb-tab-content
  name="info"
  slot="content"
  [selected]="selectedTabId == 'info'"
  style="padding: 0"
>
  <wb-grid [fullWidth]="true">
    <wb-grid-row>
      <wb-grid-col mq6="12">
        <wb-heading class="spacing-xs" size="s" tag="div">
          Co-simulation information
        </wb-heading>
      </wb-grid-col>

      <wb-grid-col mq6="3">
        <wb-text class="spacing-bot-16" size="m" tag="div"> Name:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> ID:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> Type:</wb-text>
        <wb-text
          class="spacing-bot-16"
          size="m"
          tag="div"
          *ngIf="
            this.simulationBuilderService.simulationOverview
              ?.simulation_bundle_ids?.length
          "
        >
          Contained simulations:
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          Created by:</wb-text
        >
        <wb-text class="spacing-bot-16" size="m" tag="div">
          Created at:</wb-text
        >
        <wb-text size="m" tag="div"> Package:</wb-text>
      </wb-grid-col>
      <wb-grid-col mq6="3">
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.simulationBuilderService.simulationOverview?.name }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.simulationBuilderService.simulationOverview?.id }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          Co-Simulation
        </wb-text>
        <wb-text
          class="spacing-bot-16"
          size="m"
          tag="div"
          *ngIf="
            this.simulationBuilderService.simulationOverview
              ?.simulation_bundle_ids?.length
          "
        >
          {{
            this.simulationBuilderService.simulationOverview
              ?.simulation_bundle_ids?.length
          }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.simulationBuilderService.simulationOverview?.created_by }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.simulationBuilderService.simulationOverview?.created_at }}
        </wb-text>
        <wb-text class="spacing-xs" size="m" tag="div">
          {{
            this.simulationBuilderService.simulationOverview?.package?.name
              ? this.simulationBuilderService.simulationOverview?.package?.name
              : "-"
          }}
        </wb-text>
      </wb-grid-col>
    </wb-grid-row>
    <wb-grid-row>
      <wb-grid-col mq6="6">
        <wb-heading class="spacing-xs" size="s" tag="div">
          Contained simulations
        </wb-heading>
      </wb-grid-col>
      <wb-grid-col mq6="12">
        <app-custom-grid
          *ngIf="!loadData"
          [rowData]="rowData"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
        ></app-custom-grid>
      </wb-grid-col>
    </wb-grid-row>
  </wb-grid>
</wb-tab-content>

<wb-tab-content
  name="permissions"
  slot="content"
  [selected]="selectedTabId == 'permissions'"
  style="padding: 0"
>
  <mbos-permissions
    domain="vtesting-simulation-bundle"
    [resourceId]="this.CoSimId"
  ></mbos-permissions>
</wb-tab-content>
