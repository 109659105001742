import {Component, OnInit} from '@angular/core';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {ModelSeriesListGet} from '@vtp/vtpcap-client-ts';
import {Format, ValidationService, ValidationStatus,} from '../../../../services/validation/validation.service';
import {SimulationBuilderService} from 'src/app/simulation-builder/services/simulation-builder.service';

enum FormGroup {
  name = 'name',
  modelSeries = 'modelSeries',
}

@Component({
  selector: 'app-simulation-builder-flow-base-data',
  templateUrl: './simulation-builder-flow-base-data.component.html',
  styleUrl: './simulation-builder-flow-base-data.component.css',
})
export class SimulationBuilderFlowBaseDataComponent implements OnInit {
  RadioBtnCheckedStatus: boolean = true;
  vehicleLines?: ModelSeriesListGet;
  controlHint: string = 'Continuous testing activated';
  showToggleStatus: boolean = false;
  editMode: boolean = false;
  formGroup = FormGroup;
  format = Format;
  validationStatus = ValidationStatus;

  constructor(
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService,
    public simulationbuilderService: SimulationBuilderService,
    public validationService: ValidationService
  ) {
  }

  cards = [
    {title: 'Card 1', content: 'Card 1 content'},
    {title: 'Card 2', content: 'Card 2 content'},
    {title: 'Card 3', content: 'Card 3 content'},
    {title: 'Card 4', content: 'Card 4 content'},
  ];


  ngOnInit() {
    this.validationService.initialize(FormGroup);
    /*     this.editMode = this.vTestingJobService.getEditMode(); */
    if (this.editMode) {
      this.loadEditData();
    }
    this.capabilitiesService.getModelSeries().subscribe({
      next: (response) => (this.vehicleLines = response.data),
      error: (error) => this.notificationService.handleError(error),
    });
  }

  loadEditData(): void {
    /*  this.capabilitiesService
      .getvTestingJob(this.vTestingJobService.getVtestingJobId())
      .subscribe({
        next: (element) => (this.vTestingJobService.base_data = element.data),
        error: (error) => this.notificationService.handleError(error),
      }); */
  }

  getModelSeriesOptions(): string[] {
    return this.vehicleLines?.items?.map((model) => model.name!) ?? [];
  }

  modelSeriesChange(event: any): void {
    this.simulationbuilderService.baseData.model_series = event.detail.value
      ? event.target.value
      : '';
  }
}
