<wb-tooltip position="top" position="end" *ngIf="showbtnstaus" open="false">
  <wb-round-button size="m">
    <wb-icon name="bds/info-circle/16"></wb-icon>
  </wb-round-button>

  <div slot="content" class="wb-tooltip-overlay">
    <wb-list-group hide-border>
      <wb-list-group-item>
        <wb-icon slot="icon"></wb-icon>
        <wb-text strong="true">total: {{ this.testresult.total }}</wb-text>
      </wb-list-group-item>
      <wb-list-group-item>
        <wb-icon
          slot="icon"
          name="bds/checkmark-circle/24"
          style="color: green"
        ></wb-icon>
        passed: {{ this.testresult.passed }}
      </wb-list-group-item>
      <wb-list-group-item>
        <wb-icon slot="icon" name="bds/close/24" style="color: red"></wb-icon>
        failed: {{ this.testresult.failed }}
      </wb-list-group-item>

      <wb-list-group-item>
        <wb-icon
          slot="icon"
          name="bds/exclamation-mark-circle-outline/24"
          style="color: red"
        ></wb-icon>
        execution error: {{ this.testresult.execution_error }}
      </wb-list-group-item>
      <wb-list-group-item>
        <wb-icon
          slot="icon"
          name="zoom-out-xs"
          style="color: rgb(209, 170, 0)"
        ></wb-icon>
        inconclusive: {{ this.testresult.inconclusive }}
      </wb-list-group-item>
    </wb-list-group>
  </div>
</wb-tooltip>
<ng-container *ngIf="showbtnstaus == false"> No test results</ng-container>
