<wb-grid [fullWidth]="true" class="sub-grid" *ngIf="this.simulationBuilderService.baseData.model_series?.length">
  <wb-grid-row>
    <ng-template #templatePackage>
      <wb-grid-col mq6="8">
        <wb-heading class="spacing-xs spacing-top-xs" size="xs" tag="div">
          Event chain
        </wb-heading>
        <wb-grid-row>
          <wb-grid-col mq6="5">
            <wb-input
              as="input"
              name="name"
              inputmode="text"
              class="spacing-xs"
              (wbinput)="filterInputChange($event)"
            >
              <span slot="label">Search</span>
            </wb-input>
          </wb-grid-col>
          <wb-grid-col mq6="12" mq2="12">
            <app-custom-tree-selection-grid
              *ngIf="simulationBuilderService.simulationMode"
              [autoGroupColumnDef]="autoGroupColumnDef"
              [columnDefs]="columnDefs"
              [defaultColDef]="defaultColDef"
              [getDataPath]="getDataPath"
              [rowData]="filteredRowData"
              (selectedRowData)="rowSelectedChange($event)"
              [mode]="'simulation-scope'"
            >
            </app-custom-tree-selection-grid>
          </wb-grid-col>
        </wb-grid-row>
      </wb-grid-col>
    </ng-template>

    <ng-template #templateEvent>
      <wb-grid-col mq6="4">
        <wb-heading class="spacing-xs spacing-top-xs" size="xs" tag="div">
          Simulation scope
        </wb-heading>
        <wb-combobox class="spacing-xs">
          <wb-combobox-input
            slot="input"
            name="ecu"
            ngDefaultControl
            [(ngModel)]="vEcuNameString"
            #vPackage="ngModel"
            [disabled]="this.validationService.getFormStatus(formGroup.package, validationStatus.Loading)"
            [error]="
                  validationService.isComboBoxInValid(
                    formGroup.package,
                    vPackage,
                    vEcuNameString,
                    getVEcusAsStringArray()
                  )
                "
            (wbcomboboxinput)="vECUPackageChange($event)"
          >
            <label slot="label">vECU Package*</label>
            <wb-input-action slot="actions"
                             *ngIf="this.validationService.getFormStatus(formGroup.package, validationStatus.Loading)">
              <wb-spinner id="loading-spinner"></wb-spinner>
            </wb-input-action>
          </wb-combobox-input>
          <wb-combobox-list
            no-items-selected-text="No vPackage selected"
            no-items-found-text="No vPackage found"
            (wbcomboboxlistselectionchange)="vECUPackageChange($event)"
          >
            <wb-combobox-list-item
              *ngFor="let vEcu of vEcus"
              [value]="vEcu"
            >
              {{ vEcu.name }}
            </wb-combobox-list-item>
          </wb-combobox-list>

          <div slot="message">
            <app-validation-notification
              name="vECU Package"
              [type]="format.Pick"
              *ngIf="
                    validationService.getFormStatus(
                      this.formGroup.package,
                      validationStatus.Invalid
                    ) &&
                    validationService.getFormStatus(
                      this.formGroup.package,
                      validationStatus.Touched
                    )
                  "
            >
            </app-validation-notification>
          </div>
        </wb-combobox>
        <wb-heading class="spacing-xs spacing-top-xs" size="xs" tag="div">
          Package info
        </wb-heading>
        <wb-grid-row>
          <wb-grid-col mq6="4">
            <wb-text class="spacing-top-xxs" size="m" tag="div">Package name:</wb-text>
            <wb-text class="spacing-top-xxs" size="m" tag="div">Package ID:</wb-text>
            <wb-text class="spacing-top-xxs" size="m" tag="div">Included ECUs:</wb-text>
          </wb-grid-col>
          <wb-grid-col mq6="8">
            <wb-text class="spacing-top-xxs" size="m" tag="div">
              {{ this.simulationBuilderService.selectedPackage.name }}
            </wb-text>
            <wb-text class="spacing-top-xxs" size="m" tag="div">
              {{ this.simulationBuilderService.baseData.package.id }}
            </wb-text>
            <wb-text class="spacing-top-xxs" size="m" tag="div">
              {{ this.simulationBuilderService.selectedPackage.includedEcus.join(", ") }}
            </wb-text>
          </wb-grid-col>
        </wb-grid-row>
      </wb-grid-col>
    </ng-template>

    <ng-container *ngIf="simulationBuilderService.simulationMode == 'event'; else template2">
      <ng-container *ngTemplateOutlet="templatePackage"></ng-container>
      <ng-container *ngTemplateOutlet="templateEvent"></ng-container>
    </ng-container>

    <ng-template #template2>
      <ng-container *ngTemplateOutlet="templateEvent"></ng-container>
      <ng-container *ngTemplateOutlet="templatePackage"></ng-container>
    </ng-template>
  </wb-grid-row>
</wb-grid>
