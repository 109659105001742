import { Component, OnInit } from '@angular/core';
import { CapabilitiesService } from 'src/app/services/capabilities/capabilities.service';
import { VpackageService } from 'src/app/package/services/vpackage.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { HeaderTitleService } from 'src/app/services/header/header-title.service';
import { NgModel } from '@angular/forms';
import { Format } from '../../../../services/validation/validation.service';
import {
  ColDef,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { PacmanService } from 'src/app/services/pacman/pacman.service';

@Component({
  selector: 'app-packagecontent',
  templateUrl: './packagecontent.component.html',
  styleUrls: ['./packagecontent.component.css'],
})
export class PackagecontentComponent implements OnInit {
  constructor(
    public vPackageService: VpackageService,
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService,
    public headerService: HeaderTitleService,
    private router: Router,
    private pacmanService: PacmanService
  ) {}

  diagnosticNames: string[] = [];
  
  packageLinkSpinner: boolean = false;

  ngOnInit() {
    if (this.vPackageService.modelSeries[0] == '') {
      return this.router.navigate(['/vpackages']);
    } else {
      this.headerService.secondaryButtonText = 'Back';
      this.capabilitiesService
        .getAllVPackagesForModelseries(this.vPackageService.modelSeries)
        .subscribe({
          next: (response) => {
            this.vPackageService.vpackages = response.data!.items;
            if (
              this.vPackageService.packageDataPost?.type == 'vCPKG' ||
              this.vPackageService.packageDataPost?.type == 'vGPKG'
            ) {
              this.vPackageService.vpackages =
                this.vPackageService.vpackages?.filter(
                  (vpackage) => vpackage.type != 'vCPKG'
                );
            }
            if (this.vPackageService.packageDataPost?.type == 'vDPKG') {
              this.vPackageService.vpackages =
                this.vPackageService.vpackages?.filter(
                  (vpackage) =>
                    vpackage.type != 'vCPKG' && vpackage.type != 'vDPKG'
                );
            }
          },
          error: (error) => {
            this.notificationService.handleError(error);
          },
        });
        this.packageLinkSpinner = true;
      this.pacmanService.getHardwarePackages().subscribe({
        next: (response) => {
          response.packages.forEach((element: any) => {
            if (element.releaseInformation.count) {
            }
          });
          this.vPackageService.ecuPackages = response.packages;
          this.vPackageService.filteredArray = this.vPackageService.ecuPackages;
        },
        complete: () => {
          this.vPackageService.filterByParams(
            this.vPackageService.packageDataPost!.model_series,
            this.vPackageService.packageDataPost!.type
          );
          this.packageLinkSpinner = false;
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });

      this.headerService.secondaryDisabled = false;
      this.headerService.primaryDisabled = true;
    }
  }

  resolveDiagnosticNames(packages: any[]) {
    packages.map((vPackage: any) =>
      vPackage.packages
        ? this.resolveDiagnosticNames(vPackage.packages)
        : this.diagnosticNames.push(
            vPackage.application_instances.diagnostic_name
          )
    );
  }

  onPackageInputChange(event: any): void {
    this.diagnosticNames = [];
    this.vPackageService.selectedPackageNames = []; // reset the selected package names
    if (event) {
      if (event.detail.value) {
        this.vPackageService.packageDataPost!.packages = new Array<any>();
        event.detail.value.forEach((vpackage: any) => {
          this.vPackageService.packageDataPost!.packages?.push({
            id: vpackage.value.id,
          });
          this.vPackageService.selectedPackageNames.push(vpackage.value.name);
          this.resolveDiagnosticNames(vpackage);
        });
      }
    }
  }

  isPackagesEmpty(packages: NgModel) {
    let error =
      !this.vPackageService.selectedPackageNames.length && packages.touched!;

    this.headerService.primaryDisabled = error;
    if (!packages.touched) this.headerService.primaryDisabled = true;

    return error;
  }

  protected readonly format = Format;

  public columnDefs: ColDef[] = [
    {
      headerName: '',
      filter: false,
      sortable: false,
      checkboxSelection: true,
      width: 50,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
    },
    {
      field: 'model_series',
      headerName: 'Model series',
      cellDataType: 'object',
      valueFormatter: this.modelSeriesFormatter,
      flex: 1,
    },
    {
      field: 'release_information.count',
      headerName: 'Releases',
      comparator: (valueA, valueB) => valueA - valueB,
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      valueGetter: this.ValueGetter,
      flex: 1,
    },
  ];

  ValueGetter(params: ValueGetterParams) {
    return moment(params.data.created_at)
      .utc(true)
      .format('DD-MM-YYYY HH:mm:ss');
  }

  modelSeriesFormatter(params: ValueFormatterParams): string {
    return params.value.map((modelSeries: any) => modelSeries.name).join();
  }

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    autoHeaderHeight: true,
  };

  onEcuLinkInputChange(event: any): void {}

  onEcuLinkSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageService.packageDataPost!.ecu_package_link =
          event.detail.value.value;
      }
    }
  }
}
