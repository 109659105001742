import {Component, OnInit} from '@angular/core';
import {VpackagereleaseService} from 'src/app/package/services/vpackagerelease.service';
import {HeaderTitleService} from 'src/app/services/header/header-title.service';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {Format, ValidationService, ValidationStatus,} from '../../../services/validation/validation.service';
import {ReleaseType} from '@vtp/vtpcap-client-ts';

enum FormGroup {
  semanticVersion = 'semanticVersion',
  ncdRelease = 'ncdRelease',
  ecuExtract = 'ecuExtract',
  odxd = 'odxd',
  candela = 'candela',
}

@Component({
  selector: 'app-vpackage-release',
  templateUrl: './vpackage-release.component.html',
  styleUrl: './vpackage-release.component.css',
})
export class VpackageReleaseComponent implements OnInit {
  title = 'Create new vECU package release';
  description = '';
  VariantInfos?: any;
  ECU_Extracts: string[] = [];
  ECU_Extracts_Response: any[] = [];
  ODX_D_Files: string[] = [];
  CDD_Files: string[] = [];
  modelSeries: any;

  variantInfo: string = '';
  release: string = '';
  releaseOptions: string[] = [
    'release_candidate',
    'beta',
    'release',
  ];
  formGroup = FormGroup;
  format = Format;
  validationStatus = ValidationStatus;

  ncdRelease: string = '';
  ncdReleases: any = [];
  checked: boolean = true;

  constructor(
    public vPackageReleaseService: VpackagereleaseService,
    private breadcrumbService: BreadcrumbsService,
    private headerService: HeaderTitleService,
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService,
    public validationService: ValidationService
  ) {
  }

  spinner: boolean = false;

  ngOnInit() {
    this.initValidation();
    this.capabilitiesService
      .getNcdReleases(this.vPackageReleaseService.getModelSeries())
      .subscribe({
        next: (response) => {
          this.ncdReleases = response.data.items;
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });

    this.capabilitiesService
      .getEcuOfTopologyInModelSeries(
        this.vPackageReleaseService.getModelSeries(),
        this.vPackageReleaseService.getDiagnosticName(),
        true
      )
      .subscribe({
        next: (response) => {
          this.VariantInfos = response.data.variant_info;

          this.ODX_D_Files = response.data.vecu_manifests.odx_d.filter((odxd_file: any) =>
            odxd_file.diagnostic_name && odxd_file.file_name && odxd_file.ncd_name)
            .map((item: any) => item.file_name);
          this.ECU_Extracts_Response = response.data.vecu_manifests.ecu_extract;
          if ( this.ECU_Extracts_Response.length > 0) {
            var manifestNcdRelease:any = [];
            for (let index = 0; index <  this.ECU_Extracts_Response.length; index++) {
              manifestNcdRelease.push( this.ECU_Extracts_Response[index].ncd_release);  
            }         
            this.ncdReleases = this.ncdReleases.filter((rel: { ncd_release: any; }) => manifestNcdRelease.includes(rel.ncd_release) )
          }
          this.CDD_Files = response.data.vecu_manifests.candela.filter((candela: any) =>
            candela.diagnostic_name && candela.file_name && candela.ncd_name)
            .map((item: any) => item.file_name);
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });
    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    const breadcrumb = {
      label: 'Create new release',
      route: '/',
    };
    if (this.breadcrumbService.findIndexByLabel(breadcrumb.label) > -1) {
      this.breadcrumbService.removeAboveIndex(
        this.breadcrumbService.findIndexByLabel(breadcrumb.label)
      );
    } else {
      this.breadcrumbService.breadcrumbs.push(breadcrumb);
    }
    if ( this.vPackageReleaseService.packageReleasePost!.application_instances?.ncd_release.length == 0 ) { 
      this.checked =  true;
    }else{
      this.checked =  this.vPackageReleaseService.packageReleasePost!.application_instances?.use_selected_manifest!
    }
  }

  initValidation(): void {
    this.validationService.initialize(FormGroup);
    if (
      this.vPackageReleaseService.packageOverview?.application_instances?.vecu_level == 'lvl1' || this.vPackageReleaseService.packageOverview?.application_instances?.vecu_level == 'lvl2'
    ) {
      this.validationService.setInputFieldTouched('odxd', true);
      this.validationService.setInputFieldTouched('candela', true);
    }


  }

  getNcdReleaseOptions(): string[] {
    return this.ncdReleases?.map((ncd: any) => ncd.ncd_release!) ?? [];
  }

  onReleaseTypeInputChange(event: any): void {
    this.release = event.detail.value;
    if (this.releaseOptions.includes(this.release)) {
      this.vPackageReleaseService.packageReleasePost!.release_type = <
        ReleaseType
        >this.release.replace('-', '_');
    }
  }

  onReleaseTypeSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageReleaseService.packageReleasePost!.release_type =
          event.detail.value.value;
        this.release = event.detail.value.value;
      } else {
        this.release = '';
      }
    }
  }

  onNcdReleaseInputChange(event: any): void {
    this.ncdRelease = event.detail.value;
    if (this.ncdReleases.includes(this.ncdRelease)) {
      this.vPackageReleaseService.packageReleasePost!.application_instances!.ncd_release =
        this.ncdRelease;
    }
  }

  onNcdReleaseSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.ncdRelease = event.detail.value.value;
        this.vPackageReleaseService.packageReleasePost!.application_instances!.ncd_release =
          event.detail.value.value;
      } else {
        this.variantInfo = '';
        this.vPackageReleaseService.packageReleasePost!.application_instances!.ncd_release =
          '';
      }
      if (this.ncdRelease != null && this.ncdRelease != '') {
        this.ECU_Extracts = [];
        for (let index = 0; index < this.ECU_Extracts_Response.length; index++) {
          if (this.ECU_Extracts_Response[index].ncd_release == this.ncdRelease) {
  
            this.ECU_Extracts.push(this.ECU_Extracts_Response[index].file_name);
          }else{
            this.vPackageReleaseService.packageReleasePost!.application_instances!.manifests![0].file_name = '';
          }
        }
      }
    }
  }

  onVariantInfoInputChange(event: any): void {
    this.variantInfo = event.detail.value;
    if (this.VariantInfos.includes(this.variantInfo)) {
      this.vPackageReleaseService.packageReleasePost!.variant_info =
        this.variantInfo;
    }
  }

  onVariantInfoSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.variantInfo = event.detail.value.value;
        this.vPackageReleaseService.packageReleasePost!.variant_info =
          event.detail.value.value;
      } else {
        this.variantInfo = '';
        this.vPackageReleaseService.packageReleasePost!.variant_info =
          undefined;
      }
    }
  }

  onEcuExtractFileInputChange(event: any, index: number): void {
    this.vPackageReleaseService.packageReleasePost!.application_instances!.manifests![0].file_name =
      event.detail.value;
  }

  onEcuExtractFileChange(event: any, index: number): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageReleaseService.packageReleasePost!.application_instances!.manifests![0].file_name =
          event.detail.value.value;
      } else {
        this.vPackageReleaseService.packageReleasePost!.application_instances!.manifests![0].file_name =
          '';
      }
    }
  }

  onODxDFileInputChange(event: any, index: number): void {
    this.vPackageReleaseService.packageReleasePost!.application_instances!.manifests![1].file_name =
      event.detail.value;
  }

  onODxDFileChange(event: any, index: number): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageReleaseService.packageReleasePost!.application_instances!.manifests![1].file_name =
          event.detail.value.value;
      } else {
        this.vPackageReleaseService.packageReleasePost!.application_instances!.manifests![1].file_name =
          '';
      }
    }
  }

  onCDDFileInputChange(event: any, index: number): void {
    this.vPackageReleaseService.packageReleasePost!.application_instances!.manifests![2].file_name =
      event.detail.value;
  }

  onCDDFileChange(event: any, index: number): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageReleaseService.packageReleasePost!.application_instances!.manifests![2].file_name =
          event.detail.value.value;
      } else {
        this.vPackageReleaseService.packageReleasePost!.application_instances!.manifests![2].file_name =
          '';
      }
    }
  }

  onSimulationFrameWorkVersionInputChange(event: any, index: number): void {
    this.vPackageReleaseService.packageReleasePost!.application_instances!.vecu_applications![
      index
      ].simulation_framework_version = event.detail.value;
  }

  onSimulationFrameWorkVersionSelectChange(event: any, index: number): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageReleaseService.packageReleasePost!.application_instances!.vecu_applications![
          index
          ].simulation_framework_version = event.detail.value.label;
      } else {
        this.vPackageReleaseService.packageReleasePost!.application_instances!.vecu_applications![
          index
          ].simulation_framework_version = '';
      }
    }
  }

  onManifestSelectChange(event:any): void {
    this.checked = event.detail.checked;
    this.vPackageReleaseService.packageReleasePost!.application_instances!.use_selected_manifest = this.checked;
   
  }
}
