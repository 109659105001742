<wb-tab-content
  name="info"
  slot="content"
  [selected]="selectedTabId == 'info'"
  style="padding: 0"
>
  <wb-grid [fullWidth]="true">
    <wb-grid-row>
      <wb-grid-col mq6="5">
        <wb-heading class="spacing-xs" size="s" tag="div">
          Simulation information
        </wb-heading>
      </wb-grid-col>
      <wb-grid-col mq6="7">
        <wb-heading size="s" tag="div" *ngIf="testGroups.length">
          Test groups
        </wb-heading>
        <wb-heading
          size="s"
          tag="div"
          *ngIf="
            simulationBuilderService.simulationOverview?.plant_models
              ?.simulated_ecus?.length
          "
        >
          Simulated ECUs
        </wb-heading>
      </wb-grid-col>

      <wb-grid-col mq6="2">
        <wb-text class="spacing-bot-16" size="m" tag="div"> Name:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> ID:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> Type:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          Created by:</wb-text
        >
        <wb-text class="spacing-bot-16" size="m" tag="div">
          Created at:</wb-text
        >
        <wb-text class="spacing-bot-16" size="m" tag="div"> Package:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> Instance:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> Test type:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> System:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> Function:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          Sub-functions:
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> Networks:</wb-text>
      </wb-grid-col>
      <wb-grid-col mq6="3">
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.simulationBuilderService.simulationOverview?.name }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.simulationBuilderService.simulationOverview?.id }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          Simulation
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.simulationBuilderService.simulationOverview?.created_by }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.simulationBuilderService.simulationOverview?.created_at }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{
            this.simulationBuilderService.simulationOverview?.package?.name
              ? this.simulationBuilderService.simulationOverview?.package?.name
              : "-"
          }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{
            this.simulationBuilderService.simulationOverview?.test_environment
              ?.instance
          }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{
            this.simulationBuilderService.simulationOverview?.test_environment
              ?.test_type
              ? this.simulationBuilderService.simulationOverview
                  ?.test_environment?.test_type
              : "-"
          }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{
            this.simulationBuilderService.simulationOverview?.test_environment
              ?.configuration?.system
              ? this.simulationBuilderService.simulationOverview
                  ?.test_environment?.configuration?.system
              : "-"
          }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{
            this.simulationBuilderService.simulationOverview?.test_environment
              ?.configuration?.function
              ? this.simulationBuilderService.simulationOverview
                  ?.test_environment?.configuration?.function
              : "-"
          }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ subFunctions.length ? subFunctions : "-" }}
        </wb-text>
        <wb-text class="spacing-xs" size="m" tag="div">
          {{ networks ? networks.join(", ") : "-" }}
        </wb-text>
      </wb-grid-col>
      <wb-grid-col mq6="7" *ngIf="testGroups.length">
        <app-custom-grid
          [rowData]="testGroups"
          [columnDefs]="columnDefsGroup"
          [defaultColDef]="defaultColDef"
        >
        </app-custom-grid>
      </wb-grid-col>
      <wb-grid-col
        mq6="6"
        *ngIf="
          simulationBuilderService.simulationOverview?.plant_models
            ?.simulated_ecus?.length
        "
      >
        <app-custom-grid
          [rowData]="
            simulationBuilderService.simulationOverview.plant_models
              .simulated_ecus
          "
          [columnDefs]="simulatedEcuColumnDefs"
          [defaultColDef]="defaultColDef"
        >
        </app-custom-grid>
        <wb-heading
          class="spacing-top-xs spacing-xs"
          size="s"
          tag="div"
          *ngIf="
            simulationBuilderService.simulationOverview?.test_environment
              .instance == 'FROP'
          "
        >
          Plant models
        </wb-heading>
        <app-custom-grid
          *ngIf="
            simulationBuilderService.simulationOverview?.test_environment
              .instance == 'FROP'
          "
          [rowData]="plantModels"
          [columnDefs]="plantModelsColumnDefs"
          [defaultColDef]="defaultColDef"
        >
        </app-custom-grid>
      </wb-grid-col>
      <wb-grid-col start-col-mq6="7" mq6="6"></wb-grid-col>
    </wb-grid-row>
  </wb-grid>
</wb-tab-content>

<wb-tab-content
  name="permissions"
  slot="content"
  [selected]="selectedTabId == 'permissions'"
  style="padding: 0"
>
  <mbos-permissions
    domain="vtesting-simulation-bundle"
    [resourceId]="this.simId"
  ></mbos-permissions>
</wb-tab-content>
