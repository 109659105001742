import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PackageListDto} from '@vtp/vtpcap-client-ts';
import {VecuFiles} from '../../../interfaces/vecu-files';
import {Format, ValidationService, ValidationStatus,} from '../../../../services/validation/validation.service';
import {NotificationService} from '../../../../services/notification/notification.service';
import {CapabilitiesService} from '../../../../services/capabilities/capabilities.service';
import {VirtualRemoteTestBenchVtestingJobService} from '../../../services/remote-testbench-vtesting-job.service';
import {Subject} from 'rxjs';
import {Actions} from '../remote-testbench-job-flow-test/remote-testbench-job-flow-test.component';

enum FormGroup {
  definitionType = 'definitionType',
  definitionName = 'definitionName',
  package = 'package',
  release = 'release',
  executionOrder = 'executionOrder',
}

@Component({
  selector: 'app-remote-testbench-job-flow-test-definition',
  templateUrl: './remote-testbench-job-flow-test-definition.component.html',
  styleUrl: './remote-testbench-job-flow-test-definition.component.css'
})

export class  VirtualRemoteTestBenchJobFlowTestDefinitionComponent implements OnInit {
  @Input() id!: string;
  @Input() index!: number;
  @Input() testTypes: string[] = [];
  @Input() vEcus!: PackageListDto[];
  @Output() delete = new EventEmitter<any>();

  debounceTimer: any;
  actionTransmitter: Subject<{ action: number; option: any }> = new Subject<{
    action: number;
    option: any;
  }>();

  fropOptions: string[] = ['System'];
  releases: any[] = [];
  files: VecuFiles = {ecu_extract: [], odx_d: [], candela: []};

  isBropMode: boolean = false;
  isFropMode: boolean = false;

  showNetworkConfiguration = false;

  testTypeName = '';
  vEcuNameString = '';
  releaseNameString = '';

  validationStatus = ValidationStatus;
  formGroup = FormGroup;
  format = Format;

  constructor(
    private notificationService: NotificationService,
    private capabilitiesService: CapabilitiesService,
    public VirtualRemoteTestBenchVtestingJobService: VirtualRemoteTestBenchVtestingJobService,
    public validationService: ValidationService
  ) {
  }

  hideInfo() {
    document
      .getElementsByTagName('wb-accordion-item')
      [this.index].shadowRoot!.children[1].setAttribute(
      'style',
      'overflow: hidden !important'
    );
  }

  showInfo() {
    document
      .getElementsByTagName('wb-accordion-item')
      [this.index].shadowRoot!.children[1].setAttribute(
      'style',
      'overflow: visible !important'
    );
  }

  ngOnInit(): void {
    this.initValidation();
    if (this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id]) {
      this.initTestMode();
      this.initExistingSimulationBundle();
    } else {
      this.initNewSimulationBundle();
    }
    if (
      !this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope
        .configuration
    ) {
      this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[
        this.index
        ].test_scope.configuration = {
        instance: "",
        test_type: "",
      };

      if (
        !this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope
          .configuration?.starc_testset_id
      ) {
        this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[
          this.index
          ].test_scope.configuration!.starc_testset_id = '';
        this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[
          this.index
          ].test_scope.configuration!.starc_domain = undefined;
      }
    }
  }

  initValidation(): void {
    this.validationService.initialize(FormGroup);
    this.VirtualRemoteTestBenchVtestingJobService.getToggleStatus() &&
    this.validationService.setInputFieldTouched(FormGroup.release, true);
  }

  initTestMode(): void {
    if (this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].test_environment != null && this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].test_environment != undefined) {
    this.isBropMode = this.testTypes.includes(
      this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].test_environment
        .test_type!
    );
    this.isFropMode = this.fropOptions.includes(
      this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].test_environment
        .test_type!
    );
    this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[
      this.id
      ].test_environment.instance = this.isBropMode
      ? 'BROP'
      : this.isFropMode
        ? 'FROP'
        : 'BROP';
    this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration.instance = this.isBropMode ? 'BROP' : this.isFropMode ? 'FROP' : 'BROP';
    }
  }

  initNewSimulationBundle(): void {
    this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id] = {
      model_series:
      this.VirtualRemoteTestBenchVtestingJobService.base_data.basic_configuration.model_series,
      name: this.id,
      package: {id: ''},
      test_environment: {
        test_type: '',
        configuration: {test_groups: [], networks: [{name: ''}]},
        instance: 'BROP',
      },
    };
  }

  initExistingSimulationBundle(): void {
    const packageId =
      this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].package.id;

    this.testTypeName =
      this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[
        this.id
        ].test_environment.test_type!;
    if (packageId) {
      this.vEcuNameString =
        this.vEcus.find((vEcu) => vEcu.id === packageId)?.name || '';
      if (!this.VirtualRemoteTestBenchVtestingJobService.getToggleStatus()) {
        this.VirtualRemoteTestBenchVtestingJobService.skeleton[0] = true;
        this.getPackageReleases();
      }
      this.VirtualRemoteTestBenchVtestingJobService.skeleton[1] = true;
      this.actionTransmitter.next({
        action: Actions.PackageChange,
        option: null,
      });
    }
    if (
      this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].test_environment
        .test_type
    ) {
      this.actionTransmitter!.next({
        action: Actions.TestTypeChange,
        option: null,
      });
    }
  }

  getTestTypes(): string[] {
    return [...this.testTypes, ...this.fropOptions];
  }

  getVEcusAsStringArray(): (string | undefined)[] {
    return this.vEcus.map((vEcu) => vEcu.name);
  }

  getPackageReleasesAsStringArray(): (string | undefined)[] {
    return this.releases.map((release) => release.name);
  }

  testTypeChange(event: any): void {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.testTypeName = event.detail.value
        ? typeof event.detail.value === 'string'
          ? event.detail.value
          : event.detail.value.value
        : '';
      this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[
        this.id
        ].test_environment.test_type = this.testTypeName;
      this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration.test_type = this.testTypeName;
      this.initTestMode();
      this.initExistingSimulationBundle();
      // Wait for 10ms before the application loads to prevent another backend call
      // as the subscription to the child component might not have completely ended yet.
      new Promise((resolve) => {
        setTimeout(resolve, 10);
      }).then(() =>
        this.actionTransmitter!.next({
          action: Actions.TestTypeChange,
          option: null,
        })
      );
    }, 300);
  }

  packageChange(event: any): void {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      const vEcu =
        typeof event.detail.value === 'string'
          ? event.detail.value
          : typeof event.detail.value?.name === 'string'
            ? event.detail.value
            : event.detail.value.value;

      this.vEcuNameString = typeof vEcu === 'string' ? vEcu : vEcu.name;
      this.resetPackageReleaseData();
      if (this.getVEcusAsStringArray().includes(this.vEcuNameString)) {
        this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].package.id = vEcu.id;
        this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[
          this.index
          ].test_object.package_id = vEcu.id;
        this.getPackageReleases();
        this.actionTransmitter.next({
          action: Actions.PackageChange,
          option: null,
        });
      } else {
        this.showNetworkConfiguration = true;
        this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].package.id = '';
        this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[
          this.index
          ].test_object.package_id = '';
      }
    }, 300);
  }

  releaseChange(event: any): void {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      let release = event.detail.value
        ? typeof event.detail.value == 'string'
          ? event.detail.value
          : typeof event.detail.value.name == 'string'
            ? event.detail.value
            : event.detail.value.value
        : '';

      this.files = {ecu_extract: [], odx_d: [], candela: []};
      this.releaseNameString =
        typeof release == 'string' ? release : release.name;
      if (
        this.getPackageReleasesAsStringArray().includes(this.releaseNameString)
      ) {
        this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[
          this.index
          ].test_object.package_release_id = release.id;
        this.actionTransmitter.next({
          action: Actions.ReleaseChange,
          option: release.id,
        });
      } else {
        this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[
          this.index
          ].test_object.package_release_id = undefined;
      }
    }, 300);
  }

  getPackageReleases(): void {
    this.validationService.setInputFieldLoading(FormGroup.release, true);
    this.releases = [];
    this.capabilitiesService
      .getPackageReleases(
        this.VirtualRemoteTestBenchVtestingJobService.getBaseData().basic_configuration
          ?.model_series!,
        this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].package.id
      )
      .subscribe({
        next: (response) => {
          this.releases = response.data?.items!;

          if (
            this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index]
              .test_object.package_release_id
          ) {
            let release = this.releases.find(
              (release) =>
                release.id ==
                this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index]
                  .test_object.package_release_id
            );

            if (release) {
              this.releaseNameString = release.name!;
            } else {
              this.releaseNameString = '';
            }
          }
        },
        complete: () => {
          this.validationService.setInputFieldLoading(FormGroup.release, false);
          this.VirtualRemoteTestBenchVtestingJobService.skeleton[0] = false;
        },
        error: (error) => this.notificationService.handleError(error),
      });
  }

  resetPackageReleaseData() {
    this.releases = [];
    this.files = {ecu_extract: [], odx_d: [], candela: []};
    this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[
      this.index
      ].test_object.package_release_id = undefined;
    this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[
      this.id
      ].test_environment.configuration!.networks![0].name = '';
  }

  deleteSelf() {
    this.delete.emit();
  }
}
