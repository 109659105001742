import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}


  canActivate(): Observable<boolean> {
    return this.authService.validateToken().pipe(
      map((exp) => {
        const date = new Date();
        const time = (date.getTime() / 1000) - 1;

        if (time < exp) {
          return true;
        } else {
          // User is not authenticated, redirect to the error page
          this.router.navigate(['/error']); // Replace 'error' with your actual error route
          return false;
        }
      })
    );
  }
}
