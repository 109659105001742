import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  getToken(): Observable<any> {
    return this.http.get(window.location.origin + '/oauth/token');
  }
  validateToken(): Observable<number> {
    return new Observable<number>((observer) => {
      this.getToken().subscribe({
        next: (response) => {
          const exp = response.exp;
          observer.next(exp);
        },
        error: (error) => {
          const exp=0;
          observer.next(exp);
          observer.error(error);
        },
      });
    });
  }
}
