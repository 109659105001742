<ag-grid-angular
  class="ag-theme-wb"
  style="width: 100%; height: 100%;"
  domLayout="autoHeight"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [loadingOverlayComponent]="loadingOverlayComponent"
  [rowData]="rowData"
  [treeData]="true"
  [rowSelection]="'multiple'"
  [suppressRowClickSelection]="true"
  [groupDefaultExpanded]="0"
  [getDataPath]="getDataPath"
  (gridReady)="onGridReady($event)"
  (selectionChanged)="onSelectionChanged()"
  (rowSelected)="rowSelected($event)"
  [pagination]="true"
  [paginationPageSize]="pageSize.value"
  (rowDataUpdated)="onRowDataChanged()"
  suppressPaginationPanel
></ag-grid-angular>
<wb-pagination
  *ngIf="getTotalPages() > 1"
  [currentPage]="currentPage.value"
  [pages]="getTotalPages()"
  (wbchange)="setGridPage($event)"
>
</wb-pagination>
