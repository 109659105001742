import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private _name: string = "";
  private _title: string = "";
  private _description: string = "";
  private _isActive: boolean = false;
  private discardSubject: Subject<boolean> = new Subject<boolean>();

  initializeCustomModal(name: string, title: string, description: string): void {
    this._name = name;
    this._title = title;
    this._description = description;
    this._isActive = true;
  }

  get name(): string {
    return this._name;
  }

  get title(): string {
    return this._title;
  }

  get description(): string {
    return this._description;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  confirmDiscard(): Observable<boolean> {
    return this.discardSubject.asObservable();
  }

  handleDiscard(discard: boolean): void {
    this._title = "";
    this._description = "";
    this._isActive = false;
    this.discardSubject.next(discard);
  }
}
