import {Component, OnInit} from '@angular/core';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {ModelSeriesListGet} from '@vtp/vtpcap-client-ts';
import {Format, ValidationService, ValidationStatus,} from '../../../../services/validation/validation.service';
import {VirtualRemoteTestBenchVtestingJobService} from '../../../services/remote-testbench-vtesting-job.service';
import {ConfigurationService} from "../../../../services/configuration/configuration.service";

enum FormGroup {
  Name = 'Name',
  ModelSeries = 'ModelSeries',
}

@Component({
  selector: 'app-remote-testbench-job-flow-base-data',
  // standalone: true,
  // imports: [],
  templateUrl: './remote-testbench-job-flow-base-data.component.html',
  styleUrl: './remote-testbench-job-flow-base-data.component.css'
})

export class VirtualRemoteTestBenchJobFlowBaseDataComponent implements OnInit {
  vehicleLines?: ModelSeriesListGet;
  controlHint: string = 'Each new package release will be automatically tested';
  showToggleStatus: boolean = false;
  editMode: boolean = false;

  protected readonly Format = Format;
  protected readonly FormGroup = FormGroup;
  protected readonly ValidationStatus = ValidationStatus;

  constructor(
    private capabilitiesService: CapabilitiesService,
    private configurationService: ConfigurationService,
    private notificationService: NotificationService,
    public VirtualRemoteTestBenchVtestingJobService: VirtualRemoteTestBenchVtestingJobService,
    public validationService: ValidationService
  ) {
  }

  ngOnInit() {
    this.validationService.initialize(FormGroup);
    this.editMode = this.VirtualRemoteTestBenchVtestingJobService.getEditMode();
    if (this.editMode) {
      this.loadEditData();
    }
    this.capabilitiesService.getModelSeries().subscribe({
      next: (response) => (this.vehicleLines = response.data),
      error: (error) => this.notificationService.handleError(error),
    });
  }

  loadEditData(): void {
    this.configurationService
      .getVTestingJob(this.VirtualRemoteTestBenchVtestingJobService.getVtestingJobId())
      .subscribe({
        next: (element) => (this.VirtualRemoteTestBenchVtestingJobService.base_data = element.data),
        error: (error) => this.notificationService.handleError(error),
      });
  }

  getModelSeriesOptions(): string[] {
    return this.vehicleLines?.items?.map((model) => model.name!) ?? [];
  }

  modelSeriesChange(event: any): void {
    this.VirtualRemoteTestBenchVtestingJobService.base_data.basic_configuration.model_series = event
      .detail.value
      ? event.target.value
      : '';
  }

  showToolTip(e: any): void {
    this.showToggleStatus = false;
    this.controlHint =
      'Continuous testing ' +
      (this.showToggleStatus ? 'activated' : 'deactivated');
    this.VirtualRemoteTestBenchVtestingJobService.setToggleStatus(this.showToggleStatus);
  }
}
