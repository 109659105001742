import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PacmanService {
  constructor(private http: HttpClient) {}

  apiUrl: string =
    '/api/pkg';

    getHardwarePackages(): Observable<any> {
      return this.http.get<any[]>(`${this.apiUrl}/v2/packages/traverse`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    getHardwarePackageReleases(packageId:string): Observable<any> {
      return this.http.get<any[]>(`${this.apiUrl}/v2/releases/package?packageIds=${packageId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
}
