import {NavigationEnd, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderTitleService {
  pageTitle: string = '';
  pageDescription: string = '';
  primaryButtonText: string = '';
  secondaryButtonText: string = '';
  tertiaryButtonText: string = '';

  primaryDisabled: boolean = false;
  secondaryDisabled: boolean = false;
  tertiaryDisabled: boolean = false;

  selectedTabSubject = new BehaviorSubject<string>('');
  selectedTab$ = this.selectedTabSubject.asObservable();
  tabs: { label: string; id: string }[] = [];

  navigationItems = [
    {
      name: 'vTesting jobs',
      route: '/vtesting-jobs',
      active: true
    },
    {
      name: 'vECU packages',
      route: '/vpackages',
      active: false
    },
    {
      name: 'Simulations',
      route: '/simulations',
      active: false
    },
    {
      name: 'Remote Testbench',
      route: '/remote-testbench',
      active: false,
    }
  ];

  private primaryClickSource = new Subject<void>();
  primaryClick$ = this.primaryClickSource.asObservable();

  private secondaryClickSource = new Subject<void>();
  secondaryClick$ = this.secondaryClickSource.asObservable();

  private tertiaryClickSource = new Subject<void>();
  tertiaryClick$ = this.tertiaryClickSource.asObservable();

  lastUrl!: string;
  upsertUrls = [
    "/vtesting-job/general",
    "/vtesting-job/job",
    "/vtesting-job/summary",
    "/simulation/general",
    "/simulation/scope",
    "/simulation/refinement",
    "/simulation/summary",
    "/vpackage/packagedata",
    "/vpackage/content",
    "/vpackage/applications",
    "/vpackage/summary",
    "/vpackagerelease/releasedata-general",
    "/vpackagerelease/releasedata",
    "/vpackagerelease/releasedata-applications",
    "/vpackagerelease/releasedata-general",
    "/remote-testbench-job/general",
    "/remote-testbench-job/job",
    "/remote-testbench-job/summary"
  ];

  constructor(private router: Router) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && !this.upsertUrls.includes(val.url)) {
        this.lastUrl ? this.lastUrl != this.getBaseURL(val.url) && this.resetHeaderActions() : this.resetHeaderActions();
        this.lastUrl = this.getBaseURL(val.url);
      }
    })
  }

  getBaseURL(url: string): string {
    return url.indexOf('?') !== -1 ? url.substring(0, url.indexOf('?')) : url;
  }

  resetHeaderActions() {
    this.clearPrimaryClickFunction();
    this.clearSecondaryClickFunction();
    this.clearTertiaryClickFunction();
  }

  setTabs(tabs: { label: string; id: string }[]) {
    this.tabs = tabs;
    if (tabs.length) {
      this.selectedTabSubject.next(tabs[0].id);
    } else {
      this.selectedTabSubject = new BehaviorSubject<string>('');
      this.selectedTab$ = this.selectedTabSubject.asObservable();
    }
  }

  setSelectedTab(tab: { label: string; id: string }) {
    this.selectedTabSubject.next(tab.id);
  }

  clearPrimaryClickFunction(): void {
    this.primaryButtonText = '';
    this.primaryDisabled = false;
    this.primaryClickSource = new Subject<void>();
    this.primaryClick$ = this.primaryClickSource.asObservable();
  }

  clearSecondaryClickFunction(): void {
    this.secondaryButtonText = '';
    this.secondaryDisabled = false;
    this.secondaryClickSource = new Subject<void>();
    this.secondaryClick$ = this.secondaryClickSource.asObservable();
  }

  clearTertiaryClickFunction(): void {
    this.tertiaryButtonText = '';
    this.tertiaryDisabled = false;
    this.tertiaryClickSource = new Subject<void>();
    this.tertiaryClick$ = this.tertiaryClickSource.asObservable();
  }

  triggerPrimaryClick(): void {
    this.primaryClickSource.next();
  }

  triggerSecondaryClick(): void {
    this.secondaryClickSource.next();
  }

  triggerTertiaryClick(): void {
    this.tertiaryClickSource.next();
  }

  handleNavigationSelect(selectedItem: any): void {
    this.navigationItems.forEach((item) => item.active = false);
    selectedItem.active = true;
  }
}
