export const environment = {
  production: true,
  authConfig: {
    clientId: '36C34E04-F100-4850-A980-31D0EF5424EF',
    redirectUri: 'https://portal-dev.mbos.mercedes-benz.com/virtual-testing/callback',
    vtpcapClientId: "7D4E47A3-B750-4565-AD40-B5B32A092C06",
    vtpcfgClientId: "4E449D90-4033-4447-B3D9-B6D37CFC8988"
  },
  stage: "dev"
};
