import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {VpackageService} from '../../services/vpackage.service';
import {VpackagereleaseService} from '../../services/vpackagerelease.service';
import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  IDetailCellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import {PackageGet} from '@vtp/vtpcap-client-ts';
import {HeaderTitleService} from 'src/app/services/header/header-title.service';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {VtestingJobService} from 'src/app/test-job/services/vtesting-job.service';
import * as moment from 'moment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-vpackage-overview',
  templateUrl: './vpackage-overview.component.html',
  styleUrls: ['./vpackage-overview.component.css'],
})
export class VpackageOverviewComponent implements OnDestroy {
  title = '';
  description = '';

  primaryButtonText = 'Create release';
  secondaryButtonText = 'Edit';
  vPackageId: any;

  constructor(
    private capabilitiesService: CapabilitiesService,
    public vPackageService: VpackageService,
    public vPackageReleaseService: VpackagereleaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbsService,
    private headerService: HeaderTitleService,
    private notificationService: NotificationService,
    private vtestingJobService: VtestingJobService
  ) {
    this.routerSubscription$ = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  value: any;
  rowData?: PackageGet[];

  releaseData?: any[];
  EditMode: boolean = false;

  applications: any[] = [];
  apps?: { name: string; type: string; version: string }[];

  selectedTabId!: string;
  subscription!: Subscription;

  routerSubscription$!: Subscription;

  onCellClicked(event: CellClickedEvent): void {
    this.vPackageService.vPackageID = '';
    this.vPackageService.packageOverview = {
      model_series: [],
      name: '',
      type: 'vECUPKG',
      revision: '',
      id: '',
      created_at: '',
      created_by: '',
      deleted: false,
      release_information: {count: ''},
      latest_revision: '',
      provider: 'vtp',
    };
    this.vPackageService.vPackageID = event.data.id;
    this.vPackageService.packageOverview = event.data;
    this.showVPackage(this.vPackageService.vPackageID!);
  }

  showVPackage(id: string): void {
    this.breadcrumbService.breadcrumbs.length = this.breadcrumbService.breadcrumbs.length - 1;
    this.router
      .navigate([`vpackages/vpackage/${id}/overview`])
      .then(() => {
        this.breadcrumbService.breadcrumbs.push({label: '[replace]', route: ''})
      });
  }

  onReleaseClicked(event: CellClickedEvent): void {
    this.vPackageReleaseService.packageType =
      this.vPackageService.packageOverview.type!;
    this.vPackageReleaseService.packageReleaseOverview = event.data;
    this.vPackageReleaseService.packageReleaseOverview.model_series =
      this.vPackageReleaseService.packageReleaseOverview.model_series
        .map((obj: any) => obj.name)
        .join(',');
    this.showRelease(event.data);
  }

  showRelease(release: any): void {
    this.router.navigate([`vpackage-release/${release.id}/overview`]);
  }

  public columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      cellRenderer: (params: ICellRendererParams) => {
        return `<wb-link variant="standalone" (click)="">${params.value}</wb-link>`;
      },
      onCellClicked: (event: CellClickedEvent) => this.onCellClicked(event),
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
    },
    {
      field: 'model_series',
      headerName: 'Model series',
      cellDataType: 'object',
      valueFormatter: this.modelSeriesFormatter,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
    },
    {
      field: 'release_information.count',
      headerName: 'Releases',
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
      comparator: (valueA, valueB) => valueA - valueB,
    },
    {
      field: 'release_information.latest.name',
      headerName: 'Newest release',
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
    },
    {
      field: 'release_information.latest.createdAt',
      headerName: 'Newest release created on',
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      valueGetter: this.ValueGetter,
      flex: 1,
    },
  ];

  public columnDefPackageReleases: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: (params: ICellRendererParams) => {
          return `<wb-link variant="standalone" (click)="">${params.value}</wb-link>`;
        },
      },
      onCellClicked: (event: CellClickedEvent) => this.onReleaseClicked(event),
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      valueGetter: this.ValueGetter,
      flex: 1,
    },
  ];

  public columnDefvECUReleases: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: (params: ICellRendererParams) => {
          return `<wb-link variant="standalone" (click)="">${params.value}</wb-link>`;
        },
      },
      onCellClicked: (event: CellClickedEvent) => this.onReleaseClicked(event),
      flex: 1,
    },
    {
      field: 'release_type',
      headerName: 'Type',
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
    },
    {
      field: 'application_instances.semantic_version',
      headerName: 'Semantic verison',
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      cellStyle: (params) => (params.value === '-' ? {opacity: 0.4} : null),
      flex: 1,
    },
    {
      field: 'variant_info',
      headerName: 'Variant Info',
      cellStyle: (params) => (params.value === '-' ? {opacity: 0.4} : null),
      flex: 1,
    },
    {
      field: 'diagnostic_channel',
      headerName: 'Diagnostic channel',
      minWidth: 250,
      cellStyle: (params) => (params.value === '-' ? {opacity: 0.4} : null),
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      valueGetter: this.ValueGetter,
      flex: 1,
    },
  ];

  public detailCellRendererParamPackageReleases: any = {
    detailGridOptions: {
      columnDefs: [
        {
          field: 'name',
          headerName: 'Name',
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            innerRenderer: (params: ICellRendererParams) => {
              return `<wb-link variant="standalone" (click)="">${params.value}</wb-link>`;
            },
          },
          onCellClicked: (event: CellClickedEvent) =>
            this.onReleaseClicked(event),
          flex: 1,
        },
        {
          field: 'type',
          headerName: 'Type',
          flex: 1,
          cellRenderer: (params: ICellRendererParams) => {
            return params.value ? params.value : '-';
          },
        },
        {
          field: 'description',
          headerName: 'Description',
          flex: 1,
          cellRenderer: (params: ICellRendererParams) => {
            return params.value ? params.value : '-';
          },
        },
        {
          field: 'variant_info',
          headerName: 'Variant Info',
          flex: 1,
          cellRenderer: (params: ICellRendererParams) => {
            return params.value ? params.value : '-';
          },
        },
        {
          field: 'diagnostic_channel',
          headerName: 'Diagnostic channel',
          flex: 1,
          cellRenderer: (params: ICellRendererParams) => {
            return params.value ? params.value : '-';
          },
        },
        {
          field: 'created_at',
          headerName: 'Creation date',
          valueGetter: this.ValueGetter,
          flex: 1,
        },
      ],
      defaultColDef: {
        resizable: true,
        flex: 1,
      },
      domLayout: 'autoHeight',
    },
    getDetailRowData: (params: any) => {
      let package_releases = params.data.package_releases;
      package_releases.filter(Boolean);

      params.successCallback(package_releases);
    },
  } as IDetailCellRendererParams;

  ValueGetter(params: ValueGetterParams) {
    return moment(params.data.created_at)
      .utc(true)
      .format('DD-MM-YYYY HH:mm:ss');
  }

  public detailCellRendererParamVECUReleases: any = {
    detailGridOptions: {
      columnDefs: [
        {field: 'name', flex: 1},
        {field: 'type', flex: 1},
        {field: 'version', flex: 1},
      ],
      defaultColDef: {
        flex: 1,
      },
      domLayout: 'autoHeight',
    },
    getDetailRowData: (params: any) => {
      const application_instance = params.data.application_instances;
      this.apps = [
        ...(application_instance.sensor_actors?.map((actor: any) => ({
          name: actor.name,
          type: 'Sensors/Actors',
          version: actor.version,
        })) || []),
        ...(application_instance.vecu_applications?.map((app: any) => ({
          name: app.name,
          type: 'vECU Application',
          version: app.semantic_version,
        })) || []),
        application_instance.hardware_security_module
          ? {
            name: application_instance.hardware_security_module.name,
            type: 'HSM',
            version: application_instance.hardware_security_module.version,
          }
          : null,
      ].filter(Boolean);

      params.successCallback(this.apps);
    },
  } as IDetailCellRendererParams;

  public columnDefsApps: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
    },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    autoHeaderHeight: true,
    flex: 1,
  };

  modelSeriesFormatter(params: ValueFormatterParams): string {
    let modelSeriesArray: string[] = [];
    params.value.forEach((modelSeries: any) => {
      modelSeriesArray.push(modelSeries.name);
    });
    return modelSeriesArray.join();
  }

  init() {
    this.headerService.setTabs([
      {label: 'Information', id: 'info'},
      {label: 'Contents', id: 'contents'},
      {label: 'Releases', id: 'releases'},
      {label: 'Permissions', id: 'permissions'},
    ]);
    if (this.activatedRoute.snapshot.queryParamMap.get('tab')) {
      this.headerService.setSelectedTab({label: '', id: this.activatedRoute.snapshot.queryParamMap.get('tab')!})
    }
    this.subscription?.unsubscribe();
    this.subscription = this.headerService.selectedTab$.subscribe(
      (tab) => {
        this.selectedTabId = tab;
      }
    );
    this.vPackageId = this.activatedRoute.snapshot.paramMap.get('id');
    this.capabilitiesService
      .getSpecificPackage(this.activatedRoute.snapshot.paramMap.get('id')!)
      .subscribe({
        next: (response) => {
          this.vPackageService.packageOverview = response.data;
          this.vPackageReleaseService.packageOverview = response.data;
          this.vPackageReleaseService.setModelSeries(
            response.data.model_series[0].name
          );
          if (response.data.application_instances) {
            this.vPackageReleaseService.setDiagnosticName(
              response.data.application_instances.diagnostic_name
            );
          }
        },
        complete: () => {
          this.prepareOverview();
        },
        error: (error: any) => {
          this.notificationService.handleError(error);
          this.router.navigate(['/vpackages'])
        }
      });
  }

  ngOnDestroy() {
    this.headerService.setTabs([]);
    this.subscription.unsubscribe();
    this.routerSubscription$.unsubscribe();
  }

  prepareOverview(): void {
    this.title = this.vPackageService.packageOverview.name!;
    this.description =
      this.vPackageService.packageOverview.type +
      ' | ' +
      this.vPackageService.packageOverview.model_series![0].name +
      ' | ' +
      this.vPackageService.packageOverview.revision;

    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    this.initHeaderActions();
    this.initBreadCrumb();

    if (this.vPackageService.packageOverview.type == 'vECUPKG') {
      this.applications = [
        ...(this.vPackageService.packageOverview.application_instances!
          .vecu_applications ?? []),
        ...(this.vPackageService.packageOverview.application_instances!
          .sensor_actors ?? []),
      ];
    }

    this.capabilitiesService
      .getPackageReleases(
        this.vPackageService.packageOverview.model_series![0].name!,
        this.vPackageService.packageOverview.id!
      )
      .subscribe({
        next: (element: any) => {
          this.releaseData = element.data.items;
          if (!this.releaseData![0]) return;
          this.releaseData![0] = Object.entries(this.releaseData![0]).reduce(
            (acc, [key, value]) =>
              (typeof value === 'string' && (value === '' || value === null)) ||
              value === null
                ? {...acc, [key]: '-'}
                : {...acc, [key]: value},
            {}
          );
        },
        error: (error: any) => {
          this.notificationService.handleError(error);
        },
      });
  }

  private initHeaderActions() {
    this.headerService.primaryButtonText = this.primaryButtonText;
    this.headerService.secondaryButtonText = this.secondaryButtonText;
    this.headerService.primaryClick$.subscribe(() => {
      this.createRelease(); // Call the onNextClick function when the event is triggered
    });
    this.headerService.secondaryClick$.subscribe(() => {
      this.editvPackageData(/* vtestingJobId,putVtestingJob */); // Call the onNextClick function when the event is triggered
    });
  }

  private initBreadCrumb() {
    let tab = this.activatedRoute.snapshot.queryParamMap.get('tab');

    if (this.breadcrumbService.breadcrumbs.length && this.breadcrumbService.breadcrumbs.some((bc) => bc.route.includes(this.vPackageService.packageOverview.id))) {
      let index = this.breadcrumbService.breadcrumbs.findIndex((bc) => bc.route.includes(this.vPackageService.packageOverview.id));

      if (index < this.breadcrumbService.breadcrumbs.length - 2) {
        this.breadcrumbService.removeAboveIndex(index);
        if (tab) {
          this.breadcrumbService.breadcrumbs.push({
            label: tab == 'permissions' ? "Permissions" : tab == 'releases' ? "Releases" : tab == 'contents' ? 'Contents' : 'Information',
            route: '',
          });
        } else {
          this.breadcrumbService.breadcrumbs.push(
            {
              label: "Information",
              route: '',
            }
          );
        }
        return;
      }
    }
    if (this.breadcrumbService.breadcrumbs.some((breadcrumb) => breadcrumb.label == '[replace]')) {
      this.breadcrumbService.breadcrumbs[this.breadcrumbService.breadcrumbs.length - 1] = {
        label: this.vPackageService.packageOverview.name!,
        route: '/vpackages/vpackage/' + this.vPackageService.packageOverview.id + '/overview',
      };
      if (tab) {
        this.breadcrumbService.breadcrumbs.push({
          label: tab == 'permissions' ? "Permissions" : tab == 'releases' ? "Releases" : tab == 'contents' ? 'Contents' : 'Information',
          route: '',
        });
      } else {
        this.breadcrumbService.breadcrumbs.push(
          {
            label: "Information",
            route: '',
          }
        );
      }
    } else {
      if (['Information', 'Contents', 'Releases', 'Permissions'].includes(this.breadcrumbService.breadcrumbs[this.breadcrumbService.breadcrumbs.length - 1]?.label)) {
        if (tab) {
          this.breadcrumbService.breadcrumbs[this.breadcrumbService.breadcrumbs.length - 1].label = tab == 'permissions' ? "Permissions" : tab == 'releases' ? "Releases" : tab == 'contents' ? 'Contents' : 'Information';
        } else {
          this.breadcrumbService.breadcrumbs[this.breadcrumbService.breadcrumbs.length - 1].label = "Information";
        }
        this.breadcrumbService.breadcrumbs[this.breadcrumbService.breadcrumbs.length - 1].label = tab == 'permissions' ? "Permissions" : tab == 'releases' ? "Releases" : tab == 'contents' ? 'Contents' : 'Information';
      } else {
        this.breadcrumbService.setBreadcrumbs([
          {
            label: 'vECU packages',
            route: '/vpackages',
          },
          {
            label: this.vPackageService.packageOverview.name!,
            route: '/vpackages/vpackage/' + this.vPackageService.packageOverview.id + '/overview',
          }
        ]);
        if (tab) {
          this.breadcrumbService.breadcrumbs.push({
            label: tab == 'permissions' ? "Permissions" : tab == 'releases' ? "Releases" : tab == 'contents' ? 'Contents' : 'Information',
            route: '',
          });
        } else {
          this.breadcrumbService.breadcrumbs.push(
            {
              label: "Information",
              route: '',
            }
          );
        }
      }
    }
  }

  createRelease(): void {
    // this.vPackageReleaseService.packageReleasePost.model_series =
    //   this.vPackageService.packageOverview.model_series!;
    this.vPackageReleaseService.packageReleasePost.application_instances = this
      .vPackageService.packageOverview.application_instances as any;
    this.vPackageReleaseService.packageReleasePost.package.id =
      this.vPackageService.packageOverview.id!;
    this.vPackageReleaseService.packageType =
      this.vPackageService.packageOverview.type!;
    this.vPackageReleaseService.prepareApps();
    this.router.navigate(['vpackagerelease/releasedata-general']);
  }

  editvPackageData(): void {
    this.EditMode = true;
    this.vtestingJobService.setEditMode(this.EditMode);
    this.vtestingJobService.setvPackageId(this.vPackageId);
    this.router
      .navigate([`vpackage/packagedata`])
      .then(() => this.vtestingJobService.setvPackageId(this.vPackageId));
  }
}
