<mbos-portal-global-header
  datadog-service-name="replace-me"
  datadog-service-version="replace-me"
>
</mbos-portal-global-header>


<mbos-portal-app-header
  appTitle="vTesting"
  pageTitle="{{ headerService.pageTitle }}"
  [pageDescription]="headerService.pageDescription"
>
  <div slot="app-navigation">
    <mbos-portal-navigation-item
      *ngFor="let navigationItem of this.headerService.navigationItems"
      [routerLink]="[navigationItem.route]"
      [active]="navigationItem.active"
      (click)="this.headerService.handleNavigationSelect(navigationItem)"
    >
      {{ navigationItem.name }}
    </mbos-portal-navigation-item>
  </div>
  <wb-breadcrumbs slot="breadcrumbs">
    <wb-breadcrumb
      *ngFor="let breadcrumb of breadcrumbService.breadcrumbs"
      [routerLink]="breadcrumb.route"
    >{{ breadcrumb.label }}
    </wb-breadcrumb
    >
    <!--     <wb-breadcrumb
          *ngFor="let breadcrumb of breadcrumbService.breadcrumbs$ | async"
          [routerLink]="breadcrumb.url"
          >{{ breadcrumb.label }}
        </wb-breadcrumb> -->
  </wb-breadcrumbs>
  <wb-button
    *ngIf="headerService.primaryButtonText != ''"
    slot="primary-cta"
    (click)="primaryClick()"
    [disabled]="headerService.primaryDisabled"
  >{{ headerService.primaryButtonText }}
  </wb-button>
  <wb-button
    *ngIf="headerService.secondaryButtonText != ''"
    slot="secondary-cta"
    (click)="secondaryClick()"
    [disabled]="headerService.secondaryDisabled"
  >{{ headerService.secondaryButtonText }}
  </wb-button>
  <wb-button
    *ngIf="headerService.tertiaryButtonText != ''"
    slot="tertiary-cta"
    (click)="tertiaryClick()"
    [disabled]="headerService.tertiaryDisabled"
  >{{ headerService.tertiaryButtonText }}
  </wb-button>
  <div slot="navigation" *ngIf="headerService.tabs.length">
    <wb-subnavigation-item
      *ngFor="let tab of headerService.tabs"
      (click)="handleTabClick(tab)"
      [active]="tab.id == headerService.selectedTabSubject.getValue()"
    >
      {{ tab.label }}
    </wb-subnavigation-item>
  </div>
</mbos-portal-app-header>
<!-- <app-header></app-header>  -->

<wb-grid class="content">
  <main>
    <wb-notification-host
      position="bottom-end"
      class="notification-margin"
      *ngIf="notificationService.notify"
    >
      <wb-notification
        icon="alert-circle"
        variant="error"
        (wbdismiss)="notificationService.notify = false"
      >{{ notificationService.notificationMessage }}
      </wb-notification>
    </wb-notification-host>

    <wb-notification-host
      position="bottom-end"
      class="notification-margin"
      *ngIf="notificationService.notifyWarning"
    >
      <wb-notification
        icon="alert-circle"
        variant="warning"
        (wbdismiss)="notificationService.notifyWarning = false"
      >{{ notificationService.notificationWarningMessage }}
      </wb-notification>
    </wb-notification-host>
    <wb-notification-host
      position="bottom-end"
      class="notification-margin"
      *ngIf="notificationService.notifySuccess"
    >
      <wb-notification
        icon="checkmark-circle"
        variant="success"
        lifetime="3000"
        (wbdismiss)="notificationService.notifySuccess = false"
      >{{ notificationService.notificationSuccesMessage }}
      </wb-notification>
    </wb-notification-host>
    <router-outlet></router-outlet>
  </main>
</wb-grid>
<app-modal
  *ngIf="modalService.isActive && modalService.name.includes('discard')"
></app-modal>
<mbos-portal-footer class="footer-pos"></mbos-portal-footer>
