import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ColDef, GridApi, GridReadyEvent} from 'ag-grid-community';
import {getProfile} from '@mbos/portal-service';
import {CustomLoadingOverlayComponent} from '../custom-loading-overlay/custom-loading-overlay.component';

@Component({
  selector: 'app-grid-by-user',
  templateUrl: './grid-by-user.component.html',
  styleUrl: './grid-by-user.component.css',
})
export class GridByUserComponent implements OnChanges {
  @Input() rowData?: any[];
  @Input() columnDefs?: ColDef[];
  @Input() defaultColDef?: ColDef;

  myData: any[] = [];
  gridApi?: GridApi;
  currentPage: { value: number } = {value: 1};
  pageSize: { value: number } = {value: 10}; // Changed to appropriate page size
  public loadingOverlayComponent: any = CustomLoadingOverlayComponent;
  public loadingOverlayComponentParams: any = {
    loadingMessage: 'Loading...',
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.rowData) {
      this.getProfileAndSetRowData();
    }
  }

  getProfileAndSetRowData() {
    getProfile().then((result: any) => {
      this.myData =
        this.rowData?.filter((doc) => doc.created_by === result.sub) || [];
      if (this.gridApi) {
        this.gridApi.setRowData(this.myData);
      }
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    /* this.gridApi.showNoRowsOverlay(); */
  }

  public overlayNoRowsTemplate = '<wb-text> Nothing to show</wb-text>';

  setGridPage(event: Event): void {
    const customEvent = event as CustomEvent;
    this.gridApi?.paginationGoToPage(customEvent.detail - 1);
    this.currentPage.value = customEvent.detail;
  }

  getTotalPages(): number {
    return Math.ceil((this.myData.length || 0) / this.pageSize.value);
  }
}
