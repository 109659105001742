import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CapabilitiesService } from 'src/app/services/capabilities/capabilities.service';
@Component({
  selector: 'app-button-detail-cell-renderer',
  /* standalone: true, */
  /*   imports: [], */
  templateUrl: './button-detail-cell-renderer.component.html',
  styleUrl: './button-detail-cell-renderer.component.css',
})
export class ButtonDetailCellRendererComponent
  implements ICellRendererAngularComp
{
  @Input() params?: ICellRendererParams;
  /*   @Output() cancel = new EventEmitter<void>(); */
  showTestDefRunDownload: boolean = false;
  showTestDefRunCancel: boolean = false;
  test_definition_runs_status_Array: string[] = [];

  toolTip: string = '';

  constructor(/* private capabilityservice: CapabilitiesService */) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    /*     this.capabilityservice.getvTestingJob(this.params.data.id).subscribe(); */
    this.toolTip =
      'test_definition_id' in params.data ? ' Test Definition' : '';

    this.showTestDefRunDownload = ['FINISHED', 'FAILED'].includes(
      params.data.status
    );

    if (params.data.status == 'FAILED') {
      if (params.data.status_reason == '[7|7] - integration failed') {
        this.showTestDefRunDownload = false;
      }
    }
    /*     this.showTestDefRunCancel = [
      'CREATED',
      'QUEUED',
      'RUNNING',
      'INTEGRATION',
    ].includes(params.data.test_definition_runs.status); */
    // Render a different button for the detail row
  }

  /* cancelClicked(): void {
    const allparams: any = this.params;
    allparams.cancelCallback();
  }
 */ refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return false;
  }
  downloadClicked(): void {
    const allparams: any = this.params;
    allparams.downloadCallBack();
  }
}
