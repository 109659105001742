import {Component, OnInit} from '@angular/core';
import {PackageListDto} from '@vtp/vtpcap-client-ts';
import {UUID} from "uuid-generator-ts";
import {CapabilitiesService} from "../../../../services/capabilities/capabilities.service";
import {NotificationService} from "../../../../services/notification/notification.service";
import {HeaderTitleService} from "../../../../services/header/header-title.service";
import {VtestingJobService} from "../../../services/vtesting-job.service";
import {ValidationService} from "../../../../services/validation/validation.service";
import {Router} from "@angular/router";


export enum Actions {
  TestTypeChange,
  PackageChange,
  ReleaseChange
}

@Component({
  selector: 'app-test-job-flow-test',
  templateUrl: './test-job-flow-test.component.html',
  styleUrl: './test-job-flow-test.component.css'
})
export class TestJobFlowTestComponent implements OnInit {
  vEcus: PackageListDto[] | undefined = undefined;
  testTypes: string[] = [];

  constructor(
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService,
    private headerService: HeaderTitleService,
    public vTestingJobService: VtestingJobService,
    public validationService: ValidationService,
    private router: Router
  ) {
  }

  ngOnInit() {
    const modelSeries = this.vTestingJobService.getBaseData().basic_configuration.model_series;

    if (!modelSeries) {
      return this.router.navigate(['/vtesting-jobs']);
    } else {
      this.headerService.secondaryDisabled = false;
      this.headerService.secondaryButtonText = "Back"
      if (!this.vTestingJobService.getEditMode() && !this.vTestingJobService.vtestingJobDefinitions.length) {
        this.addTestDefinition();
      }
      this.capabilitiesService.getTestTypes(modelSeries).subscribe({
        next: response => this.testTypes = response.data.items.map(({test_type}: { test_type: string }) => test_type),
        error: error => this.notificationService.handleError(error),
      });

      this.capabilitiesService.getEcus([modelSeries]).subscribe({
        next: response => this.vEcus = response.data.items,
        error: error => this.notificationService.handleError(error),
      });

      document.getElementsByTagName('app-root')[0].classList.add('scrollbar');
    }
  }

  ngOnDestroy() {
    document.getElementsByTagName('app-root')[0].classList.remove('scrollbar');
  }

  deleteTestDefinition(id: string): void {
    let jobIndex = this.vTestingJobService.vtestingJobDefinitions.findIndex((jobDefinition) => jobDefinition.id == id);

    delete this.vTestingJobService.simulationBundles[this.vTestingJobService.vtestingJobDefinitions[jobIndex].id]
    this.vTestingJobService.vtestingJobDefinitions.splice(jobIndex, 1);
  }

  addTestDefinition(): void {
    this.vTestingJobService.vtestingJobDefinitions.push({
      id: new UUID().toString(),
      name: '',
      test_scope: {
        files: {
          ecu_extract: '',
          odx_d: '',
          candela: '',
          ncd: ''
        },
        configuration: {
          test_type: "",
          instance: "",
        },
      },
      test_object: {
        simulation_bundle_id: null,
        package_id: '',
      },
      test_orchestration: {
        execution_order: 1
      },
    });
  }
}
