import {VtestingJobService} from '../../test-job/services/vtesting-job.service';
import {HeaderTitleService} from '../../services/header/header-title.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VpackageService} from 'src/app/package/services/vpackage.service';
import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import {PackageGet} from '@vtp/vtpcap-client-ts';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {NotificationService} from 'src/app/services/notification/notification.service';
import * as moment from 'moment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-app-upload-overview',
  templateUrl: './app-upload-overview.component.html',
  styleUrls: ['./app-upload-overview.component.css'],
})
export class AppUploadOverviewComponent implements OnInit, OnDestroy {
  title = 'vECU packages overview';
  description =
    'A vECU package is a compilation of all relevant simulation files of one or multiple vECUs. Once all software components are bundled into a package, it is ready to be released.';
  primaryButtonText = 'Create vECU package';
  value: any;
  rowData?: PackageGet[];
  filteredRowData?: any[] = [];

  selectedTabId!: string;
  subscription!: Subscription;

  constructor(
    private router: Router,
    private headerService: HeaderTitleService,
    private capabilitiesService: CapabilitiesService,
    private vPackageService: VpackageService,
    private breadcrumbService: BreadcrumbsService,
    private notificationService: NotificationService,
    private vtestingJobService: VtestingJobService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  onCellClicked(event: CellClickedEvent): void {
    this.vPackageService.vPackageID = event.data.id;
    this.vPackageService.packageOverview = event.data;
    this.vPackageService.countApps();
    this.showVPackage(this.vPackageService.vPackageID!);
  }

  showVPackage(id: string): void {
    this.router.navigate([`vpackages/vpackage/${id}/overview`]);
  }

  modelSeriesFormatter(params: ValueFormatterParams): string {
    return params.value.map((modelSeries: any) => modelSeries.name).join();
  }

  public columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      cellRenderer: (params: ICellRendererParams) => {
        return `<wb-link variant="standalone" (click)="">${params.value}</wb-link>`;
      },
      onCellClicked: (event: CellClickedEvent) => this.onCellClicked(event),
      flex: 2,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
    },
    {
      field: 'model_series',
      headerName: 'Model series',
      cellDataType: 'object',
      valueFormatter: this.modelSeriesFormatter,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
      flex: 1,
    },
    {
      field: 'release_information.count',
      headerName: 'Releases',
      maxWidth: 200,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value != 0 ? Number(params.value) : '-';
      },
      comparator: (valueA, valueB) => valueA - valueB,
      flex: 1,
    },
    {
      field: 'release_information.latest.name',
      headerName: 'Newest release',
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
      flex: 2,
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      valueGetter: this.ValueGetter,
      flex: 1,
      comparator: this.DateComparator,
    },
  ];

  DateComparator(date1: string, date2: string) {
    const PartsofDate1 = date1.split(' ');
    const PartsofDate1Date = PartsofDate1[0].split('-');
    const date1TimeParts = PartsofDate1[1].split(':');

    const date1Obj = new Date(
      parseInt(PartsofDate1Date[2]),
      parseInt(PartsofDate1Date[1]) - 1,
      parseInt(PartsofDate1Date[0]),
      parseInt(date1TimeParts[0]),
      parseInt(date1TimeParts[1]),
      parseInt(date1TimeParts[2])
    );

    const date2Parts = date2.split(' ');
    const date2DateParts = date2Parts[0].split('-');
    const date2TimeParts = date2Parts[1].split(':');

    const date2Obj = new Date(
      parseInt(date2DateParts[2]),
      parseInt(date2DateParts[1]) - 1,
      parseInt(date2DateParts[0]),
      parseInt(date2TimeParts[0]),
      parseInt(date2TimeParts[1]),
      parseInt(date2TimeParts[2])
    );

    if (date1Obj < date2Obj) return -1;
    if (date1Obj > date2Obj) return 1;
    return 0;
  }

  ValueGetter(params: ValueGetterParams) {
    return moment(params.data.created_at)
      .utc(true)
      .format('DD-MM-YYYY HH:mm:ss');
  }

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    autoHeaderHeight: true,
    flex: 1,
  };

  fetchGridData() {
    this.capabilitiesService.getAllVPackages().subscribe({
      next: (element: any) => {
        this.rowData = element.data.items;
        this.filteredRowData = this.rowData;
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  ngOnInit() {
    this.headerService.setTabs([
      {label: 'My vECU package uploads', id: 'my-vecus'},
      {label: 'All vECU package uploads', id: 'all-vecus'},
    ]);
    if (this.activatedRoute.snapshot.queryParamMap.get('tab')) {
      this.headerService.setSelectedTab({label: '', id: this.activatedRoute.snapshot.queryParamMap.get('tab')!})
    }
    this.subscription = this.headerService.selectedTab$.subscribe(
      (tab) => {
        this.selectedTabId = tab;
        this.breadcrumbService.setBreadcrumbs([{label: 'vECU packages', route: '/vpackages'}]);

        if (tab) {
          this.breadcrumbService.breadcrumbs.push({
            label: (tab == 'my-vecus' ? "My" : "All") + " vECU package uploads",
            route: ''
          });
        } else {
          this.breadcrumbService.breadcrumbs.push({
            label: 'My vECU package uploads',
            route: ''
          });
        }
      }
    );

    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    this.initHeaderActions();
    this.initBreadcrumbs();
    this.fetchGridData();
  }

  private initHeaderActions() {
    this.headerService.resetHeaderActions();
    this.headerService.primaryButtonText = this.primaryButtonText;
    setTimeout(() => this.setPrimaryClick(), 500);
  }

  private initBreadcrumbs() {
    let tab = this.activatedRoute.snapshot.queryParamMap.get('tab');

    this.breadcrumbService.setBreadcrumbs([{label: 'vECU packages', route: '/vpackages'}]);

    if (tab) {
      this.breadcrumbService.breadcrumbs.push({
        label: (tab == 'my-vecus' ? "My" : "All") + " vECU package uploads",
        route: ''
      });
    } else {
      this.breadcrumbService.breadcrumbs.push({
        label: 'My vECU package uploads',
        route: ''
      });
    }
  }

  ngOnDestroy() {
    this.headerService.setTabs([]);
    this.subscription.unsubscribe();
  }

  setPrimaryClick(): void {
    this.headerService.primaryClick$.subscribe(() => {
      this.startProcess(); // Call the onNextClick function when the event is triggered
      this.vtestingJobService.setEditMode(false);
    });
  }

  filterInputChange(event: any) {
    this.filteredRowData = this.rowData!.filter((node) =>
    node.name.trim().toLowerCase().includes(event.detail.value.trim().toLowerCase())
   );
}

  startProcess(): void {
    this.router.navigate(['vpackage/packagedata']);
  }
}
