<wb-radio-group class="spacing-group" [horizontal]="true" *ngIf="params?.data.decision">
  <wb-radio-button [checked]="simulationType == 'restbus'" (click)="onSimulationTypeChange('restbus')"
                   class="spacing-button"
                   theme="" name="restbus" value="restbus">
    Restbus
  </wb-radio-button>
  <wb-radio-button [checked]="simulationType == 'generic_ecu'" (click)="onSimulationTypeChange('generic_ecu')" theme=""
                   name="generic_ecu" value="generic_ecu">
    Generic
  </wb-radio-button>
</wb-radio-group>
<wb-control-error *ngIf="params?.data.error" style="font-size: medium;" slot="message">Simulated ECU does not match the topology definition of the selected model series. Please contact your platfrom support for help!</wb-control-error>
<wb-text class="spacing-group" *ngIf="!params?.data.decision && !params?.data.error">{{params?.value}}</wb-text>
