import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-custom-cell-renderer',
  templateUrl: './row-actions.component.html',
  styleUrls: ['./row-actions.component.css'],
})
export class CustomCellRendererComponent implements ICellRendererAngularComp {
  @Input() params?: ICellRendererParams;
  @Output() delete = new EventEmitter<void>();
  showDeleteButton: boolean = true;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    // VTP-1615 Starts
    if (this.params.data.type == 'Sim') {
      this.showDeleteButton = false;
    }    
    // VTP -1615 Ends
  }
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    // As we have updated the params we return true to let AG Grid know we have handled the refresh.
    // So AG Grid will not recreate the cell renderer from scratch.
    return true;
  }
  deleteClicked(): void {
    const allparams: any = this.params;
    allparams.deleteCallback();
  }
}
