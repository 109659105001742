<wb-grid-row style="margin: 1%">
  <wb-grid-col mq6="12">
    <wb-grid [fullWidth]="true">
      <wb-heading size="xs" class="spacing-xxs">Info</wb-heading>
      <wb-grid-row>
        <wb-grid-col mq6="4">
          <wb-input
            as="input"
            [(ngModel)]="
              this.vTestingJobService.vtestingJobDefinitions[index].test_scope.configuration!.starc_testset_id
            "
            name="starc_test_set_id"
            ngDefaultControl
            inputmode="text"
            class="spacing-s"
            (wbinput)="onStarcIdInputChange($event)"
          >
            <span slot="label"> STARC test set ID (optional) </span>
          </wb-input>
          <wb-combobox>
            <wb-combobox-input
              slot="input"
              name="domain"
            >
              <label slot="label">STARC domain (optional)</label>
            </wb-combobox-input>
            <wb-combobox-list
              no-items-selected-text="No system selected"
              no-items-found-text="No systems found"
              (wbcomboboxlistitemselectionchange)="domainChange($event)"
            >
              <wb-combobox-list-item
                *ngFor="let domain of starcDomains"
                value="{{ domain }}"
              >
                {{ domain }}
              </wb-combobox-list-item>
            </wb-combobox-list>
          </wb-combobox>
        </wb-grid-col>
        <wb-grid-col mq6="4">
          <wb-combobox class="spacing-s" *ngIf="showEnvironments.system">
            <wb-combobox-input
              slot="input"
              name="system"
              ngDefaultControl
              #system="ngModel"
              [ngModel]="
                this.vTestingJobService.simulationBundles[this.id]
                  .test_environment.configuration!.system!
              "
              [disabled]="
                this.validationService.getFormStatus(
                  FormGroup.system,
                  ValidationStatus.Loading
                )
              "
              [error]="
                validationService.isComboBoxInValid(
                  FormGroup.system,
                  system,
                  this.vTestingJobService.simulationBundles[this.id]
                    .test_environment.configuration!.system!,
                  mapSystemsToStringArray()
                )
              "
              (wbcomboboxinput)="systemChange($event)"
            >
              <label slot="label">System*</label>
              <wb-input-action
                slot="actions"
                *ngIf="
                  this.validationService.getFormStatus(
                    FormGroup.system,
                    ValidationStatus.Loading
                  )
                "
              >
                <wb-spinner id="loading-spinner"></wb-spinner>
              </wb-input-action>
            </wb-combobox-input>
            <wb-combobox-list
              no-items-found-text="No systems found"
              no-items-selected-text="No system selected"
              (wbcomboboxlistselectionchange)="systemChange($event)"
            >
              <wb-combobox-list-item
                *ngFor="let environment of environments.systems"
                [value]="environment"
              >
                {{ environment.system }}
              </wb-combobox-list-item>
            </wb-combobox-list>
            <div slot="message">
              <app-validation-notification
                name="System"
                [type]="Format.Pick"
                *ngIf="
                  validationService.getFormStatus(
                    FormGroup.system,
                    ValidationStatus.Invalid
                  ) &&
                  validationService.getFormStatus(
                    FormGroup.system,
                    ValidationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-combobox>
          <wb-combobox class="spacing-s" *ngIf="showEnvironments.function">
            <wb-combobox-input
              slot="input"
              name="function"
              ngDefaultControl
              #function="ngModel"
              [ngModel]="
                this.vTestingJobService.simulationBundles[this.id]
                  .test_environment.configuration!.function!
              "
              [disabled]="
                this.validationService.getFormStatus(
                  FormGroup.function,
                  ValidationStatus.Loading
                )
              "
              [error]="
                validationService.isComboBoxInValid(
                  FormGroup.function,
                  function,
                  this.vTestingJobService.simulationBundles[this.id]
                    .test_environment.configuration!.function!,
                  mapFunctionsToStringArray()
                )
              "
              (wbcomboboxinput)="functionChange($event)"
            >
              <label slot="label">Function*</label>
              <wb-input-action
                slot="actions"
                *ngIf="
                  this.validationService.getFormStatus(
                    FormGroup.function,
                    ValidationStatus.Loading
                  )
                "
              >
                <wb-spinner id="loading-spinner"></wb-spinner>
              </wb-input-action>
            </wb-combobox-input>
            <wb-combobox-list
              no-items-found-text="No functions found"
              no-items-selected-text="No function selected"
              (wbcomboboxlistselectionchange)="functionChange($event)"
            >
              <wb-combobox-list-item
                *ngFor="let environment of environments.functions"
                [value]="environment"
              >
                {{ environment.function }}
              </wb-combobox-list-item>
            </wb-combobox-list>
            <div slot="message">
              <app-validation-notification
                name="Function"
                [type]="Format.Pick"
                *ngIf="
                  validationService.getFormStatus(
                    FormGroup.function,
                    ValidationStatus.Invalid
                  ) &&
                  validationService.getFormStatus(
                    FormGroup.function,
                    ValidationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-combobox>
          <wb-combobox class="spacing-s" *ngIf="showEnvironments.sub_function && environments.subfunctions.length > 0">
            <wb-combobox-input
              slot="input"
              name="subFunction"
              [disabled]="
                this.validationService.getFormStatus(
                  FormGroup.subFunction,
                  ValidationStatus.Loading
                )
              "
            >
              <label slot="label">Sub Functions*</label>
              <wb-input-action
                slot="actions"
                *ngIf="
                  this.validationService.getFormStatus(
                    FormGroup.subFunction,
                    ValidationStatus.Loading
                  )
                "
              >
                <wb-spinner id="loading-spinner"></wb-spinner>
              </wb-input-action>
            </wb-combobox-input>
            <wb-combobox-list
              variant="multi"
              show-chip-list-row="false"
              no-items-selected-text="No sub functions selected"
              no-items-found-text="No sub functions found"
              name="subFunctionList"
              (wbcomboboxlistselectionchange)="subFunctionSelectChange($event)"
            >
              <wb-combobox-list-item
                *ngFor="let environment of environments.subfunctions"
                [selected]="isEnvironmentSelected(environment)"
                [value]="environment"
              >
                {{ environment.sub_function }}
              </wb-combobox-list-item>
            </wb-combobox-list>
          </wb-combobox>

          <wb-combobox class="spacing-s" *ngIf="showEnvironments.sub_function && environments.subfunctions.length == 0">
            <wb-combobox-input             
              slot="input"
              name="subFunction"
              id="subFunction"
            >
              <label slot="label">Sub Functions*</label>
            </wb-combobox-input>
          </wb-combobox>
          
        </wb-grid-col>
        <wb-grid-col mq6="4">
          <wb-combobox class="spacing-s">
            <wb-combobox-input
              slot="input"
              name="simulation"
              ngDefaultControl
              #simulation="ngModel"
              [ngModel]="this.selectedSimulation?.name"
              [disabled]="
                this.validationService.getFormStatus(
                  FormGroup.simulation,
                  ValidationStatus.Loading
                )
              "
              [error]="
                validationService.isComboBoxInValid(
                  FormGroup.simulation,
                  simulation,
                  this.selectedSimulation?.name!,
                  mapSimulationsToStringArray()
                )
              "
              (wbcomboboxinput)="simulationChange($event)"
            >
              <label slot="label">Simulation*</label>
              <wb-input-action
                slot="actions"
                *ngIf="
                  this.validationService.getFormStatus(
                    FormGroup.simulation,
                    ValidationStatus.Loading
                  )
                "
              >
                <wb-spinner id="loading-spinner"></wb-spinner>
              </wb-input-action>
            </wb-combobox-input>
            <wb-combobox-list
              no-items-found-text="No Simulations found"
              no-items-selected-text="No Simulations selected"
              (wbcomboboxlistselectionchange)="simulationChange($event)"
            >
              <wb-combobox-list-item
                *ngFor="let simulation of allSimulations"
                [value]="simulation"
              >
                {{ simulation.name }}
              </wb-combobox-list-item>
            </wb-combobox-list>
            <div slot="message">
              <app-validation-notification
                name="Simulation"
                [type]="Format.Pick"
                *ngIf="
                  validationService.getFormStatus(
                    FormGroup.simulation,
                    ValidationStatus.Invalid
                  ) &&
                  validationService.getFormStatus(
                    FormGroup.simulation,
                    ValidationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-combobox>
        </wb-grid-col>
      </wb-grid-row>
    </wb-grid>
  </wb-grid-col>
</wb-grid-row>
