import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PackageComponent} from './package.component';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {VpackageOverviewComponent} from './components/vpackage-overview/vpackage-overview.component';
import {AgGridModule} from 'ag-grid-angular';
import {CustomGridsModule} from '../components/custom-grids/custom-grids.module';
import {ValidationModule} from "../components/validation/validation.module";
import {PackagedataComponent} from "./components/create-vecu-package/packagedata/packagedata.component";
import {PackagecontentComponent} from "./components/create-vecu-package/packagecontent/packagecontent.component";
import {ApplicationsComponent} from "./components/create-vecu-package/applications/applications.component";
import {PackagesummaryComponent} from "./components/create-vecu-package/packagesummary/packagesummary.component";
import {
  VpackageReleaseGridComponent
} from "../package-release/components/vpackage-release-grid/vpackage-release-grid.component";

@NgModule({
  declarations: [PackageComponent, VpackageOverviewComponent, PackagedataComponent, PackagecontentComponent, ApplicationsComponent, PackagesummaryComponent, VpackageReleaseGridComponent],
  imports: [CommonModule, RouterModule, HttpClientModule, FormsModule, AgGridModule, CustomGridsModule, ValidationModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    VpackageReleaseGridComponent
  ]
})
export class PackageModule {
}
