import {Component, Input} from '@angular/core';
import {ColDef, GetDataPath, GridApi, GridReadyEvent} from "ag-grid-community";
import {CustomLoadingOverlayComponent} from "../custom-loading-overlay/custom-loading-overlay.component";

@Component({
  selector: 'app-custom-tree-grid',
  templateUrl: './custom-tree-grid.component.html',
  styleUrl: './custom-tree-grid.component.css'
})
export class CustomTreeGridComponent {
  @Input() columnDefs!: ColDef[];
  @Input() defaultColDef!: ColDef;
  @Input() autoGroupColumnDef!: ColDef;
  @Input() rowData?: any[];
  @Input() getDataPath!: GetDataPath;
  @Input() pageSize: { value: number } = {value: 10};
  currentPage: { value: number } = {value: 1};

  gridApi!: GridApi;
  public loadingOverlayComponent: any = CustomLoadingOverlayComponent;

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  setGridPage(event: Event): void {
    const customEvent = event as CustomEvent;
    this.gridApi?.paginationGoToPage(customEvent.detail - 1);
    this.currentPage.value = customEvent.detail;
  }

  getTotalPages(): number {
    return Math.ceil((this.rowData?.filter((item) => item.id.length == 1).length || 0) / this.pageSize.value);
  }
}
