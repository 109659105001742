<wb-grid-col mq6="5">
  <wb-input
    as="input"
    name="name"
    inputmode="text"
    class="spacing-xs"
    (wbinput)="filterInputChange($event)"
  >
    <span slot="label">Search</span>
  </wb-input>
</wb-grid-col>
<wb-tab-content
  name="my-vecus"
  slot="content"
  [selected]="selectedTabId == 'my-vecus'"
  style="padding: 0"
>
  <app-grid-by-user
    [rowData]="filteredRowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
  ></app-grid-by-user>
</wb-tab-content>
<wb-tab-content name="all-vecus" slot="content" [selected]="selectedTabId == 'all-vecus'" style="padding: 0">
  <app-custom-grid
  [rowData]="filteredRowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
  >
  </app-custom-grid>
</wb-tab-content>
