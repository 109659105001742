<wb-button-group>
  <!--   JOB RUN -->
  <wb-tooltip position="top" *ngIf="showJobRunCancel">
    <wb-button size="m" variant="tertiary" (click)="cancelClicked()">
      Cancel
    </wb-button>
    <div slot="content">Cancel {{ toolTip }} Run</div>
  </wb-tooltip>
  <wb-tooltip position="top" *ngIf="showJobRunDownload">
    <wb-round-button size="m" (click)="downloadClicked()">
      <wb-icon class="icon-color" name="bds/download/24"></wb-icon>
      Details
    </wb-round-button>
    <div slot="content">Download {{ toolTip }} Report</div>
  </wb-tooltip>
</wb-button-group>
