import { Injectable } from '@angular/core';
import { CapabilitiesService } from 'src/app/services/capabilities/capabilities.service';
import { PackageGet, PackagePost, VecuLevel } from '@vtp/vtpcap-client-ts';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class VpackageService {
  constructor(
    private capabilitiesService: CapabilitiesService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  selectedPackageNames: string[] = [];
  diagnosticNames: string[] = [];

  private nextClickSource = new Subject<void>();
  nextClick$ = this.nextClickSource.asObservable();

  simFramework: string = '';

  typeList: string[] = ['vECUPKG', 'vDPKG', 'vCPKG', 'vGPKG'];
  packageTypeMap: { [id: string]: string } = {
    vECUPKG: 'vECU package',
    vDPKG: 'Domain package',
    vCPKG: 'Car package',
    vGPKG: 'Generic package',
  };

  levelMap: { [id: string]: string } = {
    lvl1: 'LEVEL-1',
    lvl2: 'LEVEL-2',
    lvl3: 'LEVEL-3',
    lvl4: 'LEVEL-4',
  };

  ecuPackages: any[] = [];
  filteredArray: any[] = [];

  PACKAGE_TYPE_MAP = {
    vECUPKG: 'ECUPKG',
    vDPKG: 'DPKG',
    vGPKG: 'GPKG',
    vCPKG: 'CPKG',
  };

  level: string = '';

  packageOverview: PackageGet = {
    model_series: [],
    name: '',
    type: 'vECUPKG',
    revision: '',
    id: '',
    created_at: '',
    created_by: '',
    deleted: false,
    release_information: { count: '' },
    latest_revision: '',
    provider: 'vtp',
  };
  modelSeries: string[] = [''];
  vPackageID: string = '';
  packageOverviewAppCount?: number;

  // vpackage?: PackageListDto = {id: '', name: ''};
  ngOnInitMode: boolean = false;
  modelSeriesList?: any[];

  packageDataPost?: PackagePost = {
    model_series: [{ name: '' }],
    name: '',
    type: 'vECUPKG',
    description: '',
    packages: [],
    provider: 'vtp',
    application_instances: {
      diagnostic_name: '',
      ncd_name: '',
      vecu_level: VecuLevel.Lvl1,
      sensor_actors: [],
      hardware_security_module: { name: '' },
      vecu_applications: [],
    },
  };
  vpackages?: any[];
  spinner: boolean = false;

  getContainedPackages(id: string): string {
    return this.vpackages?.find((pkg) => pkg.id == id).name;
  }

  countApps(): void {
    let sensorActorCount: number = 0;
    let appCount: number = 0;
    if (this.packageOverview.application_instances?.sensor_actors) {
      sensorActorCount =
        this.packageOverview.application_instances?.sensor_actors.length;
    }
    if (this.packageOverview.application_instances?.vecu_applications) {
      appCount =
        this.packageOverview.application_instances?.vecu_applications.length;
    }

    this.packageOverviewAppCount = sensorActorCount + appCount;
    if (this.packageOverview.application_instances?.hardware_security_module) {
      this.packageOverviewAppCount += 1;
    }
  }

  loadModelSeriesList(): void {
    this.capabilitiesService.getModelSeries().subscribe({
      next: (response) => {
        this.modelSeriesList = response.data.items;
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  postPackage(): void {
    this.spinner = true;
    if (this.packageDataPost?.type != 'vECUPKG') {
      this.packageDataPost!.application_instances = undefined;
    }
    if (this.packageDataPost?.type == 'vECUPKG') {
      this.packageDataPost.packages = undefined;
      this.packageDataPost.application_instances?.vecu_applications?.forEach(
        (app) => {
          app.simulation_framework = this.simFramework;
        }
      );
    }

    if (
      this.packageDataPost?.application_instances?.hardware_security_module
        ?.name == ''
    ) {
      delete this.packageDataPost.application_instances
        .hardware_security_module;
    }
    if (!this.packageDataPost?.application_instances?.sensor_actors?.length) {
      delete this.packageDataPost?.application_instances?.sensor_actors;
    }
    this.capabilitiesService.postPackage(this.packageDataPost!).subscribe({
      next: (response) => {
        this.notificationService.notifySuccess = true;
        this.notificationService.notificationSuccesMessage = response.message;
      },
      complete: () => {
        this.clearAll();
        this.spinner = false;
        this.router.navigate(['vpackages']);
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  editPackage(): void {
    this.spinner = true;
    if (
      this.packageDataPost?.application_instances?.hardware_security_module
        ?.name == ''
    ) {
      delete this.packageDataPost.application_instances
        .hardware_security_module;
    }
    if (!this.packageDataPost?.application_instances?.sensor_actors?.length) {
      delete this.packageDataPost?.application_instances?.sensor_actors;
    }

    const data = this.packageDataPost as Record<string, any>;
    const excludedKeys = ['type'];
    const filteredJsonObject = Object.keys(data)
      .filter((key) => !excludedKeys.includes(key))
      .reduce((obj: any, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    if (this.packageDataPost?.type == 'vECUPKG') {
      filteredJsonObject.packages = undefined;
    }

    this.capabilitiesService
      .UpdatePackage(this.vPackageID, filteredJsonObject)
      .subscribe({
        next: (response) => {
          this.notificationService.notifySuccess = true;
          this.notificationService.notificationSuccesMessage = response.message;
        },
        complete: () => {
          this.clearAll();
          this.spinner = false;
          this.router.navigate(['vpackages']);
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });
  }

  setngOnInitMode(mode: boolean): void {
    this.ngOnInitMode = mode;
  }

  getngOnInitMode() {
    return this.ngOnInitMode;
  }

  clearAll(): void {
    this.nextClickSource = new Subject<void>();
    this.nextClick$ = this.nextClickSource.asObservable();

    this.packageOverview = {
      model_series: [],
      name: '',
      type: 'vECUPKG',
      revision: '',
      id: '',
      created_at: '',
      created_by: '',
      deleted: false,
      release_information: { count: '' },
      latest_revision: '',
      provider: 'vtp',
    };
    this.modelSeries = [];
    this.vPackageID = '';

    // this.vpackage = {id: '', name: ''};

    this.packageDataPost = {
      model_series: [{ name: '' }],
      name: '',
      type: 'vECUPKG',
      description: '',
      packages: [],
      provider: 'vtp',
      application_instances: {
        diagnostic_name: '',
        ncd_name: '',
        vecu_level: VecuLevel.Lvl1,
        sensor_actors: [],
        hardware_security_module: { name: '' },
        vecu_applications: [],
      },
    };
    this.level = ''; //vtp-1649
    this.simFramework = ''; //vtp-1649
    this.selectedPackageNames = [];
  }

  compareArrays(arr1: string[], arr2: string[]): boolean {
    return arr1.sort().toString() === arr2.sort().toString();
  }

  filterByParams(
    modelSeries: any[],
    packageType: keyof typeof this.PACKAGE_TYPE_MAP
  ): void {
    this.filteredArray = this.ecuPackages;
    if (modelSeries[0].name) {
      const modelSeriesNames = modelSeries.map((series) => series.name);

      this.filteredArray = this.filteredArray.filter((ecuPackage: any) =>
        ecuPackage.modelSeries.some((series: any) =>
          modelSeriesNames.includes(series.name)
        )
      );
    }
    if (packageType) {
      this.filteredArray = this.filteredArray.filter(
        (ecuPackage: any) =>
          ecuPackage.type === this.PACKAGE_TYPE_MAP[packageType]
      );
    }

  }

  filterByDiagnosticNames(): void{
    this.filterByParams(this.packageDataPost!.model_series, this.packageDataPost!.type);
    this.filteredArray = this.filteredArray.filter((ecuPackage: any) =>
      ecuPackage.ecuNames.sort().toString() === this.diagnosticNames.sort().toString()    
    );

  }
}
