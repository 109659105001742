import {Component} from '@angular/core';
import {HeaderTitleService} from 'src/app/services/header/header-title.service';
import {VtestingJobService} from 'src/app/test-job/services/vtesting-job.service';
import {VpackageService} from 'src/app/package/services/vpackage.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-packagesummary',
  templateUrl: './packagesummary.component.html',
  styleUrls: ['./packagesummary.component.css'],
})
export class PackagesummaryComponent {
  title = 'Create new vECU package';
  description =
    'Create a new vECU package. Enter some basic data and add vECU applications or vECU packages to your new vECU package.';

  constructor(
    public vPackageService: VpackageService,
    public vtestingJobService: VtestingJobService,
    public headerService: HeaderTitleService,
    private router: Router
  ) {
  }

  ngOnInit() {
    if (this.vPackageService.modelSeries[0] == '') {
      return this.router.navigate(['/vpackages']);
    } else {
      if (this.vtestingJobService.getEditMode()) {
        this.description =
          'Edit vECU package. Change some basic data and modify vECU applications or vECU packages to your vECU package.';
        this.title = 'Edit vECU package';
      }
      this.headerService.pageDescription = this.description;
      this.headerService.secondaryButtonText = 'Back'
      this.headerService.pageTitle = this.title;
    }
  }
}
