import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomGridComponent} from './custom-grid/custom-grid.component';
import {CustomLoadingOverlayComponent} from './custom-loading-overlay/custom-loading-overlay.component';
import {AgGridModule} from 'ag-grid-angular';
import {CustomNestedGridComponent} from './custom-nested-grid/custom-nested-grid.component';
import {ListSelectionGridComponent} from './list-selection-grid/list-selection-grid.component';
import {CustomTreeSelectionGridComponent} from "./custom-tree-selection-grid/custom-tree-selection-grid.component";
import {GridByUserComponent} from "./grid-by-user/grid-by-user.component";
import {CustomTreeGridComponent} from "./custom-tree-grid/custom-tree-grid.component";

@NgModule({
  declarations: [
    GridByUserComponent,
    CustomGridComponent,
    CustomLoadingOverlayComponent,
    CustomNestedGridComponent,
    ListSelectionGridComponent,
    CustomTreeSelectionGridComponent,
    CustomTreeGridComponent
  ],
  imports: [CommonModule, AgGridModule],
  exports: [
    GridByUserComponent,
    CustomGridComponent,
    CustomLoadingOverlayComponent,
    CustomNestedGridComponent,
    ListSelectionGridComponent,
    CustomTreeSelectionGridComponent,
    CustomTreeGridComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomGridsModule {
}
