<wb-control-error *ngIf="type == format.None">
  Please enter a valid {{ name }}
</wb-control-error>
<wb-control-error *ngIf="type == format.MultiSelect">
  Please select at least one {{ name }}
</wb-control-error>
<wb-control-error *ngIf="type == format.Pick">
  Please pick a {{ name }}
</wb-control-error>
<wb-control-error *ngIf="type == format.AlphaNumericUnderscore">
  {{ name }} need to be _ seperated letters or numbers
</wb-control-error>
<wb-control-error *ngIf="type == format.Float">
  {{ name }} need to be a decimal number
</wb-control-error>
<wb-control-error *ngIf="type == format.Name">
  {{ name }} requires at least two characters
</wb-control-error>
<wb-control-error *ngIf="type == format.NcdRelease">
  {{ name }} need following format (2023.23.10)
</wb-control-error>
<wb-control-error *ngIf="type == format.NoWhiteSpace">
  No whitespaces allowed for {{ name }}
</wb-control-error>
<wb-control-error *ngIf="type == format.Number">
  {{ name }} only contains numbers
</wb-control-error>
<wb-control-error *ngIf="type == format.SemanticVersion">
  {{ name }} need following format (1.0.0)
</wb-control-error>
<wb-control-error *ngIf="type == format.YearWeekPatch">
  {{ name }} need following format (24/10/10)
</wb-control-error>
<wb-control-hint *ngIf="type == format.Info">
  {{ name }}
</wb-control-hint>
