import {TestJobOverview} from './test-job/components/test-job-overview/test-job-overview.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TestJobsOverviewComponent} from './test-job/components/test-jobs-overview/test-jobs-overview.component';
import {AppUploadOverviewComponent} from './components/app-upload-overview/app-upload-overview.component';
import {PackageComponent} from './package/package.component';
import {UploadDialogComponent} from './package/components/upload-dialog/upload-dialog.component';
import {CallbackComponent} from './components/auth/callback/callback.component';
import {VpackageOverviewComponent} from './package/components/vpackage-overview/vpackage-overview.component';
import {PackageReleaseComponent} from './package-release/package-release.component';
import {ErrorComponent} from './components/error/error.component';
import {
  TestJobFlowBaseDataComponent
} from "./test-job/components/test-job-flow/test-job-flow-base-data/test-job-flow-base-data.component";
import {TestJobFlowComponent} from "./test-job/components/test-job-flow/test-job-flow.component";
import {
  TestJobFlowTestComponent
} from "./test-job/components/test-job-flow/test-job-flow-test/test-job-flow-test.component";
import {
  TestJobFlowSummaryComponent
} from "./test-job/components/test-job-flow/test-job-flow-summary/test-job-flow-summary.component";
import {PackagedataComponent} from "./package/components/create-vecu-package/packagedata/packagedata.component";
import {
  PackagecontentComponent
} from "./package/components/create-vecu-package/packagecontent/packagecontent.component";
import {ApplicationsComponent} from "./package/components/create-vecu-package/applications/applications.component";
import {
  PackagesummaryComponent
} from "./package/components/create-vecu-package/packagesummary/packagesummary.component";
import {
  VpackageReleaseOverviewComponent
} from "./package-release/components/vpackage-release-overview/vpackage-release-overview.component";
import {
  VpackageReleaseFormComponent
} from "./package-release/components/vpackage-release-form/vpackage-release-form.component";
import {
  VpackageReleaseBaseDataComponent
} from './package-release/components/vpackage-release-base-data/vpackage-release-base-data.component';
import {
  VpackageReleaseApplicationsComponent
} from './package-release/components/vpackage-release-applications/vpackage-release-applications.component';
import {
  SimulationOverviewComponent
} from "./simulation-builder/components/simulation-overview/simulation-overview.component";
import {
  CoSimulationBundleDetailComponent
} from "./simulation-builder/components/co-simulation-bundle-detail/co-simulation-bundle-detail.component";
import {
  SimulationBundleDetailComponent
} from "./simulation-builder/components/simulation-bundle-detail/simulation-bundle-detail.component";
import {
  SimulationBuilderFlowComponent
} from "./simulation-builder/components/simulation-builder-flow-component/simulation-builder-flow/simulation-builder-flow.component";
import {
  SimulationBuilderFlowBaseDataComponent
} from "./simulation-builder/components/simulation-builder-flow/simulation-builder-flow-base-data/simulation-builder-flow-base-data.component";
import {
  SimulationBuilderFlowSummaryComponent
} from "./simulation-builder/components/simulation-builder-flow/simulation-builder-flow-summary/simulation-builder-flow-summary.component";
import {
  SimulationBuilderFlowRefinementComponent
} from "./simulation-builder/components/simulation-builder-flow/simulation-builder-flow-refinement/simulation-builder-flow-refinement.component";
import {
  SimulationBuilderFlowScopeComponent
} from "./simulation-builder/components/simulation-builder-flow/simulation-builder-flow-scope/simulation-builder-flow-scope.component";
import { VirtualRemoteTestBenchJobsOverviewComponent } from './remote-testbench/components/remote-testbench-jobs-overview/remote-testbench-jobs-overview.component';
import { VirtualRemoteTestBenchJobOverviewComponent } from './remote-testbench/components/remote-testbench-job-overview/remote-testbench-job-overview.component';
import { VirtualRemoteTestBenchJobFlowComponent } from './remote-testbench/components/remote-testbench-job-flow/remote-testbench-job-flow.component';
import { VirtualRemoteTestBenchJobFlowBaseDataComponent } from './remote-testbench/components/remote-testbench-job-flow/remote-testbench-job-flow-base-data/remote-testbench-job-flow-base-data.component';
import { VirtualRemoteTestBenchJobFlowTestComponent } from './remote-testbench/components/remote-testbench-job-flow/remote-testbench-job-flow-test/remote-testbench-job-flow-test.component';
import { VirtualRemoteTestBenchJobFlowSummaryComponent } from './remote-testbench/components/remote-testbench-job-flow/remote-testbench-job-flow-summary/remote-testbench-job-flow-summary.component';
import { AuthGuard } from './services/auth/auth.guard';

const routes: Routes = [
  {path: 'callback', component: CallbackComponent},
  {path: '', redirectTo: '/vtesting-jobs', pathMatch: 'full'},
  {
    path: 'error',
    component: ErrorComponent,
  },

  //path: ':id' id will act as a parameter e.g. http://localhost:4200/vtesting-jobs/033d1b8d-32e2-4bf5-8745-6011b190a7f1 -> id
  {
    path: 'vtesting-jobs',
    component: TestJobsOverviewComponent,
    canActivate: [AuthGuard],
    /*  children: [
      {
        path: ':id',
        component: JobDefinitionOverviewComponent,
      },
    ], */
  },
  {
    path: 'remote-testbench',
    component: VirtualRemoteTestBenchJobsOverviewComponent,
     canActivate: [AuthGuard],
    /*  children: [
      {
        path: ':id',
        component: JobDefinitionOverviewComponent,
      },
    ], */
  },
  {
    path: 'simulations',
    component: SimulationOverviewComponent,
    canActivate: [AuthGuard],
    /*  children: [
      {
        path: ':id',
        component: JobDefinitionOverviewComponent,
      },
    ], */
  },
  {
    path: 'co-simulation/:id/overview',
    component: CoSimulationBundleDetailComponent,
    canActivate: [AuthGuard],
    /*  children: [
      {
        path: ':id',
        component: JobDefinitionOverviewComponent,
      },
    ], */
  },
  {
    path: 'simulation/:id/overview',
    component: SimulationBundleDetailComponent,
    canActivate: [AuthGuard],
    /*  children: [
      {
        path: ':id',
        component: JobDefinitionOverviewComponent,
      },
    ], */
  },
  {
    path: 'simulation',
    component: SimulationBuilderFlowComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'general',
        component: SimulationBuilderFlowBaseDataComponent,
        data: {step: 1},
      },
      {
        path: 'scope',
        component: SimulationBuilderFlowScopeComponent,
        data: {step: 2},
      },
      {
        path: 'refinement',
        component: SimulationBuilderFlowRefinementComponent,
        data: {step: 3},
      },
      {
        path: 'summary',
        component: SimulationBuilderFlowSummaryComponent,
        data: {step: 4},
      },
    ],
  },
  {
    path: 'remote-testbench/remote-testbench-job/:id/overview',
    component: VirtualRemoteTestBenchJobOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'vtesting-jobs/vtesting-job/:id/overview',
    component: TestJobOverview,
    canActivate: [AuthGuard],
  },
  {
    path: 'remote-testbench-job',
    component: VirtualRemoteTestBenchJobFlowComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'general',
        component: VirtualRemoteTestBenchJobFlowBaseDataComponent,
        data: {step: 1},
      },
      {
        path: 'job', 
        component: VirtualRemoteTestBenchJobFlowTestComponent, 
        data: {step: 2}
      },
      {
        path: 'summary',
        component: VirtualRemoteTestBenchJobFlowSummaryComponent,
        data: {step: 3},
      },
    ],
  },
  {
    path: 'vtesting-job',
    component: TestJobFlowComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'general',
        component: TestJobFlowBaseDataComponent,
        data: {step: 1},
      },
      {path: 'job', component: TestJobFlowTestComponent, data: {step: 2}},
      {
        path: 'summary',
        component: TestJobFlowSummaryComponent,
        data: {step: 3},
      },
    ],
  },
  {
    path: 'vpackages/vpackage/:id/overview',
    component: VpackageOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'vpackage-release/:id/overview',
    component: VpackageReleaseOverviewComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'vpackages',
    component: AppUploadOverviewComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'vpackagerelease/releasedata',
  //   component: VpackagereleasedataComponent,
  //   // canActivate: [AuthGuard],
  // },
  // {
  //   path: 'vpackagerelease/upload',
  //   component: UploadDialogComponent,
  //   // canActivate: [AuthGuard],
  // },
  {
    path: 'vpackage',
    component: PackageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'packagedata',
        component: PackagedataComponent,
        data: {step: 1},
      },
      {
        path: 'content',
        component: PackagecontentComponent,
        data: {step: 2},
      },
      {
        path: 'applications',
        component: ApplicationsComponent,
        data: {step: 3},
      },

      {
        path: 'summary',
        component: PackagesummaryComponent,
        data: {step: 4},
      },
    ],
  },
  {
    path: 'vpackagerelease',
    component: PackageReleaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'releasedata-general',
        component: VpackageReleaseBaseDataComponent,
        data: {step: 1},
      },
      {
        path: 'releasedata',
        component: VpackageReleaseFormComponent,
        data: {step: 2},
      },
      {
        path: 'releasedata-applications',
        component: VpackageReleaseApplicationsComponent,
        data: {step: 3},
      },
      {
        path: 'upload',
        component: UploadDialogComponent,
        data: {step: 4},
      },
    ],
  },
  {path: '**', redirectTo: '/vtesting-jobs'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
