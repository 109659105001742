import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CapabilitiesService } from 'src/app/services/capabilities/capabilities.service';

@Component({
  selector: 'app-button-cell-renderer',
  templateUrl: './button-cell-renderer.component.html',
  styleUrl: './button-cell-renderer.component.css',
})
export class ButtonCellRendererComponent implements ICellRendererAngularComp {
  @Input() params?: ICellRendererParams;
  @Output() cancel = new EventEmitter<void>();

  showJobRunDownload: boolean = false;
  showTestDefRunDownload: boolean = false;
  showTestDefRunCancel: boolean = false;
  showJobRunCancel: boolean = false;
  test_definition_runs_status_Array: string[] = [];

  toolTip: string = '';

  constructor(/* private capabilityservice: CapabilitiesService */) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    /*     this.capabilityservice.getvTestingJob(this.params.data.id).subscribe(); */
    this.toolTip = 'vtesting_job_id' in params.data ? 'Job' : '';

    this.showJobRunDownload = 'FINISHED' == params.data.status;
    this.showJobRunCancel = [
      'CREATED',
      'QUEUED',
      'RUNNING',
      'INTEGRATION',
    ].includes(params.data.status);
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return false;
  }

  cancelClicked(): void {
    const allparams: any = this.params;
    allparams.cancelCallback();
  }

  downloadClicked(): void {
    const allparams: any = this.params;
    allparams.downloadCallBack();
  }
}
