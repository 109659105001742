import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  CellClickedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  IRowNode,
  RowDataUpdatedEvent,
  RowSelectedEvent,
} from 'ag-grid-community';
import { CustomLoadingOverlayComponent } from '../custom-loading-overlay/custom-loading-overlay.component';
import { VpackageService } from 'src/app/package/services/vpackage.service';
import { HeaderTitleService } from 'src/app/services/header/header-title.service';

@Component({
  selector: 'app-list-selection-grid',
  templateUrl: './list-selection-grid.component.html',
  styleUrl: './list-selection-grid.component.css',
})
export class ListSelectionGridComponent implements OnChanges {
  @Input() rowData?: any[];
  @Input() columnDefs?: ColDef[];
  @Input() defaultColDef?: ColDef;

  myData: any[] = [];
  gridApi?: GridApi;
  currentPage: { value: number } = { value: 1 };
  pageSize: { value: number } = { value: 10 }; // Changed to appropriate page size
  public loadingOverlayComponent: any = CustomLoadingOverlayComponent;
  public loadingOverlayComponentParams: any = {
    loadingMessage: 'Loading...',
  };

  constructor(
    private vPackageService: VpackageService,
    private headerService: HeaderTitleService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.rowData) {
      this.setRowData();
    }
  }

  setRowData() {
    if (this.gridApi) {
      this.gridApi.setRowData(this.rowData!);
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    /* this.gridApi.showNoRowsOverlay(); */
  }

  public overlayNoRowsTemplate = '<wb-text> Nothing to show</wb-text>';

  setGridPage(event: Event): void {
    const customEvent = event as CustomEvent;
    this.gridApi?.paginationGoToPage(customEvent.detail - 1);
    this.currentPage.value = customEvent.detail;
  }

  getTotalPages(): number {
    return Math.ceil((this.rowData?.length || 0) / this.pageSize.value);
  }

  resolveDiagnosticNames(vpackage: any) {
    if(vpackage.packages){
      vpackage.packages.map((vPackage: any) =>
        vPackage.packages
          ? this.resolveDiagnosticNames(vPackage.packages)
          : this.vPackageService.diagnosticNames.push(
              vPackage.application_instances.diagnostic_name
            )
      );
    } else{
      this.vPackageService.diagnosticNames.push(
        vpackage.application_instances.diagnostic_name
      )
    }
  }

  onSelectionChanged(): void {
    this.vPackageService.diagnosticNames = [];
    this.vPackageService.packageDataPost!.packages = new Array<any>();
    this.gridApi?.getSelectedRows().forEach((vpackage: any) => {
      this.vPackageService.packageDataPost!.packages?.push({
        id: vpackage.id,
      });
      this.resolveDiagnosticNames(vpackage);
    });
    this.vPackageService.filterByDiagnosticNames();

    this.headerService.primaryDisabled =
      !this.vPackageService.packageDataPost!.packages!.length;
  }

  onRowDataChanged(): void {
    const nodesToSelect: IRowNode[] = [];
    if (this.gridApi) {
      this.gridApi!.forEachNode((node: IRowNode) => {
        if (
          node.data &&
          this.vPackageService.packageDataPost?.packages?.some(
            (obj) => obj.id === node.data.id
          )
        ) {
          nodesToSelect.push(node);
        }
      });
      this.gridApi!.setNodesSelected({ nodes: nodesToSelect, newValue: true });
    }
  }
}
