import {Injectable} from '@angular/core';
import {NgModel} from '@angular/forms';
import {HeaderTitleService} from 'src/app/services/header/header-title.service';

export enum Format {
  None,
  MultiSelect,
  Pick,
  Info,
  Name,
  Float,
  YearWeekPatch,
  SemanticVersion,
  NcdRelease,
  NoWhiteSpace,
  Number,
  AlphaNumericUnderscore,
  SemanticVersionOther
}

export enum ValidationStatus {
  Invalid = "invalid",
  Touched = "touched",
  Regex = "regex",
  Length = "length",
  Loading = "loading",
}

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  private isInputInvalid: Record<string, {
    invalid: boolean,
    touched: boolean,
    regex: boolean,
    length: boolean,
    loading: boolean
  }> = {};

  constructor(private headerService: HeaderTitleService) {
  }

  initialize<T extends Record<string, string>>(formGroup: T): void {
    const existingFields = Object.keys(this.isInputInvalid);
    const formGroupFields = Object.keys(formGroup);
    const hasAllFields = formGroupFields.every(field => existingFields.includes(field));

    if (!hasAllFields) {
      this.isInputInvalid = Object.assign({}, ...formGroupFields.map((field) => ({
        [field]: {invalid: false, touched: false, regex: false, length: false, loading: false}
      })));
    }

    this.isFormInvalid();
  }

  addExtraFields<T extends Record<string, string>>(formGroup: T): void {
    Object.keys(formGroup).forEach(field => {
      this.isInputInvalid[field] = {invalid: false, touched: false, regex: false, length: false, loading: false};
    });
  }

  removeFields<T extends Record<string, string>>(formGroup: T): void {
    Object.keys(formGroup).forEach(field => delete this.isInputInvalid[field]);
    this.isFormInvalid();
  }

  getFormStatus(field: string, status: ValidationStatus) {
    return this.isInputInvalid[field][status];
  }

  isComboBoxInValid(field: string, fieldModel: NgModel | undefined, input: string, options: any[] | undefined): boolean {
    if (!options) {
      return false;
    }
    this.isInputInvalid[field].touched = this.hasFieldBeenTouched(fieldModel) || (typeof input == 'string' ? input.trim().length > 1 : false);
    this.isInputInvalid[field].invalid = options ?
      options.some((option) => option == input) ? false : (this.isInputInvalid[field].touched) :
      input.length > 0 && this.isInputInvalid[field].touched || false;
    this.isInputInvalid[field].touched ? this.isFormInvalid() : this.headerService.primaryDisabled = true;

    return !this.isInputInvalid[field].loading && this.isInputInvalid[field].invalid;
  }

  isFieldInvalid(field: string, fieldModel: NgModel | undefined, input: string, format: Format, invalidValue?: any[] ): boolean {
    this.isInputInvalid[field].touched = this.hasFieldBeenTouched(fieldModel) || (typeof input == 'string' ? format == Format.Number ? input.trim().length > 0 : input.trim().length > 1 : false);
    this.isInputInvalid[field].invalid = this.isInputInvalid[field].touched && (typeof input == 'string' ? !input.trim().length : true);
    this.isInputInvalid[field].regex = !this.isInputInvalid[field].invalid && this.isInputInvalid[field].touched && !this.isFormatValid(input, format);
    this.isInputInvalid[field].touched ? this.isFormInvalid() : this.headerService.primaryDisabled = true;
    this.isInputInvalid[field].length = format == Format.Number ? false :
      !this.isInputInvalid[field].invalid && this.isInputInvalid[field].touched && (typeof input == 'string' ? input.trim().length < 2 : true);
    if (invalidValue && invalidValue.length > 0) {
      for (let index = 0; index < invalidValue.length; index++) {
        if (input == invalidValue[index]) {
          this.isInputInvalid[field].invalid = true;
          break;
        }   
      }
    }
    return this.isInputInvalid[field].invalid || this.isInputInvalid[field].length || this.isInputInvalid[field].regex;
  }

  isRequiredMultiFieldInvalid(field: string, fieldModel: NgModel | undefined, input: string, format: Format, items: any[] | null | undefined): boolean {
    this.isInputInvalid[field].invalid = items?.length == 0;
    this.isOptionalFieldInvalid(field, fieldModel, input, format);

    return this.isInputInvalid[field].length;
  }

  isOptionalFieldInvalid(field: string, fieldModel: NgModel | undefined, input: string, format: Format): boolean {
    this.isInputInvalid[field].regex = !this.isFormatValid(input, format);
    this.isInputInvalid[field].touched = true;
    this.isInputInvalid[field].length = this.hasFieldBeenTouched(fieldModel) && (typeof input == 'string' ? input.trim().length == 1 : false);

    return this.isInputInvalid[field].length;
  }

  isMultiSelectInvalid(field: string, fieldModel: NgModel | undefined, items: any[]): boolean {
    this.isInputInvalid[field].touched = !!items.length || this.hasFieldBeenTouched(fieldModel);
    this.isInputInvalid[field].invalid = !items.length;

    return this.isInputInvalid[field].invalid && this.hasFieldBeenTouched(fieldModel);
  }

  hasFieldBeenTouched(field: NgModel | undefined): boolean {
    return field?.touched || false;
  }

  setInputFieldTouched(field: string, status: boolean): void {
    this.isInputInvalid[field].touched = status;
  }

  setInputFieldLoading(field: string, status: boolean): void {
    this.isInputInvalid[field].loading = status;
  }

  resetInputField(field: string): void {
    this.isInputInvalid[field] = {invalid: false, touched: false, regex: false, length: false, loading: false}
  }

  isFormInvalid(): void {
    this.headerService.primaryDisabled = Object.values(this.isInputInvalid).some((input) => input.invalid || !input.touched || input.regex || input.length);
  }

  isInputFloat(input: string): boolean {
    //42.1
    return /^[0-9]+(\.[0-9]+)?$/.test(input);
  }

  isInputYearWeekPatchFormat(input: string): boolean {
    //Year/Weeks/Version
    return /^[0-9]{2}\/[0-9]{2}\/[0-9]{2}$/.test(input);
  }

  isInputSemanticVersionFormat(input: string): boolean {
    //1.0.0
    return /^[0-9]+(\.([0-9]+\.)*[0-9]+)*$/.test(input);
  }

  isInputSemanticVersionFormatOther(input: string): boolean {
    //1.0.0_0000 
    return /^\d+(\.\d+)*(_\d+)?$/.test(input);
  }

  isInputNcdReleaseFormat(input: string): boolean {
    //2023.23.10
    return /^2[0-3][0-9]{2}.[0-9]{2}.[0-9]{2}$/.test(input);
  }

  isInputNoWhiteSpaceFormat(input: string): boolean {
    //application1.0_name
    return /^\S+$/.test(input);
  }

  isInputNumber(input: string): boolean {
    //666
    return /^[0-9]+$/.test(input);
  }

  isInputAlphaNumericUnderscoreFormat(input: string): boolean {
    //EZS174_174SZE
    return /^[0-9a-zA-Z]+(_[0-9a-zA-Z]+)*$/.test(input);
  }

  isFormatValid(input: string, format: Format): boolean {
    if (format == Format.Float)
      return this.isInputFloat(input);
    if (format == Format.YearWeekPatch)
      return this.isInputYearWeekPatchFormat(input);
    if (format == Format.SemanticVersion)
      return this.isInputSemanticVersionFormat(input);
    if (format == Format.SemanticVersionOther)
      return this.isInputSemanticVersionFormatOther(input);  
    if (format == Format.NcdRelease)
      return this.isInputNcdReleaseFormat(input);
    if (format == Format.NoWhiteSpace)
      return this.isInputNoWhiteSpaceFormat(input);
    if (format == Format.AlphaNumericUnderscore)
      return this.isInputAlphaNumericUnderscoreFormat(input);
    if (format == Format.Number)
      return this.isInputNumber(input);

    return true;
  }
}
