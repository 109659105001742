<wb-grid [fullWidth]="true">
    <wb-grid-row class="gutter" style="padding-top: 24px">
      <wb-grid-col mq6="5">
        <wb-heading size="xs" class="spacing-xs">Enter base data</wb-heading>
        <wb-input
          as="input"
          [(ngModel)]="VirtualRemoteTestBenchVtestingJobService.base_data.name"
          name="name"
          ngDefaultControl
          inputmode="text"
          class="spacing-xs"
          required
          #name="ngModel"
          [error]="
            validationService.isFieldInvalid(
              FormGroup.Name,
              name,
              VirtualRemoteTestBenchVtestingJobService.base_data.name,
              Format.Name
            )
          "
        >
          <span slot="label">Remote testbench name*</span>
          <div slot="message">
            <app-validation-notification
              name="Job name"
              [type]="Format.None"
              *ngIf="
                validationService.getFormStatus(
                  FormGroup.Name,
                  ValidationStatus.Invalid
                ) &&
                validationService.getFormStatus(
                  FormGroup.Name,
                  ValidationStatus.Touched
                )
              "
            >
            </app-validation-notification>
            <app-validation-notification
              name="Job name"
              [type]="Format.Name"
              *ngIf="
                validationService.getFormStatus(
                  FormGroup.Name,
                  ValidationStatus.Length
                ) &&
                validationService.getFormStatus(
                  FormGroup.Name,
                  ValidationStatus.Touched
                )
              "
            >
            </app-validation-notification>
          </div>
        </wb-input>
        <wb-combobox class="spacing-xs">
          <wb-combobox-input
            slot="input"
            [(ngModel)]="
            VirtualRemoteTestBenchVtestingJobService.base_data.basic_configuration.model_series
            "
            [disabled]="editMode"
            name="modelSeries"
            ngDefaultControl
            required
            #modelSeries="ngModel"
            [error]="
              validationService.isComboBoxInValid(
                FormGroup.ModelSeries,
                modelSeries,
                VirtualRemoteTestBenchVtestingJobService.base_data.basic_configuration.model_series,
                getModelSeriesOptions()
              )
            "
            (wbcomboboxinput)="modelSeriesChange($event)"
          >
            <label slot="label">Model series*</label>
          </wb-combobox-input>
          <wb-combobox-list
            no-items-selected-text="No model series selected"
            no-items-found-text="No model series found"
            (wbcomboboxlistitemselectionchange)="modelSeriesChange($event)"
          >
            <wb-combobox-list-item
              *ngFor="let line of vehicleLines?.items"
              [value]="line.name"
            >
              {{ line.name }}
            </wb-combobox-list-item>
          </wb-combobox-list>
          <div slot="message">
            <app-validation-notification
              name="model series"
              [type]="Format.Pick"
              *ngIf="
                validationService.getFormStatus(
                  FormGroup.ModelSeries,
                  ValidationStatus.Invalid
                ) &&
                validationService.getFormStatus(
                  FormGroup.ModelSeries,
                  ValidationStatus.Touched
                )
              "
            >
            </app-validation-notification>
          </div>
        </wb-combobox>
        <wb-input
          as="input"
          class="spacing-xs"
          [(ngModel)]="VirtualRemoteTestBenchVtestingJobService.base_data.further_notes"
          inputmode="text"
          name="furtherNotes"
          ngDefaultControl
        >
          <span slot="label">Further notes</span>
        </wb-input>
      </wb-grid-col>
      <wb-grid-col mq6="5" id="hide">
        <wb-heading size="xs" class="spacing-bot-24"
        >Continuous testing
          <!--   <wb-tooltip position="top" position="end">
            <wb-round-button size="m">
              <wb-icon name="bds/info-circle/16"></wb-icon>
              Details
            </wb-round-button>
            <div slot="content">With this option every new package release is automatically tested.</div>
          </wb-tooltip> -->
        </wb-heading>
        <wb-toggle
          name="ct-mode"
          [checked]="VirtualRemoteTestBenchVtestingJobService.ToggleStatus"
          (wbchange)="showToolTip($event)"
        >
          <wb-text size="m">{{
              controlHint
            }}
          </wb-text>
          <!-- <wb-control-hint slot="message" id="IdOfHint" size="m"></wb-control-hint> -->
        </wb-toggle>
      </wb-grid-col>
    </wb-grid-row>
  </wb-grid>
  
