import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {VpackageService} from './services/vpackage.service';
import {HeaderTitleService} from '../services/header/header-title.service';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {VtestingJobService} from '../test-job/services/vtesting-job.service';
import {Subscription} from "rxjs";
import {ModalService} from "../services/modal/modal.service";

@Component({
  selector: 'app-upload',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css'],
})
export class PackageComponent implements OnInit, OnDestroy {
  title = 'Create new vECU package';
  description =
    'Create a new vECU package. Enter some basic data and add vECU Applications or vECU Packages to your new vECU Package.';
  primaryButtonText = 'Next';
  secondaryButtonText = 'Back';
  tertiaryButtonText = 'Cancel';
  EditButtonText = 'Save';
  packagetype: string = '';

  currentStep: number = 1;

  discardModalTitle: string = `Discard ${(this.vtestingJobService.getEditMode() ? "edit" : "create")} vECU package?`;
  discardModalDescription: string = "You will lose all unsaved changes made to the data.";
  discardModalSubscription: Subscription = this.modalService.confirmDiscard().subscribe(discard => {
    if (discard) {
      this.vPackageService.clearAll();
      this.router.navigate(['vpackages']);
    }
  })

  constructor(
    private router: Router,
    public vPackageService: VpackageService,
    private modalService: ModalService,
    public headerService: HeaderTitleService,
    public breadcrumbService: BreadcrumbsService,
    public vtestingJobService: VtestingJobService
  ) {
  }

  ngOnInit() {
    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    this.initHeaderActions();
    this.initBreadcrumbs();
  }

  private initHeaderActions() {
    this.headerService.primaryButtonText = this.primaryButtonText;
    this.headerService.secondaryButtonText = this.currentStep === 1 ? '' : this.secondaryButtonText;
    this.headerService.tertiaryButtonText = this.tertiaryButtonText;

    setTimeout(() => this.setPrimaryClick(), 500);

    this.headerService.secondaryClick$.subscribe(() => {
      this.backOneStep(); // Call the onNextClick function when the event is triggered
    });

    this.headerService.tertiaryClick$.subscribe(() => {
      this.cancel(); // Call the onNextClick function when the event is triggered
    });
  }

  private initBreadcrumbs() {
    this.breadcrumbService.setBreadcrumbs([
      {
        label: 'vECU packages',
        route: '/vpackages',
      },
      {
        label: (this.vtestingJobService.getEditMode() ? 'Edit' : 'Create') + ' vECU package',
        route: ''
      }
    ]);
  }

  ngOnDestroy(): void {
    this.discardModalSubscription.unsubscribe();
  }

  setPrimaryClick(): void {
    this.headerService.primaryClick$.subscribe(() => {
      this.nextStep(); // Call the onNextClick function when the event is triggered
    });
  }

  setPrimaryClickAgain(): void {
    this.headerService.primaryClick$.subscribe(() => {
      this.createPackage(); // Call the onNextClick function when the event is triggered
    });
  }

  nextStep(): void {
    this.currentStep++;
    this.vPackageService.setngOnInitMode(true);

    if (
      this.vPackageService.packageDataPost!.type === 'vECUPKG' &&
      this.currentStep === 2
    ) {
      this.currentStep++;
    }

    if (
      this.vPackageService.packageDataPost!.type !== 'vECUPKG' &&
      this.currentStep === 3
    ) {
      this.currentStep++;
    }

    if (this.currentStep == 4) {
      this.headerService.clearPrimaryClickFunction();
      if (this.vtestingJobService.getEditMode()) {
        this.headerService.primaryButtonText = this.EditButtonText;
      } else {
        this.headerService.primaryButtonText = `Create ${this.vPackageService.packageDataPost?.type} package`;
      }
      setTimeout(() => this.setPrimaryClickAgain(), 500);
    }
    const path = this.findPath();
    this.router.navigate(['vpackage/' + path]);
  }

  backOneStep(): void {
    this.currentStep--;
    if (
      this.vPackageService.packageDataPost!.type === 'vECUPKG' &&
      this.currentStep === 2
    ) {
      this.currentStep--;
      this.headerService.primaryButtonText = 'Next';
    }

    if (
      this.vPackageService.packageDataPost!.type !== 'vECUPKG' &&
      this.currentStep === 3
    ) {
      this.currentStep--;
      this.headerService.primaryButtonText = 'Next';
    }
    this.headerService.clearPrimaryClickFunction();
    this.headerService.primaryButtonText = this.primaryButtonText;
    this.headerService.secondaryButtonText = this.currentStep === 1 ? '' : 'Back'
    setTimeout(() => this.setPrimaryClick(), 1000);
    const path = this.findPath();
    this.router.navigate(['vpackage/' + path]);
    this.vPackageService.setngOnInitMode(
      this.vPackageService.getngOnInitMode()
    );
  }

  cancel(): void {
    this.currentStep = 1;
    this.modalService.initializeCustomModal("discard", this.discardModalTitle, this.discardModalDescription);
  }

  createPackage(): void {
    if (this.vtestingJobService.getEditMode()) {
      this.vPackageService.editPackage();
    } else this.vPackageService.postPackage();
  }

  findPath(): string {
    let vPackageRoutes = this.router.config.filter(this.isVPackageRoute);
    let uploadRoutes = vPackageRoutes?.[0]?.children;
    let path = uploadRoutes?.find(
      (route) => route.data?.['step'] === this.currentStep
    )?.path;
    return path!;
  }

  isVPackageRoute(route: any) {
    if (route.path == 'vpackage') {
      return route;
    }
    return false;
  }
}
