<ag-grid-angular
  class="ag-theme-wb spacing-top-xs"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [loadingOverlayComponent]="loadingOverlayComponent"
  [loadingOverlayComponentParams]="loadingOverlayComponentParams"
  [masterDetail]="masterDetail"
  [detailCellRendererParams]="detailCellRendererParams"
  (gridReady)="onGridReady($event)"
  [isRowMaster]="isRowMaster"
  [pagination]="true"
  [paginationPageSize]="10"
  unSortIcon
  domLayout="autoHeight"
  suppressPaginationPanel
  suppressRowClickSelection
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
>
</ag-grid-angular>
<wb-pagination
  class="spacing-top-xs"
  *ngIf="getTotalPages() > 1"
  [currentPage]="currentPage.value"
  [pages]="getTotalPages()"
  (wbchange)="setGridPage($event)"></wb-pagination>
