import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, Input } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { TestResult } from '../../interfaces/TestResults';
@Component({
  selector: 'app-my-info-icon',
  standalone: false,
  templateUrl: './my-info-icon.component.html',
  styleUrl: './my-info-icon.component.css',
})
export class MyInfoIconComponent implements ICellRendererAngularComp {
  @Input() params?: ICellRendererParams<any>;
  execution_error: string = '';
  clicked: boolean = false;
  total: string = '';
  passed: string = '';
  failed: string = '';
  inconclusive: string = '';
  strObj: any;
  prsObj: any;
  showbtnstaus: boolean = true;
  testresult: TestResult = {
    execution_error: this.execution_error,
    total: this.total,
    passed: this.passed,
    failed: this.failed,
    inconclusive: this.inconclusive,
  };

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.testresult = params.data.test_results;
  }
  onInfoClick(): void {
    if (Object.keys(this.testresult).length === 0) {
    } else {
      this.clicked = true;
      this.strObj = JSON.stringify(this.testresult);
      this.prsObj = JSON.parse(this.strObj);
      this.testresult = this.prsObj;
    }
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
  ngOnInit(): void {
    let isEmpty = true;

    for (const key in this.testresult) {
      isEmpty = false;
      break;
    }
    this.showbtnstaus = !isEmpty;
  }
}


