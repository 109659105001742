<wb-grid [fullWidth]="true" class="sub-grid">
  <wb-grid-row class="spacing-0">
    <wb-grid-col mq6="12">
      <wb-heading size="xs" class="spacing-top-xs">Test definitions</wb-heading>
    </wb-grid-col>
  </wb-grid-row>
  <ng-container
    *ngFor="
      let testDefinition of this.vTestingJobService.vtestingJobDefinitions;
      let i = index
    "
  >
    <app-test-job-flow-test-definition
      *ngIf="vEcus"
      [id]="testDefinition.id"
      [index]="i"
      [testTypes]="testTypes"
      [vEcus]="vEcus!"
      (delete)="deleteTestDefinition(testDefinition.id)"
    >
    </app-test-job-flow-test-definition>
  </ng-container>
  <wb-grid-row class="spacing-top-xs">
    <wb-grid-col mq6="12">
      <wb-button variant="secondary" (click)="addTestDefinition()">
        <wb-icon name="bds/plus/24"></wb-icon>
        Add test definition
      </wb-button>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
