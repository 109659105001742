import {Component, ElementRef, ViewChild} from '@angular/core';
import {VpackagereleaseService} from '../../services/vpackagerelease.service';
import {HeaderTitleService} from 'src/app/services/header/header-title.service';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.css'],
})
export class UploadDialogComponent {
  title = 'Upload vECU package release';
  description =
    'Create a new vECU package release. Enter some basic data and add vECU applications or vECU packages to your new vECU package.';
  primaryButtonText = 'Upload vECU package release';
  tertiaryButtonText = '';

  @ViewChild('uploader', {static: false, read: ElementRef})
  fileInput!: ElementRef;
  uploadedFile?: File;

  constructor(
    private vPackageReleaseService: VpackagereleaseService,
    public headerService: HeaderTitleService,
    public breadcrumbService: BreadcrumbsService
  ) {
  }

  spinner: boolean = false;

  ngOnInit() {
    this.headerService.clearPrimaryClickFunction();
    setTimeout(() => this.setPrimaryClick(), 500);
    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    this.headerService.primaryButtonText = this.primaryButtonText;
    this.headerService.tertiaryButtonText = this.tertiaryButtonText;

    this.headerService.primaryDisabled = true;
  }

  setPrimaryClick(): void {
    this.headerService.primaryClick$.subscribe(() => {
      this.uploadVEcu();
    });
  }

  ngAfterViewInit() {
    this.initializeFileInputEvents();
  }

  initializeFileInputEvents() {
    //Normaler File Input
    this.fileInput?.nativeElement.addEventListener('wbinput', (evt: any) => {
      if (evt.detail.value.length != 0) {
        this.headerService.primaryDisabled = false;
      }
    });
    //Drag and Drop File Input
    /*
      Drag and Drop hat ein eigenes Event und man geht davon aus das ein Liste
      an Datein eingefügt werden. Da nur ein Datei Typ erlaubt ist nimmt er die
      Datei an 1. Stelle
    */
    this.fileInput?.nativeElement.addEventListener('wbchange', (evt: any) => {
      if (evt.detail[0]) {
        this.headerService.primaryDisabled = false;
      }
    });

    this.fileInput?.nativeElement.addEventListener('wbretry', (evt: any) => {
    });

    this.fileInput?.nativeElement.addEventListener(
      'wbremove',
      async (evt: any) => {
        const confirmMessage = `A "wbremove" event was fired. \n\nAre you sure that you want to remove "${evt.detail.name}"?`;

        if (confirm(confirmMessage)) {
          await this.fileInput?.nativeElement.removeFile(evt.detail);

          this.headerService.primaryDisabled = true;
        }
      }
    );
  }

  uploadVEcu(): void {
    this.fileInput.nativeElement
      .getFiles()
      .then((files: any[]) => {
        if (files.length > 0) {
          this.uploadedFile = files[0].original;
          this.spinner = true;
          this.vPackageReleaseService.uploadVEcu(this.uploadedFile!);
        }
      });
  }
}
