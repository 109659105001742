<wb-grid [fullWidth]="true">
  <wb-grid-row>
    <wb-grid-col mq6="12" mq1="12">
      <wb-heading size="xs" class="spacing-top-xs"
      >Package info
      </wb-heading
      >
    </wb-grid-col>
  </wb-grid-row
  >
  <wb-grid-row>
    <wb-grid-col mq6="3" mq1="6">
      <wb-text class="wb-padding-top-summary" size="m" tag="div"> Model series:</wb-text>
      <wb-text class="wb-padding-top-summary" size="m" tag="div"> Type:</wb-text>
      <wb-text class="wb-padding-top-summary" size="m" tag="div"> Package name:</wb-text>
    </wb-grid-col>
    <wb-grid-col mq6="3" mq1="6">
      <wb-text class="wb-padding-top-summary" size="m" tag="div">
        {{ this.vPackageService.modelSeries }}
      </wb-text>
      <wb-text class="wb-padding-top-summary" size="m" tag="div">
        {{ this.vPackageService.packageDataPost?.type }}
      </wb-text>
      <wb-text class="wb-padding-top-summary" size="m" tag="div">
        {{ this.vPackageService.packageDataPost?.name }}
      </wb-text>
    </wb-grid-col>
  </wb-grid-row>
  <wb-grid-row *ngIf="vPackageService.packageDataPost?.type !== 'vECUPKG'">
    <wb-grid-col mq6="12">
      <wb-heading size="xs" class="wb-padding-top-summary">Contained packages</wb-heading>
    </wb-grid-col>
  </wb-grid-row>
  <div
    *ngIf="vPackageService.packageDataPost?.type !== 'vECUPKG' && vPackageService.packageDataPost!.packages!.length">
    <wb-grid-row
      *ngFor="let package of vPackageService.packageDataPost?.packages"
    >
      <wb-grid-col mq6="12">
        <wb-text class="wb-padding-top-summary" size="m" tag="div">
          {{ vPackageService.getContainedPackages(package.id) }}
        </wb-text>
      </wb-grid-col>
    </wb-grid-row>
  </div>
  <wb-grid-row *ngIf="vPackageService.packageDataPost?.type == 'vECUPKG'">
    <wb-grid-col mq6="12" mq1="12">
      <wb-heading size="xs" class="spacing-top-xs"
      >Application instance
      </wb-heading
      >
    </wb-grid-col>
    <wb-grid-col mq6="3" mq1="6">
      <wb-text class="wb-padding-top-summary" size="m" tag="div">
        Diagnostic name:
      </wb-text>
      <wb-text class="wb-padding-top-summary" size="m" tag="div"> NCD name:</wb-text>
      <wb-text class="wb-padding-top-summary" size="m" tag="div"> vECU level:</wb-text>
      <wb-text class="wb-padding-top-summary" size="m" tag="div">
        Hardware security module:
      </wb-text>
    </wb-grid-col>
    <wb-grid-col mq6="3" mq1="6">
      <wb-text class="wb-padding-top-summary" size="m" tag="div">
        {{
          vPackageService.packageDataPost?.application_instances
            ?.diagnostic_name
        }}
      </wb-text>
      <wb-text class="wb-padding-top-summary" size="m" tag="div">
        {{ vPackageService.packageDataPost?.application_instances?.ncd_name }}
      </wb-text>
      <wb-text class="wb-padding-top-summary" size="m" tag="div">
        {{
          vPackageService.packageDataPost?.application_instances
            ?.vecu_level!.valueOf()
            .replace("lvl", "LEVEL-")
        }}
      </wb-text>
      <wb-text class="wb-padding-top-summary" size="m" tag="div">
        {{
          vPackageService.packageDataPost?.application_instances
            ?.hardware_security_module?.name ? vPackageService.packageDataPost?.application_instances
            ?.hardware_security_module?.name : '-'
        }}
      </wb-text>
    </wb-grid-col>
  </wb-grid-row>

  <div
    *ngIf="vPackageService.packageDataPost?.type == 'vECUPKG' &&
    vPackageService.packageDataPost?.application_instances?.sensor_actors?.length"
  >
    <wb-grid-row>
      <wb-grid-col mq6="12" mq1="12"> 
        <wb-heading size="xs" class="spacing-top-xs"
        >Sensors Actors
        </wb-heading
        >
      </wb-grid-col>
    </wb-grid-row>
    <wb-grid-row
      *ngFor="
        let sa of vPackageService.packageDataPost?.application_instances
          ?.sensor_actors
      "
    >
      <wb-grid-col mq6="3" mq1="6">
        <wb-text class="wb-padding-top-summary" size="m" tag="div"> Name:</wb-text>
      </wb-grid-col>
      <wb-grid-col mq6="3" mq1="6">
        <wb-text class="wb-padding-top-summary" size="m" tag="div">
          {{ sa.name }}
        </wb-text>
      </wb-grid-col>
    </wb-grid-row>
  </div>
  <div *ngIf="vPackageService.packageDataPost?.type == 'vECUPKG'">
    <wb-grid-row>
      <wb-grid-col mq6="12" mq1="12">
        <wb-heading size="xs" class="spacing-top-xs"
        >vECU Applications
        </wb-heading
        >
      </wb-grid-col>
    </wb-grid-row>
    <wb-grid-row
      *ngFor="
        let vecu of vPackageService.packageDataPost?.application_instances
          ?.vecu_applications
      "
    >
      <wb-grid-col mq6="3" mq1="6">
        <wb-text class="wb-padding-top-summary" size="m" tag="div"> Name:</wb-text>
      </wb-grid-col>
      <wb-grid-col mq6="3" mq1="6">
        <wb-text class="wb-padding-top-summary" size="m" tag="div">
          {{ vecu.name }}
        </wb-text>
      </wb-grid-col>
    </wb-grid-row>
  </div>
</wb-grid>
