import {Injectable, SimpleChanges} from '@angular/core';

import {forkJoin, map, Subject} from 'rxjs';
import {CoSimulationBundlePost, Instance, NetworkNamesItem, SimulationBundlePost,} from '@vtp/vtpcap-client-ts';
import {IRowNode} from 'ag-grid-community';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {Router} from '@angular/router';
import {HeaderTitleService} from 'src/app/services/header/header-title.service';

@Injectable({
  providedIn: 'root',
})
export class SimulationBuilderService {
  modelSeries: any;

  constructor(
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService,
    private router: Router,
    private headerService: HeaderTitleService
  ) {
  }

  backStep: boolean = false; //VTP-1682
  checkSimulatedEcus: any = [];
  spinner: boolean = true;
  refinementSpinner: boolean = true;

  coSimulationBundle: CoSimulationBundlePost = {
    name: '',
    description: '',
    simulation_bundle_ids: [''],
    config: {},
    package: {id: '', revision: '0'},
  };

  baseData: SimulationBundlePost = {
    description: '',
    plant_models: {
      simulated_ecus: [],
      physics: [],
      generics: [],
    },
    model_series: '',
    name: '',
    package: {id: '', revision: ''},
    test_environment: {
      test_type: '',
      test_suite: {version: ''},
      instance: Instance.Brop || Instance.Frop,
      configuration: {
        test_groups: [],
        sub_functions: [],
        networks: [{name: ''}],
        system: '',
        function: '',
      },
    },
  };

  selectedRows: any[] = [];

  simulationId: string = '';
  simulationOverview?: any;

  simulationBundleCollection: SimulationBundlePost[] = [];
  type: string = '';
  simulatedEcus: any[] = [];
  plantModels: any = [];
  physics: any[] = [];
  generics: any[] = [];
  undecided: any[] = [];

  eventChainEcus: any = [];

  selectedPackage = {
    id: '',
    name: '',
    includedEcus: [''],
    simbun_packages: [
      {
        name: '',
        id: '',
        simulation_framework: '',
        networks: [{name: ''}],
        diagnosticNames: [''],
        simulatedEcus: [{}],
        physics: [{}],
        generics: [{}],
      },
    ],
  };

  selectedNodes: IRowNode[] = [];

  loadingSpinner: boolean = false;
  EditMode: boolean = false;
  simulationMode: string = 'event';

  unprepared: boolean = true;

  private nextClickSource = new Subject<void>();
  nextClick$ = this.nextClickSource.asObservable();

  triggerNextClick(): void {
    this.nextClickSource.next();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  prepareSimulationBundles(): void {
    let diagnosticNames: string[] = [];
    let observables: any[] = [];

    function resolveDiagnosticNames(packages: any[]) {
      packages.map((vPackage: any) =>
        vPackage.packages
          ? resolveDiagnosticNames(vPackage.packages)
          : diagnosticNames.push(vPackage.application_instances.diagnostic_name)
      );
    }
// VTP-1682
    if (this.unprepared || this.backStep) { 
      this.selectedPackage.simbun_packages.forEach((pkg) => {
        observables.push(
          this.capabilitiesService.getVPackageResolved(pkg.id).pipe(
            map((response: any) => {
              return {pkg, response};
            })
          )
        );
      });

      let observables2: any[] = [];

      forkJoin(observables).subscribe({
        next: (responses: any) => {
          responses.forEach((response: any) => {
            resolveDiagnosticNames(response.response.data?.packages!);
            response.pkg.diagnosticNames = diagnosticNames;
            diagnosticNames = [];
            observables2.push(
              this.capabilitiesService
                .getEcuOfTopology(
                  [this.baseData.model_series!],
                  response.pkg.diagnosticNames
                )
                .pipe(
                  map((res: any) => {
                    return {pkg: response.pkg, res};
                  })
                )
            );
          });
        },
        complete: () => {
          forkJoin(observables2).subscribe({
            next: (responses) => {
              responses.forEach((res) => {
                res.pkg.networks = res.res.data.items[0].networks;
              });
            },
            complete: () => {
              this.matchSimulatedEcus();
            },
          });
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });
    }
  }

  matchSimulatedEcus(): void {
    let observables: any[] = [];
    this.simulatedEcus.forEach((ecu) => {
      observables.push(
        this.capabilitiesService
          .getEcuOfTopology(
            [this.baseData.model_series!],
            [ecu.diagnostic_name]
          )
          .pipe(
            map((response: any) => {
              return {ecu: ecu, response};
            })
          )
      );
    });

    forkJoin(observables).subscribe({
      next: (responses) => {
        responses.forEach((response) => {
          response.ecu.networks = response.response.data.items[0].networks;
          let simNets = response.ecu.networks.map(
            (network: any) => network.name
          );
          this.selectedPackage.simbun_packages.forEach((pkg) => {
            if (!pkg.simulatedEcus) {
              pkg.simulatedEcus = [];
            }
            let simNetsPackage = pkg.networks.map(
              (network: any) => network.name
            );
            response.ecu.networks.forEach((net: any) => {
              if (simNetsPackage.includes(net.name)) {
                // Prüfen, ob die simulatedEcu bereits in einem anderen Paket vorhanden ist
                let isPresentInCurrentPackage =
                  this.selectedPackage.simbun_packages.some(
                    (samePkg) =>
                      samePkg == pkg &&
                      samePkg.simulatedEcus?.some(
                        (simEcu: any) =>
                          simEcu.diagnostic_name ===
                          response.ecu.diagnostic_name
                      )
                  );
                if (isPresentInCurrentPackage) {
                  return;
                }
                let isPresentInOtherPackage =
                  this.selectedPackage.simbun_packages.some(
                    (otherPkg) =>
                      otherPkg !== pkg &&
                      otherPkg.simulatedEcus?.some(
                        (simEcu: any) =>
                          simEcu.diagnostic_name ===
                          response.ecu.diagnostic_name
                      )
                  );
                if (isPresentInOtherPackage) {
                  // Wenn ja, fügen Sie es dem undecided Array hinzu und entfernen Sie es aus allen Paketen
                  response.ecu.decision = true;
                  this.undecided.push(response.ecu);
                  this.selectedPackage.simbun_packages.forEach((pkg2) => {
                    pkg2.simulatedEcus = pkg2.simulatedEcus.filter(
                      (simEcu2: any) =>
                        simEcu2.diagnostic_name !== response.ecu.diagnostic_name
                    );
                  });
                } else {
                  // Wenn nicht, fügen Sie es dem aktuellen Paket hinzu
                  pkg.simulatedEcus.push(response.ecu);
                }
              }
            });
          });
        });
      },
      complete: () => {
        this.plantModels.forEach((model: any) => {
          if (model.simulation_framework == 'Silver') {
            if (this.generics.length == 0 || this.generics.findIndex(newGen => newGen.file_name == model.file_name && newGen.type == model.type) == -1) {
              this.generics.push(model);
            }   
          } else {
            if (this.physics.length == 0 || this.physics.findIndex(newGen => newGen.file_name == model.file_name && newGen.type == model.type) == -1) {
              this.physics.push(model);
            }
          }
        });
        this.selectedPackage.simbun_packages.forEach((pkg) => {
          if (pkg.simulation_framework == 'Silver') {
            pkg.generics = this.generics;

            pkg.simulatedEcus.forEach((ecu: any) => {
              ecu.type = 'generic_ecu';
            });
          } else {
            pkg.physics = this.physics;
          }

          if (this.unprepared) {
            this.simulationBundleCollection.push(
              this.initializeSimulationBundle(
                pkg.name,
                pkg.id,
                pkg.networks,
                pkg.simulatedEcus,
                pkg.physics,
                pkg.generics
              )
            );
          }
        });
        this.spinner = false;
        this.simulationBundleCollection.forEach((simbun) => {
          this.simulatedEcus = [
            ...this.simulatedEcus,
            ...simbun.plant_models!.simulated_ecus!,
          ];
          this.checkSimulatedEcus = [
            ...this.checkSimulatedEcus,
            ...simbun.plant_models!.simulated_ecus!,
          ];
        });
        this.simulatedEcus = [...this.simulatedEcus, ...this.undecided].filter(
          (obj, index) =>
            this.simulatedEcus.findIndex(
              (item) => item.diagnostic_name === obj.diagnostic_name
            ) === index
        );


        this.simulatedEcus.forEach((simulatedEcu) => {
          const found = this.checkSimulatedEcus.some(
            (checkEcu: any) =>
              checkEcu.diagnostic_name === simulatedEcu.diagnostic_name
          );
          if (!found) {
            simulatedEcu.error = true;
            simulatedEcu.type = '';
            this.headerService.primaryDisabled = true;
          }
        });
        this.refinementSpinner = false;
        this.unprepared = false;
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  initializeSimulationBundle(
    name: string,
    packageId: string,
    networks: NetworkNamesItem[],
    simulatedEcus: any[],
    physics?: any[],
    generics?: any[]
  ): SimulationBundlePost {
    let simulation: SimulationBundlePost = {
      model_series: this.baseData.model_series,
      name: name,
      package: {
        id: packageId,
      },
      test_environment: {
        configuration: {
          networks: networks,
          system: this.baseData.test_environment.configuration.system,
          function: this.baseData.test_environment.configuration.function,
          sub_functions:
          this.baseData.test_environment.configuration.sub_functions,
        },
        instance: 'FROP',
      },
      plant_models: {
        simulated_ecus: simulatedEcus,
        physics: physics,
        generics: generics,
      },
    };

    return simulation;
  }

  matchUndecidedSimulatedEcus(): void {
    this.undecided.forEach((simEcu) => {
      this.simulationBundleCollection.some((simbun) => {
        if (simEcu.type == 'generic_ecu') {
          if (simbun.plant_models?.generics) {
            simbun.plant_models.simulated_ecus?.push(simEcu);
          }
        }
        if (simEcu.type == 'restbus') {
          if (simbun.plant_models?.physics) {
            simbun.plant_models.simulated_ecus?.push(simEcu);
          }
        }
        // {diagnostic_name: simEcu.diagnostic_name, networks: simEcu.network, type: simEcu.type}
      });
    });
  }

  postSimulationBundles(): void {
    let observables: any[] = [];
    let simIds: any[] = [];

    this.loadingSpinner = true;
    
    //VTP -1618 pt-2,3 start
    let newSimulationCollectionArray: any[] = [];
    
    for (let index = 0; index <  this.simulationBundleCollection.length; index++) {
      if (newSimulationCollectionArray.length == 0 || newSimulationCollectionArray.findIndex(sim => sim.package.id == this.simulationBundleCollection[index].package.id) == -1) {
        newSimulationCollectionArray.push(this.simulationBundleCollection[index]);
      }
    }
    let New_array:any = [];
    newSimulationCollectionArray.forEach((simulation) => {
      if ( newSimulationCollectionArray.length === 1) {
        //VTP -1618 pt-2,3 ends 
        simulation.name = this.baseData.name;
      }
      if (simulation.test_environment.configuration.sub_functions.length >= 0) {
        let new_Sub_Functions = simulation.test_environment.configuration.sub_functions
          for (let index = 0; index < new_Sub_Functions.length; index++) {
            var jsonString = JSON.stringify(simulation.test_environment.configuration.sub_functions[index]);
            var jsonObject = JSON.parse(jsonString);
            if (Object.keys(jsonObject).length != 0 && jsonObject['sub_function']) {
              New_array.push( simulation.test_environment.configuration.sub_functions.pop(index))
            }
          }
        }
        if (New_array.length > 0) {
          simulation.test_environment.configuration.sub_functions = New_array
        }else{
          simulation.test_environment.configuration.sub_functions = null;
        }
      observables.push(
        this.capabilitiesService.postSimulationBundle(
          this.baseData.model_series!,
          simulation
        )
      );
    });

    forkJoin(observables).subscribe({
      next: (responses) => {
        responses.forEach((response) => {
          simIds.push(response.data.id);
        });
      },
      complete: () => {
        if (this.simulationBundleCollection.length >= 1) {
          this.coSimulationBundle.name = this.baseData.name;
          this.coSimulationBundle.description = this.baseData.description
            ? this.baseData.description
            : '-';
          this.coSimulationBundle.simulation_bundle_ids = simIds;
          this.coSimulationBundle.package = this.baseData.package;
          this.coSimulationBundle.package.revision = '0';

          this.capabilitiesService
            .postCoSimulationBundle(this.coSimulationBundle)
            .subscribe({
              next: (response) => {
                this.notificationService.notifySuccess = true;
                this.notificationService.notificationSuccesMessage =
                  response.message;
              },
              complete: () => {
                this.clearAll();
                this.loadingSpinner = false;
                this.router.navigate([`simulations`]);
              },
              error: (error) => {
                this.notificationService.handleError(error);
              },
            });
        }
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  clearAll(): void {
    this.loadingSpinner = false;
    this.baseData = {
      description: '',
      plant_models: {
        simulated_ecus: [],
        physics: [],
        generics: [],
      },
      model_series: '',
      name: '',
      package: {id: '', revision: ''},
      test_environment: {
        test_type: '',
        test_suite: {version: ''},
        instance: Instance.Brop || Instance.Frop,
        configuration: {
          test_groups: [],
          sub_functions: [{sub_function: ''}],
          networks: [{name: ''}],
          system: '',
          function: '',
        },
      },
    };
    this.EditMode = false;
    this.nextClickSource = new Subject<void>();
    this.nextClick$ = this.nextClickSource.asObservable();

    this.coSimulationBundle = {
      name: '',
      description: '',
      simulation_bundle_ids: [''],
      config: {},
      package: {id: ''},
    };

    this.simulationBundleCollection = [];

    this.type = '';
    this.simulatedEcus = [];
    this.plantModels = [];
    this.physics = [];
    this.generics = [];

    this.eventChainEcus = [];

    this.selectedPackage = {
      id: '',
      name: '',
      includedEcus: [''],
      simbun_packages: [
        {
          name: '',
          id: '',
          simulation_framework: '',
          networks: [{name: ''}],
          diagnosticNames: [''],
          simulatedEcus: [{}],
          physics: [{}],
          generics: [{}],
        },
      ],
    };

    this.selectedNodes = [];

    this.loadingSpinner = false;
    this.EditMode = false;
    this.simulationMode = 'event';
  }

  getEditMode() {
    return this.EditMode;
  }
}
