<ag-grid-angular
  class="ag-theme-wb"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [loadingOverlayComponent]="loadingOverlayComponent"
  [loadingOverlayComponentParams]="loadingOverlayComponentParams"
  (gridReady)="onGridReady($event)"
  [pagination]="true"
  [paginationPageSize]="pageSize.value"
  unSortIcon
  domLayout="autoHeight"
  suppressPaginationPanel
  suppressRowClickSelection
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
>
</ag-grid-angular>
<ng-template #emptyState>
  <app-empty-state></app-empty-state>
</ng-template>
<wb-pagination
  *ngIf="getTotalPages() > 1"
  [currentPage]="currentPage.value"
  [pages]="getTotalPages()"
  (wbchange)="setGridPage($event)"
>
</wb-pagination>


