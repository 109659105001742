<div *ngIf="spinner">
  <wb-spinner
    class="centered-element"
    theme="light"
    style="width: 128px; height: 128px"
  ></wb-spinner>
</div>
<wb-grid [fullWidth]="true" *ngIf="!spinner">
  <wb-grid-row
    *ngFor="
      let vecu of this.vPackageReleaseService.packageReleasePost
        .application_instances?.vecu_applications;
      let i = index
    "
  >
    <wb-grid-col mq6="12">
      <wb-grid-row>
        <wb-grid-col mq6="12">
          <wb-heading size="xs" class="spacing-xs spacing-top-xs"
            >vECU Application:
            {{
              (this.vPackageReleaseService.packageReleasePost
                .application_instances?.vecu_applications)![i].name
            }}</wb-heading
          >
        </wb-grid-col>
        <wb-grid-col mq6="4">
          <wb-input
            as="input"
            *ngIf="this.vPackageReleaseService.packageReleasePost!.release_type != 'release'"
            [(ngModel)]="
              this.vPackageReleaseService.packageReleasePost!
                .application_instances!.vecu_applications![i].semantic_version
            "
            name="vecuSemanticVersion"
            ngDefaultControl
            inputmode="text"
            required
            #vecuSemanticVersion="ngModel"
            [error]="
              validationService.isFieldInvalid(
                formGroup.vecuSemanticVersion,
                vecuSemanticVersion,
                this.vPackageReleaseService.packageReleasePost!
                  .application_instances!.vecu_applications![i]
                  .semantic_version!,
                format.SemanticVersionOther
              )
            "
            class="spacing-xs"
          >
            <span slot="label">Semantic version*</span>
            <div slot="message">
              <app-validation-notification
                name="Semantic version"
                [type]="format.None"
                *ngIf="
                  validationService.getFormStatus(
                    this.formGroup.vecuSemanticVersion,
                    validationStatus.Invalid
                  ) &&
                  validationService.getFormStatus(
                    this.formGroup.vecuSemanticVersion,
                    validationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
              <app-validation-notification
                name="Semantic version"
                [type]="format.SemanticVersionOther"
                *ngIf="
                  validationService.getFormStatus(
                    this.formGroup.vecuSemanticVersion,
                    validationStatus.Regex
                  ) &&
                  validationService.getFormStatus(
                    this.formGroup.vecuSemanticVersion,
                    validationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-input>
          <wb-input
            as="input"
            *ngIf="this.vPackageReleaseService.packageReleasePost!.release_type == 'release'"
            [(ngModel)]="
              this.vPackageReleaseService.packageReleasePost!
                .application_instances!.vecu_applications![i].semantic_version
            "
            name="vecuSemanticVersion"
            ngDefaultControl
            inputmode="text"
            required
            #vecuSemanticVersion="ngModel"
            [error]="
              validationService.isFieldInvalid(
                formGroup.vecuSemanticVersion,
                vecuSemanticVersion,
                this.vPackageReleaseService.packageReleasePost!
                  .application_instances!.vecu_applications![i]
                  .semantic_version!,
                format.SemanticVersion
              )
            "
            class="spacing-xs"
          >
            <span slot="label">Semantic version*</span>
            <div slot="message">
              <app-validation-notification
                name="Semantic version"
                [type]="format.None"
                *ngIf="
                  validationService.getFormStatus(
                    this.formGroup.vecuSemanticVersion,
                    validationStatus.Invalid
                  ) &&
                  validationService.getFormStatus(
                    this.formGroup.vecuSemanticVersion,
                    validationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
              <app-validation-notification
                name="Semantic version"
                [type]="format.SemanticVersion"
                *ngIf="
                  validationService.getFormStatus(
                    this.formGroup.vecuSemanticVersion,
                    validationStatus.Regex
                  ) &&
                  validationService.getFormStatus(
                    this.formGroup.vecuSemanticVersion,
                    validationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-input>
        </wb-grid-col>
        <!--<wb-grid-col mq6="4">
           <wb-combobox class="spacing-xs">
            <wb-combobox-input
              slot="input"
              [(ngModel)]="
                this.vPackageReleaseService.packageReleasePost!
                  .application_instances!.vecu_applications![i]
                  .simulation_framework
              "
              name="simulationFramework"
              ngDefaultControl
              required
              #simulationFramework="ngModel"
              [error]="
                this.validationService.isComboBoxInValid(
                  this.formGroup.simulationFramework,
                  simulationFramework,
                  this.vPackageReleaseService.packageReleasePost!
                    .application_instances!.vecu_applications![i]
                    .simulation_framework,
                  Simulation_frameworks
                )
              "
              (wbcomboboxinput)="onSimulationFrameWorkInputChange($event, i)"
            >
              <label slot="label">Simulation framework*</label>
            </wb-combobox-input>
            <wb-combobox-list
              no-items-selected-text="No Simulation Framework selected"
              no-items-found-text="No Simulation Framework found"
              (wbcomboboxlistitemselectionchange)="
                onSimulationFrameWorkSelectChange($event, i)
              "
            >
              <wb-combobox-list-item
                *ngFor="let simulation_framework of Simulation_frameworks"
                value="{{ simulation_framework }}"
                >{{ simulation_framework }}</wb-combobox-list-item
              >
            </wb-combobox-list>
            <div slot="message">
              <app-validation-notification
                name="Simulation framework"
                [type]="format.Pick"
                *ngIf="
                  validationService.getFormStatus(
                    this.formGroup.simulationFramework,
                    validationStatus.Invalid
                  ) &&
                  validationService.getFormStatus(
                    this.formGroup.simulationFramework,
                    validationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-combobox> 
        </wb-grid-col>-->
        <wb-grid-col mq6="4">
          <wb-combobox class="spacing-xs">
            <wb-combobox-input
              slot="input"
              [(ngModel)]="
                this.vPackageReleaseService.packageReleasePost!
                  .application_instances!.vecu_applications![i]
                  .simulation_framework_version
              "
              name="simulationframeworkversion"
              ngDefaultControl
              required
              #simulationframeworkversion="ngModel"
              [error]="
                this.validationService.isComboBoxInValid(
                  this.formGroup.simulationFrameworkVersion,
                  simulationframeworkversion,
                  this.vPackageReleaseService.packageReleasePost!
                    .application_instances!.vecu_applications![i]
                    .simulation_framework_version!,
                  Simulation_frameworks_versions
                )
              "
              (wbcomboboxinput)="
                onSimulationFrameWorkVersionInputChange($event, i)
              "
            >
              <label slot="label">Simulation framework version*</label>
            </wb-combobox-input>
            <wb-combobox-list
              no-items-selected-text="No Framework Version selected"
              no-items-found-text="No Framework Version found"
              (wbcomboboxlistselectionchange)="
                onSimulationFrameWorkVersionSelectChange($event, i)
              "
            >
              <wb-combobox-list-item
                *ngFor="
                  let simulation_framework_version of Simulation_frameworks_versions
                "
                value="{{ simulationframeworkversion }}"
                >{{ simulation_framework_version }}</wb-combobox-list-item
              >
            </wb-combobox-list>
            <div slot="message">
              <app-validation-notification
                name="Simulation framework version"
                [type]="format.Pick"
                *ngIf="
                  validationService.getFormStatus(
                    this.formGroup.simulationFrameworkVersion,
                    validationStatus.Invalid
                  ) &&
                  validationService.getFormStatus(
                    this.formGroup.simulationFrameworkVersion,
                    validationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-combobox>
        </wb-grid-col>
      </wb-grid-row>
    </wb-grid-col>
  </wb-grid-row>
  <wb-grid-row
    *ngFor="
      let sa of this.vPackageReleaseService.packageReleasePost
        .application_instances?.sensor_actors;
      let i = index
    "
  >
    <wb-grid-col mq6="12">
      <wb-grid-row>
        <wb-grid-col mq6="12">
          <wb-heading size="xs" class="spacing-xs"
            >Sensor / Actor:
            {{
              this.vPackageReleaseService.packageReleasePost
                .application_instances?.sensor_actors![i].name
            }}</wb-heading
          >
        </wb-grid-col>
        <wb-grid-col mq6="4">
          <wb-input
            as="input"
            [(ngModel)]="
              this.vPackageReleaseService.packageReleasePost
                .application_instances!.sensor_actors![i].version
            "
            name="sensorVersion"
            ngDefaultControl
            inputmode="text"
            required
            #sensorVersion="ngModel"
            [error]="
              validationService.isFieldInvalid(
                this.formGroup.sensorVersion,
                sensorVersion,
                this.vPackageReleaseService.packageReleasePost
                  .application_instances!.sensor_actors![i].version!,
                this.format.SemanticVersion
              )
            "
            class="spacing-xs"
          >
            <span slot="label">Version*</span>
            <div slot="message">
              <app-validation-notification
                name="Sensor version"
                [type]="format.None"
                *ngIf="
                  validationService.getFormStatus(
                    this.formGroup.sensorVersion,
                    validationStatus.Invalid
                  ) &&
                  validationService.getFormStatus(
                    this.formGroup.sensorVersion,
                    validationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
              <app-validation-notification
                name="Sensor version"
                [type]="format.SemanticVersion"
                *ngIf="
                  validationService.getFormStatus(
                    this.formGroup.sensorVersion,
                    validationStatus.Regex
                  ) &&
                  validationService.getFormStatus(
                    this.formGroup.sensorVersion,
                    validationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-input>
        </wb-grid-col>
      </wb-grid-row>
    </wb-grid-col>
  </wb-grid-row>
  <wb-grid-row>
    <wb-grid-col
      mq6="12"
      *ngIf="
        this.vPackageReleaseService.packageReleasePost!.application_instances
          ?.hardware_security_module
      "
    >
      <wb-heading size="xs" class="spacing-xs">HSM:</wb-heading>
    </wb-grid-col>
    <wb-grid-col mq6="4">
      <wb-input
        as="input"
        *ngIf="
          this.vPackageReleaseService.packageReleasePost!.application_instances
            ?.hardware_security_module
        "
        [(ngModel)]="
          (this.vPackageReleaseService.packageReleasePost!.application_instances
            ?.hardware_security_module)!.version
        "
        name="hsmVersion"
        ngDefaultControl
        inputmode="text"
        required
        #hsmVersion="ngModel"
        [error]="
          validationService.isFieldInvalid(
            formGroup.hsmVersion,
            hsmVersion,
            (this.vPackageReleaseService.packageReleasePost!
              .application_instances?.hardware_security_module)!.version!,
            format.None
          )
        "
        class="spacing-xs"
      >
        <span slot="label">Hardware security module version*</span>
        <div slot="message">
          <app-validation-notification
            name="Hsm version"
            [type]="format.None"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.hsmVersion,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.hsmVersion,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-input>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
