import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ColDef, GridApi, GridReadyEvent} from 'ag-grid-community';
import {CustomLoadingOverlayComponent} from '../custom-loading-overlay/custom-loading-overlay.component';

@Component({
  selector: 'app-custom-grid',
  templateUrl: './custom-grid.component.html',
  styleUrl: './custom-grid.component.css',
})
export class CustomGridComponent implements OnChanges {
  @Input() rowData?: any[];
  @Input() columnDefs?: ColDef[];
  @Input() defaultColDef?: ColDef;
  @Input() pageSize: { value: number } = {value: 10};
  gridApi?: GridApi;
  currentPage: { value: number } = {value: 1};

  public loadingOverlayComponent: any = CustomLoadingOverlayComponent;
  public loadingOverlayComponentParams: any = {
    loadingMessage: 'Loading...',
  };

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      if (changes) {
        this.setRowData();
      }
    }, 0)
  }

  setRowData() {
    setTimeout(() => {
      if (this.rowData) {
        if (this.rowData!.length > 0) {
          if (this.gridApi) {
            this.gridApi!.setRowData(this.rowData as any);
          }
        }
      }
    }, 0)
  }

  onGridReady(params: GridReadyEvent) {
    setTimeout(() => {
      this.gridApi = params.api;
    }, 0)
    // this.gridApi.showLoadingOverlay();
    // this.gridApi.showNoRowsOverlay();
  }

  public overlayNoRowsTemplate = '<wb-text> Nothing to show</wb-text>';

  setGridPage(event: Event): void {
    setTimeout(() => {
      const customEvent = event as CustomEvent;
      this.gridApi?.paginationGoToPage(customEvent.detail - 1);
      this.currentPage.value = customEvent.detail;
    }, 0)
  }

  getTotalPages(): number {
    return Math.ceil((this.rowData?.length || 0) / this.pageSize.value);
  }
}
