import {Component, OnInit} from '@angular/core';
import {forkJoin, of} from 'rxjs';
import {VtestingJobService} from '../../../services/vtesting-job.service';
import {CapabilitiesService} from '../../../../services/capabilities/capabilities.service';
import {HeaderTitleService} from '../../../../services/header/header-title.service';
import {NotificationService} from '../../../../services/notification/notification.service';
import {ColDef} from 'ag-grid-community';
import {Router} from "@angular/router";

@Component({
  selector: 'app-test-job-flow-summary',
  templateUrl: './test-job-flow-summary.component.html',
  styleUrl: './test-job-flow-summary.component.css',
})
export class TestJobFlowSummaryComponent implements OnInit {
  testDefinitions: { name: string; vEcuName: string; releaseName: string }[] =
    [];

  dataLoaded: boolean = false;

  constructor(
    public vTestingJobService: VtestingJobService,
    private capabilitiesService: CapabilitiesService,
    private headerService: HeaderTitleService,
    private notificationService: NotificationService,
    private router: Router
  ) {
  }

  ngOnInit() {
    if (!this.vTestingJobService.getBaseData().basic_configuration.model_series) {
      return this.router.navigate(['/vtesting-jobs']);
    } else {
      let sizeOfTestDefinition = this.vTestingJobService.vtestingJobDefinitions.length;
      this.headerService.primaryDisabled = true;
      this.vTestingJobService.vtestingJobDefinitions.map((testDefinition) => {
        forkJoin({
          ecuObservable: this.capabilitiesService.getSpecificPackage(
            testDefinition.test_object.package_id
          ),
          releaseObservable: this.vTestingJobService.getToggleStatus()
            ? of({data: {name: 'Continuous testing'}})
            : this.capabilitiesService.getSpecificPackageRelease(
              testDefinition.test_object.package_release_id!
            ),
        }).subscribe({
          next: ({ecuObservable, releaseObservable}) => {
            this.testDefinitions.push({
              name: testDefinition.name,
              vEcuName: ecuObservable.data.name,
              releaseName: releaseObservable.data.name,
            });
            this.headerService.primaryDisabled = false;
          },
          complete: () => {
            //VTPL-phase-2 starts
            sizeOfTestDefinition --;
            if(sizeOfTestDefinition  == 0){
              this.dataLoaded = true;
            }
            //VTPL-phase-2 ends
          },
          error: (error) => this.notificationService.handleError(error),
        });
      });
    }
  }

  public columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Definition name',
      flex: 1,
    },
    {
      field: 'vEcuName',
      headerName: 'vECU package',
      flex: 1,
    },
    {
      field: 'releaseName',
      headerName: 'vECU package release',
      flex: 1,
    },
  ];

  public defaultColDef: ColDef = {
    autoHeaderHeight: true,
    flex: 1,
  };
}
