import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {AgGridModule} from 'ag-grid-angular';
import 'ag-grid-enterprise';
import {ValidationModule} from '../components/validation/validation.module';
import {CustomGridsModule} from '../components/custom-grids/custom-grids.module';
import {
  SimulationBuilderFlowRefinementComponent
} from './components/simulation-builder-flow/simulation-builder-flow-refinement/simulation-builder-flow-refinement.component';
import {
  SimulationBuilderFlowScopeComponent
} from './components/simulation-builder-flow/simulation-builder-flow-scope/simulation-builder-flow-scope.component';
import {
  SimulationBuilderFlowSummaryComponent
} from './components/simulation-builder-flow/simulation-builder-flow-summary/simulation-builder-flow-summary.component';
import {
  SimulationBuilderFlowBaseDataComponent
} from "./components/simulation-builder-flow/simulation-builder-flow-base-data/simulation-builder-flow-base-data.component";
import {
  SimulationBuilderFlowComponent
} from "./components/simulation-builder-flow-component/simulation-builder-flow/simulation-builder-flow.component";
import {SimulationOverviewComponent} from "./components/simulation-overview/simulation-overview.component";
import { CoSimulationBundleDetailComponent } from './components/co-simulation-bundle-detail/co-simulation-bundle-detail.component';
import { SimulationBundleDetailComponent } from './components/simulation-bundle-detail/simulation-bundle-detail.component';

@NgModule({
  declarations: [
    SimulationOverviewComponent,
    SimulationBuilderFlowComponent,
    SimulationBuilderFlowBaseDataComponent,
    SimulationBuilderFlowScopeComponent,
    SimulationBuilderFlowRefinementComponent,
    SimulationBuilderFlowSummaryComponent,
    CoSimulationBundleDetailComponent,
    SimulationBundleDetailComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    AgGridModule,
    ValidationModule,
    CustomGridsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SimulationModule {
}
