<div *ngIf="this.vTestingJobService.loadingSpinner">
  <wb-spinner class="centered-element" theme="light" style="width: 128px; height: 128px"></wb-spinner>
</div>
<wb-grid [fullWidth]="true" class="sub-grid" *ngIf="!this.vTestingJobService.loadingSpinner">
  <main>
    <wb-stepper>
      <wb-step #step1>
        Base data
      </wb-step>
      <wb-step #step2>
        Test definitions
      </wb-step>
      <wb-step #step3>
        Summary
      </wb-step>
    </wb-stepper>
    <router-outlet></router-outlet>
  </main>
</wb-grid>
