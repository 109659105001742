import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {Injectable} from '@angular/core';
import {forkJoin, map, Subject} from 'rxjs';
import {CreateVTestingJobDto, NewTestDefinitionDto,} from '@vtp/vtpcfg-client-ts';
import {SimulationBundleGet, SimulationBundlePost,} from '@vtp/vtpcap-client-ts';
import {Router} from '@angular/router';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {CreateBaseData} from '../interfaces/Base Data/CreateBaseData ';
import {ConfigurationService} from '../../services/configuration/configuration.service';
import {BreadcrumbsService} from "../../services/breadcrumbs/breadcrumbs.service";
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VtestingJobService {
  netWorkNames: any[] = [];
  base_data: CreateVTestingJobDto = {
    name: '',
    continuous: true,
    basic_configuration: {model_series: ''},
    admin_configuration: {priority: ''},
    further_notes: '',
    test_definitions: this.getVtestingJobDefinitions(),
  };
  vtestingJob: CreateBaseData = {};
  vtestingJobDefinitions: NewTestDefinitionDto[] = [];
  started_time: any[] = [];
  finished_time: any[] = [];
  jobId: string = '';
  vECUTestJobName: string = '';
  loadingSpinner: boolean = false;
  skeleton: boolean[] = [];

  simulationBundles: { [id: string]: SimulationBundlePost } = {};
  putSimulationBundles: { [id: string]: SimulationBundleGet } = {};
  ToggleStatus: boolean = true;
  vtestingJobId: any;
  EditMode: boolean = false;
  UpdateMode: boolean = false;
  dataLoaded: boolean = false;
  currentJobOverview: any;

  private nextClickSource = new Subject<void>();
  nextClick$ = this.nextClickSource.asObservable();

  constructor(
    private capabilityservice: CapabilitiesService,
    private configurationService: ConfigurationService,
    private router: Router,
    private notificationService: NotificationService,
    private breadCrumbService: BreadcrumbsService
  ) {
  }

  triggerNextClick(): void {
    this.nextClickSource.next();
  }

  clearAll(): void {
    this.loadingSpinner = false;
    this.netWorkNames = [];
    this.base_data = {
      name: '',
      continuous: true,
      basic_configuration: {model_series: ''},
      admin_configuration: {priority: ''},
      further_notes: '',
      test_definitions: this.getVtestingJobDefinitions(),
    };
    this.vtestingJob = {};
    this.vtestingJobDefinitions = [];
    this.started_time = [];
    this.finished_time = [];
    this.jobId = '';

    this.simulationBundles = {};
    this.putSimulationBundles = {};
    this.ToggleStatus = true;
    this.EditMode = false;
    this.UpdateMode = false;
    this.dataLoaded = false;
    this.nextClickSource = new Subject<void>();
    this.nextClick$ = this.nextClickSource.asObservable();
  }

  //*************************************GENERAL DATA *************************************
  getBaseData(): CreateVTestingJobDto {
    return this.base_data;
  }
  
  postAllSimulationBundles(): void {
      const observables: any = [];
      this.loadingSpinner = true;
  
      Object.values(this.simulationBundles).forEach((bundle: any, index) => {
        if (this.vtestingJobDefinitions[index].test_scope.configuration.instance == "FROP") return;
  
        if (bundle.test_environment.instance == 'FROP') {
          if (bundle.test_environment.configuration.function == undefined && bundle.test_environment.test_type != "system") {
            bundle.test_environment.configuration.function = bundle.test_environment.configuration?._function;
          }
          bundle.test_environment.configuration.sub_functions = bundle.test_environment.configuration?.subfunctions;
        }
        bundle.test_environment.configuration?.networks?.[0]?.name === '' && (bundle.test_environment.configuration.networks[0].name = undefined);
  
        const observable = this.capabilityservice
          .postSimulationBundle(
            this.base_data.basic_configuration.model_series,
            bundle
          )
          .pipe(
            map((response: any) => {
              return {index, simulationBundleId: response.data.id};
            })
          );
        observables.push(observable);
      });
  
      if (observables.length) {
        forkJoin(observables).subscribe({
          next: (responses: any) => {
            responses.forEach(
              (response: any) =>
                (this.vtestingJobDefinitions[
                  response.index
                  ].test_object.simulation_bundle_id = response.simulationBundleId)
            );
            this.setVtestingJob();
          },
          error: (error) => this.notificationService.handleError(error),
        });
      } else {
        this.setVtestingJob();
      }
    } 
  

  putAllSimulationBundles(id: string): void{
    const observables: any = [];
    Object.entries(this.putSimulationBundles).forEach(
      (entry: [string, any], index) => {
        var jobDefIndex = this.vtestingJobDefinitions.findIndex((jobDefinition) => jobDefinition.id == entry[0]);
        if (jobDefIndex != -1) {
          if (this.vtestingJobDefinitions[jobDefIndex].test_scope.configuration.instance == "FROP") return;
          if (!(entry[0] in this.simulationBundles)) {
            delete this.putSimulationBundles[entry[0]];
          } else {
            if (entry[1].simulation_bundle_ids == null || entry[1].simulation_bundle_ids.length == 0) {
              entry[1].test_environment!.configuration!.system = null;;
              entry[1].test_environment!.configuration!.function = null
              entry[1].test_environment!.configuration!.subfunctions = null;
              entry[1].test_environment!.configuration!._functions = null;
              const observable = this.capabilityservice.putSimulationBundle(entry[1].id!, entry[1]).pipe(
                map((response) => {
                  return {
                    name: response.data.name,
                    simulationBundleId: response.data.id,
                  };
                })
              );
            observables.push(observable);
            } 
          }
          }
      })
    Object.entries(this.simulationBundles).forEach(
      (entry: [string, any], index) => {
        var isPostReq = false;
        var jobDefIndex = this.vtestingJobDefinitions.findIndex((jobDefinition) => jobDefinition.id == entry[0]);
        if (jobDefIndex != -1) {
          if (this.vtestingJobDefinitions[jobDefIndex].test_scope.configuration.instance == "FROP") return;
            if (!(entry[0] in this.putSimulationBundles)) {
              isPostReq = true;
            }
          }else{
            isPostReq = true;
          }
          if(isPostReq){
            entry[1].test_environment!.configuration!.system = null;;
              entry[1].test_environment!.configuration!.function = null
              entry[1].test_environment!.configuration!.subfunctions = null;
              entry[1].test_environment!.configuration!._functions = null;
              const observable = this.capabilityservice.postSimulationBundle(
                  this.base_data.basic_configuration.model_series,
                  entry[1]
                ).pipe(
                  map((response) => {
                    return {
                      name: response.data.name,
                      simulationBundleId: response.data.id,
                    };
                  })
                );
              observables.push(observable);
          }
      })  

      if (observables.length) {
        
        forkJoin(observables).subscribe({
          next: (responses: any) => {
            responses.forEach((response: any) => {
              let index = this.vtestingJobDefinitions.findIndex(
                (jobDefinition) => jobDefinition.id == response.name
              );
              this.vtestingJobDefinitions[index].test_object.simulation_bundle_id =
                response.simulationBundleId; 
            });
            this.EditVtestingJob(id);
          },
          error: (error) => {
            this.notificationService.handleError(error);
          },
        });
      }

    this.EditVtestingJob(id);    
  }

  EditVtestingJob(testjobId: string): void {
    this.base_data.test_definitions = this.vtestingJobDefinitions;
    for (let index = 0; index < this.base_data.test_definitions.length; index++) {
      const element = this.base_data.test_definitions[index];
      if (element.test_object.co_simulation_bundle_id == '') {
        element.test_object.co_simulation_bundle_id = undefined;
      }
      if (element.test_object.co_simulation_bundle_release_id == '') {
        element.test_object.co_simulation_bundle_release_id = undefined;
      }
      if (element.test_object.simulation_bundle_id == '') {
        element.test_object.simulation_bundle_id = undefined;
      }
      if (element.test_object.simulation_bundle_release_id == '') {
        element.test_object.simulation_bundle_release_id = undefined;
      }
      if (element.test_object.package_release_id == '') {
        element.test_object.package_release_id = undefined;
      }
    }
    this.base_data.continuous = this.getToggleStatus();
    const data: Record<string, any> = {...this.base_data};
    const excludedKeys: string[] = [
      'created_at',
      'modified_at',
      'modified_by',
      'created_by',
      'id',
    ];
    const filteredData: Record<string, any> = Object.keys(data)
      .filter((key: string) => !excludedKeys.includes(key))
      .reduce(
        (obj: Record<string, any>, key: string) => ({
          ...obj,
          [key]: data[key],
        }),
        {}
      );

    this.configurationService
      .updateVTestingJob(testjobId, filteredData)
      .subscribe({
        next: (vtestingjob) => {
          this.notificationService.notifySuccess = true;
          this.notificationService.notificationSuccesMessage =
            vtestingjob.message;
          this.vtestingJob = vtestingjob.data;
        },
        complete: () => {
          this.clearAll();
          this.breadCrumbService.removeAboveIndex(0);
          this.router.navigate([
            `vtesting-jobs/vtesting-job/${testjobId}/overview`,
          ]);
        },
        error: (err: any) => this.notificationService.handleError(err),
      });
  }

  setVtestingJob(): void {
    this.vtestingJobDefinitions.forEach(def => {
      if (def.test_scope.configuration?.starc_testset_id == '') {
      def.test_scope.configuration.starc_testset_id = undefined;
      }
      if (def.test_object.co_simulation_bundle_id) {
        def.test_object.simulation_bundle_id = undefined;
       }
    })
    this.base_data.test_definitions = this.vtestingJobDefinitions;
    this.base_data.continuous = this.getToggleStatus();

    this.configurationService.postVTestingJob(this.base_data).subscribe({
      next: (vtestingjob) => {
        this.notificationService.notifySuccess = true;
        this.notificationService.notificationSuccesMessage =
          vtestingjob.message;
        this.vtestingJob = vtestingjob.data;
      },
      complete: () => {
        let id = this.vtestingJob.id;

        this.clearAll();
        this.breadCrumbService.removeAboveIndex(0);
        this.router.navigate([`vtesting-jobs/vtesting-job/${id}/overview`]);
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  runVTestingJob(id: any): void {
    this.configurationService.runVTestingJob(id).subscribe({
      next: (testJob) => {
        this.notificationService.notifySuccess = true;
        this.notificationService.notificationSuccesMessage = testJob.message;
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  setVtestingJobId(id: any): void {
    this.vtestingJobId = id;
  }

  getVtestingJobId() {
    return this.vtestingJobId;
  }

  setvPackageId(id: any): void {
    this.vtestingJobId = id;
  }

  getvPackageId() {
    return this.vtestingJobId;
  }

  getVtestingJobDefinitions(): NewTestDefinitionDto[] {
    // DEN WERT
    this.vtestingJobDefinitions = [...new Set(this.vtestingJobDefinitions)];
    return this.vtestingJobDefinitions;
  }

  setToggleStatus(ToggleStatus: boolean): void {
    this.ToggleStatus = ToggleStatus;
  }

  getToggleStatus(): boolean {
    return this.ToggleStatus;
  }

  // *************************************DATE DEFINITION FOR AG GRID [ MASTER TABLE ]*************************************
  setStartedTime(started_time: any): void {
    this.started_time = started_time;
  }

  setFinishedTime(finished_time: any): void {
    this.finished_time = finished_time;
  }

  setEditMode(edit: boolean): void {
    this.EditMode = edit;
  }

  getEditMode() {
    return this.EditMode;
  }

  setvECUTestJobName(JobName: string): void {
    this.vECUTestJobName = JobName;
  }

  getvECUTestJobName() {
    return this.vECUTestJobName;
  }
}
