<wb-tab-content name="info" slot="content" selected style="padding: 0 !important;">
  <div *ngIf="!showDataTable">
    <wb-spinner
      class="centered-element"
      theme="light"
      style="width: 128px; height: 128px"
    ></wb-spinner>
  </div>
  <wb-grid [fullWidth]="true" *ngIf="showDataTable">
    <wb-grid-row>
      <wb-grid-col mq6="12">
        <wb-heading class="spacing-xs" size="s" tag="div">
          Release Information
        </wb-heading>
      </wb-grid-col>
      <wb-grid-col mq6="3">
        <wb-text class="spacing-bot-16" size="m" tag="div"> Name:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> ID:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> Model series:</wb-text>
        <!-- <wb-text class="spacing-xs" size="m" tag="div"> Description: </wb-text> -->
        <wb-text class="spacing-bot-16" size="m" tag="div"> Type:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> ECU package release link:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> Created by:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> Created at:</wb-text>
      </wb-grid-col>
      <wb-grid-col mq6="3">
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.vPackageReleaseService.packageReleaseOverview?.name }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.vPackageReleaseService.packageReleaseOverview?.id }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ modelSeries }}
        </wb-text>
        <!-- <wb-text class="spacing-xs" size="m" tag="div">
            {{
              this.vPackageReleaseService.packageReleaseOverview?.description
            }}
          </wb-text> -->
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{
            this.vPackageReleaseService.packageReleaseOverview?.release_type
              ? this.vPackageReleaseService.packageReleaseOverview?.release_type
              : "-"
          }}
        </wb-text>
        <div>
          <wb-text class="spacing-bot-16" size="m" tag="div" *ngIf="!this.vPackageReleaseService.packageReleaseOverview.ecu_package_release_link">
            -
          </wb-text>
          <div data-on="main" class="spacing-bot-16" *ngIf="this.vPackageReleaseService.packageReleaseOverview.ecu_package_release_link">
            <wb-link href="/package-management/packages/{{this.vPackageService.packageOverview.ecu_package_link}}/releases/{{this.vPackageReleaseService.packageReleaseOverview?.ecu_package_release_link}}/content" variant="standalone" size="l">
              {{this.vPackageReleaseService.packageReleaseOverview?.ecu_package_release_link}}
            </wb-link>
          </div>
        </div>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.vPackageReleaseService.packageReleaseOverview?.created_by }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.vPackageReleaseService.packageReleaseOverview?.created_at }}
        </wb-text>
      </wb-grid-col>
    </wb-grid-row>
    <wb-grid-row
      *ngIf="vPackageReleaseService.packageReleaseOverview?.type == 'vECUPKG'"
    >
      <wb-grid-col mq6="12">
        <wb-heading class="spacing-xs" size="s" tag="div">
          Hardware Security Module
        </wb-heading>
      </wb-grid-col>
      <wb-grid-col mq6="3">
        <wb-text class="spacing-bot-16" size="m" tag="div"> Name:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> Version:</wb-text>
      </wb-grid-col>
      <wb-grid-col mq6="3">
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{
            vPackageReleaseService.packageReleaseOverview.application_instances
              .hardware_security_module.name
          }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{
            vPackageReleaseService.packageReleaseOverview.application_instances
              .hardware_security_module.version
          }}
        </wb-text>
      </wb-grid-col>
    </wb-grid-row>
    <wb-grid-row
      *ngIf="vPackageReleaseService.packageType == 'vECUPKG'"
    >
      <wb-grid-col mq6="12">
        <wb-heading size="s" tag="div"> Applications</wb-heading>
      </wb-grid-col>
      <wb-grid-col mq6="12" class="spacing-xs">
        <app-custom-grid
          [rowData]="
            vPackageReleaseService.packageReleaseOverview.application_instances
              .vecu_applications
          "
          [columnDefs]="applicationColDef"
          [defaultColDef]="defaultColDef"
        >
        </app-custom-grid>
      </wb-grid-col>
    </wb-grid-row>
    <wb-grid-row
      *ngIf="vPackageReleaseService.packageReleaseOverview?.application_instances?.sensor_actors"
    >
      <wb-grid-col mq6="12">
        <wb-heading size="s" tag="div"> Sensors / Actors</wb-heading>
      </wb-grid-col>
      <wb-grid-col mq6="12">
        <app-custom-grid
          [rowData]="
            vPackageReleaseService.packageReleaseOverview.application_instances
              .sensor_actors
          "
          [columnDefs]="sensorActorColDef"
          [defaultColDef]="defaultColDef"
        >
        </app-custom-grid>
      </wb-grid-col>
    </wb-grid-row>
    <wb-grid-row
      *ngIf="
        !vPackageReleaseService.packageReleaseOverview?.application_instances
      "
    >
      <wb-grid-col mq6="12">
        <wb-heading size="s" tag="div"> Contained Releases</wb-heading>
      </wb-grid-col>
      <wb-grid-col mq6="12">
        <app-custom-tree-selection-grid
          [rowData]="rowData"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [autoGroupColumnDef]="autoGroupColumnDef"
          [getDataPath]="getDataPath"
        >
        </app-custom-tree-selection-grid>
      </wb-grid-col>
    </wb-grid-row>
  </wb-grid>
</wb-tab-content>
