import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {firstValueFrom, Subject, tap} from 'rxjs';
import {Format, ValidationService, ValidationStatus,} from '../../../../../services/validation/validation.service';
import {CapabilitiesService} from '../../../../../services/capabilities/capabilities.service';
import {NotificationService} from '../../../../../services/notification/notification.service';
import {VirtualRemoteTestBenchVtestingJobService} from '../../../../services/remote-testbench-vtesting-job.service';
import {Actions} from '../../remote-testbench-job-flow-test/remote-testbench-job-flow-test.component';
import {FropType} from '@vtp/vtpcap-client-ts';
import {StarcDomain} from '@vtp/vtpcfg-client-ts';

enum FormGroup {
  tmSystem = 'tmSystem',
  system = 'system',
  function = 'function',
  subFunction = 'subFunction',
  simulation = 'simulation',
}

@Component({
  selector: 'app-remote-testbench-frop-settings',
  // standalone: true,
  // imports: [],
  templateUrl: './remote-testbench-frop-settings.component.html',
  styleUrl: './remote-testbench-frop-settings.component.css'
})

export class VirtualRemoteTestBenchFropSettingsComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() index!: number;
  @Input() testType!: number;
  @Input() actionTransmitter!: Subject<{ action: number; option: any }>;

  subscription$: any;

  tmSystems: string[] = ['STARC', 'XRAY', 'P:TA'];
  starcDomains: StarcDomain[] = Object.values(StarcDomain);
  simulations: any[] = [];
  coSimulations: any[] = [];
  allSimulations: any[] = [];

  selectedSystem?: any;
  selectedFunction?: any;
  selectedSimulation?: any;

  showEnvironments: {
    system: boolean;
    function: boolean;
    sub_function: boolean;
  } = {
    system: false,
    function: false,
    sub_function: false,
  };
  environments: { systems: any[]; functions: any[]; subfunctions: any[] } = {
    systems: [],
    functions: [],
    subfunctions: [],
  };

  protected readonly Format = Format;
  protected readonly FormGroup = FormGroup;
  protected readonly ValidationStatus = ValidationStatus;

  constructor(
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService,
    public VirtualRemoteTestBenchVtestingJobService: VirtualRemoteTestBenchVtestingJobService,
    public validationService: ValidationService
  ) {
  }

  ngOnInit() {
    this.initValidation();
    this.initEnvironments();
    this.initActionTransmitter();
  }

  ngOnDestroy(): void {
    // this.validationService.removeFields(FormGroup); bug fix for vTpl-11
    this.subscription$.unsubscribe();
  }

  initValidation(): void {
    this.validationService.addExtraFields(FormGroup);
    this.validationService.setInputFieldTouched(FormGroup.tmSystem, true);
  }

  initActionTransmitter(): void {
    this.subscription$ = this.actionTransmitter.subscribe((data) => {
      if (data.action == Actions.PackageChange) {
        this.getSimulationBundles();
      } else if (data.action == Actions.TestTypeChange) {
        this.initEnvironments();
      }
    });
  }

  initEnvironments(): void {
    const testType = this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[
      this.id
      ].test_environment
      .test_type!.toLowerCase()
      .replace('-', '_');
    const types = [FropType.System, FropType.Function, FropType.SubFunction];
    const config: any =
      this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].test_environment
        .configuration!;

    this.resetEnvironments();
    this.setEnvironmentsBasedOnConfig(config);

    if (
      this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_object
        .package_id
    ) {
      this.getSimulationBundles();
    }

    if (
      this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_object
        .simulation_bundle_id
    ) {
      this.capabilitiesService
        .getSimulationBundle(
          this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_object
            .simulation_bundle_id
        )
        .subscribe({
          next: (response) => (this.selectedSimulation = response.data),
          error: (err) => this.notificationService.handleError(err),
        });
    }
    if (
      this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_object
        .co_simulation_bundle_id
    ) {
  this.capabilitiesService.getCoSimulationBundle(this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_object
    .co_simulation_bundle_id).subscribe({
          next: (response) => (this.selectedSimulation = response.data),
          error: (err) => this.notificationService.handleError(err),
        });
    }
    if (testType != FropType.SubFunction && config.subfunctions?.length) {
      config.subfunctions[0].sub_function = undefined;
      if (testType == FropType.System) {
        config.function = undefined;
      }
    }

    this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].test_environment.configuration.subfunctions = undefined
    ;
    if (testType === FropType.System) {
      this.validationService.setInputFieldTouched(
        this.FormGroup.function,
        true
      );
      this.validationService.setInputFieldTouched(
        this.FormGroup.subFunction,
        true
      );
    } else if (testType === FropType.Function) {
      this.showEnvironments.function = true;
      this.validationService.setInputFieldTouched(
        this.FormGroup.subFunction,
        true
      );
    } else if (testType.replace("_", "") === FropType.SubFunction) {
      this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].test_environment.configuration.subfunctions = [];
      this.showEnvironments.function = true;
      this.showEnvironments.sub_function = true;
    }
  }

  resetEnvironments(): void {
    this.environments = {
      systems: [],
      functions: [],
      subfunctions: [],
    };
    this.showEnvironments = {
      system: true,
      function: false,
      sub_function: false,
    };
  }

  async setEnvironmentsBasedOnConfig(config: any): Promise<void> {
    await this.getEnvironments(FropType.System, undefined);
    if (config.system) {
      const selectedSystem = this.environments.systems.find(
        (system) => system.system === config.system
      );
      await this.getEnvironments(FropType.Function, selectedSystem?.frop_id);
    }
    if (config.function) {
      const selectedFunction = this.environments.functions.find(
        (_function) => _function.function === config.function
      );

      await this.getEnvironments(FropType.SubFunction, selectedFunction?.frop_id);
    }
  }

  getSimulationBundles(): void {
    this.validationService.setInputFieldLoading(FormGroup.simulation, true);
    this.capabilitiesService
      .getSimulationBundleListByPackageId(
        this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].package.id
      )
      .subscribe({
        next: (response) => (this.simulations = response.data?.items ?? []),
        complete: () => {
          this.validationService.setInputFieldLoading(
            FormGroup.simulation,
            false
          )
        },
        error: (error) => this.notificationService.handleError(error),
      });
      this.capabilitiesService.getCoSimulationBundles().subscribe({
        next: (response) => {
          response.data.items.forEach((item: any) => {
            if (
              item.package?.id ===
              this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].package
                .id
            )
              this.coSimulations.push(item);
          });
        },
        complete: () => {
          this.allSimulations = [
            ...this.simulations,
            ...this.coSimulations,
          ];
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });
  }

  async getEnvironments(type: FropType, fropId: string | undefined): Promise<void> {
    const mappedType = type.toLowerCase().replace('bf', 'bF');

    try {
      await firstValueFrom(
        this.capabilitiesService
          .getEventChainListOfModelSeries(
            this.VirtualRemoteTestBenchVtestingJobService.base_data.basic_configuration.model_series,
            fropId,
            <FropType>mappedType.replace("bF", "b_f")
          )
          .pipe(
            tap({
              next: (response) => {
                if (type === FropType.System) {
                  this.environments.systems = response.data?.items!;
                } else if (type === FropType.Function) {
                  response.data?.items!.forEach(
                    (item: any) => (item._function = item.function)
                  );
                  this.environments.functions = response.data?.items!;
                } else if (type === FropType.SubFunction) {
                  this.environments.subfunctions = response.data?.items!;
                }
              },
              complete: () =>
                this.validationService.setInputFieldLoading(mappedType, false),
              error: (error) => this.notificationService.handleError(error),
            })
          )
      );
    } catch (error) {
      this.notificationService.handleError(error);
    }
  }

  systemChange(event: any) {
    this.selectedSystem =
      typeof event.detail.value == 'string'
        ? this.environments.systems.find(
          (system) => system.system == event.detail.value
        )
        : event.detail.value
          ? event.detail.value.value
          : undefined;
    this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[
      this.id
      ].test_environment.configuration!.system = this.selectedSystem?.system;

    this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration.test_type = this.selectedSystem?.system;
    this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration.system = this.selectedSystem?.system;
    this.selectedSystem && this.getEnvironments(FropType.Function, this.selectedSystem.frop_id);
    this.resetFunctions();
  }

  functionChange(event: any) {
    this.selectedFunction =
      typeof event.detail.value == 'string'
        ? this.environments.functions.find(
          (_function) => _function._function == event.detail.value
        )
        : event.detail.value
          ? event.detail.value.value
          : undefined;
    this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[
      this.id
      ].test_environment.configuration!.function =
      this.selectedFunction?.function;

    this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration.functions = [
      {
        name: this.selectedFunction?.function,
        sub_functions: [],
      }
    ];

    this.selectedFunction && this.getEnvironments(FropType.SubFunction, this.selectedFunction.frop_id);
    this.resetSubFunctions();
  }

  subFunctionSelectChange(event: any) {
    if (event && event.detail.value) {
      this.validationService.setInputFieldTouched(FormGroup.subFunction, false);
      this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].test_environment.configuration!.subfunctions = [];
      this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration!.functions![0].sub_functions = [];
      event.detail.value.forEach((group: any) => {
          this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[
            this.id
            ].test_environment.configuration!.subfunctions!.push({
            subfunction: group.label.trim(),
          });
          this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration!.functions![0].sub_functions?.push({name: group.label.trim()});
        }
      );
      if (this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].test_environment.configuration!.subfunctions!.length)
        this.validationService.setInputFieldTouched(FormGroup.subFunction, true);
    }
  }

  simulationChange(event: any) {
    if (!event.detail.value) return;

    if (
      this.coSimulations.find(
        (simulation) => simulation.name == event.detail.value.value.name
      )
    ) {
      this.selectedSimulation = event.detail.value.value;
      this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[
        this.index
        ].test_object.co_simulation_bundle_id = this.selectedSimulation?.id ?? '';
    }

    if (
      this.simulations.find(
        (simulation) => simulation.name == event.detail.value.value.name
      )
    ) {
      this.selectedSimulation = event.detail.value.value;
      this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[
        this.index
        ].test_object.simulation_bundle_id = this.selectedSimulation?.id ?? '';
    }

    // this.selectedSimulation =
    //   typeof event.detail.value == 'string'
    //     ? this.simulations.find(
    //         (simulation) => simulation.name == event.detail.value
    //       )
    //     : event.detail.value
    //     ? event.detail.value.value
    //     : undefined;
    // this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[
    //   this.index
    // ].test_object.simulation_bundle_id = this.selectedSimulation?.name ?? '';
  }

  mapSystemsToStringArray(): string[] {
    return this.environments.systems.map((system) => system.system);
  }

  mapFunctionsToStringArray(): string[] {
    return <string[]>(
      this.environments.functions.map((_function) => _function._function)
    );
  }

  mapSimulationsToStringArray(): string[] {
    return <string[]>this.allSimulations.map((simulation) => simulation.name);
  }

  isEnvironmentSelected(environment: any): boolean {
    let condition = this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration.functions![0].sub_functions!.some((sf) => sf.name === environment.sub_function)

    this.validationService.setInputFieldTouched(FormGroup.subFunction, !!this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration.functions![0].sub_functions?.length);

    return condition;
  }

  private resetFunctions() {
    this.environments.functions = [];
    this.resetSubFunctions();
  }

  private resetSubFunctions() {
    this.environments.subfunctions = [];
  }

  onStarcIdInputChange(event: any): void {
    this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration!.starc_testset_id = event.detail.value;
  }

  domainChange(event: any): void {

    this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].test_scope.configuration!.starc_domain = event
      .detail.value
      ? event.target.value
      : undefined;
  }
}
