<wb-grid-row
  *ngIf="
    (showCandelaConfiguration ||
      showOdxdConfiguration ||
      showEcuExtractConfiguration) &&
    !this.vTestingJobService.getToggleStatus()
  "
>
  <wb-grid-col mq6="12">
    <wb-grid [fullWidth]="true">
      <wb-heading class="spacing-xs" size="xs">Files</wb-heading>
      <wb-grid-row>
        <wb-grid-col mq6="6">
          <wb-combobox class="spacing-xs" *ngIf="showEcuExtractConfiguration">
            <wb-combobox-input
              slot="input"
              [(ngModel)]="selectedEcuExtractFile"
              name="ecuExtract"
              ngDefaultControl
              #ecuExtract="ngModel"
              [disabled]="
                this.validationService.getFormStatus(
                  FormGroup.EcuExtract,
                  ValidationStatus.Loading
                )
              "
              [error]="
                validationService.isComboBoxInValid(
                  FormGroup.EcuExtract,
                  ecuExtract,
                  this.vTestingJobService.vtestingJobDefinitions[this.index]
                    .test_scope.files!.ecu_extract!,
                  this.files.ecu_extract
                )
              "
              (wbcomboboxinput)="ecuExtractChange($event)"
            >
              <label slot="label">ECU extract*</label>
              <wb-input-action
                slot="actions"
                *ngIf="
                  this.validationService.getFormStatus(
                    FormGroup.EcuExtract,
                    ValidationStatus.Loading
                  )
                "
              >
                <wb-spinner id="loading-spinner"></wb-spinner>
              </wb-input-action>
            </wb-combobox-input>
            <wb-combobox-list
              no-items-selected-text="No ECU Extract selected"
              no-items-found-text="No ECU Extract found"
              (wbcomboboxlistselectionchange)="ecuExtractChange($event)"
            >
              <wb-combobox-list-item
                *ngFor="let file of files.ecu_extract"
                value="{{ file }}"
              >
                {{ file }}
              </wb-combobox-list-item>
            </wb-combobox-list>
            <div slot="message">
              <app-validation-notification
                name="Ecu extract"
                [type]="Format.Pick"
                *ngIf="
                  validationService.getFormStatus(
                    FormGroup.EcuExtract,
                    ValidationStatus.Invalid
                  ) &&
                  validationService.getFormStatus(
                    FormGroup.EcuExtract,
                    ValidationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-combobox>
        </wb-grid-col>
        <wb-grid-col mq6="6">
          <wb-combobox class="spacing-xs" *ngIf="showOdxdConfiguration">
            <wb-combobox-input
              slot="input"
              [(ngModel)]="selectedOdxdFile"
              name="odxd"
              ngDefaultControl
              #odxd="ngModel"
              [disabled]="
                this.validationService.getFormStatus(
                  FormGroup.Odxd,
                  ValidationStatus.Loading
                )
              "
              [error]="
                validationService.isComboBoxInValid(
                  FormGroup.Odxd,
                  odxd,
                  this.vTestingJobService.vtestingJobDefinitions[this.index]
                    .test_scope.files!.odx_d!,
                  this.files.odx_d
                )
              "
              (wbcomboboxinput)="odxdChange($event)"
            >
              <label slot="label">ODX-D file*</label>
              <wb-input-action
                slot="actions"
                *ngIf="
                  this.validationService.getFormStatus(
                    FormGroup.Odxd,
                    ValidationStatus.Loading
                  )
                "
              >
                <wb-spinner id="loading-spinner"></wb-spinner>
              </wb-input-action>
            </wb-combobox-input>
            <wb-combobox-list
              no-items-selected-text="No ODXD File selected"
              no-items-found-text="No ODXD File found"
              (wbcomboboxlistselectionchange)="odxdChange($event)"
            >
              <wb-combobox-list-item
                *ngFor="let file of files.odx_d"
                value="{{ file }}"
              >
                {{ file }}
              </wb-combobox-list-item>
            </wb-combobox-list>
            <div slot="message">
              <app-validation-notification
                name="ODX-D file"
                [type]="Format.Pick"
                *ngIf="
                  validationService.getFormStatus(
                    FormGroup.Odxd,
                    ValidationStatus.Invalid
                  ) &&
                  validationService.getFormStatus(
                    FormGroup.Odxd,
                    ValidationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-combobox>
        </wb-grid-col>
        <wb-grid-col mq6="6">
          <wb-combobox class="spacing-xs" *ngIf="showCandelaConfiguration">
            <wb-combobox-input
              slot="input"
              [(ngModel)]="selectedCandelaFile"
              name="candela"
              ngDefaultControl
              #candela="ngModel"
              [disabled]="
                this.validationService.getFormStatus(
                  FormGroup.Candela,
                  ValidationStatus.Loading
                )
              "
              [error]="
                validationService.isComboBoxInValid(
                  FormGroup.Candela,
                  candela,
                  this.vTestingJobService.vtestingJobDefinitions[this.index]
                    .test_scope.files!.candela!,
                  this.files.candela
                )
              "
              (wbcomboboxinput)="candelaChange($event)"
            >
              <label slot="label">CDD file*</label>
            </wb-combobox-input>
            <wb-input-action
              slot="actions"
              *ngIf="
                this.validationService.getFormStatus(
                  FormGroup.Candela,
                  ValidationStatus.Loading
                )
              "
            >
              <wb-spinner id="loading-spinner"></wb-spinner>
            </wb-input-action>
            <wb-combobox-list
              no-items-selected-text="No CDD File selected"
              no-items-found-text="No CDD File found"
              (wbcomboboxlistselectionchange)="candelaChange($event)"
            >
              <wb-combobox-list-item
                *ngFor="let file of files.candela"
                value="{{ file }}"
              >
                {{ file }}
              </wb-combobox-list-item>
            </wb-combobox-list>
            <div slot="message">
              <app-validation-notification
                name="Candela file"
                [type]="Format.Pick"
                *ngIf="
                  validationService.getFormStatus(
                    FormGroup.Candela,
                    ValidationStatus.Invalid
                  ) &&
                  validationService.getFormStatus(
                    FormGroup.Candela,
                    ValidationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-combobox>
        </wb-grid-col>
      </wb-grid-row>
    </wb-grid>
  </wb-grid-col>
</wb-grid-row>
<wb-grid-row>
  <wb-grid-col mq6="12">
    <wb-heading class="spacing-xs" size="xs">Info</wb-heading>
  </wb-grid-col>
  <wb-grid-col mq6="6">
    <wb-input
      as="input"
      [(ngModel)]="
        this.vTestingJobService.vtestingJobDefinitions[index].test_scope
          .configuration!.starc_testset_id
      "
      name="starc_test_set_id"
      ngDefaultControl
      inputmode="text"
      class="spacing-xs"
      (wbinput)="onStarcIdInputChange($event)"
    >
      <span slot="label"> STARC test set ID (optional) </span>
    </wb-input>
  </wb-grid-col>
  <wb-grid-col mq6="6">
    <wb-combobox class="spacing-xs">
      <wb-combobox-input slot="input" name="domain">
        <label slot="label">STARC domain (optional)</label>
      </wb-combobox-input>
      <wb-combobox-list
        no-items-selected-text="No system selected"
        no-items-found-text="No systems found"
        (wbcomboboxlistitemselectionchange)="domainChange($event)"
      >
        <wb-combobox-list-item
          *ngFor="let domain of starcDomains"
          value="{{ domain }}"
        >
          {{ domain }}
        </wb-combobox-list-item>
      </wb-combobox-list>
    </wb-combobox>
  </wb-grid-col>
</wb-grid-row>
<wb-grid-row *ngIf="testGroups.length > 0 || showNetworkConfiguration">
  <wb-grid-col mq6="12">
    <wb-grid [fullWidth]="true">
      <wb-grid-row>
        <wb-grid-col mq6="6">
          <wb-combobox *ngIf="testGroups.length > 0">
            <wb-combobox-input
              slot="input"
              selection-count-suffix="groups selected"
              name="groups"
              id="groups"
            >
              <label slot="label">Test groups*</label>
            </wb-combobox-input>
            <wb-combobox-list
              variant="multi"
              show-chip-list-row="false"
              no-items-selected-text="No groups selected"
              no-items-found-text="No groups found"
              name="testgroups"
              (wbcomboboxlistselectionchange)="testGroupChange($event)"
            >
              <wb-combobox-list-item
                *ngFor="let group of testGroups"
                [selected]="
                  this.vTestingJobService.simulationBundles[
                    this.id
                  ].test_environment.configuration!.test_groups!.includes(group)
                "
                value="{{ group }}"
              >
                {{ group }}
              </wb-combobox-list-item>
            </wb-combobox-list>
          </wb-combobox>
        </wb-grid-col>
        <wb-grid-col mq6="6">
          <wb-combobox *ngIf="showNetworkConfiguration">
            <wb-combobox-input
              slot="input"
              [(ngModel)]="
                this.vTestingJobService.simulationBundles[this.id]
                  .test_environment.configuration!.networks![0].name
              "
              name="networkName"
              ngDefaultControl
              #network="ngModel"
              [disabled]="
                this.validationService.getFormStatus(
                  FormGroup.Network,
                  ValidationStatus.Loading
                )
              "
              [error]="
                validationService.isComboBoxInValid(
                  FormGroup.Network,
                  network,
                  this.vTestingJobService.simulationBundles[this.id]
                    .test_environment.configuration!.networks![0].name!,
                  this.networkNames
                )
              "
              (wbcomboboxinput)="networkChange($event)"
            >
              <label slot="label">Network name*</label>
              <wb-input-action
                slot="actions"
                *ngIf="
                  this.validationService.getFormStatus(
                    FormGroup.Network,
                    ValidationStatus.Loading
                  )
                "
              >
                <wb-spinner id="loading-spinner"></wb-spinner>
              </wb-input-action>
            </wb-combobox-input>
            <wb-combobox-list
              no-items-found-text="No Networks Names found"
              no-items-selected-text="No Networks Names selected"
              (wbcomboboxlistselectionchange)="networkChange($event)"
            >
              <wb-combobox-list-item
                *ngFor="let networkName of networkNames"
                value="{{ networkName }}"
              >
                {{ networkName }}
              </wb-combobox-list-item>
            </wb-combobox-list>
            <div slot="message">
              <app-validation-notification
                name="network"
                [type]="Format.Pick"
                *ngIf="
                  !this.validationService.getFormStatus(
                    FormGroup.Network,
                    ValidationStatus.Loading
                  ) &&
                  validationService.getFormStatus(
                    FormGroup.Network,
                    ValidationStatus.Invalid
                  ) &&
                  validationService.getFormStatus(
                    FormGroup.Network,
                    ValidationStatus.Touched
                  )
                "
              >
              </app-validation-notification>
            </div>
          </wb-combobox>
        </wb-grid-col>
      </wb-grid-row>
    </wb-grid>
  </wb-grid-col>
</wb-grid-row>
