import {Component} from '@angular/core';
import {VpackagereleaseService} from 'src/app/package/services/vpackagerelease.service';
import {HeaderTitleService} from 'src/app/services/header/header-title.service';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {Format, ValidationService, ValidationStatus,} from '../../../services/validation/validation.service';
import {VpackageService} from 'src/app/package/services/vpackage.service';

enum FormGroup {
  hsmVersion = 'hsmVersion',
  vecuSemanticVersion = 'vecuSemanticVersion',
  simulationFrameworkVersion = 'simulationFrameworkVersion',
  sensorVersion = 'sensorVersion',
}

@Component({
  selector: 'app-vpackage-release-applications',
  templateUrl: './vpackage-release-applications.component.html',
  styleUrl: './vpackage-release-applications.component.css',
})
export class VpackageReleaseApplicationsComponent {
  title = 'Create new vECU package release';
  description = '';
  primaryButtonText = 'Finish Release';
  Simulation_frameworks: string[] = [];
  Simulation_frameworks_versions: string[] = [];
  formGroup = FormGroup;
  format = Format;
  validationStatus = ValidationStatus;

  constructor(
    public vPackageReleaseService: VpackagereleaseService,
    private breadcrumbService: BreadcrumbsService,
    private headerService: HeaderTitleService,
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService,
    public validationService: ValidationService,
    public vPackageService: VpackageService
  ) {
  }

  spinner: boolean = false;

  ngOnInit() {

    this.initValidation();
    if (
      !this.vPackageReleaseService.packageReleasePost.application_instances
        ?.sensor_actors?.length
    ) {
      this.validationService.setInputFieldTouched(FormGroup.sensorVersion, true);
    } else {
      this.vPackageReleaseService.packageReleasePost.application_instances.sensor_actors.forEach(
        (sa) => (sa.version = '')
      );
    }
    this.GetToolVersions(
      this.vPackageService.packageOverview!.application_instances!
        .vecu_applications![0].simulation_framework
    );
    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    this.headerService.primaryButtonText = this.primaryButtonText;
    const breadcrumb = {
      label: 'Create new release',
      route: '/',
    };
    if (this.breadcrumbService.findIndexByLabel(breadcrumb.label) > -1) {
      this.breadcrumbService.removeAboveIndex(
        this.breadcrumbService.findIndexByLabel(breadcrumb.label)
      );
    } else {
      this.breadcrumbService.breadcrumbs.push(breadcrumb);
    }

    // this.headerService.clearPrimaryClickFunction();
    setTimeout(() => this.setPrimaryClick(), 500);
  }

  initValidation(): void {
    this.validationService.initialize(FormGroup);
    if (
      !this.vPackageReleaseService.packageReleasePost.application_instances
        ?.hardware_security_module
    ) {
      this.validationService.setInputFieldTouched(FormGroup.hsmVersion, true);
    }
  }

  GetToolVersions(name: any): void {
    this.capabilitiesService.v1ToolsNameGet(name).subscribe({
      next: (response) => {
        response.data.versions.forEach((item: any) => {
          this.Simulation_frameworks_versions.push(item.version);
        });
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  setPrimaryClick(): void {
    this.headerService.primaryClick$.subscribe(() => {
      this.finishRelease();
    });
  }

  finishRelease(): void {
    this.spinner = true;
    this.vPackageReleaseService.postPackageRelease();
  }

  onSimulationFrameWorkVersionInputChange(event: any, index: number): void {
    this.vPackageReleaseService.packageReleasePost!.application_instances!.vecu_applications![
      index
      ].simulation_framework_version = event.detail.value;
  }

  onSimulationFrameWorkVersionSelectChange(event: any, index: number): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageReleaseService.packageReleasePost!.application_instances!.vecu_applications![
          index
          ].simulation_framework_version = event.detail.value.label;
      } else {
        this.vPackageReleaseService.packageReleasePost!.application_instances!.vecu_applications![
          index
          ].simulation_framework_version = '';
      }
    }
  }
}
