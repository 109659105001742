<wb-grid [fullWidth]="true">
  <wb-grid-row>
    <wb-grid-col start-col-mq6="1" mq6="4">
      <div style="height: 100%;"
      >
        <wb-card style="height: 100%;" interactive (click)="routeTo(cards[0].buttonLink)">
          <wb-card-layout-nba>
            <wb-icon name="bds/logistics/24"></wb-icon>
            <wb-text size="l" strong>{{ cards[0].header }}</wb-text>
            <wb-text size="m">{{ cards[0].description }}</wb-text>
          </wb-card-layout-nba>
        </wb-card>
      </div>
    </wb-grid-col>
    <wb-grid-col start-col-mq6="5" mq6="4">
      <div style="height: 100%;"
      >
        <wb-card style="height: 100%;" interactive (click)="routeTo(cards[1].buttonLink)">
          <wb-card-layout-nba>
            <wb-icon name="bds/van-checked/24"></wb-icon>
            <wb-text size="l" strong>{{ cards[1].header }}</wb-text>
            <wb-text size="m">{{ cards[1].description }}</wb-text>
          </wb-card-layout-nba>
        </wb-card>
      </div>
    </wb-grid-col>
    <!-- <wb-grid-col start-col-mq6="9" mq6="4">
      <div style="height: 100%;"
      >
        <wb-card style="height: 100%;" interactive (click)="routeTo(cards[2].buttonLink)">
          <wb-card-layout-nba>
            <wb-icon name="vehicle-managment-tool"></wb-icon>
            <wb-text size="l" strong>{{ cards[2].header }}</wb-text>
            <wb-text size="m">{{ cards[2].description }}</wb-text>
          </wb-card-layout-nba>
        </wb-card>
      </div>
    </wb-grid-col> -->
  </wb-grid-row>
</wb-grid>
