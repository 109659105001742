import {Component} from '@angular/core';
import {VpackagereleaseService} from 'src/app/package/services/vpackagerelease.service';
import {HeaderTitleService} from 'src/app/services/header/header-title.service';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {VpackageService} from 'src/app/package/services/vpackage.service';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {ColDef, GetDataPath} from "ag-grid-community";
import {forkJoin} from "rxjs";
import {map} from "rxjs/operators";
import {NotificationService} from "../../../services/notification/notification.service";

@Component({
  selector: 'app-vpackage-release-content',
  templateUrl: './vpackage-release-content.component.html',
  styleUrl: './vpackage-release-content.component.css',
})
export class VpackageReleaseContentComponent {
  title = 'Create new vPackage release';
  description = '';

  constructor(
    public vPackageReleaseService: VpackagereleaseService,
    private breadcrumbService: BreadcrumbsService,
    private headerService: HeaderTitleService,
    public vPackageService: VpackageService,
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService
  ) {
  }

  releases: { [id: string]: [] } = {};
  showDataTable: boolean = false;


  public columnDefs: ColDef[] = [
    {
      headerName: ' ',
      resizable: false,
      sortable: false,
      filter: false,
      suppressMovable: true,
      maxWidth: 50,
      menuTabs: [],
      checkboxSelection: function (params: any) {
        return params.node && params.node.data && params.node.data.type === 'Release';
      }
    },
    {field: 'name', flex: 5},
    {field: 'type', flex: 3},
    {field: 'revision', flex: 2},
    {field: 'ncdRelease', flex: 3},
    {field: 'semanticVersion', flex: 3},
    {field: 'appName', flex: 3},
    {field: 'appSemanticVersion', flex: 3},
    {field: 'framework', flex: 3},
  ];

  public defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
    flex: 1,
    valueFormatter: (params) => {
      return params.value != null && params.value !== '' ? params.value : '-';
    },
  };

  public autoGroupColumnDef: ColDef = {
    headerName: ' ',
    field: 'id',
    flex: 4,
    cellRendererParams: {
      suppressCount: true,
    },
    resizable: false,
    sortable: false,
    filter: false,
    suppressMovable: true,
    maxWidth: 75,
    menuTabs: [],
    valueFormatter: () => '',
  };

  public rowData: any[] = [];
  public filteredRowData: any[] = [];

  public getDataPath: GetDataPath = (data: any) => {
    return data.id;
  };

  ngOnInit() {
    this.initReleaseSelectionGrid();

    this.headerService.primaryDisabled = true;
    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    const breadcrumb = {
      label: 'Create new release',
      route: '/',
    };
    if (this.breadcrumbService.findIndexByLabel(breadcrumb.label) > -1) {
      this.breadcrumbService.removeAboveIndex(
        this.breadcrumbService.findIndexByLabel(breadcrumb.label)
      );
    } else {
      this.breadcrumbService.breadcrumbs.push(breadcrumb);
    }

    this.headerService.clearPrimaryClickFunction();
    this.headerService.primaryDisabled = true;
    this.headerService.primaryButtonText = "Finish";
    setTimeout(() => this.setPrimaryClick(), 500);
  }

  setSelectedReleases(event: any) {
    this.vPackageReleaseService.packageReleasePost.package_releases = [];
    event.forEach((release: any) => this.vPackageReleaseService.packageReleasePost.package_releases?.push({id: release.id[release.id.length - 1]}));
    this.headerService.primaryDisabled = this.vPackageReleaseService.packageReleasePost.package_releases.length < this.vPackageService.packageOverview.packages!.length;
  }

  private initReleaseSelectionGrid() {
    let observables: any[] = [];

    this.vPackageService.packageOverview.packages?.forEach((pkg: any) => {
      this.rowData.push({
        id: [pkg.id],
        name: pkg.name,
        type: this.evaluatePackageType(pkg.type),
        revision: pkg.revision
      });

      observables.push(this.capabilitiesService.getPackageReleases(
        this.vPackageService.packageOverview.model_series![0].name!,
        pkg.id
      ).pipe(
        map((response: any) => {
          return {pkg, releases: response.data.items};
        })
      ));
    });

    forkJoin(observables).subscribe({
      next: (results) => results.forEach((result) => result.releases.forEach((release: any) => {
        if (!release.package_releases) {
          this.rowData.push({
            id: [result.pkg.id, release.id],
            name: release.name,
            type: "Release",
            revision: "-",
            ncdRelease: release.application_instances.ncd_release,
            semanticVersion: release.application_instances.semantic_version,
            appName: release.application_instances.vecu_applications[0].name,
            appSemanticVersion: release.application_instances.vecu_applications[0].semantic_version,
            framework: release.application_instances.vecu_applications[0].simulation_framework_version
          });
          return;
        }

        this.rowData.push({
          id: [result.pkg.id, release.id],
          name: release.name,
          type: "Release",
          revision: "-",
        });
        release.package_releases.forEach((pRelease: any) => {
          if (pRelease.package_releases) {
            pRelease.package_releases.forEach((lowerPackageRelease: any) => {
              this.rowData.push({
                id: [result.pkg.id, release.id, lowerPackageRelease.id],
                name: pRelease.name,
                type: "Application",
                revision: "-",
                ncdRelease: lowerPackageRelease.application_instances.ncd_release,
                semanticVersion: lowerPackageRelease.application_instances.semantic_version,
                appName: lowerPackageRelease.application_instances.vecu_applications[0].name,
                appSemanticVersion: lowerPackageRelease.application_instances.vecu_applications[0].semantic_version,
                framework: lowerPackageRelease.application_instances.vecu_applications[0].simulation_framework_version
              });
            });
          } else {
            this.rowData.push({
              id: [result.pkg.id, release.id, pRelease.id],
              name: pRelease.name,
              type: "Application",
              revision: "-",
              ncdRelease: pRelease.application_instances.ncd_release,
              semanticVersion: pRelease.application_instances.semantic_version,
              appName: pRelease.application_instances.vecu_applications[0].name,
              appSemanticVersion: pRelease.application_instances.vecu_applications[0].semantic_version,
              framework: pRelease.application_instances.vecu_applications[0].simulation_framework_version
            });
          }
        });
      })),
      complete: () => this.showDataTable = true,
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  private evaluatePackageType(shortenedPkgType: string) {
    let pkgType;

    switch (shortenedPkgType) {
      case "vGPKG":
        pkgType = "Generic package";
        break;
      case "vDPKG":
        pkgType = "Domain package";
        break;
      case "vECUPKG":
        pkgType = "vECU package";
        break;
      default:
        pkgType = "not implemented"
    }

    return pkgType;
  }


  setPrimaryClick(): void {
    this.headerService.primaryClick$.subscribe(() => {
      this.finishRelease();
    });
  }

  finishRelease(): void {
    this.showDataTable = false;
    this.vPackageReleaseService.postPackageRelease();
  }
}
