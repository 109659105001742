<div *ngIf="spinner">
  <wb-spinner
    class="centered-element"
    theme="light"
    style="width: 128px; height: 128px"
  ></wb-spinner>
</div>
<wb-grid [fullWidth]="true" *ngIf="!spinner">
  <wb-grid-row>
    <wb-grid-col mq6="4" class="spacing-xs spacing-top-xs">
      <wb-heading size="xs">Release data</wb-heading>
    </wb-grid-col>
  </wb-grid-row>
  <wb-grid-row>
    <wb-grid-col mq6="4">
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="
            this.vPackageReleaseService.packageReleasePost!
              .application_instances!.ncd_release
          "
          name="ncdRelease"
          ngDefaultControl
          required
          #ncdRelease="ngModel"
          [error]="
            this.validationService.isComboBoxInValid(
              this.formGroup.ncdRelease,
              ncdRelease,
              this.vPackageReleaseService.packageReleasePost!
                .application_instances!.ncd_release,
              getNcdReleaseOptions()
            )
          "
          (wbcomboboxinput)="onNcdReleaseInputChange($event)"
        >
          <label slot="label">NCD release*</label>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No ECU Extract selected"
          no-items-found-text="No ECU Extract found"
          (wbcomboboxlistselectionchange)="onNcdReleaseSelectChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let ncd of ncdReleases"
            value="{{ ncd.ncd_release }}"
          >{{ ncd.ncd_release }}
          </wb-combobox-list-item
          >
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="NCD release"
            [type]="format.Pick"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.ncdRelease,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.ncdRelease,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
    </wb-grid-col>
    <wb-grid-col mq6="4">
      <wb-input
      *ngIf="this.vPackageReleaseService.packageReleasePost!.release_type != 'release'"
        as="input"
        [(ngModel)]="
          this.vPackageReleaseService.packageReleasePost!.application_instances!
            .semantic_version
        "
        name="semanticVersion"
        ngDefaultControl
        inputmode="text"
        required
        #semanticVersion="ngModel"
        [error]="
          validationService.isFieldInvalid(
            this.formGroup.semanticVersion,
            semanticVersion,
            this.vPackageReleaseService.packageReleasePost!
              .application_instances!.semantic_version,
              format.SemanticVersionOther,
            SEMENTIC_VERSIONS
          )
        "
        class="spacing-xs"
      >
        <span slot="label">Semantic version*</span>
        <div slot="message">
          <app-validation-notification
            name="Semantic version"
            [type]="format.None"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.semanticVersion,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.semanticVersion,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
          <app-validation-notification
            name="Semantic version"
            [type]="format.SemanticVersionOther"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.semanticVersion,
                validationStatus.Regex
              ) &&
              validationService.getFormStatus(
                this.formGroup.semanticVersion,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-input>
      <wb-input
        as="input"
        *ngIf="this.vPackageReleaseService.packageReleasePost!.release_type == 'release'"
        [(ngModel)]="
          this.vPackageReleaseService.packageReleasePost!.application_instances!
            .semantic_version
        "
        name="semanticVersion"
        ngDefaultControl
        inputmode="text"
        required
        #semanticVersion="ngModel"
        [error]="
          validationService.isFieldInvalid(
            this.formGroup.semanticVersion,
            semanticVersion,
            this.vPackageReleaseService.packageReleasePost!
              .application_instances!.semantic_version,
            format.SemanticVersion,
            SEMENTIC_VERSIONS
          )
        "
        class="spacing-xs"
      >
        <span slot="label">Semantic version*</span>
        <div slot="message">
          <app-validation-notification
            name="Semantic version"
            [type]="format.None"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.semanticVersion,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.semanticVersion,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
          <app-validation-notification
            name="Semantic version"
            [type]="format.SemanticVersion"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.semanticVersion,
                validationStatus.Regex
              ) &&
              validationService.getFormStatus(
                this.formGroup.semanticVersion,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-input>
    </wb-grid-col>
  </wb-grid-row>
  <wb-grid-row>
    <wb-grid-col mq6="10">
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="
            this.vPackageReleaseService.packageReleasePost!
              .application_instances!.manifests![0].file_name
          "
          name="ecu_extract"
          ngDefaultControl
          required
          #ecu_extract="ngModel"
          [error]="
            this.validationService.isComboBoxInValid(
              this.formGroup.ecuExtract,
              ecu_extract,
              this.vPackageReleaseService.packageReleasePost!
                .application_instances!.manifests![0].file_name,
              ECU_Extracts
            )
          "
          (wbcomboboxinput)="onEcuExtractFileInputChange($event, 1)"
        >
          <label slot="label">ECU extract*</label>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No ECU Extract selected"
          no-items-found-text="No ECU Extract found"
          (wbcomboboxlistselectionchange)="onEcuExtractFileChange($event, 1)"
        >
          <wb-combobox-list-item
            *ngFor="let Ecu_extract of ECU_Extracts"
            value="{{ Ecu_extract }}"
          >{{ Ecu_extract }}
          </wb-combobox-list-item
          >
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="ECU extract"
            [type]="format.Pick"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.ecuExtract,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.ecuExtract,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
    </wb-grid-col>

    <wb-grid-col
      mq6="4"
    >
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="
            this.vPackageReleaseService.packageReleasePost!
              .application_instances!.manifests![1].file_name
          "
          name="odxd"
          ngDefaultControl
          selection-count-suffix="groups selected"
          required
          #odxd="ngModel"
          [error]="
            vPackageReleaseService.packageOverview?.application_instances
              ?.vecu_level == 'lvl1' ||
            vPackageReleaseService.packageOverview?.application_instances
              ?.vecu_level == 'lvl2'
              ? false
              : this.validationService.isComboBoxInValid(
                  this.formGroup.odxd,
                  odxd,
                  this.vPackageReleaseService.packageReleasePost!
                    .application_instances!.manifests![1].file_name,
                  ODX_D_Files
                )
          "
          (wbcomboboxinput)="onODxDFileInputChange($event, 1)"
        >
          <label slot="label"
          >ODX-D file
            {{
              vPackageReleaseService.packageOverview?.application_instances
                ?.vecu_level == "lvl1" ||
              vPackageReleaseService.packageOverview?.application_instances
                ?.vecu_level == "lvl2"
                ? "(optional)"
                : "*"
            }}</label
          >
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No ODX-D File selected"
          no-items-found-text="No ODX-D File found"
          (wbcomboboxlistselectionchange)="onODxDFileChange($event, 1)"
        >
          <wb-combobox-list-item
            *ngFor="let odx_d_file of ODX_D_Files"
            value="{{ odx_d_file }}"
          >{{ odx_d_file }}
          </wb-combobox-list-item
          >
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="ODX-D file"
            [type]="format.Pick"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.odxd,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.odxd,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
    </wb-grid-col>

    <wb-grid-col mq6="4">
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="
            this.vPackageReleaseService.packageReleasePost!
              .application_instances!.manifests![2].file_name
          "
          name="candela"
          ngDefaultControl
          selection-count-suffix="groups selected"
          required
          #candela="ngModel"
          [error]="
            vPackageReleaseService.packageOverview?.application_instances
              ?.vecu_level == 'lvl1' ||
            vPackageReleaseService.packageOverview?.application_instances
              ?.vecu_level == 'lvl2'
              ? false
              : this.validationService.isComboBoxInValid(
                  this.formGroup.candela,
                  candela,
                  this.vPackageReleaseService.packageReleasePost!
                    .application_instances!.manifests![2].file_name,
                  CDD_Files
                )
          "
          (wbcomboboxinput)="onCDDFileInputChange($event, 1)"
        >
          <label slot="label"
          >CDD file
            {{
              vPackageReleaseService.packageOverview?.application_instances
                ?.vecu_level == "lvl1" ||
              vPackageReleaseService.packageOverview?.application_instances
                ?.vecu_level == "lvl2"
                ? "(optional)"
                : "*"
            }}</label
          >
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No CDD File selected"
          no-items-found-text="No CDD File found"
          (wbcomboboxlistselectionchange)="onCDDFileChange($event, 1)"
        >
          <wb-combobox-list-item
            *ngFor="let cdd_file of CDD_Files"
            value="{{ cdd_file }}"
          >{{ cdd_file }}
          </wb-combobox-list-item
          >
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="Candela file"
            [type]="format.Pick"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.candela,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.candela,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
    </wb-grid-col>

    <wb-grid-col mq6="4">
    
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="
            this.vPackageReleaseService.packageReleasePost!
              .application_instances!.manifests![3].file_name
          "
          name="SMR-D"
          #smr_d="ngModel"
          (wbcomboboxinput)="onSMRDFileInputChange($event, 1)"
        >
         <!--Error is remove to make it optional--> 
          <label slot="label">SMR-D (optional)</label>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No SMR-D selected"
          no-items-found-text="No SMR-D found"
          (wbcomboboxlistselectionchange)="onSMRDFileChange($event, 1)"
        >
          <wb-combobox-list-item
            *ngFor="let smr_d of SMRD_Files"
            value="{{ smr_d }}"
          >{{ smr_d}}
          </wb-combobox-list-item
          >
        </wb-combobox-list>
        <!-- <div slot="message">
          <app-validation-notification
            name="SMR_D"
            [type]="format.Pick"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.smrd,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.smrd,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div> -->
      </wb-combobox>
    </wb-grid-col>
  </wb-grid-row>

<wb-grid-row *ngIf="this.LIN_Network_Length > 0">
  <wb-grid-col mq6="4" class="spacing-xs spacing-top-xs">
    <wb-heading size="xs">LIN Networks</wb-heading>
  </wb-grid-col>
</wb-grid-row>
<wb-grid-row *ngFor="let LIN_Network of this.LIN_Files | keyvalue">
  <wb-grid-col mq6="4">
    
    <wb-combobox class="spacing-xs">
      <wb-combobox-input
        slot="input"
        
          
          (wbcomboboxinput)="onLINFileInputChange($event, 1)"
      >
       <!--Error is remove to make it optional--> 
        <label slot="label">{{LIN_Network.key}}</label>
      </wb-combobox-input>
      <wb-combobox-list
      no-items-selected-text="No LDF File selected"
          no-items-found-text="No LDF File found"
          (wbcomboboxlistselectionchange)="onLINFileInputChange($event, 1)"
      >
        <wb-combobox-list-item
          *ngFor="let Ldf of LIN_Network.value; let isFirst = first"
          value="{{ Ldf }}"
          [selected]=" isFirst "
        >{{ Ldf }}
        </wb-combobox-list-item
        >
      </wb-combobox-list>
    </wb-combobox>
  </wb-grid-col>
</wb-grid-row>

  <wb-radio-group>
    <wb-checkbox
      (wbchange)="onManifestSelectChange($event)"
      class="spacing-xs"
      ngDefaultControl
      [checked] = "checked"
      name="Manifest"
    >
      Use Selected Manifest
    </wb-checkbox>
  </wb-radio-group>
  <wb-grid-row></wb-grid-row>
</wb-grid>
