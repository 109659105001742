import { Component } from '@angular/core';
import { VpackageService } from 'src/app/package/services/vpackage.service';

@Component({
  selector: 'app-vpackage-release-form',
  templateUrl: './vpackage-release-form.component.html',
  styleUrl: './vpackage-release-form.component.css'
})
export class VpackageReleaseFormComponent {

  constructor(public vPackageService: VpackageService){}
}
