import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ColDef, GetDataPath, GridApi, GridReadyEvent, IRowNode, RowNode} from "ag-grid-community";
import {SimulationBuilderService} from "../../../simulation-builder/services/simulation-builder.service";
import {CustomLoadingOverlayComponent} from "../custom-loading-overlay/custom-loading-overlay.component";

@Component({
  selector: 'app-custom-tree-selection-grid',
  templateUrl: './custom-tree-selection-grid.component.html',
  styleUrl: './custom-tree-selection-grid.component.css'
})
export class CustomTreeSelectionGridComponent implements OnDestroy {
  @Input() columnDefs!: ColDef[];
  @Input() defaultColDef!: ColDef;
  @Input() autoGroupColumnDef!: ColDef;
  @Input() rowData?: any[];
  @Input() getDataPath!: GetDataPath;
  @Input() mode: "normal" | "simulation-scope" | "singleCategory" = "normal";
  @Output() selectedRowData = new EventEmitter;
  @Input() pageSize: { value: number } = {value: 10};
  currentPage: { value: number } = {value: 1};

  gridApi!: GridApi;
  public loadingOverlayComponent: any = CustomLoadingOverlayComponent;

  constructor(private simulationBuilderService: SimulationBuilderService) {
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  ngOnDestroy() {
    this.simulationBuilderService.selectedNodes = this.gridApi.getSelectedNodes();
  }

  onSelectionChanged() {
    this.selectedRowData.emit(this.gridApi.getSelectedNodes().map(node => node.data));
  }

  rowSelected(event: any): void {
    const node: RowNode = event.node;

    if (this.mode == "normal" || this.mode == "simulation-scope")
      this.handleNormalMode(node);
    if (this.mode == "singleCategory" && node.isSelected())
      this.handleSingleCategoryMode(node);
  }

  private handleNormalMode(node: RowNode) {
    if (node.isSelected() !== undefined) {
      this.updateChildSelections(node, node.isSelected()!);
    }

    this.updateParentSelections(node);
  }

  private handleSingleCategoryMode(node: RowNode) {
    node.parent?.childrenAfterGroup?.forEach(sibling =>
      sibling.id != node.id && sibling.isSelected() && sibling.setSelected(false));
  }

  updateChildSelections(node: RowNode, isSelected: boolean): void {
    node.childrenAfterGroup && node.childrenAfterGroup.forEach((childNode) => {
      childNode.setSelected(isSelected);
      this.updateChildSelections(childNode, isSelected);
    });
  }

  updateParentSelections(node: RowNode): void {
    if (!node.parent) return;

    const children = node.parent.childrenAfterGroup ?? [];
    const allChildrenSelected = children.every(child => child.isSelected() === true);
    const anyChildSelected = children.some(child => child.isSelected() === true || child.isSelected() === undefined);

    node.parent.setSelected(allChildrenSelected ? true : anyChildSelected ? undefined! : false);

    this.updateParentSelections(node.parent);
  }

  setGridPage(event: Event): void {
    const customEvent = event as CustomEvent;
    this.gridApi?.paginationGoToPage(customEvent.detail - 1);
    this.currentPage.value = customEvent.detail;
  }

  getTotalPages(): number {
    return Math.ceil((this.rowData?.filter((item) => item.id?.length == 1)?.length || 0) / this.pageSize.value);
  }

  onRowDataChanged(): void {
    if (this.mode == "simulation-scope" && this.simulationBuilderService.selectedNodes.length) {
      const nodesToSelect: IRowNode[] = [];
      if (this.gridApi) {
        this.gridApi!.forEachNode((node: IRowNode) => {
          let selectedNode = this.simulationBuilderService.selectedNodes.some((selectedNode) => selectedNode.data.fropId == node.data.fropId);

          if (node.data && selectedNode) {
            nodesToSelect.push(node);
          }
        });
        this.gridApi!.setNodesSelected({nodes: nodesToSelect, newValue: true});
      }
    }
  }
}
