import {Component, Input} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {SimulationBuilderService} from 'src/app/simulation-builder/services/simulation-builder.service';

@Component({
  selector: 'app-simulation-type-renderer',
  templateUrl: './simulation-type-renderer.component.html',
  styleUrl: './simulation-type-renderer.component.css',
})
export class SimulationTypeRendererComponent implements ICellRendererAngularComp {
  @Input() diagnosticName!: string;
  @Input() params?: ICellRendererParams;

  simulationType?: "restbus" | "generic_ecu";

  constructor(public simulationBuilderService: SimulationBuilderService) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.simulationType = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onSimulationTypeChange(type: "restbus" | "generic_ecu"): void {
    let ecu = this.simulationBuilderService.simulatedEcus.find((ecu) => ecu.diagnostic_name == this.params?.data.diagnostic_name);

    ecu.type = type;
  }
}
