<!-- <wb-skeleton
  *ngIf="VirtualRemoteTestBenchVtestingJobService.skeleton.includes(true)"
  style="width: 100%; height: 300px"
  class="spacing-s"
></wb-skeleton> -->

<wb-grid-row>
    <wb-grid-col mq6="12" class="spacing-top-xs">
      <wb-grid [fullWidth]="true">
        <wb-grid-row>
          <wb-grid-col mq6="1">
            <wb-combobox>
              <wb-combobox-input
                slot="input"
                name="definition"
                [(ngModel)]="this.testTypeName"
                ngDefaultControl
                required
                #definitionType="ngModel"
                [error]="
                  validationService.isComboBoxInValid(
                    formGroup.definitionType,
                    definitionType,
                    this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id].test_environment.test_type!,
                    getTestTypes()
                  )
                "
                (wbcomboboxinput)="testTypeChange($event)"
              >
                <label slot="label">Definition type*</label>
              </wb-combobox-input>
              <wb-combobox-list
                no-items-found-text="No types found"
                (wbcomboboxlistselectionchange)="testTypeChange($event)"
              >
                <!-- <wb-list-group-item>
                  <strong> Brop Component </strong>
                </wb-list-group-item>
                <wb-combobox-list-item
                  *ngFor="let type of testTypes"
                  value="{{ type }}"
                  style="margin-left: 5px"
                >
                  {{ type }}
                </wb-combobox-list-item> -->
                <wb-list-group-item>
                  <strong> Frop </strong>
                </wb-list-group-item>
                <wb-combobox-list-item
                  *ngFor="let fropOption of fropOptions"
                  value="{{ fropOption }}"
                  style="margin-left: 5px"
                >
                  {{ fropOption }}
                </wb-combobox-list-item>
              </wb-combobox-list>
              <div slot="message">
                <app-validation-notification
                  name="definition type"
                  [type]="format.Pick"
                  *ngIf="
                    validationService.getFormStatus(
                      this.formGroup.definitionType,
                      validationStatus.Invalid
                    ) &&
                    validationService.getFormStatus(
                      this.formGroup.definitionType,
                      validationStatus.Touched
                    )
                  "
                >
                </app-validation-notification>
              </div>
            </wb-combobox>
          </wb-grid-col>
          <wb-grid-col mq6="3">
            <wb-input
              inputmode="text"
              [(ngModel)]="
                this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].name
              "
              name="jobDefinition"
              ngDefaultControl
              required
              #definitionName="ngModel"
              [error]="
                validationService.isFieldInvalid(
                  formGroup.definitionName,
                  definitionName,
                  this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[this.index].name,
                  format.Name
                )
              "
            >
              <span slot="label">Definition name*</span>
              <div slot="message">
                <app-validation-notification
                  name="Definition name"
                  [type]="format.None"
                  *ngIf="
                    validationService.getFormStatus(
                      this.formGroup.definitionName,
                      validationStatus.Invalid
                    ) &&
                    validationService.getFormStatus(
                      this.formGroup.definitionName,
                      validationStatus.Touched
                    )
                  "
                >
                </app-validation-notification>
                <app-validation-notification
                  name="Job name"
                  [type]="format.Name"
                  *ngIf="
                    validationService.getFormStatus(
                      this.formGroup.definitionName,
                      validationStatus.Length
                    ) &&
                    validationService.getFormStatus(
                      this.formGroup.definitionName,
                      validationStatus.Touched
                    )
                  "
                >
                </app-validation-notification>
              </div>
            </wb-input>
          </wb-grid-col>
          <wb-grid-col mq6="3">
            <wb-combobox>
              <wb-combobox-input
                slot="input"
                name="ecu"
                ngDefaultControl
                [(ngModel)]="vEcuNameString"
                #vPackage="ngModel"
                [error]="
                  validationService.isComboBoxInValid(
                    formGroup.package,
                    vPackage,
                    vEcuNameString,
                    getVEcusAsStringArray()
                  )
                "
                (wbcomboboxinput)="packageChange($event)"
              >
                <label slot="label">vECU package*</label>
              </wb-combobox-input>
              <wb-combobox-list
                no-items-selected-text="No vPackage selected"
                no-items-found-text="No vPackage found"
                (wbcomboboxlistselectionchange)="packageChange($event)"
              >
                <wb-combobox-list-item *ngFor="let vEcu of vEcus" [value]="vEcu">
                  {{ vEcu.name }}
                </wb-combobox-list-item>
              </wb-combobox-list>
              <div slot="message">
                <app-validation-notification
                  name="vECU package"
                  [type]="format.Pick"
                  *ngIf="
                    validationService.getFormStatus(
                      this.formGroup.package,
                      validationStatus.Invalid
                    ) &&
                    validationService.getFormStatus(
                      this.formGroup.package,
                      validationStatus.Touched
                    )
                  "
                >
                </app-validation-notification>
              </div>
            </wb-combobox>
          </wb-grid-col>
          <wb-grid-col mq6="3">
            <wb-combobox>
              <wb-combobox-input
                slot="input"
                name="vEcu"
                ngDefaultControl
                [(ngModel)]="releaseNameString"
                #release="ngModel"
                [disabled]="
                  validationService.getFormStatus(
                    formGroup.release,
                    validationStatus.Loading
                  )
                "
                [error]="
                  validationService.isComboBoxInValid(
                    formGroup.release,
                    release,
                    releaseNameString,
                    getPackageReleasesAsStringArray()
                  )
                "
                (wbcomboboxinput)="releaseChange($event)"
              >
                <label slot="label">vECU package release*</label>
                <wb-input-action
                  slot="actions"
                  *ngIf="
                    validationService.getFormStatus(
                      formGroup.release,
                      validationStatus.Loading
                    )
                  "
                >
                  <wb-spinner id="loading-spinner"></wb-spinner>
                </wb-input-action>
              </wb-combobox-input>
              <wb-combobox-list
                no-items-selected-text="No vPackage release selected"
                no-items-found-text="No vPackage release found"
                (wbcomboboxlistselectionchange)="releaseChange($event)"
              >
                <wb-combobox-list-item
                  *ngFor="let release of releases"
                  [value]="release"
                >
                  {{ release.name }}
                </wb-combobox-list-item>
              </wb-combobox-list>
              <div slot="message">
                <app-validation-notification
                  name="vECU Release"
                  [type]="format.Pick"
                  *ngIf="
                    !validationService.getFormStatus(
                      formGroup.release,
                      validationStatus.Loading
                    ) &&
                    validationService.getFormStatus(
                      this.formGroup.release,
                      validationStatus.Invalid
                    ) &&
                    validationService.getFormStatus(
                      this.formGroup.release,
                      validationStatus.Touched
                    )
                  "
                >
                </app-validation-notification>
              </div>
            </wb-combobox>
          </wb-grid-col>
          <wb-grid-col mq6="1" [ngStyle]="{display:'None'}">
            <wb-input
              inputmode="text"
              [(ngModel)]="
                this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[index]
                  .test_orchestration.execution_order
              "
              name="execOrder"
              ngDefaultControl
              #executionOrder="ngModel"
              [error]="
                validationService.isFieldInvalid(
                  formGroup.executionOrder,
                  executionOrder,
                  VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions[
                    index
                  ].test_orchestration.execution_order!.toString(),
                  format.Number
                )
              "
            >
              <span slot="label">Execution Order</span>
              <div slot="message">
                <app-validation-notification
                  name="Execution order"
                  [type]="format.None"
                  *ngIf="
                    validationService.getFormStatus(
                      this.formGroup.executionOrder,
                      validationStatus.Invalid
                    ) &&
                    validationService.getFormStatus(
                      this.formGroup.executionOrder,
                      validationStatus.Touched
                    )
                  "
                >
                </app-validation-notification>
                <app-validation-notification
                  name="Execution order"
                  [type]="format.Number"
                  *ngIf="
                    validationService.getFormStatus(
                      this.formGroup.executionOrder,
                      validationStatus.Regex
                    ) &&
                    validationService.getFormStatus(
                      this.formGroup.executionOrder,
                      validationStatus.Touched
                    )
                  "
                >
                </app-validation-notification>
              </div>
            </wb-input>
          </wb-grid-col>
          <wb-grid-col mq6="1" style="align-self: center">
            <wb-tooltip position="top">
              <wb-round-button size="m" (click)="deleteSelf()">
                <wb-icon name="bds/trash/24" class="icon-color" variant="primary">
                </wb-icon>
                Delete
              </wb-round-button>
              <div slot="content">Delete Test Definition</div>
            </wb-tooltip>
          </wb-grid-col>
        </wb-grid-row>
        <wb-accordion
          *ngIf="this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[this.id]
          .test_environment.test_type"
          class="spacing-top-xs"
          style="background-color: transparent"
        >
          <wb-accordion-item (wbopen)="showInfo()" (wbclose)="hideInfo()">
            <span slot="header"> Additional settings </span>
            <div slot="content">
              <wb-grid-row>
                <wb-grid-col mq6="12">
                  <app-remote-testbench-job-brop
                    *ngIf="isBropMode"
                    [id]="id"
                    [index]="index"
                    [actionTransmitter]="actionTransmitter"
                  />
                  <app-remote-testbench-frop-settings
                    *ngIf="isFropMode"
                    [id]="id"
                    [index]="index"
                    [actionTransmitter]="actionTransmitter"
                  />
                </wb-grid-col>
              </wb-grid-row>
            </div>
          </wb-accordion-item>
        </wb-accordion>
      </wb-grid>
    </wb-grid-col>
  </wb-grid-row>
  
