import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {Injectable} from '@angular/core';
import {forkJoin, map, Subject} from 'rxjs';
import {CreateVTestingJobDto, NewTestDefinitionDto,} from '@vtp/vtpcfg-client-ts';
import {SimulationBundleGet, SimulationBundlePost,} from '@vtp/vtpcap-client-ts';
import {Router} from '@angular/router';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {CreateBaseData} from '../interfaces/Base Data/CreateBaseData ';
import {ConfigurationService} from '../../services/configuration/configuration.service';
import {BreadcrumbsService} from "../../services/breadcrumbs/breadcrumbs.service";

@Injectable({
  providedIn: 'root'
})

export class VirtualRemoteTestBenchVtestingJobService {
  netWorkNames: any[] = [];
  base_data: CreateVTestingJobDto = {
    name: '',
    continuous: false,
    basic_configuration: {model_series: ''},
    admin_configuration: {priority: ''},
    further_notes: '',
    test_definitions: this.getVtestingJobDefinitions(),
  };
  vtestingJob: CreateBaseData = {};
  vtestingJobDefinitions: NewTestDefinitionDto[] = [];
  started_time: any[] = [];
  finished_time: any[] = [];
  jobId: string = '';
  vECUTestJobName: string = '';
  loadingSpinner: boolean = false;
  skeleton: boolean[] = [];

  simulationBundles: { [id: string]: SimulationBundlePost } = {};
  putSimulationBundles: { [id: string]: SimulationBundleGet } = {};
  ToggleStatus: boolean = false;
  vtestingJobId: any;
  EditMode: boolean = false;
  UpdateMode: boolean = false;
  dataLoaded: boolean = false;

  private nextClickSource = new Subject<void>();
  nextClick$ = this.nextClickSource.asObservable();

  constructor(
    private capabilityservice: CapabilitiesService,
    private configurationService: ConfigurationService,
    private router: Router,
    private notificationService: NotificationService,
    private breadCrumbService: BreadcrumbsService
  ) {
  }

  triggerNextClick(): void {
    this.nextClickSource.next();
  }

  clearAll(): void {
    this.loadingSpinner = false;
    this.netWorkNames = [];
    this.base_data = {
      name: '',
      continuous: false,
      basic_configuration: {model_series: ''},
      admin_configuration: {priority: ''},
      further_notes: '',
      test_definitions: this.getVtestingJobDefinitions(),
    };
    this.vtestingJob = {};
    this.vtestingJobDefinitions = [];
    this.started_time = [];
    this.finished_time = [];
    this.jobId = '';

    this.simulationBundles = {};
    this.putSimulationBundles = {};
    this.ToggleStatus = false;
    this.EditMode = false;
    this.UpdateMode = false;
    this.dataLoaded = false;
    this.nextClickSource = new Subject<void>();
    this.nextClick$ = this.nextClickSource.asObservable();
  }

  //*************************************GENERAL DATA *************************************
  getBaseData(): CreateVTestingJobDto {
    return this.base_data;
  }

  postAllSimulationBundles(): void {
    const observables: any = [];
    this.loadingSpinner = true;

    Object.values(this.simulationBundles).forEach((bundle: any, index) => {
      if (bundle.test_environment.instance == 'FROP') {
        if (bundle.test_environment.configuration.function == undefined && bundle.test_environment.test_type != "system") {
          bundle.test_environment.configuration.function = bundle.test_environment.configuration?._function;
        }
        bundle.test_environment.configuration.sub_functions = bundle.test_environment.configuration?.subfunctions;
      }
      bundle.test_environment.configuration?.networks?.[0]?.name === '' && (bundle.test_environment.configuration.networks[0].name = undefined);

      const observable = this.capabilityservice
        .postSimulationBundle(
          this.base_data.basic_configuration.model_series,
          bundle
        )
        .pipe(
          map((response: any) => {
            return {index, simulationBundleId: response.data.id};
          })
        );
      observables.push(observable);
    });

    if (observables.length) {
      forkJoin(observables).subscribe({
        next: (responses: any) => {
          responses.forEach(
            (response: any) =>
              (this.vtestingJobDefinitions[
                response.index
                ].test_object.simulation_bundle_id = response.simulationBundleId)
          );
          this.setVtestingJob();
        },
        error: (error) => this.notificationService.handleError(error),
      });
    } else {
      this.setVtestingJob();
    }
  }

  putAllSimulationBundles(id: string): void {
    const observables: any = [];

    Object.entries(this.putSimulationBundles).forEach(
      (entry: [string, any], index) => {
        if (this.vtestingJobDefinitions[index].test_scope.configuration.instance == "FROP") return;
        entry[1].test_environment!.configuration?.networks?.[0]?.name === '' &&
        (entry[1].test_environment!.configuration.networks[0].name =
          undefined);
        entry[1].test_environment!.configuration!.function =
          entry[1].test_environment!.configuration?._function;
        if (entry[1].test_environment.instance == 'FROP') {
          if (entry[1].test_environment.configuration.function == undefined && entry[1].test_environment.test_type != "system") {
            entry[1].test_environment.configuration.function = entry[1].test_environment.configuration?._function;
          }
          if (entry[1].test_environment.test_type != "Sub_Function") {
            entry[1].test_environment.configuration.subfunctions = [];
          }else if(entry[1].test_environment.test_type == "Sub_Function"){
            entry[1].test_environment.configuration.subfunctions = entry[1].test_environment.configuration?.subfunctions.map((item: any) => {
              return {
                sub_function: item.subfunction
              }
            });
          }
          entry[1].test_environment.configuration.sub_functions = entry[1].test_environment.configuration?.subfunctions;
        }
        if (!(entry[0] in this.simulationBundles)) {
          /*
        This should be the actual approach to delete a simulation bundle.
        But backend throws 415 Method not Supported. Meanwhile, use this approach.
        It does not delete the simulation bundle but remove the relation to the testDefinition
        this.capabilityservice.deleteSimulationBundle(entry[1].id).subscribe({
          next: () => delete this.putSimulationBundles[entry[0]],
          error: (error) => this.notificationService.handleError(error)
        });
         */
          delete this.putSimulationBundles[entry[0]];
        } else {
          Object.assign(
            this.putSimulationBundles[entry[0]],
            this.simulationBundles[entry[0]]
          );
          const observable = this.capabilityservice
            .putSimulationBundle(entry[1].id!, entry[1])
            .pipe(
              map((response) => {
                return {
                  name: response.data.name,
                  simulationBundleId: response.data.id,
                };
              })
            );
          observables.push(observable);
        }
      }
    );
    Object.entries(this.simulationBundles).forEach((entry, index) => {
      if (this.vtestingJobDefinitions[index].test_scope.configuration.instance == "FROP") return;
      if (!(entry[0] in this.putSimulationBundles)) {
        const observable = this.capabilityservice
          .postSimulationBundle(
            this.base_data.basic_configuration.model_series,
            entry[1]
          )
          .pipe(
            map((response) => {
              return {
                name: response.data.name,
                simulationBundleId: response.data.id,
              };
            })
          );
        observables.push(observable);
      }
    });
   
    if (observables.length) {
    forkJoin(observables).subscribe({
      next: (responses: any) => {
        responses.forEach((response: any) => {
          let index = this.vtestingJobDefinitions.findIndex(
            (jobDefinition) => jobDefinition.id == response.name
          );
          this.vtestingJobDefinitions[index].test_object.simulation_bundle_id =
            response.simulationBundleId;
        });
        this.EditVtestingJob(id);
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  } else {
     this.EditVtestingJob(id)
  }
}

  EditVtestingJob(testjobId: string): void {
    this.base_data.test_definitions = this.vtestingJobDefinitions;
    this.base_data.continuous = false;
    const data: Record<string, any> = {...this.base_data};
    const excludedKeys: string[] = [
      'created_at',
      'modified_at',
      'modified_by',
      'created_by',
      'id',
    ];
    const filteredData: Record<string, any> = Object.keys(data)
      .filter((key: string) => !excludedKeys.includes(key))
      .reduce(
        (obj: Record<string, any>, key: string) => ({
          ...obj,
          [key]: data[key],
        }),
        {}
      );

    this.configurationService
      .updateVTestingJob(testjobId, filteredData)
      .subscribe({
        next: (vtestingjob) => {
          this.notificationService.notifySuccess = true;
          this.notificationService.notificationSuccesMessage =
            vtestingjob.message;
          this.vtestingJob = vtestingjob.data;
        },
        complete: () => {
          this.clearAll();
          this.breadCrumbService.removeAboveIndex(0);
          this.router.navigate([
            `remote-testbench/remote-testbench-job/${testjobId}/overview`,
          ]);
        },
        error: (err: any) => this.notificationService.handleError(err),
      });
  }

  setVtestingJob(): void {
    this.vtestingJobDefinitions.forEach(def => {
      if (def.test_scope.configuration?.starc_testset_id == '') {
        delete def.test_scope.configuration.starc_testset_id;
      }
    })
    this.base_data.test_definitions = this.vtestingJobDefinitions;
    this.base_data.continuous = false;
    this.base_data.manual = true;

    this.base_data.test_definitions.forEach((defi: any) => {
      if (defi.test_object.co_simulation_bundle_id) {
        delete defi.test_object.simulation_bundle_id;
      }
    });
    this.configurationService.postVTestingJob(this.base_data).subscribe({
      next: (vtestingjob) => {
        this.notificationService.notifySuccess = true;
        this.notificationService.notificationSuccesMessage =
          vtestingjob.message;
        this.vtestingJob = vtestingjob.data;
      },
      complete: () => {
        let id = this.vtestingJob.id;

        this.clearAll();
        this.breadCrumbService.removeAboveIndex(0);
        this.router.navigate([`remote-testbench/remote-testbench-job/${id}/overview`]);
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  runVTestingJob(id: any): void {
    this.configurationService.runVTestingJob(id).subscribe({
      next: (testJob) => {
        this.notificationService.notifySuccess = true;
        this.notificationService.notificationSuccesMessage = testJob.message;
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  setVtestingJobId(id: any): void {
    this.vtestingJobId = id;
  }

  getVtestingJobId() {
    return this.vtestingJobId;
  }

  setvPackageId(id: any): void {
    this.vtestingJobId = id;
  }

  getvPackageId() {
    return this.vtestingJobId;
  }

  getVtestingJobDefinitions(): NewTestDefinitionDto[] {
    // DEN WERT
    this.vtestingJobDefinitions = [...new Set(this.vtestingJobDefinitions)];
    return this.vtestingJobDefinitions;
  }

  setToggleStatus(ToggleStatus: boolean): void {
    this.ToggleStatus = ToggleStatus;
  }

  getToggleStatus(): boolean {
    return this.ToggleStatus;
  }

  // *************************************DATE DEFINITION FOR AG GRID [ MASTER TABLE ]*************************************
  setStartedTime(started_time: any): void {
    this.started_time = started_time;
  }

  setFinishedTime(finished_time: any): void {
    this.finished_time = finished_time;
  }

  setEditMode(edit: boolean): void {
    this.EditMode = edit;
  }

  getEditMode() {
    return this.EditMode;
  }

  setvECUTestJobName(JobName: string): void {
    this.vECUTestJobName = JobName;
  }

  getvECUTestJobName() {
    return this.vECUTestJobName;
  }
}

