<div *ngIf="spinner">
  <wb-spinner class="centered-element" theme="light" style="width: 128px; height: 128px"></wb-spinner>
</div>

<wb-file-input *ngIf="!spinner" #uploader accept=".zip" name="my-file" class="spacing-s">
  <span slot="title"
    >Drag & Drop your files here or
    <wb-link variant="inline">Browse</wb-link></span
  >
  <span slot="description">Supports .zip files</span>
</wb-file-input>

