import { Component } from '@angular/core';

@Component({
  selector: 'app-brop-compound-settings',
  templateUrl: './brop-compound-settings.component.html',
  styleUrl: './brop-compound-settings.component.css'
})
export class BropCompoundSettingsComponent {

}
