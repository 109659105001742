import { Component, OnInit, ViewChild } from '@angular/core';
import { VpackageService } from 'src/app/package/services/vpackage.service';
import { VecuLevel } from '@vtp/vtpcap-client-ts';
import { CapabilitiesService } from 'src/app/services/capabilities/capabilities.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { NgModel } from '@angular/forms';
import { HeaderTitleService } from 'src/app/services/header/header-title.service';
import {
  Format,
  ValidationService,
  ValidationStatus,
} from 'src/app/services/validation/validation.service';
import { VtestingJobService } from 'src/app/test-job/services/vtesting-job.service';
import { Router } from '@angular/router';
import { PacmanService } from 'src/app/services/pacman/pacman.service';

enum FormGroup {
  vecuApp = 'vecuApp',
  diagnosticName = 'diagnosticName',
  vecuLevel = 'vecuLevel',
  hsm = 'hsm',
  sensor = 'sensor',
  vendor = 'vendor',
}

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css'],
})
export class ApplicationsComponent implements OnInit {
  title = 'Create new vECU package: Applications';
  description =
    'Create a new vECU package. Enter some basic data and add vECU Applications or vECU packages to your new vECU package.';

  constructor(
    public vPackageService: VpackageService,
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService,
    public headerService: HeaderTitleService,
    public validationService: ValidationService,
    public vtestingjobservice: VtestingJobService,
    private router: Router,
    private pacmanService: PacmanService
  ) {}

  // @ViewChild(<string>FormGroup.ncdName) ncdName: NgModel | undefined;
  @ViewChild(<string>FormGroup.diagnosticName) diagnosticName:
    | NgModel
    | undefined;
  @ViewChild(<string>FormGroup.vecuLevel) vecuLevel: NgModel | undefined;
  @ViewChild(<string>FormGroup.vecuApp) vecuApp: NgModel | undefined;
  formGroup = FormGroup;
  format = Format;
  validationStatus = ValidationStatus;

  levelOptions: string[] = ['LEVEL-1', 'LEVEL-2', 'LEVEL-3', 'LEVEL-4'];

  sensoractor: string = '';
  vecuApplication: string = '';

  diagnosticNames: any[] = [];
  ncdNames: { [id: string]: string } = {};

  packageLinkSpinner: boolean = false;

  ngOnInit() {
    if (this.vPackageService.modelSeries[0] == '') {
      return this.router.navigate(['/vpackages']);
    } else {
      if (this.vtestingjobservice.getEditMode()) {
        this.title = 'Edit vECU package';
        this.description =
          'Edit vECU package. Change some basic data and modify vECU Appliactions or vECU packages to your vECU package.';
        this.vPackageService.level = this.evaluateShortLevel(
          this.vPackageService.packageOverview.application_instances
            ?.vecu_level!
        );
      } else if (!this.vtestingjobservice.getEditMode()) {
        this.title = 'Create new vECU package';
        this.description =
          'Create a new vECU package. Enter some basic data and add vECU Applications or vECU packages to your new vECU package.';
      }
      this.headerService.pageDescription = this.description;
      this.headerService.pageTitle = this.title;
      this.headerService.secondaryButtonText = 'Back';

      this.packageLinkSpinner = true;
      this.pacmanService.getHardwarePackages().subscribe({
        next: (response) => {
          response.packages.forEach((element: any) => {
            if (element.releaseInformation.count) {
            }
          });
          this.vPackageService.ecuPackages = response.packages;
          this.vPackageService.filteredArray = this.vPackageService.ecuPackages;
        },
        complete: () => {
          this.vPackageService.filterByParams(
            this.vPackageService.packageDataPost!.model_series,
            this.vPackageService.packageDataPost!.type
          );
          this.packageLinkSpinner = false;
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });

      this.capabilitiesService
        .getDiagnosticNames(this.vPackageService.modelSeries)
        .subscribe({
          next: (response) => {
            this.diagnosticNames = response.data.items;
            response.data.items.forEach((element: any) => {
              this.ncdNames[element.diagnostic_name] = element.ncd_name;
            });
          },
          error: (error) => {
            this.notificationService.handleError(error);
          },
        });

      this.headerService.secondaryDisabled = false;
      this.validationService.initialize(FormGroup);
    }
  }

  onDiagnosticNameInputChange(event: any): void {
    this.vPackageService.packageDataPost!.application_instances!.diagnostic_name =
      event.detail.value;
  }

  onDiagnosticNameSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageService.packageDataPost!.application_instances!.diagnostic_name =
          event.detail.value.value;
        this.vPackageService.diagnosticNames[0] = event.detail.value.value;
        this.vPackageService.packageDataPost!.application_instances!.ncd_name =
          this.ncdNames[
            this.vPackageService.packageDataPost!.application_instances!.diagnostic_name
          ];
        this.packageLinkSpinner = true;
        this.vPackageService.filterByDiagnosticNames();
        this.packageLinkSpinner = false;
      } else {
        this.vPackageService.packageDataPost!.application_instances!.diagnostic_name =
          '';
      }
    }
  }

  onNcdNameInputChange(event: any): void {
    this.vPackageService.packageDataPost!.application_instances!.ncd_name =
      event.detail.value;

    this.validationService.setInputFieldTouched('ncdName', true);
  }

  onNcdNameSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageService.packageDataPost!.application_instances!.ncd_name =
          event.detail.value.value;
      } else {
        this.vPackageService.packageDataPost!.application_instances!.ncd_name =
          '';
      }
    }
  }

  getDiagnosticNameOptions() {
    return this.diagnosticNames.map((name) => name.diagnostic_name);
  }

  getNcdNameOptions() {
    return this.ncdNames;
  }

  onLevelInputChange(event: any): void {
    this.vPackageService.level = event.detail.value;
    if (this.levelOptions.includes(event.detail.value)) {
      this.vPackageService.packageDataPost!.application_instances!.vecu_level =
        this.evaluateLevel(this.vPackageService.level);
    }
  }

  onLevelSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageService.level = event.detail.value.value;
        this.vPackageService.packageDataPost!.application_instances!.vecu_level =
          this.evaluateLevel(this.vPackageService.level);
      } else {
        this.vPackageService.level = '';
      }
    }
  }

  private evaluateLevel(input: string): VecuLevel {
    return input == this.levelOptions[0]
      ? VecuLevel.Lvl1
      : input == this.levelOptions[1]
      ? VecuLevel.Lvl2
      : input == this.levelOptions[2]
      ? VecuLevel.Lvl3
      : VecuLevel.Lvl4;
  }

  private evaluateShortLevel(input: string): string {
    return input == VecuLevel.Lvl1
      ? this.levelOptions[0]
      : input == VecuLevel.Lvl2
      ? this.levelOptions[1]
      : input == VecuLevel.Lvl3
      ? this.levelOptions[2]
      : this.levelOptions[3];
  }

  addSensorActor(sensor: NgModel): void {
    if (this.sensoractor.trim().length < 2) return;
    this.vPackageService.packageDataPost?.application_instances?.sensor_actors!.push(
      { name: this.sensoractor }
    );
    this.sensoractor = '';
    sensor.reset();
  }

  addVecuApplication(vecuApp: NgModel): void {
    if (this.vecuApplication.trim().length < 2) return;
    this.vPackageService.packageDataPost?.application_instances?.vecu_applications!.push(
      { name: this.vecuApplication, simulation_framework: 'CANoe' }
    );
    this.vecuApplication = '';
    vecuApp.reset();
  }

  deleteSensoractor(index: number) {
    this.vPackageService.packageDataPost?.application_instances?.sensor_actors!.splice(
      index,
      1
    );
  }

  deleteVecuApplication(index: number) {
    this.vPackageService.packageDataPost?.application_instances?.vecu_applications!.splice(
      index,
      1
    );
  }

  onHsmChange(event: any): void {
    this.vPackageService.packageDataPost!.application_instances!.hardware_security_module! =
      { name: event.detail.name };
  }

  onEcuLinkInputChange(event: any): void {}

  onEcuLinkSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageService.packageDataPost!.ecu_package_link =
          event.detail.value.value;
      }
    }
  }
}
