import { Injectable } from '@angular/core';
import {
  Configuration as vtpcfgConf,
  RunService,
  VtestingJobService,
} from '@vtp/vtpcfg-client-ts';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  apiUrl: string = '/api/vtpcfg/' + environment.stage;

  constructor(
    private vTJobService: VtestingJobService,
    private configurationRunService: RunService
  ) {
    const cfgConf = new vtpcfgConf();

    cfgConf.basePath = this.apiUrl;
    this.vTJobService.configuration = cfgConf;
    this.configurationRunService.configuration = cfgConf;
  }

  postVTestingJob(job: any): Observable<any> {
    return this.vTJobService.v1VtestingJobsPost(job);
  }

  getVTestingJobs(manual?: boolean): Observable<any> {
    return this.vTJobService.v1VtestingJobsGet(manual);
  }

  getVTestingJob(id: any): Observable<any> {
    return this.vTJobService.v1VtestingJobsVtestingJobIdGet(id);
  }

  deleteVTestingJob(id: any): Observable<any> {
    return this.vTJobService.v1VtestingJobsVtestingJobIdDelete(
      id,
      'application/json'
    );
  }

  getAllJobRuns(id: any): Observable<any> {
    return this.configurationRunService.v1VtestingJobsVtestingJobIdRunsGet(id);
  }

  getJobRunReport(JobId: any, JobRunId: any): Observable<any> {
    return this.configurationRunService.v1VtestingJobsVtestingJobIdRunsVtestingJobRunIdReportGet(
      JobId,
      JobRunId
    );
  }

  getTestDefRunReport(
    JobId: any,
    JobRunId: any,
    TestDefRunId: any
  ): Observable<any> {
    return this.configurationRunService.v1VtestingJobsVtestingJobIdRunsVtestingJobRunIdTestDefinitionRunsTestDefinitionRunIdReportGet(
      JobId,
      JobRunId,
      TestDefRunId
    );
  }

  cancelCurrentRunningVTestingJobRun(
    vTesting_job_id: any,
    vTesting_job_run_id: any
  ): Observable<any> {
    return this.configurationRunService.v1VtestingJobsVtestingJobIdRunsVtestingJobRunIdDelete(
      vTesting_job_id,
      vTesting_job_run_id,
      'application/json'
    );
  }

  updateVTestingJob(VTestingJobId: any, VTestingJob: any): Observable<any> {
    return this.vTJobService.v1VtestingJobsVtestingJobIdPut(
      VTestingJobId,
      VTestingJob
    );
  }

  runVTestingJob(id: any): Observable<any> {
    return this.configurationRunService.v1VtestingJobsVtestingJobIdRunsPost(
      id,
      undefined,
      'application/json'
    );
  }

  terminateTestBench(vtestingJobId: any, vtestingJobRunId: string, instanceId: string): Observable<any>{
    return this.configurationRunService.v1VtestingJobsVtestingJobIdRunsVtestingJobRunIdRemotetestbenchTerminateInstanceIdDelete(
      vtestingJobId,
      vtestingJobRunId,
      instanceId,
      'application/json'
  
    );
  }

  startTestBench(vtestingJobId: any, vtestingJobRunId: string, instanceId: string): Observable<any>{
    return this.configurationRunService.v1VtestingJobsVtestingJobIdRunsVtestingJobRunIdRemotetestbenchStartInstanceIdPost(
      vtestingJobId,
      vtestingJobRunId,
      instanceId
    );
  }

  stopTestBench(vtestingJobId: any, vtestingJobRunId: string, instanceId: string): Observable<any>{
    return this.configurationRunService.v1VtestingJobsVtestingJobIdRunsVtestingJobRunIdRemotetestbenchStopInstanceIdPost(
      vtestingJobId,
      vtestingJobRunId,
      instanceId  
    );
  }
}
