import {ErrorHandler} from '@angular/core';

export class CustomErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (error.code == -100) {
      return;
    }

    throw error;
  }
}
