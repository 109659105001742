import {Component} from '@angular/core';
import {HeaderTitleService} from './services/header/header-title.service';
import {BreadcrumbsService} from './services/breadcrumbs/breadcrumbs.service';
import {NotificationService} from './services/notification/notification.service';
import {ModalService} from './services/modal/modal.service';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  navigationItems = [
    {
      name: 'vTesting jobs',
      route: '/vtesting-jobs',
      active: true,
    },
    {
      name: 'vECU packages',
      route: '/vpackages',
      active: false,
    },
    {
      name: 'Simulation Builder',
      route: '/simulations',
      active: false,
    },
    {
      name: 'Remote Testbench',
      route: '/remote-testbench',
      active: false,
    }
  ];

  constructor(
    public headerService: HeaderTitleService,
    public breadcrumbService: BreadcrumbsService,
    public notificationService: NotificationService,
    public modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  primaryClick(): void {
    this.headerService.triggerPrimaryClick();
  }

  secondaryClick(): void {
    this.headerService.triggerSecondaryClick();
  }

  tertiaryClick(): void {
    this.headerService.triggerTertiaryClick();
  }

  handleTabClick(tab: any): void {
    const queryParams: Params = {tab: tab.id};

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams
    });
    this.headerService.setSelectedTab(tab);
  }
}
