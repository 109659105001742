import { Component, Input } from '@angular/core';
import { Format } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-validation-notification',
  templateUrl: './validation-notification.component.html',
  styleUrl: './validation-notification.component.css'
})
export class ValidationNotificationComponent {
  @Input() name: string = "";
  @Input() type: Format = Format.None;
  format = Format;
}
