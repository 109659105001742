<wb-grid [fullWidth]="true">
  <main>
    <wb-stepper [preventInteraction]="true">
      <wb-step
        #step1
        aria-label="Step 1: Base data"
        [status]="currentStep > 1 ? 'done' : ''"
        >Base data</wb-step
      >
      <wb-step
        #step2
        aria-label="Step 2: Release data"
        [status]="currentStep > 2 ? 'done' : currentStep == 2 ? '' : 'disabled'"
        >Release</wb-step
      >
      <wb-step
        #step3
        aria-label="Step 3: Application data"
        [status]="currentStep > 3 ? 'done' : currentStep == 3 ? '' : 'disabled'"
        *ngIf="vPackageReleaseService.packageType == 'vECUPKG'"
        >Applications</wb-step
      >
    <!-- <div
    *ngIf=""
    ></div> -->
      <wb-step
        #step4
        aria-label="Step 4: Upload"
        [status]="currentStep == 4 ? '' : 'disabled'"
        *ngIf="vPackageReleaseService.packageType == 'vECUPKG' && !this.isInfosim"
        >Upload</wb-step
      >
    </wb-stepper>
    <router-outlet></router-outlet>
  </main>
</wb-grid>
