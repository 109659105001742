import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationNotificationComponent } from "./validation-notification/validation-notification.component";


@NgModule({
  declarations: [ValidationNotificationComponent],
  imports: [CommonModule],
  exports: [ValidationNotificationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValidationModule {}
