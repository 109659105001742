import { Component, OnInit, ViewChild } from '@angular/core';
import { VpackageService } from 'src/app/package/services/vpackage.service';
import { CapabilitiesService } from 'src/app/services/capabilities/capabilities.service';
import { HeaderTitleService } from 'src/app/services/header/header-title.service';
import { ModelSeriesArrayInner, PackageListDto } from '@vtp/vtpcap-client-ts';
import { NgModel } from '@angular/forms';
import { VtestingJobService } from 'src/app/test-job/services/vtesting-job.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import {
  Format,
  ValidationService,
  ValidationStatus,
} from 'src/app/services/validation/validation.service';

enum FormGroup {
  modelSeries = 'modelSeries',
  type = 'type',
  packageName = 'packageName',
  simulationFramework = 'simulationFramework',
}

@Component({
  selector: 'app-packagedata',
  templateUrl: './packagedata.component.html',
  styleUrls: ['./packagedata.component.css'],
})
export class PackagedataComponent implements OnInit {
  title = 'Create new vECU package';
  description =
    'Create a new vECU package. Enter some basic data and add vECU applications or vECU packages to your new vECU package.';

  vpackages?: PackageListDto[];
  // vpackage?: PackageListDto = {id: '', name: ''};
  vPackageId: any;
  editmode: boolean = false;
  typeInput: string = '';
  frameworks: any[] = [];

  @ViewChild(<string>FormGroup.packageName) packageName: NgModel | undefined;
  @ViewChild(<string>FormGroup.modelSeries) modelSeries: NgModel | undefined;
  @ViewChild(<string>FormGroup.type) type: NgModel | undefined;
  formGroup = FormGroup;
  format = Format;
  validationStatus = ValidationStatus;

  constructor(
    public vPackageService: VpackageService,
    public headerService: HeaderTitleService,
    private capabilitiesService: CapabilitiesService,
    private vtestingJobService: VtestingJobService,
    private notificationService: NotificationService,
    public validationService: ValidationService
  ) {}

  ngOnInit() {
    this.editmode = this.vtestingJobService.getEditMode();

    if (this.editmode) {
      this.description =
        'Edit vECU package. Change some basic data and modify vECU applications or vECU packages to your vECU package.';
      this.title = 'Edit vECU package';
    }
    this.capabilitiesService.GetToolList().subscribe({
      next: (response) => {
        this.frameworks = response.data.items;
        this.frameworks = this.frameworks.map((obj) => obj.name);
      },
    });
    this.typeInput =
      this.vPackageService.packageTypeMap[
        this.vPackageService.packageDataPost!.type
      ];
    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;

    this.vPackageService.loadModelSeriesList();

    this.validationService.initialize(FormGroup);

    this.vPackageId = this.vtestingJobService.getvPackageId();

    if (this.editmode && !this.vPackageService.getngOnInitMode()) {
      this.capabilitiesService.getvPackage(this.vPackageId).subscribe({
        next: (element: any) => {
          this.typeInput =
            this.vPackageService.packageTypeMap[element.data.type];
          this.vPackageService.packageDataPost!.name = element.data.name;
          this.vPackageService.packageDataPost!.type = element.data.type;
          if (element.data.application_instances.vecu_applications.length) {
            this.vPackageService.simFramework =
              element.data.application_instances.vecu_applications[0].simulation_framework;
          }
          this.vPackageService.modelSeries = element.data.model_series.map(
            (modelSeries: any) => modelSeries.name
          );
          this.vPackageService.packageDataPost!.model_series =
            element.data.model_series;
          if (this.vPackageService.packageDataPost?.type == 'vECUPKG') {
            this.vPackageService.packageDataPost!.application_instances!.ncd_name =
              element.data.application_instances?.ncd_name;
            this.vPackageService.packageDataPost!.application_instances!.diagnostic_name =
              element.data.application_instances?.diagnostic_name;
            let length =
              element.data.application_instances?.vecu_applications.length;
            for (let i = 0; i < length; i++) {
              this.vPackageService.packageDataPost?.application_instances?.vecu_applications!.push(
                {
                  name: element.data.application_instances.vecu_applications[i]
                    .name,
                  simulation_framework: 'CANoe',
                }
              );
            }

            let length1 =
              element.data.application_instances?.sensor_actors?.length;
            for (let i = 0; i < length1; i++) {
              this.vPackageService.packageDataPost?.application_instances?.sensor_actors?.push(
                {
                  name: element.data.application_instances?.sensor_actors[i]
                    ?.name,
                }
              );
            }
            this.vPackageService.packageDataPost!.application_instances!.vecu_level =
              element.data.application_instances?.vecu_level;
            this.vPackageService.packageDataPost!.application_instances!.hardware_security_module!.name =
              element.data.application_instances?.hardware_security_module.name;
          } else {
            this.vPackageService.packageDataPost!.application_instances =
              undefined;
            this.vPackageService.packageDataPost!.packages =
              element.data.packages;
          }
        },
        error: (err) => {
          this.notificationService.handleError(err);
        },
      });
    }
  }

  isModelSeriesSelected(modelSeriesValue: string): boolean {
    return this.vPackageService.packageDataPost?.model_series.some(
      (modelSeries) => modelSeries.name == modelSeriesValue
    )!;
  }

  onModelSeriesInputChange(event: any): void {
    this.vPackageService.modelSeries = event.detail.value;
    this.vPackageService.packageDataPost!.model_series =
      new Array<ModelSeriesArrayInner>({ name: event.detail.value.value });
  }

  onModelSeriesSelectChange(event: any): void {
    if (event.detail.value) {
      const modelSeriesArray = event.detail.value.map((item: any) => {
        return { name: item.value };
      });
      this.vPackageService.modelSeries = modelSeriesArray.map(
        (obj: any) => obj.name
      );
      this.vPackageService.packageDataPost!.model_series = modelSeriesArray;
    } else {
      this.vPackageService.modelSeries = [];
      this.vPackageService.packageDataPost!.model_series =
        new Array<ModelSeriesArrayInner>({ name: '' });
    }
  }

  getModelSeriesOptions(): string[] | undefined {
    return this.vPackageService.modelSeriesList?.map((model) => model.name);
  }

  getTypeOptions(): string[] | undefined {
    return Object.values(this.vPackageService.packageTypeMap);
  }

  onTypeInputChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.typeInput = event.detail.value;
        if (this.getTypeOptions()?.includes(this.typeInput)) {
          this.vPackageService.packageDataPost!.type = event.detail.value.value;
        }
      }
    }
  }

  onTypeSelectChange(event: any): void {
    if (event) {
      this.typeInput = '';
      if (event.detail.value) {
        this.typeInput = event.detail.value.label.trim();
        this.vPackageService.packageDataPost!.type = event.detail.value.value;
        if (this.typeInput != 'vECUPKG') {
          this.validationService.setInputFieldTouched(
            FormGroup.simulationFramework,
            true
          );
          this.vPackageService.simFramework = '';
        }
      } else {
        this.vPackageService.simFramework = '';
        this.validationService.setInputFieldTouched(
          FormGroup.simulationFramework,
          true
        );
      }
    }
  }

  onInfosimChange(event: any): void {
    if (event.detail.checked) {
      this.vPackageService.packageDataPost!.provider = 'infosim';
      this.vPackageService.simFramework = 'Silver';
    } else {
      this.vPackageService.simFramework = ''; //VTPL-1725 added
      this.vPackageService.packageDataPost!.provider = 'vtp';
    }
  }

  onFrameworkSelectChange(event: any): void {
    if (event) {
      // this.vPackageService.simFramework = '';//VTPL-1725 removed
      if (event.detail.value) {
        this.vPackageService.simFramework = event.detail.value.label.trim();
        this.vPackageService.simFramework = event.detail.value.value;
      }
    }
  }

  onFrameworkInputChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageService.simFramework = event.detail.value;
      }
    }
  }
}
