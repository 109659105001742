// import {Component, Input} from '@angular/core';
// import {ColDef, GetDataPath, GridApi, GridReadyEvent} from "ag-grid-community";
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ColDef, GridApi, GetDataPath, GridReadyEvent} from 'ag-grid-community';
import {getProfile} from '@mbos/portal-service';
import {CustomLoadingOverlayComponent} from "../custom-loading-overlay/custom-loading-overlay.component";

@Component({
  selector: 'app-custom-tree-grid-user',
  templateUrl: './custom-tree-grid-user.component.html',
  styleUrl: './custom-tree-grid-user.component.css'
})
export class CustomTreeGridUserComponent {
  @Input() columnDefs!: ColDef[];
  @Input() defaultColDef!: ColDef;
  @Input() autoGroupColumnDef!: ColDef;
  @Input() rowData?: any[];
  @Input() getDataPath!: GetDataPath;
  @Input() pageSize: { value: number } = {value: 10};
  currentPage: { value: number } = {value: 1};
  
  myData: any[] = [];
  // gridApi?: GridApi;
  gridApi!: GridApi;
  public loadingOverlayComponent: any = CustomLoadingOverlayComponent;

  // onGridReady(params: GridReadyEvent) {
  //   this.gridApi = params.api;
  // }
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    /* this.gridApi.showNoRowsOverlay(); */
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {     
      this.getProfileAndSetRowData();
    }
  }

  getProfileAndSetRowData() {

    getProfile().then((result: any) => {
      this.myData =
        this.rowData?.filter((doc) => doc.created_by === result.sub) || [];
      if (this.gridApi) {
        this.gridApi.setRowData(this.myData);
      }
    });
  }

  

  setGridPage(event: Event): void {
    const customEvent = event as CustomEvent;
    this.gridApi?.paginationGoToPage(customEvent.detail - 1);
    this.currentPage.value = customEvent.detail;
  }

  getTotalPages(): number {
    return Math.ceil((this.rowData?.filter((item) => item.id.length == 1).length || 0) / this.pageSize.value);
  }
}
