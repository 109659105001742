import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {Component} from '@angular/core';
import {VpackagereleaseService} from 'src/app/package/services/vpackagerelease.service';
import {ActivatedRoute, Router} from '@angular/router';

import {HeaderTitleService} from '../../../services/header/header-title.service';
import {forkJoin, Subscription} from 'rxjs';
import {CellClickedEvent, ColDef, GetDataPath, ICellRendererParams} from 'ag-grid-community';
import {NotificationService} from "../../../services/notification/notification.service";
import {BreadcrumbsService} from "../../../services/breadcrumbs/breadcrumbs.service";
import {VpackageService} from "../../../package/services/vpackage.service";

@Component({
  selector: 'app-vpackage-release-overview',
  templateUrl: './vpackage-release-overview.component.html',
  styleUrl: './vpackage-release-overview.component.css',
})
export class VpackageReleaseOverviewComponent {
  showDataTable: boolean = false;

  constructor(
    public vPackageReleaseService: VpackagereleaseService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private headerService: HeaderTitleService,
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService,
    private breadcrumbService: BreadcrumbsService,
    public vPackageService: VpackageService,
  ) {
  }

  selectedTabId!: string;
  subscription!: Subscription;
  modelSeries: string = '';

  ngOnInit() {
    this.headerService.setTabs([{label: 'Information', id: 'info'}]);
    this.subscription = this.headerService.selectedTab$.subscribe(
      (tab) => (this.selectedTabId = tab)
    );

    this.vPackageReleaseService.vPackageReleaseId =
      this.activatedRoute.snapshot.paramMap.get('id')!;
    this.capabilitiesService
      .getSpecificPackageRelease(this.vPackageReleaseService.vPackageReleaseId)
      .subscribe({
        next: (response) => {
          this.vPackageReleaseService.packageReleaseOverview = response.data;
          this.initReleaseSelectionGrid();
        },
        complete: () => {
          this.headerService.pageTitle = this.vPackageReleaseService.packageReleaseOverview.name
          this.initBreadcrumbs()
          this.modelSeries =
            this.vPackageReleaseService.packageReleaseOverview.model_series.map(
              (ms: any) => ms.name
            );
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });
    // this.vPackageReleaseService.loadPackageRelease();
  }

  public applicationColDef: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
    },
    {
      field: 'ywp_version',
      headerName: 'YWP Version',
    },
    {
      field: 'ncd_release',
      headerName: 'NCD Release',
    },
    {field: 'semantic_version', headerName: 'Semantic version'},
    {field: 'sw_part_number', headerName: 'Software part number'},
    {field: 'simulation_framework', headerName: 'Simulation framework'},
    {
      field: 'simulation_framework_version',
      headerName: 'Simulation framework version',
    },
  ];

  public sensorActorColDef: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
    },
    {field: 'version', headerName: 'Version'},
  ];

  public defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
  };

  public columnDefs: ColDef[] = [
    {
      field: 'name',
      flex: 5,
      cellRenderer: (params: ICellRendererParams) => {
        return `<wb-link variant="standalone" (click)="">${params.value}</wb-link>`;
      },
    },
    {field: 'type', flex: 3},
    {field: 'revision', flex: 2},
    {field: 'ncdRelease', flex: 3},
    {field: 'semanticVersion', flex: 3},
    {field: 'applicationName', flex: 3},
    {field: 'applicationSemanticVersion', flex: 3},
    {field: 'simulationFrameworkVersion', flex: 3},
  ];

  public autoGroupColumnDef: ColDef = {
    headerName: ' ',
    field: 'id',
    flex: 4,
    cellRendererParams: {
      suppressCount: true,
    },
    resizable: false,
    sortable: false,
    filter: false,
    suppressMovable: true,
    maxWidth: 40,
    menuTabs: [],
  };

  public rowData: any[] = [];
  public filteredRowData: any[] = [];

  public getDataPath: GetDataPath = (data: any) => {
    return data.id;
  };

  onReleaseClicked(event: CellClickedEvent): void {
    this.vPackageReleaseService.packageReleaseOverview = event.data;
    this.vPackageReleaseService.packageReleaseOverview.model_series =
      this.vPackageReleaseService.packageReleaseOverview.model_series
        .map((obj: any) => obj.name)
        .join(',');
    this.showRelease(event.data);
  }

  showRelease(release: any): void {
    this.router.navigate([`vpackage-release/${release.id}/overview`]);
  }

  getModelSeriesNames(): string {
    if (!this.vPackageReleaseService.packageReleaseOverview?.model_series)
      return "";

    return this.vPackageReleaseService.packageReleaseOverview.model_series.map((modelSeries: {
      name: string
    }) => modelSeries.name).join(", ");
  }

  private initReleaseSelectionGrid() {
    let observables: any[] = [];

    if (this.vPackageReleaseService.packageReleaseOverview.package.type != 'vECUPKG')
      this.vPackageReleaseService.packageReleaseOverview.package_releases.forEach((release: any) =>
        observables.push(this.capabilitiesService.getResolvedPackageRelease(release.id)));

    forkJoin(observables).subscribe({
      next: (responses) => responses.forEach((response) => {
        let release = response.data;

        this.rowData.push({
          id: [release.package.id],
          name: release.package.name,
          type: this.evaluatePackageType(release.package.type),
          revision: release.package.revision
        });

        this.rowData.push({
          id: [release.package.id, release.id],
          name: release.name,
          type: "Release",
          revision: "-",
          ncdRelease: release.package_releases ? release.package_releases[0].application_instances?.ncd_release : "-",
          semanticVersion: release.package_releases ? release.package_releases[0].application_instances?.semantic_version : "-",
          applicationName: release.package_releases ? release.package_releases[0].application_instances?.vecu_applications[0].name : "-",
          applicationSemanticVersion: release.package_releases ? release.package_releases[0].application_instances?.vecu_applications[0].semantic_version : "-",
          simulationFrameworkVersion: release.package_releases ? release.package_releases[0].application_instances?.vecu_applications[0].simulation_framework_version : "-"
        });
      }),
      complete: () => {
        this.showDataTable = true
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  private evaluatePackageType(shortenedPkgType: string) {
    let pkgType;

    switch (shortenedPkgType) {
      case "vGPKG":
        pkgType = "Generic package";
        break;
      case "vDPKG":
        pkgType = "Domain package";
        break;
      case "vECUPKG":
        pkgType = "vECU package";
        break;
      default:
        pkgType = "not implemented"
    }

    return pkgType;
  }

  private initBreadcrumbs() {
    if (this.vPackageService.packageOverview.name) {
      this.breadcrumbService.setBreadcrumbs([
        {
          label: 'vECU packages',
          route: '/vpackages',
        },
        {
          label: this.vPackageService.packageOverview.name!,
          route: '/vpackages/vpackage/' + this.vPackageService.packageOverview.id + '/overview',
        },
        {
          label: this.vPackageReleaseService.packageReleaseOverview.name,
          route: '/vpackage-release/' + this.vPackageReleaseService.packageReleaseOverview.id + '/overview',
        },
        {
          label: "Information",
          route: ''
        }
      ])
    } else {
      this.capabilitiesService.getvPackage(this.vPackageReleaseService.packageReleaseOverview.package.id)
        .subscribe({
          next: (response) => {
            this.vPackageService.packageOverview = response.data;
            this.headerService.pageDescription = this.vPackageService.packageOverview.type +
              ' | ' +
              this.vPackageService.packageOverview.model_series![0].name +
              ' | ' +
              this.vPackageService.packageOverview.revision;
            this.breadcrumbService.setBreadcrumbs([
              {
                label: 'vECU packages',
                route: '/vpackages',
              },
              {
                label: response.data.name!,
                route: '/vpackages/vpackage/' + response.data.id + '/overview',
              },
              {
                label: this.vPackageReleaseService.packageReleaseOverview.name,
                route: '/vpackage-release/' + this.vPackageReleaseService.packageReleaseOverview.id + '/overview',
              },
              {
                label: "Information",
                route: ''
              }
            ]);
          },
          error: (error) => {
            this.notificationService.handleError(error);
          },
        });
    }
  }
}
