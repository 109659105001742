import { Component, Input } from '@angular/core';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  IsRowMaster,
} from 'ag-grid-community';
import { HeaderTitleService } from 'src/app/services/header/header-title.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import { CustomLoadingOverlayComponent } from 'src/app/components/custom-grids/custom-loading-overlay/custom-loading-overlay.component';

@Component({
  selector: 'app-vpackage-release-grid',
  templateUrl: './vpackage-release-grid.component.html',
  styleUrl: './vpackage-release-grid.component.css',
})
export class VpackageReleaseGridComponent {
  @Input() rowData?: any[];
  @Input() masterDetail: boolean = true;
  @Input() columnDefs?: ColDef[];
  @Input() detailCellRendererParams?: any;
  gridApi?: GridApi;
  currentPage: { value: number } = { value: 1 };
  pageSize: { value: number } = { value: 10 };
  public loadingOverlayComponent: any = CustomLoadingOverlayComponent;
  public loadingOverlayComponentParams: any = {
    loadingMessage: 'Loading...',
  };
  constructor(
    public headerService: HeaderTitleService,
    public breadcrumbService: BreadcrumbsService
  ) {}

  public isRowMaster: IsRowMaster = (dataItem: any) => {
    return !!dataItem;
  };
  public defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
  };
// onFirstDataRendered(params: FirstDataRenderedEvent) {
  //   // arbitrarily expand a row for presentational purposes
  //   setTimeout(() => {
  //     params.api.getDisplayedRowAtIndex(1)!.setExpanded(true);
  //   }, 0);
  // }
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
  this.gridApi.showNoRowsOverlay(); 
  }
  public overlayNoRowsTemplate = '<wb-text> Nothing to show</wb-text>';
  setGridPage(event: Event): void {
    const customEvent = event as CustomEvent;
    this.gridApi?.paginationGoToPage(customEvent.detail - 1);
    this.currentPage.value = customEvent.detail;
  }

  getTotalPages(): number {
    return Math.ceil((this.rowData?.length || 0) / this.pageSize.value);
  }
}
