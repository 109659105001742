import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PackageGet, PackageReleasePost, ReleaseType} from '@vtp/vtpcap-client-ts';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {VpackageService} from './vpackage.service';

@Injectable({
  providedIn: 'root',
})
export class VpackagereleaseService {
  constructor(
    private capabilitiesService: CapabilitiesService,
    private router: Router,
    private notificationService: NotificationService,
    private vPackageService: VpackageService
  ) {
  }

  valid: boolean = false;
  packageOverview?: PackageGet;
  presignedData?: any;
  vPackageReleaseId?: string;
  packageReleaseOverview?: any;
  modelseries: any;
  DiagnosticName: any;
  releaseList?: { [id: string]: { id: string } };
  packageType: string = '';

  packageReleasePost: PackageReleasePost = {
    description: '',
    model_series: [],
    name: '',
    package_releases: undefined,
    release_type: ReleaseType.Beta,
    conditions: '',
    package: {id: ''},
    variant_info: undefined,
    application_instances: {
      use_selected_manifest: true,
      ncd_release: '',
      vecu_applications: [],
      sensor_actors: [],
      semantic_version: '',
      hardware_security_module: {},
    },
  };

  prepareApps(): void {
    if (this.packageReleasePost.application_instances) {
      this.packageReleasePost.application_instances!.manifests = [];

      this.packageReleasePost.application_instances!.manifests.push({
        file_name: '',
        file_type: 'ecu-extract',
      });
      this.packageReleasePost.application_instances!.manifests.push({
        file_name: '',
        file_type: 'odx-d',
      });
      this.packageReleasePost.application_instances!.manifests.push({
        file_name: '',
        file_type: 'candela',
      });
    }
  }

  postPackageRelease(): void {
    /*     this.packageReleasePost.variant_info = 'test'; */
    if (this.vPackageService.packageOverview.type === 'vECUPKG') {
      if (!this.packageReleasePost.application_instances?.sensor_actors?.length) {
        delete this.packageReleasePost.application_instances!.sensor_actors;
      }
    }

    if (this.packageReleasePost!.application_instances?.manifests)
      this.packageReleasePost!.application_instances!.manifests! = this.packageReleasePost!.application_instances!.manifests!.filter((manifest) => manifest.file_name);

    this.capabilitiesService
      .postPackageRelease(this.packageReleasePost!)
      .subscribe({
        next: (response) => {
          this.presignedData = response.data.presigned_s3_url;
          this.notificationService.notifySuccess = true;
          this.notificationService.notificationSuccesMessage = response.message;
         if (this.presignedData.url != null && this.packageType == 'vECUPKG') {
          this.router.navigate(['vpackagerelease/upload']);
         }else{
          this.router.navigate(['vpackages']);
         }
        },
        error: (err) => {
          this.notificationService.handleError(err);
        },
      });
  }

  uploadVEcu(file: File): void {
    const formData = new FormData();

    Object.keys(this.presignedData.fields).forEach((key) => {
      formData.append(key, this.presignedData.fields[key]);
    });

    formData.append('file', file);

    this.capabilitiesService
      .uploadFile(formData, this.presignedData.url)
      .subscribe({
        next: (response) => {
          this.notificationService.notifySuccess = true;
          this.notificationService.notificationSuccesMessage = response.message;
        },
        complete: () => {
          this.clearAll();
          this.router.navigate(['vpackages']);
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });
  }

  setModelSeries(modelseries: any): void {
    this.modelseries = modelseries;
  }

  getModelSeries() {
    return this.modelseries;
  }

  setDiagnosticName(diagnosticName: any): void {
    this.DiagnosticName = diagnosticName;
  }

  getDiagnosticName() {
    return this.DiagnosticName;
  }

  clearAll(): void {
    this.valid = false;

    this.packageOverview = undefined;
    this.presignedData = undefined;
    this.packageReleasePost = {
      description: '',
      model_series: [],
      name: '',
      package_releases: null,
      release_type: ReleaseType.Beta,
      conditions: '',
      package: {id: ''},
      // variant_info: '',
      application_instances: {
        ncd_release: '',
        vecu_applications: [],
        sensor_actors: [],
        semantic_version: '',
        hardware_security_module: {},
      },
    };
  }
}
