import {Component, OnInit, ViewChild} from '@angular/core';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {ModelSeriesListGet, ReleaseType} from '@vtp/vtpcap-client-ts';
import {Format, ValidationService, ValidationStatus,} from '../../../../services/validation/validation.service';
import {VtestingJobService} from '../../../services/vtesting-job.service';
import {ConfigurationService} from "../../../../services/configuration/configuration.service";
import {NgModel} from "@angular/forms";
import {Operator} from "@vtp/vtpcfg-client-ts";

enum FormGroup {
  Name = 'Name',
  ModelSeries = 'ModelSeries',
  ReleaseCondition = 'ReleaseCondition',
  ReleaseOperator = 'ReleaseOperator',
}

@Component({
  selector: 'app-test-job-flow-base-data',
  templateUrl: './test-job-flow-base-data.component.html',
  styleUrls: ['./test-job-flow-base-data.component.css'],
})
export class TestJobFlowBaseDataComponent implements OnInit {
  vehicleLines?: ModelSeriesListGet;
  controlHint: string = 'Each new package release will be automatically tested';
  showToggleStatus: boolean = false;
  editMode: boolean = false;

  releaseCondition: string = '';
  releaseConditionOperator: string = '';
  releaseConditions: string[] = ['beta', 'release', 'release-candidate'];
  releaseConditionOperators: string[] = ['equal', 'greater equal'];
  @ViewChild(<string>FormGroup.ReleaseCondition) releaseConditionModel: NgModel | undefined;
  @ViewChild(<string>FormGroup.ReleaseOperator) releaseOperatorModel: NgModel | undefined;

  protected readonly Format = Format;
  protected readonly FormGroup = FormGroup;
  protected readonly ValidationStatus = ValidationStatus;

  constructor(
    private capabilitiesService: CapabilitiesService,
    private configurationService: ConfigurationService,
    private notificationService: NotificationService,
    public vTestingJobService: VtestingJobService,
    public validationService: ValidationService
  ) {
  }

  ngOnInit() {
    this.validationService.initialize(FormGroup);
    this.editMode = this.vTestingJobService.getEditMode();
    if (this.editMode) {
      if (this.vTestingJobService.currentJobOverview != null || this.vTestingJobService.currentJobOverview != undefined) {
        this.vTestingJobService.setToggleStatus(this.vTestingJobService.currentJobOverview.continuous);
      }
      this.loadEditData();
    }
    this.validationService.setInputFieldTouched(this.FormGroup.ReleaseOperator, !this.vTestingJobService.getToggleStatus());
    this.validationService.setInputFieldTouched(this.FormGroup.ReleaseCondition, !this.vTestingJobService.getToggleStatus());
    this.capabilitiesService.getModelSeries().subscribe({
      next: (response) => (this.vehicleLines = response.data),
      error: (error) => this.notificationService.handleError(error),
    });
  }

  loadEditData(): void {
    this.configurationService
      .getVTestingJob(this.vTestingJobService.getVtestingJobId())
      .subscribe({
        next: (element) => {
          this.vTestingJobService.base_data = element.data;
          if (!this.vTestingJobService.base_data.basic_configuration.trigger!) return
          this.releaseCondition = this.vTestingJobService.base_data.basic_configuration.trigger!.release_type!.replace("_", "-");
          this.releaseConditionOperator = this.vTestingJobService.base_data.basic_configuration.trigger!.operator!.toLowerCase().replace("_", " ");
        },
        error: (error) => this.notificationService.handleError(error),
      });
  }

  getModelSeriesOptions(): string[] {
    return this.vehicleLines?.items?.map((model) => model.name!) ?? [];
  }

  modelSeriesChange(event: any): void {
    this.vTestingJobService.base_data.basic_configuration.model_series = event
      .detail.value
      ? event.target.value
      : '';
  }

  releaseConditionChange(event: any): void {
    this.releaseCondition = event.detail.value ? event.target.value : '';

    if (this.releaseConditions.includes(this.releaseCondition)) {
      const trigger = this.vTestingJobService.base_data.basic_configuration.trigger || <any>{};

      trigger.type = "TRIGGER_BY_RELEASE_TYPE";
      trigger.release_type = <ReleaseType>this.releaseCondition.replace("-", "_");
      this.vTestingJobService.base_data.basic_configuration.trigger = trigger;
    }
  }

  releaseOperatorChange(event: any): void {
    this.releaseConditionOperator = event.detail.value ? event.target.value : '';

    if (this.releaseConditionOperators.includes(this.releaseConditionOperator)) {
      const trigger = this.vTestingJobService.base_data.basic_configuration.trigger || <any>{};

      trigger.type = "TRIGGER_BY_RELEASE_TYPE";
      trigger.operator = <Operator>this.releaseConditionOperator.toUpperCase().replace(" ", "_");
      this.vTestingJobService.base_data.basic_configuration.trigger = trigger;
    }
  }

  showToolTip(e: any): void {
    this.showToggleStatus = e.target.checked;
    this.controlHint = 'Continuous testing ' + (this.showToggleStatus ? 'activated' : 'deactivated');
    this.vTestingJobService.setToggleStatus(this.showToggleStatus);

    this.releaseConditionModel?.reset();
    this.releaseCondition = '';
    this.releaseOperatorModel?.reset();
    this.releaseConditionOperator = '';

    if (!this.showToggleStatus) {
      this.vTestingJobService.base_data.basic_configuration.trigger = undefined
    }

    [this.FormGroup.ReleaseOperator, this.FormGroup.ReleaseCondition].forEach((item) => {
      this.validationService.resetInputField(item);
      this.validationService.setInputFieldTouched(item, !this.showToggleStatus);
    });
  }
}
