import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TestJobOverview } from './components/test-job-overview/test-job-overview.component';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { ValidationModule } from "../components/validation/validation.module";
import { CustomGridsModule } from "../components/custom-grids/custom-grids.module";
import {TestJobFlowComponent} from "./components/test-job-flow/test-job-flow.component";
import {
  TestJobFlowBaseDataComponent
} from "./components/test-job-flow/test-job-flow-base-data/test-job-flow-base-data.component";
import {TestJobFlowTestComponent} from "./components/test-job-flow/test-job-flow-test/test-job-flow-test.component";
import {
  TestJobFlowTestDefinitionComponent
} from "./components/test-job-flow/test-job-flow-test-definition/test-job-flow-test-definition.component";
import {
  TestJobFlowSummaryComponent
} from "./components/test-job-flow/test-job-flow-summary/test-job-flow-summary.component";
import { BropComponentSettingsComponent } from './components/test-job-flow/test-job-flow-test-definition/brop-component-settings/brop-component-settings.component';
import { BropCompoundSettingsComponent } from './components/test-job-flow/test-job-flow-test-definition/brop-compound-settings/brop-compound-settings.component';
import { FropSettingsComponent } from './components/test-job-flow/test-job-flow-test-definition/frop-settings/frop-settings.component';

@NgModule({
  declarations: [
    TestJobOverview,
    TestJobFlowComponent,
    TestJobFlowBaseDataComponent,
    TestJobFlowTestComponent,
    TestJobFlowTestDefinitionComponent,
    TestJobFlowSummaryComponent,
    BropComponentSettingsComponent,
    BropCompoundSettingsComponent,
    FropSettingsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    AgGridModule,
    ValidationModule,
    CustomGridsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TestJobModule {}
