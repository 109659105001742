import {HttpClientModule} from '@angular/common/http';
import {AgGridModule} from 'ag-grid-angular';
import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppOverviewComponent} from './components/app-overview/app-overview.component';
import {TestJobModule} from './test-job/test-job.module';
import {TestJobsOverviewComponent} from './test-job/components/test-jobs-overview/test-jobs-overview.component';
import {FormsModule} from '@angular/forms';
import {PackageModule} from './package/package.module';
import {AppUploadOverviewComponent} from './components/app-upload-overview/app-upload-overview.component';
import {CallbackComponent} from './components/auth/callback/callback.component';
import {OAuthModule} from 'angular-oauth2-oidc';
import {ApiModule as Capabilities} from '@vtp/vtpcap-client-ts';
import {PackageReleaseModule} from './package-release/package-release.module';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {ButtonCellRendererComponent} from './components/custom-cell-renderers/button-cell-renderer/button-cell-renderer.component';
import {
  ButtonDetailCellRendererComponent
} from './components/custom-cell-renderers/button-detail-cell-renderer/button-detail-cell-renderer.component';

import {ErrorComponent} from './components/error/error.component';
import {APP_BASE_HREF} from '@angular/common';
import {CustomGridsModule} from './components/custom-grids/custom-grids.module';
import {MyInfoIconComponent} from './components/my-info-icon/my-info-icon.component';
import {CustomCellRendererComponent} from './components/row-actions/row-actions/row-actions.component';
import {ValidationModule} from './components/validation/validation.module';
import {
  VpackageReleaseOverviewComponent
} from './package-release/components/vpackage-release-overview/vpackage-release-overview.component';
import {ModalComponent} from "./components/modal/modal.component";
import {CustomErrorHandler} from "./services/error-handler/error-handler";
import {SimulationModule} from "./simulation-builder/simulation.module";
import { SimulationTypeRendererComponent } from './components/custom-cell-renderers/simulation-type-renderer/simulation-type-renderer/simulation-type-renderer.component';
import { VirtualRemoteTestBenchJobsOverviewComponent } from './remote-testbench/components/remote-testbench-jobs-overview/remote-testbench-jobs-overview.component';
import { VirtualRemoteTestBenchModule } from './remote-testbench/remote-testbench.module';



@NgModule({
  declarations: [
    AppComponent,
    AppOverviewComponent,
    TestJobsOverviewComponent,
    VirtualRemoteTestBenchJobsOverviewComponent,
    AppUploadOverviewComponent,
    CallbackComponent,
    CustomCellRendererComponent,
    ErrorComponent,
    ButtonCellRendererComponent,
    ButtonDetailCellRendererComponent,
    VpackageReleaseOverviewComponent,
    MyInfoIconComponent,
    ModalComponent,
    SimulationTypeRendererComponent
  ],
  imports: [
    AgGridModule,
    BrowserModule,
    AppRoutingModule,
    TestJobModule,
    VirtualRemoteTestBenchModule,
    FormsModule,
    PackageModule,
    PackageReleaseModule,
    OAuthModule.forRoot(),
    HttpClientModule,
    Capabilities,
    ClipboardModule,
    CustomGridsModule,
    ValidationModule,
    SimulationModule
  ],
  providers: [{provide: APP_BASE_HREF, useValue: '/virtual-testing'}, {
    provide: ErrorHandler,
    useClass: CustomErrorHandler
  }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
