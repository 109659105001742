<wb-grid [fullWidth]="true">
  <wb-grid-row>
    <wb-grid-col mq6="5">
      <wb-heading size="xs" tag="div" class="spacing-xs spacing-top-xs">
        Base data
      </wb-heading>
      <wb-grid-row>
        <wb-grid-col mq6="5">
          <wb-text class="spacing-bot-16" size="m" tag="div">
            Simulation name:
          </wb-text>
          <wb-text class="spacing-bot-16" size="m" tag="div"
          >Model series:
          </wb-text
          >
          <wb-text class="spacing-xs" size="m" tag="div"
          >Further notes:
          </wb-text
          >
        </wb-grid-col>
        <wb-grid-col mq6="7">
          <wb-grid-col mq6="2">
            <wb-text class="spacing-bot-16" size="m" tag="div">
              {{ simulationBuilderService.baseData.name }}
            </wb-text>
            <wb-text class="spacing-bot-16" size="m" tag="div">
              {{ simulationBuilderService.baseData.model_series }}
            </wb-text>
            <wb-text class="spacing-xs" size="m" tag="div">
              {{ simulationBuilderService.baseData.description }}
            </wb-text>
          </wb-grid-col>
        </wb-grid-col>
      </wb-grid-row>
      <wb-heading size="xs" tag="div" class="spacing-xs">
        Package info
      </wb-heading>
      <wb-grid-row>
        <wb-grid-col mq6="5">
          <wb-text class="spacing-bot-16" size="m" tag="div">
            Package name:
          </wb-text>
          <wb-text class="spacing-bot-16" size="m" tag="div">
            Package id:
          </wb-text>
          <wb-text class="spacing-bot-16" size="m" tag="div">
            Included ECUs:
          </wb-text>
        </wb-grid-col>
        <wb-grid-col mq6="7">
          <wb-text class="spacing-bot-16" size="m" tag="div">
            {{ simulationBuilderService.selectedPackage.name }}
          </wb-text>
          <wb-text class="spacing-bot-16" size="m" tag="div">
            {{ simulationBuilderService.baseData.package.id }}
          </wb-text>
          <wb-text class="spacing-bot-16" size="m" tag="div">
            {{ simulationBuilderService.selectedPackage.includedEcus }}
          </wb-text>
        </wb-grid-col>
      </wb-grid-row>
    </wb-grid-col>
    <wb-grid-col mq6="1"></wb-grid-col>
    <wb-grid-col mq6="6">
      <wb-heading size="xs" tag="div" class="spacing-xs spacing-top-xs">
        Simulation info
      </wb-heading>
      <wb-text class="spacing-bot-16"> Simulated ECUs:</wb-text>
      <app-custom-grid
        [columnDefs]="simulatedEcuColumnDefs"
        [rowData]="simulatedEcuRowData"
        [pageSize]="{ value: 5 }"
      ></app-custom-grid>
      <wb-text class="spacing-top-xs spacing-bot-16"> Plant models (simulation dependencies):</wb-text>
      <app-custom-grid
        class="spacing-xs"
        [columnDefs]="genericPhysicColumnDefs"
        [rowData]="simulationBuilderService.plantModels"
      ></app-custom-grid>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
