<wb-tab-content
  name="my-jobs"
  slot="content"
  [selected]="selectedTabId == 'my-jobs'"
  style="padding: 0"
>
  <app-grid-by-user
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
  >
  </app-grid-by-user>
</wb-tab-content>
<wb-tab-content name="all-jobs" slot="content" [selected]="selectedTabId == 'all-jobs'" style="padding: 0">
  <app-custom-grid
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
  >
  </app-custom-grid>
</wb-tab-content>
<wb-modal
  (wbmodalclose)="closeModal()"
  *ngIf="this.buttonStatus"
  class="wb-modal"
  variant="edit"
  size="m"
  active-level="1"
  close-on-backdrop-click="false"
  close-on-escape-key="false"
>
  <wb-modal-level class="wb-modal-level" level="1" variant="edit"
  >
    <wb-text size="l" strong="" tag="div">
      <strong>Delete vTesting job "{{ vECUTestJobName }}"?</strong></wb-text
    >
    <wb-text class="spacing-top-xs">
      You are about to permanently delete a resource. This action is
      irreversible, meaning the resource will be completely removed from our
      system and cannot be recovered. Please confirm that you understand and
      wish to proceed with the deletion.
    </wb-text
    >
    <wb-checkbox
      class="spacing-s checkbox hydrated"
      modelvalue="false"
      data-testid="delete-checkbox"
      style="max-width: 600px"
      theme="light"
      (click)="checkBoxArea($event)"
      type="checkbox"
      [checked]="this.checkedStatus"
    >
      Yes, I want to permanently delete the vTesting job<strong>
      "{{ vECUTestJobName }}"</strong
    >
      and understand that it cannot be recovered.
      <input
        aria-hidden="true"
        type="checkbox"
        style="display: none"
        name="deleteCheckboxPipeline"
        value="on"
      /></wb-checkbox>

    <wb-button
      class="wb-button hydrated"
      slot="footer"
      variant="secondary"
      size="l"
      theme="light"
      wbmodalclose="true"
      (click)="cancelVEcuTestJob()"
    >Cancel
    </wb-button
    >

    <wb-button
      variant="destructive"
      size="l"
      slot="footer"
      data-testid="delete-btn"
      [disabled]="buttonDeleteStatus"
      [aria-checked]="isAreaChecked"
      (click)="deleteVEcuTestJob(this.data)"
    ><!-- <wb-icon flip-rtl slot="icon" name="bds/trash/24"></wb-icon
        > -->
      Delete vTesting job
    </wb-button
    >

    <!--    <wb-button
      class="wb-button hydrated"
      data-testid="delete-btn"
      slot="footer"
      aria-disabled="true"
      variant="primary"
      size="l"
      theme="light"
      [disabled]="buttonDeleteStatus"
      [aria-checked]="isAreaChecked"
      (click)="deleteVEcuTestJob(this.data)"
    >
      Delete vTesting job
    </wb-button> --></wb-modal-level
  >
</wb-modal
>
