<wb-grid [fullWidth]="true">
  <wb-grid-row>
    <wb-grid-col mq6="12" mq1="12">
      <wb-heading size="xs" tag="div" class="spacing-xs spacing-top-xs">
        Base data
      </wb-heading>
    </wb-grid-col>
  </wb-grid-row>

  <wb-grid-row>
    <wb-grid-col mq6="2">
      <wb-text class="spacing-top-0 spacing-xxs" size="m" tag="div"
      >vTesting job name:
      </wb-text>
      <wb-text class="spacing-xxs" size="m" tag="div">Model series:</wb-text>
      <wb-text class="spacing-xxs" size="m" tag="div">Further notes:</wb-text>
    </wb-grid-col>
    <wb-grid-col mq6="2">
      <wb-text class="spacing-xxs" size="m" tag="div">
        {{ this.vTestingJobService.base_data.name }}
      </wb-text>
      <wb-text class="spacing-xxs" size="m" tag="div">
        {{ this.vTestingJobService.base_data.basic_configuration.model_series }}
      </wb-text>
      <wb-text class="spacing-xxs" size="m" tag="div">
        {{ this.vTestingJobService.base_data.further_notes }}
      </wb-text>
    </wb-grid-col>
  </wb-grid-row>

  <wb-grid-row>
    <wb-grid-col mq6="12">
      <wb-heading class="spacing-xs spacing-top-xs" size="xs" tag="div">
        Test definitions
      </wb-heading>
      <app-custom-grid
        *ngIf="dataLoaded"
        [rowData]="testDefinitions"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
      >
      </app-custom-grid>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
