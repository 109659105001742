import {Component, Input, OnInit} from '@angular/core';
import {PackageListDto} from '@vtp/vtpcap-client-ts';
import {Format, ValidationService, ValidationStatus,} from '../../../../services/validation/validation.service';
import {NotificationService} from '../../../../services/notification/notification.service';
import {CapabilitiesService} from '../../../../services/capabilities/capabilities.service';
import {VtestingJobService} from 'src/app/test-job/services/vtesting-job.service';
import {SimulationBuilderService} from 'src/app/simulation-builder/services/simulation-builder.service';
import {ColDef, GetDataPath} from 'ag-grid-community';
import {catchError, combineLatest, of} from 'rxjs';
import {HelperService} from '../../../services/helper.service';
import {Router} from "@angular/router";

enum FormGroup {
  package = 'package',
}

@Component({
  selector: 'app-simulation-builder-flow-scope',
  templateUrl: './simulation-builder-flow-scope.component.html',
  styleUrl: './simulation-builder-flow-scope.component.css',
})
export class SimulationBuilderFlowScopeComponent implements OnInit {
  @Input() vEcus!: PackageListDto[];

  validationStatus = ValidationStatus;
  formGroup = FormGroup;
  format = Format;

  vEcuNameString = '';

  debounceTimer: any;

  vEcuResponse: PackageListDto[] = [];
  
  constructor(
    private notificationService: NotificationService,
    private capabilitiesService: CapabilitiesService,
    public vTestingJobService: VtestingJobService,
    public validationService: ValidationService,
    public simulationBuilderService: SimulationBuilderService,
    private helperService: HelperService,
    private router: Router
  ) {
  }

  ngOnInit() {
    if (!this.simulationBuilderService.baseData.model_series?.length) {
      return this.router.navigate(['/simulations'])
    } else {
      this.initValidation();
      if (this.simulationBuilderService.selectedPackage.name) {
        this.vEcuNameString = this.simulationBuilderService.selectedPackage.name;
      }
      if (this.simulationBuilderService.simulationMode == 'event') {
        this.initEventChainBasedMode();
      } else if (this.simulationBuilderService.simulationMode == 'package') {
        this.initPackageBasedMode();
      }
    }
  }

  public columnDefs: ColDef[] = [
    {field: 'type', flex: 1},
    {field: 'fropId', flex: 1},
  ];

  public defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
    flex: 2,
  };

  public autoGroupColumnDef: ColDef = {
    headerName: 'Name',
    cellRendererParams: {
      suppressCount: true,
      checkbox: true,
    },
  };

  public rowData: any[] = [];
  public filteredRowData: any[] = [];

  public getDataPath: GetDataPath = (data: any) => {
    return data.name;
  };

  initEventChainBasedMode() {
    this.capabilitiesService
      .getEventChainListOfModelSeries(
        this.simulationBuilderService.baseData.model_series!,
        undefined,
        undefined
      )
      .subscribe({
        next: (eventChain) => {
          this.rowData = [];
          eventChain.data?.items?.forEach((item: any) => {
            let rowDataItem: { name: string[]; type: string; fropId: string } =
              {
                name: [],
                type: '',
                fropId: item.frop_id,
              };

            rowDataItem.name = [
              item.system,
              item.function ? item.function : undefined,
              item.sub_function ? item.sub_function : undefined,
            ].filter((item) => item) as string[];
            rowDataItem.type = item.sub_function
              ? 'sub function'
              : item.function
                ? 'function'
                : 'system';
            this.rowData?.push(rowDataItem);
          });
        },
        complete: () => (this.filteredRowData = this.rowData),
        error: (error) => this.notificationService.handleError(error),
      });
  }

  rowSelectedChange(selectedRows: []) {
    if (selectedRows.length > 0) {
    let observablesPackages: any[] = [];
    let observablesFrop: any[] = [];

    this.simulationBuilderService.unprepared = true;
    if (this.simulationBuilderService.simulationMode == 'event') {
      this.validationService.setInputFieldLoading(this.formGroup.package, true);
    }
    this.simulationBuilderService.selectedRows = selectedRows;
    selectedRows.forEach((rowData: any) => {
      if (!rowData) return;

      observablesFrop.push(
        this.capabilitiesService
          .getFropById(
            this.simulationBuilderService.baseData.model_series!,
            rowData.fropId
          )
          .pipe(
            catchError((error) => {
              this.notificationService.handleError(error);
              return of(null);
            })
          )
      );
      if (this.simulationBuilderService.simulationMode == 'event') {
        observablesPackages.push(
          this.capabilitiesService
            .getMatchingPackagesOfEventChainList(rowData.fropId, [
              this.simulationBuilderService.baseData.model_series!,
            ])
            .pipe(
              catchError((error) => {
                this.notificationService.handleError(error);
                return of(null);
              })
            )
        );
      }   
    });

   if (observablesPackages.length > 0) {
    combineLatest(observablesPackages).subscribe({
      next: (packages) =>
        (this.vEcus = packages
          .map((item) => item.data.items)
          .reduce((acc, array) =>
            acc.filter((obj: any) =>
              array.some((item: any) => item.id === obj.id)
            )
          )),
      complete: () =>
        this.validationService.setInputFieldLoading(
          this.formGroup.package,
          false
        ),
      error: (error) => this.notificationService.handleError(error),
    });
   }

    combineLatest(observablesFrop).subscribe({
      next: (responses) => {
        responses.forEach((response) => {
          if (
            this.simulationBuilderService.baseData.test_environment
              .configuration.system == ''
          ) {
            this.simulationBuilderService.baseData.test_environment.configuration.system =
              response.data.system;
          }

          if (
            this.simulationBuilderService.baseData.test_environment
              .configuration.function == ''
          ) {
            this.simulationBuilderService.baseData.test_environment.configuration.function =
              response.data.function;
          }

          if (
            !this.simulationBuilderService.baseData.test_environment
              .configuration.sub_functions?.length
          ) {
            this.simulationBuilderService.baseData.test_environment.configuration.sub_functions =
              [];
          }
          this.simulationBuilderService.baseData.test_environment.configuration.sub_functions.push(
            {sub_function: response.data.subfunction}
          );

          this.simulationBuilderService.baseData.test_environment.configuration
            .system;
          this.simulationBuilderService.plantModels = this.mergeUniqueObjects(
            this.simulationBuilderService.plantModels,
            response.data.event_chain.plant_models
          );
          this.simulationBuilderService.eventChainEcus = [
            ...this.simulationBuilderService.eventChainEcus,
            ...response.data.event_chain.ecus,
          ];
        });
      },
      complete: () => {
        this.simulationBuilderService.eventChainEcus =
          this.simulationBuilderService.eventChainEcus.filter(
            (v: any, i: any, a: any) =>
              a.findIndex(
                (t: any) =>
                  t.diagnostic_name === v.diagnostic_name &&
                  t.qualifier === v.qualifier
              ) === i
          );

        this.simulationBuilderService.simulatedEcus =
          this.simulationBuilderService.eventChainEcus
            .filter((ecu: any) => ecu.qualifier !== 'm')
            .map((ecu: any) => ({
              diagnostic_name: ecu.diagnostic_name,
              type: 'restbus',
            }));
      },
      error: (err) => this.notificationService.handleError(err),
    });
    }else{
      if(this.simulationBuilderService.simulationMode == 'event'){
        this.vEcuNameString = '';
        this.vEcus = [];
        this.simulationBuilderService.selectedRows = [];
      }
    }
  }

  initPackageBasedMode() {
    if (this.vEcuNameString != null && this.vEcuNameString !='') {
      this.fetchGridData();
    }
    this.capabilitiesService
      .getEcus([this.simulationBuilderService.baseData.model_series!])
      .subscribe({
        next: (response) => (this.vEcus = response.data.items),
        error: (error) => this.notificationService.handleError(error),
      });
  }

  initValidation(): void {
    this.validationService.initialize(FormGroup);
  }

  fetchGridData() {
    this.capabilitiesService
      .getEventChainListOfPackage(
        this.simulationBuilderService.baseData.package.id
      )
      .subscribe({
        next: (eventChain) => {
          this.rowData = [];
          eventChain.data?.items?.forEach((item: any) => {
            let rowDataItem: { name: string[]; type: string; fropId: string } =
              {
                name: [],
                type: '',
                fropId: item.frop_id,
              };

            rowDataItem.name = [
              item.system,
              item.function ? item.function : undefined,
              item.sub_function ? item.sub_function : undefined,
            ].filter((item) => item) as string[];
            rowDataItem.type = item.sub_function
              ? 'sub function'
              : item.function
                ? 'function'
                : 'system';
            this.rowData?.push(rowDataItem);
          });
        },
        complete: () => (this.filteredRowData = this.rowData),
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });
  }

  getVEcusAsStringArray(): (string | undefined)[] {
    return this.vEcus ? this.vEcus.map((vEcu) => vEcu.name) : [];
  }

  vECUPackageChange(event: any): void {
    let selectedVEcu:any = null;
    let diagnosticNames: string[] = [];
    
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      const vEcu =
        typeof event.detail.value === 'string'
          ? event.detail.value
          : typeof event.detail.value.name === 'string'
            ? event.detail.value
            : event.detail.value.value;

      this.vEcuNameString = (
        typeof vEcu === 'string' ? vEcu : vEcu.name
      ).trim();
      if (this.getVEcusAsStringArray().includes(this.vEcuNameString)) {
        selectedVEcu = this.vEcus.find(
          (item) => item.name == this.vEcuNameString
        )!;
      }
      if (selectedVEcu != null ) {
        this.capabilitiesService.getVPackageResolved(selectedVEcu.id).subscribe({
        next: (response) => {
          this.helperService.package1 = response.data!.packages![0];
          this.helperService.package2 = response.data!.packages![1];
          resolveDiagnosticNames(response.data?.packages!);
          this.simulationBuilderService.selectedPackage.includedEcus =
            diagnosticNames;
        },
        complete: () => {
          if (this.simulationBuilderService.simulationMode == 'event') {
            this.validationService.setInputFieldLoading(
              this.formGroup.package,
              false
            );
          }          
        },
        error: (error) => this.notificationService.handleError(error),
      });
    } else {
      this.simulationBuilderService.baseData.package.id = '';
    }
        this.simulationBuilderService.baseData.package.id = selectedVEcu.id;
        this.simulationBuilderService.selectedPackage.name = selectedVEcu.name;
        this.simulationBuilderService.simulationMode == 'package' &&
          this.fetchGridData();
      },300);
      function resolveDiagnosticNames(packages: any[]) {
        packages.map((vPackage: any) =>
          vPackage.packages
            ? resolveDiagnosticNames(vPackage.packages)
            : diagnosticNames.push(vPackage.application_instances.diagnostic_name)
        );
      }
      if (this.simulationBuilderService.simulationMode == 'event') {
        this.validationService.setInputFieldLoading(this.formGroup.package, true);
      }
  }

  filterInputChange(event: any) {
    function ignoreWhiteSpacesAndCasing(str: string) {
      return str.replace(/\s/g, '').toLowerCase();
    }

    this.filteredRowData = this.rowData.filter((node) =>
      node.name.some((item: string) =>
        ignoreWhiteSpacesAndCasing(item).includes(
          ignoreWhiteSpacesAndCasing(event.detail.value)
        )
      )
    );
  }

  checkMandatoryEcus(): boolean {
    let valid = false;

    let eventChainEcus: string[] = [];
    eventChainEcus = this.simulationBuilderService.eventChainEcus
      .filter((obj: any) => obj.qualifier === 'm')
      .map((obj: any) => obj.diagnostic_name);

    valid = eventChainEcus.every((val) =>
      this.simulationBuilderService.selectedPackage.includedEcus.includes(val)
    );
    return valid;
  }

  mergeUniqueObjects(array1: any[], array2: any[]): any[] {
    const newArray = array2.filter(
      (o1) =>
        !array1.some(
          (o2) =>
            o1.name === o2.name &&
            o1.type === o2.type &&
            o1.simulation_framework === o2.simulation_framework
        )
    );
    return [...array1, ...newArray];
  }
}
