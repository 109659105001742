import {Component} from '@angular/core';
import {ColDef} from 'ag-grid-community';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {SimulationBuilderService} from 'src/app/simulation-builder/services/simulation-builder.service';
import {
  SimulationTypeRendererComponent
} from '../../../../components/custom-cell-renderers/simulation-type-renderer/simulation-type-renderer/simulation-type-renderer.component';
import {Router} from "@angular/router";
import {NotificationService} from "../../../../services/notification/notification.service";

@Component({
  selector: 'app-simulation-builder-flow-refinement',
  templateUrl: './simulation-builder-flow-refinement.component.html',
  styleUrl: './simulation-builder-flow-refinement.component.css',
})
export class SimulationBuilderFlowRefinementComponent {
  constructor(
    public simulationBuilderService: SimulationBuilderService,
    private capabilitiesService: CapabilitiesService,
    private router: Router,
    private notificationService: NotificationService
  ) {
  }

  public columnDefs: ColDef[] = [
    {
      field: 'diagnostic_name',
      headerName: 'Diagnostic name',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Simulation type',
      sortable: false,
      flex: 1,
      cellRenderer: SimulationTypeRendererComponent
    },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    wrapText: true,
    autoHeight: true,
    suppressMovable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
  };

  ngOnInit() {
    if (!this.simulationBuilderService.baseData.model_series?.length) {
      return this.router.navigate(['/simulations'])
    } else {
      this.simulationBuilderService.refinementSpinner = true;
      this.capabilitiesService
        .getVPackageResolved(this.simulationBuilderService.baseData.package.id)
        .subscribe({
          next: (response) => {
            this.simulationBuilderService.selectedPackage.simbun_packages =
              this.processPackages(response.data);
          },
          complete: () =>
            this.simulationBuilderService.prepareSimulationBundles(),
          error: (error) => {
            this.notificationService.handleError(error);
          },
        });
        // VTP-1682 Starts
        if (this.simulationBuilderService.backStep) {
          this.simulationBuilderService.prepareSimulationBundles();
        } 
        // VTP-1682 Ends
        // VTP-1689 Starts
        this.simulationBuilderService.simulatedEcus.forEach((ecu: any) =>{
          if (ecu.ncdName == undefined ) {
            this.capabilitiesService
                .getEcuOfTopology(
                  [this.simulationBuilderService.baseData.model_series!],
                  [ecu.diagnostic_name]
                ).subscribe(
                  response => ecu.ncdName = response.data.items[0].ncd_name
                )
          }
          
        } 
        );
        // VTP-1689 Ends
    }
  }

  getSimulationFramework(packageObj: any): string | null {
    if (!packageObj.application_instances) return null;

    return packageObj.application_instances.vecu_applications.length > 0
      ? packageObj.application_instances.vecu_applications[0]
        .simulation_framework
      : null;
  }

  processPackages(packageObj: any): any[] {
    let frameworks = new Set<string>();
    let childIds: any[] = [];

    if (packageObj.packages && packageObj.packages.length > 0) {
      for (let pkg of packageObj.packages) {
        const childFramework = this.getSimulationFramework(pkg);

        if (childFramework) {
          frameworks.add(childFramework);
        } else {
          const childResults = this.processPackages(pkg);

          if (childResults.length > 0) {
            childIds.push(...childResults);
          }
        }
      }
    }

    if (frameworks.size === 1) {
      packageObj.framework = frameworks.values().next();
    } else {
      if (packageObj.packages) {
        for (let pkg of packageObj.packages) {
          if (!(pkg.packages && !pkg.framework)) {

            childIds.push({
              id: pkg.id,
              name: pkg.name,
              simulation_framework: pkg.framework.value,
            });
          }
        }
      }
    }

    return childIds.length > 0 ? childIds : [];
  }
}
