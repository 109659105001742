<wb-grid [fullWidth]="true">
  <wb-grid-row class="gutter" style="padding-top: 24px">
    <wb-grid-col mq6="5">
      <wb-heading size="xs" class="spacing-xs">Enter base data</wb-heading>
      <wb-input
        as="input"
        [(ngModel)]="simulationbuilderService.baseData.name"
        name="name"
        ngDefaultControl
        inputmode="text"
        class="spacing-xs"
        required
        #name="ngModel"
        [error]="
          validationService.isFieldInvalid(
            formGroup.name,
            name,
            simulationbuilderService.baseData.name,
            format.Name
          )
        "
      >
        <span slot="label">Simulation name*</span>
        <div slot="message">
          <app-validation-notification
            name="Simulation name"
            [type]="format.None"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.name,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.name,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
          <app-validation-notification
            name="Simulation name"
            [type]="format.Name"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.name,
                validationStatus.Length
              ) &&
              validationService.getFormStatus(
                this.formGroup.name,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-input>
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="simulationbuilderService.baseData.model_series"
          [disabled]="editMode"
          name="modelSeries"
          ngDefaultControl
          required
          #modelSeries="ngModel"
          [error]="
            validationService.isComboBoxInValid(
              formGroup.modelSeries,
              modelSeries,
              simulationbuilderService.baseData.model_series!,
              getModelSeriesOptions()
            )
          "
          (wbcomboboxinput)="modelSeriesChange($event)"
        >
          <label slot="label">Model series*</label>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No model series selected"
          no-items-found-text="No model series found"
          (wbcomboboxlistitemselectionchange)="modelSeriesChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let line of vehicleLines?.items"
            [value]="line.name"
          >
            {{ line.name }}
          </wb-combobox-list-item>
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="model series"
            [type]="format.Pick"
            *ngIf="
              validationService.getFormStatus(
                formGroup.modelSeries,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                formGroup.modelSeries,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
      <wb-input
        as="input"
        class="spacing-xs"
        [(ngModel)]="simulationbuilderService.baseData.description"
        inputmode="text"
        name="furtherNotes"
        ngDefaultControl
      >
        <span slot="label">Further notes</span>
      </wb-input>
    </wb-grid-col>
    <wb-grid-col mq6="5" class="spacing-top-24"
    >
      <wb-heading size="xs" class="spacing-xs">Simulation mode</wb-heading>
      <wb-radio-group>
        <wb-radio-button
          name="transmission"
          value="automatic"
          value="one"
          [checked]="simulationbuilderService.simulationMode == 'event'"
          (click)="simulationbuilderService.simulationMode = 'event'"
        >
          <strong> Event-chain-based </strong><br/>
          <wb-text>
            User defines the system/function under test and the application
            calculates all matching available packages.
          </wb-text>
        </wb-radio-button
        >

        <wb-radio-button
          name="transmission"
          value="automatic"
          value="two"
          [checked]="simulationbuilderService.simulationMode == 'package'"
          (click)="simulationbuilderService.simulationMode = 'package'"
        >
          <strong> Package-based </strong><br/>
          <wb-text>
            In this mode the user selects a package and the application
            calculates all matching vehicle systems / functions.
          </wb-text>
        </wb-radio-button
        >
      </wb-radio-group>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
