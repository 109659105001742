<ag-grid-angular
  class="ag-theme-wb"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [loadingOverlayComponent]="loadingOverlayComponent"
  [loadingOverlayComponentParams]="loadingOverlayComponentParams"
  (gridReady)="onGridReady($event)"
  [pagination]="true"
  [paginationPageSize]="10"
  unSortIcon
  domLayout="autoHeight"
  suppressPaginationPanel
  suppressRowClickSelection
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  rowSelection="multiple"
  (selectionChanged)="onSelectionChanged()"
  (rowDataUpdated)="onRowDataChanged()"
>
</ag-grid-angular>
<wb-pagination
  *ngIf="getTotalPages() > 1"
  [currentPage]="currentPage.value"
  [pages]="getTotalPages()"
  (wbchange)="setGridPage($event)">
</wb-pagination>
