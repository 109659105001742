import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {HeaderTitleService} from '../../services/header/header-title.service';

@Component({
  selector: 'app-app-overview',
  templateUrl: './app-overview.component.html',
  styleUrls: ['./app-overview.component.css'],
})
export class AppOverviewComponent {
  title = 'vTesting';
  description =
    'Virtual testing provides you a virtual test lab to test virtual ECUs in component setups. It enables you to configure test jobs which are continuously executed with every new uploaded vECU release. Even test setups for manual testing are supported.';
  primaryButtonText = '';
  secondaryButtonText = '';
  tertiaryButtonText = '';

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbsService,
    public headerService: HeaderTitleService
  ) {
  }

  ngOnInit() {
    this.breadcrumbService.breadcrumbs = [];
    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;

    this.headerService.primaryButtonText = this.primaryButtonText;
    this.headerService.secondaryButtonText = this.secondaryButtonText;
    this.headerService.tertiaryButtonText = this.tertiaryButtonText;
  }

  cards: { header: string, description: string, buttonText: string, buttonLink: string }[] = [
    {
      header: 'vECU packages',
      description:
        'A vECU package is a compilation of all relevant simulation files of one or multiple vECUs. Once all software components are bundled into a package, it is ready to be released.',
      buttonText: 'vECU packages',
      buttonLink: '/vpackages',
    },
    {
      header: 'vTesting jobs',
      description:
        'A vTesting job defines one or multiple test definitions that shall run either for one particular vECU package release or continuously for every new release. Tests for BROP and FROP are supported.',
      buttonText: 'vTesting jobs',
      buttonLink: '/vtesting-jobs',
    }
  ];

  routeTo(path: string): void {
    this.router.navigate([path]);
  }
}
