import { Injectable } from '@angular/core';
import { CapabilitiesService } from '../../services/capabilities/capabilities.service';
import { forkJoin, map } from 'rxjs';
import { NotificationService } from '../../services/notification/notification.service';
import { Router } from '@angular/router';
import { SimulationBuilderService } from './simulation-builder.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService,
    private router: Router,
    private simulationBuilderService: SimulationBuilderService
  ) {}

  package1 = {
    id: '',
    revision: '0',
  };

  package2 = {
    id: '',
    revision: '0',
  };

  simulationBundle1 = {
    description: 'This is a complete CDCC SiL from Infosim.',
    model_series: 'C174',
    name: 'C174-89.CDCC_LVL4',
    package: {
      id: 'b49cfd8c-4426-48f5-93e8-1bf774fca586',
      revision: '0',
    },
    test_environment: {
      test_type: null,
      test_suite: null,
      configuration: {
        networks: [
          {
            name: 'FR_CHASSIS',
          },
          {
            name: 'CAN_FD_ENERGY',
          },
          {
            name: 'CAN_FD_INV',
          },
          {
            name: 'ETHERNET_MAIN',
          },
          {
            name: 'CAN_FD_INLET',
          },
        ],
        system: 'PNM-EMM',
      },
      instance: 'FROP',
    },
    plant_models: {
      simulated_ecus: [
        {
          diagnostic_name: 'ESP178',
          ncd_name: 'ESP',
          type: 'generic_ecu',
          networks: [
            {
              name: 'FR_CHASSIS'
            }
          ]
        },
        {
          diagnostic_name: 'SCCM174',
          ncd_name: 'SCCM',
          type: 'generic_ecu',
          networks: [
            {
              name: 'FR_CHASSIS'
            }
          ]
        },
        {
          diagnostic_name: 'BMS50',
          ncd_name: 'BMS',
          type: 'generic_ecu',
          networks: [
            {
              name: 'CAN_FD_ENERGY'
            }
          ]
        },
        {
          diagnostic_name: 'EPT30INV1',
          ncd_name: 'INV1',
          type: 'generic_ecu',
          networks: [
            {
              name: 'CAN_FD_INV'
            }
          ]
        },
        {
          diagnostic_name: 'EPT30INV2',
          ncd_name: 'INV2',
          type: 'generic_ecu',
          networks: [
            {
              name: 'CAN_FD_INV'
            }
          ]
        },
        {
          diagnostic_name: 'ORC174',
          ncd_name: 'ORC',
          type: 'generic_ecu',
          networks: [
            {
              name: 'FR_CHASSIS'
            }
          ]
        },
        {
          diagnostic_name: 'ONEBOX174',
          ncd_name: 'PCCU',
          type: 'generic_ecu',
          networks: [
            {
              name: 'CAN_FD_ENERGY'
            }
          ]
        },
        {
          diagnostic_name: 'CIC174',
          ncd_name: 'CIC',
          type: 'generic_ecu',
          networks: [
            {
              name: 'CAN_FD_INLET'
            }
          ]
        },
        {
          diagnostic_name: 'DCDC174',
          ncd_name: 'DCDC_B',
          type: 'generic_ecu',
          networks: [
            {
              name: 'CAN_FD_ENERGY'
            }
          ]
        }
      ],
      generics: [
        {
          name: 'Co-Simulation',
          simulation_framework: 'Silver'
        }
      ],
      physics: null,
    },
  };
  simulationBundle2 = {
    description: 'This is a Body SiL for PNM.',
    model_series: 'C174',
    name: 'PNM_Body_SiL',
    package: {
      id: '1a8932ec-0959-47aa-a5c5-8d452c9c1d1c',
      revision: '0',
    },
    test_environment: {
      test_type: null,
      test_suite: null,
      configuration: {
        networks: [
          { name: 'BODY1_CAN' },
          { name: 'BODY4_CAN' },
          {
            name: 'ETH_MAIN',
          },
        ],
        system: 'Power Network Management',
      },
      instance: 'FROP',
    },
    plant_models: {
      simulated_ecus: [
        {
          diagnostic_name: 'EZS174',
          ncd_name: 'EIS',
          type: 'generic_ecu',
          networks: [
            {
              name: 'BODY1_CAN',
            },
            {
              name: 'BODY4_CAN',
            },
          ],
        },
        {
          diagnostic_name: 'BC',
          ncd_name: 'BC',
          type: 'generic_ecu',
          networks: [
            {
              name: 'BODY4_CAN',
            },
          ],
        },
        {
          diagnostic_name: 'LIBB_MMA',
          ncd_name: 'BAT14',
          type: 'generic_ecu',
          networks: [
            {
              name: 'BODY4_CAN',
            },
          ],
        },
        {
          diagnostic_name: 'ARTEMIS',
          ncd_name: 'CU',
          type: 'restbus',
          networks: [
            {
              name: 'ETH_MAIN',
            },
          ],
        },
        {
          diagnostic_name: 'IDC_GEN6_P',
          ncd_name: 'IDC_M_P',
          type: 'restbus',
          networks: [
            {
              name: 'ETH_MAIN',
            },
          ],
        },
        {
          diagnostic_name: 'IDC_GEN6_C',
          ncd_name: 'IDC_M_C',
          type: 'restbus',
          networks: [
            {
              name: 'ETH_MAIN',
            },
          ],
        },
        {
          diagnostic_name: 'CIVIC174M',
          ncd_name: 'CIVIC_M_P',
          type: 'restbus',
          networks: [
            {
              name: 'ETH_MAIN',
            },
          ],
        },
        {
          diagnostic_name: 'CIVIC174V',
          ncd_name: 'CIVIC',
          type: 'restbus',
          networks: [
            {
              name: 'ETH_MAIN',
            },
          ],
        },
        {
          diagnostic_name: 'OHCM174',
          ncd_name: 'OHCM',
          type: 'restbus',
          networks: [
            {
              name: 'BODY1_CAN',
            },
          ],
        },
        {
          diagnostic_name: 'DMFL_MMA',
          ncd_name: 'DM_FL',
          type: 'restbus',
          networks: [
            {
              name: 'BODY1_CAN',
            },
          ],
        },
        {
          diagnostic_name: 'DMFR_MMA',
          ncd_name: 'DM_FR',
          type: 'restbus',
          networks: [
            {
              name: 'BODY1_CAN',
            },
          ],
        },
        {
          diagnostic_name: 'DMRL_MMA',
          ncd_name: 'DM_RL',
          type: 'restbus',
          networks: [
            {
              name: 'BODY1_CAN',
            },
          ],
        },
        {
          diagnostic_name: 'DMRR_MMA',
          ncd_name: 'DM_RR',
          type: 'restbus',
          networks: [
            {
              name: 'BODY1_CAN',
            },
          ],
        },
        {
          diagnostic_name: 'TM223',
          ncd_name: 'TM',
          type: 'restbus',
          networks: [
            {
              name: 'BODY1_CAN',
            },
          ],
        },
      ],
      generics: null,
      physics: [
        {
          name: 'Environment_Dependency',
          simulation_framework: 'CANoe',
        },
      ],
    },
  };
  coSimulationBundle = {
    name: 'CoSimulation PNM-EMM',
    description: 'Co-Simulation for PNM-EMM Usecase',
    simulation_bundle_ids: [''],
    config: {},
    package: { id: '30e46f8f-6401-46e5-add9-e12361cf525b' },
  };

  postAllSimulationBundles(): void {
    const observables: any = [];

    this.simulationBundle1.package = this.package1;
    this.simulationBundle2.package = this.package2;

    Object.values([this.simulationBundle1, this.simulationBundle2]).forEach(
      (bundle: any, index) => {
        const observable = this.capabilitiesService
          .postSimulationBundle('C174', bundle)
          .pipe(
            map((response: any) => {
              return { index, simulationBundleId: response.data.id };
            })
          );
        observables.push(observable);
      }
    );

    forkJoin(observables).subscribe({
      next: (responses: any) => {
        this.coSimulationBundle.simulation_bundle_ids = [];
        responses.forEach((response: any) =>
          this.coSimulationBundle.simulation_bundle_ids.push(
            response.simulationBundleId
          )
        );
        this.postCoSimulationBundle();
      },
      error: (error) => this.notificationService.handleError(error),
    });
  }

  postCoSimulationBundle(): void {
    this.coSimulationBundle.package.id =
      this.simulationBuilderService.baseData.package.id;
    this.capabilitiesService
      .postCoSimulationBundle(this.coSimulationBundle)
      .subscribe({
        next: (response) => {
          this.notificationService.notifySuccess = true;
          this.notificationService.notificationSuccesMessage = response.message;
        },
        complete: () => this.router.navigate(['/simulations']),
        error: (error) => this.notificationService.handleError(error),
      });
  }
}
