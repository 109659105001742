import {Component, ElementRef, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {Route, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {HeaderTitleService} from 'src/app/services/header/header-title.service';
import {BreadcrumbsService} from 'src/app//services/breadcrumbs/breadcrumbs.service';
import {SimulationBuilderService} from 'src/app/simulation-builder/services/simulation-builder.service';
import {ModalService} from "../../../../services/modal/modal.service";

const MAX_STEPS = 4;

@Component({
  selector: 'app-simulation-builder-flow',
  templateUrl: './simulation-builder-flow.component.html',
  styleUrl: './simulation-builder-flow.component.css',
})
export class SimulationBuilderFlowComponent implements OnDestroy, OnInit {
  primaryButtonText: string = 'Next';
  secondaryButtonText: string = 'Back';
  tertiaryButtonText: string = 'Cancel';
  currentStep: number = 1;
  routerConfig: Route[] = [];
  routeSubscription: Subscription = this.router.events.subscribe(
    () => (this.routerConfig = this.router.config)
  );
  discardModalSubscription: Subscription = this.modalService.confirmDiscard().subscribe(discard => {
    if (discard) {
      this.simulationBuilderService.clearAll();
      this.router.navigate(['simulations']);
    }
  });

  @ViewChild('step1') step1Ref!: ElementRef;
  @ViewChild('step2') step2Ref!: ElementRef;
  @ViewChild('step3') step3Ref!: ElementRef;
  @ViewChild('step4') step4Ref!: ElementRef;
  breadCrumb: { label: string; route: string } = {label: '', route: ''};

  constructor(
    private router: Router,
    private headerService: HeaderTitleService,
    private breadCrumbService: BreadcrumbsService,
    public simulationBuilderService: SimulationBuilderService,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.setModeAndBreadcrumb();
    this.initHeaderActions();
    this.updateStatusBar();
  }

  private initHeaderActions() {
    this.headerService.resetHeaderActions();
    this.headerService.primaryButtonText = this.primaryButtonText;
    this.headerService.secondaryButtonText =
      this.currentStep == 1 ? '' : 'Back';
    this.headerService.tertiaryButtonText = this.tertiaryButtonText;

    this.headerService.primaryClick$.subscribe(() => this.nextStep());
    this.headerService.secondaryClick$.subscribe(() => this.backOneStep());
    this.headerService.tertiaryClick$.subscribe(() =>
      this.cancelCreateSimulation()
    );
  }

  ngOnDestroy() {
    this.simulationBuilderService.clearAll();
    this.routeSubscription.unsubscribe();
    this.discardModalSubscription.unsubscribe();
  }

  setModeAndBreadcrumb(): void {
    let index;

    this.simulationBuilderService.loadingSpinner = false;
    this.headerService.pageTitle =
      (this.simulationBuilderService.getEditMode() ? 'Edit' : 'Create') +
      ' simulation';
    this.headerService.pageDescription =
      'The simulation builder enables you to configure complex simulations for system testing.';
    this.breadCrumb = {
      label:
        (this.simulationBuilderService.getEditMode() ? 'Edit' : 'Create') +
        ' simualation',
      route: '',
    };
    if (this.breadCrumbService.breadcrumbs.length == 0) {
      this.breadCrumbService.breadcrumbs.push(
        {
          label: 'All Simulations',
          route: '/simulations',
        },
        {
          label:
            (this.simulationBuilderService.getEditMode()
              ? 'Modify'
              : 'Create') + ' simualation',
          route: '',
        }
      );
    }
    this.breadCrumbService.breadcrumbs.push({
      label:
        (this.simulationBuilderService.getEditMode()
          ? 'Modify'
          : 'Create') + ' simualation',
      route: '',
    })
  }

  nextStep(): void {
    if (this.currentStep < MAX_STEPS) {
      this.simulationBuilderService.triggerNextClick();
      this.currentStep++;
      this.headerService.secondaryButtonText =
        this.currentStep === 1 ? '' : 'Back';
      this.headerService.primaryButtonText =
        this.currentStep < MAX_STEPS ? 'Next' : 'Finish';
      this.router.navigate(['simulation', this.findPath()]);
      this.headerService.pageTitle =
        (this.simulationBuilderService.getEditMode() ? 'Edit' : 'Create') +
        ' Simulations';
      this.updateStatusBar();
    } else {
      this.finish();
    }
  }

  findPath(): string {
    const route = this.routerConfig.find(
      (route) => route.path === 'simulation'
    );

    return (
      route?.children?.find(
        (child) => child.data?.['step'] === this.currentStep
      )?.path || ''
    );
  }

  finish(): void {
    //TODO needs to be removed
    this.simulationBuilderService.postSimulationBundles();
  }

  backOneStep(): void {
    this.simulationBuilderService.backStep = this.currentStep == 4; //VTP-1682
    this.currentStep--;
    this.updateStatusBar();
    this.headerService.secondaryButtonText =
      this.currentStep === 1 ? '' : 'Back';
    if (this.currentStep === MAX_STEPS - 1) {
      this.headerService.clearPrimaryClickFunction();
      this.headerService.primaryButtonText = 'Next';
      this.headerService.primaryClick$.subscribe(() => this.nextStep());
    }
    this.router.navigate(['simulation', this.findPath()]);
    this.headerService.pageTitle =
      (this.simulationBuilderService.getEditMode() ? 'Modify' : 'Create') +
      ' Simulation';
  }

  updateStatusBar(): void {
    if (this.step1Ref && this.step2Ref && this.step3Ref && this.step4Ref)
      [this.step1Ref, this.step2Ref, this.step3Ref, this.step4Ref].forEach(
        (step, index) =>
          (step.nativeElement.status =
            index < this.currentStep - 1
              ? 'done'
              : index === this.currentStep - 1
                ? ''
                : 'disabled')
      );
  }

  cancelCreateSimulation(): void {
    let discardModalTitle: string = `Discard create simulation?`;
    let discardModalDescription: string = "You will lose all unsaved changes made to the data.";

    this.modalService.initializeCustomModal("discard", discardModalTitle, discardModalDescription);
  }
}
