
<wb-tooltip position="top" *ngIf="showDeleteButton">
  <wb-button variant="tertiary" size="s">
    <wb-icon
      name="bds/trash/24"
      class="icon-color"
      variant="primary"
      (click)="deleteClicked()"
      delete
    >
    </wb-icon>
  </wb-button>

  <div slot="content">Delete</div>
</wb-tooltip>
<!--     <wb-button-group-item variant="primary" size="l" icon-only><wb-icon slot="icon" flip-rtl name="bds/trash/24"></wb-icon>Delete</wb-button-group-item> -->
