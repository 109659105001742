import {Component, OnInit} from '@angular/core';
import {SimulationBuilderService} from "../../../services/simulation-builder.service";
import {ColDef, ICellRendererParams, ValueFormatterParams} from "ag-grid-community";
import {HelperService} from "../../../services/helper.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-simulation-builder-flow-summary',
  templateUrl: './simulation-builder-flow-summary.component.html',
  styleUrl: './simulation-builder-flow-summary.component.css',
})
export class SimulationBuilderFlowSummaryComponent implements OnInit {
  genericPhysicColumnDefs: ColDef[] = [
    {
      field: 'file_name',
      headerName: 'Name',
      flex: 2,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
    },
    {
      field: 'simulation_framework',
      headerName: 'Simulation framework',
      flex: 2,
    }
  ];
  genericPhysicRowData: { name: string, type: "Physic" | "Generic", simulationFramework: string }[] = [];

  simulatedEcuColumnDefs: ColDef[] = [
    {
      field: 'diagnosticName',
      headerName: 'Diagnostic name',
      flex: 4,
    },
    {
      field: 'ncdName',
      headerName: 'NCD name',
      flex: 3,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      }
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 3,
    },
    {
      field: 'network',
      headerName: 'Network',
      flex: 3,
      valueFormatter: this.networksFormatter
    }
  ];
  simulatedEcuRowData: { diagnosticName: string, ncdName: string, type: string, network: string }[] = [];

  constructor(protected simulationBuilderService: SimulationBuilderService, private helperService: HelperService, private router: Router) {
  }

  ngOnInit() {
    if (!this.simulationBuilderService.baseData.model_series?.length) {
      return this.router.navigate(['/simulations'])
    } else {
      this.simulationBuilderService.matchUndecidedSimulatedEcus();
      this.simulationBuilderService.simulationBundleCollection.forEach((simulationBundle: any) => {
        this.initializePlantModelGrid(simulationBundle);
        this.initializeSimulatedEcuGrid();
      });
    }
  }

  private initializePlantModelGrid(simulationBundle: any) {
    let simulationBundleData;
    let simulationBundleType: 'Physic' | 'Generic';

    if (simulationBundle.plant_models && simulationBundle.plant_models.physics) {
      simulationBundleData = simulationBundle.plant_models.physics;
      simulationBundleType = "Physic";
    } else if (simulationBundle.plant_models && simulationBundle.plant_models?.generics) {
      simulationBundleData = simulationBundle.plant_models.generics;
      simulationBundleType = "Generic"
    } else return;

    simulationBundleData.forEach((item: any) => this.genericPhysicRowData.push({
      name: item.file_name,
      simulationFramework: item.simulation_framework,
      type: simulationBundleType!
    }));
  }

  networksFormatter(params: ValueFormatterParams): string {
    return params.value.map((network: any) => network.name).join();
  }

  private initializeSimulatedEcuGrid() {
    this.simulatedEcuRowData = [];
    //VTP-1689 Starts
    this.simulationBuilderService.simulatedEcus.forEach((ecu: any) =>{
      this.simulatedEcuRowData.push({
        diagnosticName: ecu.diagnostic_name,
        ncdName: ecu.ncdName,
        type: ecu.type,
        network: ecu.networks
      })
    } 
    );
    //VTP-1689 Ends
  }
}
