<wb-grid [fullWidth]="true">
  <wb-grid-row class="spacing-24">
    <wb-grid-col mq6="8">
      <wb-heading size="xs" class="spacing-xxs">Package contents</wb-heading>
      <wb-text size="l" class="spacing-xxs">Contained Packages</wb-text>
    </wb-grid-col>
    <wb-grid-col mq6="4">
      <wb-heading size="xs" class="spacing-xxs">ECU package link</wb-heading>
    </wb-grid-col>
  </wb-grid-row>
  <wb-grid-row class="spacing-24">
    <wb-grid-col mq6="8">
      <app-list-selection-grid
        [rowData]="vPackageService.vpackages"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
      >
      </app-list-selection-grid>
    </wb-grid-col>
    <wb-grid-col mq6="4">
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="this.vPackageService.packageDataPost!.ecu_package_link"
          name="hardwarelink"
          ngDefaultControl
          #hardwarelink="ngModel"
          (wbcomboboxinput)="onEcuLinkInputChange($event)"
          [disabled]="packageLinkSpinner"
        >
          <label slot="label">ECU package link (optional) </label>
          <wb-input-action slot="actions" *ngIf="packageLinkSpinner">
            <wb-spinner id="loading-spinner"></wb-spinner>
          </wb-input-action>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No hardware link selected"
          no-items-found-text="No hardware link found"
          (wbcomboboxlistselectionchange)="onEcuLinkSelectChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let ecuPackage of vPackageService.filteredArray"
            value="{{ ecuPackage.id }}"
            >{{ ecuPackage.name }}
          </wb-combobox-list-item>
        </wb-combobox-list>
      </wb-combobox>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
