import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PackageReleaseComponent} from './package-release.component';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {UploadDialogComponent} from '../package/components/upload-dialog/upload-dialog.component';
import {AgGridModule} from 'ag-grid-angular';
import {VpackageReleaseComponent} from './components/vpackage-release/vpackage-release.component';
import {
  VpackageReleaseContentComponent
} from './components/vpackage-release-content/vpackage-release-content.component';
import {VpackageReleaseFormComponent} from './components/vpackage-release-form/vpackage-release-form.component';
import {ValidationModule} from "../components/validation/validation.module";
import {
  VpackageReleaseBaseDataComponent
} from './components/vpackage-release-base-data/vpackage-release-base-data.component';
import {
  VpackageReleaseApplicationsComponent
} from './components/vpackage-release-applications/vpackage-release-applications.component';
import {CustomGridsModule} from "../components/custom-grids/custom-grids.module";

@NgModule({
  declarations: [
    PackageReleaseComponent,
    UploadDialogComponent,
    VpackageReleaseComponent,
    VpackageReleaseContentComponent,
    VpackageReleaseFormComponent,
    VpackageReleaseBaseDataComponent,
    VpackageReleaseApplicationsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    AgGridModule,
    ValidationModule,
    CustomGridsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PackageReleaseModule {
}
