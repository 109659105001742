<div class="container wb-margin-bottom-s" *ngIf="testJob[0]">
  <wb-tab-content
    name="info"
    [selected]="selectedTabId == 'info'"
    slot="content"
    style="padding: 0 !important;"
  >
    <wb-grid-col mq6="12">
      <wb-grid-row>
        <wb-grid-col mq6="12">
          <div>
            <wb-grid-row>
              <wb-grid-col mq6="3">
                <wb-text class="wb-text" size="l" strong tag="div">
                  Selected vTesting job
                </wb-text>
              </wb-grid-col>
            </wb-grid-row>
          </div>

          <wb-grid-row class="wb-padding-top-summary">
            <wb-grid-col mq6="3">
              <wb-text class="wb-text" size="m" tag="div">
                vTesting job name
              </wb-text>
            </wb-grid-col>
            <wb-grid-col mq4="9">
              <wb-text class="wb-text" size="m" tag="div"
              >{{ testJob[0].name }}
              </wb-text>
            </wb-grid-col>
          </wb-grid-row>
          <wb-grid-row class="wb-padding-top-summary">
            <wb-grid-col mq6="3">
              <wb-text class="wb-text" size="m" tag="div">
                vTesting job ID
              </wb-text>
            </wb-grid-col>
            <wb-grid-col mq6="9">
              <wb-text class="wb-text" size="m" tag="div">
                {{ testJob[0].id }}
              </wb-text>
            </wb-grid-col>
          </wb-grid-row>

          <wb-grid-row class="wb-padding-top-summary">
            <wb-grid-col mq6="3">
              <wb-text class="wb-text" size="m" tag="div">
                Model series
              </wb-text>
            </wb-grid-col>
            <wb-grid-col mq6="9">
              <wb-text class="wb-text" size="m" tag="div"
              >{{ testJob[0].basic_configuration.model_series }}
              </wb-text>
            </wb-grid-col>
          </wb-grid-row>

          <wb-grid-row class="wb-padding-top-summary">
            <wb-grid-col mq6="3">
              <wb-text class="wb-text" size="m" tag="div">
                Package
              </wb-text>
            </wb-grid-col>
            <wb-grid-col mq6="9">
              <wb-text class="wb-text" size="m" tag="div"
              >
                <wb-link variant="standalone" (click)="showPackage()">{{ packageName }}</wb-link>
              </wb-text>
            </wb-grid-col>
          </wb-grid-row>

          <wb-grid-row class="wb-padding-top-summary">
            <wb-grid-col mq6="3">
              <wb-text class="wb-text" size="m" tag="div">
                Further notes
              </wb-text>
            </wb-grid-col>
            <wb-grid-col mq6="9">
              <wb-text class="wb-text" size="m" tag="div"
              >{{ testJob[0].further_notes ? testJob[0].further_notes : '-' }}
              </wb-text>
            </wb-grid-col>
          </wb-grid-row>

          <wb-grid-row class="wb-padding-top-summary">
            <wb-grid-col mq4="3">
              <wb-text class="wb-text" size="m" tag="div"> Created by</wb-text>
            </wb-grid-col>
            <wb-grid-col mq6="9">
              <wb-text class="wb-text" size="m" tag="div"
              >{{ testJob[0].created_by }}
              </wb-text>
            </wb-grid-col>
          </wb-grid-row>

          <wb-grid-row class="wb-padding-top-summary">
            <wb-grid-col mq4="3">
              <wb-text class="wb-text" size="m" tag="div"> Created at</wb-text>
            </wb-grid-col>
            <wb-grid-col mq6="9">
              <wb-text class="wb-text" size="m" tag="div"
              >{{ this.formattedDate }}
              </wb-text>
            </wb-grid-col>
          </wb-grid-row>

          <wb-grid-row class="wb-padding-top-summary" *ngIf="testJob[0]?.basic_configuration?.trigger?.release_type">
            <wb-grid-col mq4="3">
              <wb-text class="wb-text" size="m" tag="div"> Trigger condition:</wb-text>
            </wb-grid-col>
            <wb-grid-col mq6="9">
              <wb-text class="wb-text" size="m" tag="div">
                release_type
                {{ (testJob[0]?.basic_configuration?.trigger?.operator == "EQUAL" ? "== " : ">= ") + testJob[0]?.basic_configuration?.trigger?.release_type }}
              </wb-text>
            </wb-grid-col>
          </wb-grid-row>
          <div>
            <div>
              <wb-text
                class="wb-text"
                style="padding: 24px 0px 24px 0px"
                size="l"
                strong
                tag="div"
                style="float: left; padding-top: 24px"
              >
                Job runs
              </wb-text>
            </div>
            <div dir="rtl">
              <wb-round-button
                style="padding-top: 12px;"
                theme="light"
                variant="tertiary"
                size="m"
                (click)="refreshRowsData()"
                icon-only
              >
                <wb-icon name="bds/reset/24"></wb-icon>
              </wb-round-button>
              <!--   <wb-button
                theme="light"
                variant="tertiary"
                size="m"
                (click)="startTestJobManually()"
                icon-only
                style="margin-right: 8px"
              >
                <wb-icon slot="icon" name="play"></wb-icon>
              </wb-button> -->
            </div>
          </div>
          <app-custom-nested-grid
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [detailCellRendererParams]="detailCellRendererParams"
            (GridReady)="onGridReady($event)"
          >
          </app-custom-nested-grid>
          <wb-notification-host>
            <wb-notification
              *ngIf="showTestDefinitionDownloadNotification"
              icon="bds/time/24"
              lifetime="3500"
              position="top-end"
            >
              Downloading test definition report
            </wb-notification>
            <wb-notification
              *ngIf="showJobDefinitionDownloadNotification"
              (wbdismiss)="notificationService.notify = false"
              icon="bds/time/24"
              lifetime="3500"
              position="top-end"
            >
              Downloading job report
            </wb-notification>
          </wb-notification-host>
        </wb-grid-col>
      </wb-grid-row>
    </wb-grid-col>
  </wb-tab-content>
  <wb-tab-content
    name="permissions"
    [selected]="selectedTabId == 'permissions'"
    slot="content"
  >
    <mbos-permissions
      domain="vtesting-testing"
      [resourceId]="testJob[0].id"
    ></mbos-permissions>
  </wb-tab-content>
</div>
<wb-modal
  *ngIf="
    modalService.isActive && modalService.name.includes('test-job-manually-run')
  "
  variant="prompt"
  close-on-backdrop-click
  close-on-escape-key
  scroll-lock
>
  <wb-modal-level>
    <wb-heading size="xs">
      {{ modalService.title }}
    </wb-heading>
    {{ modalService.description }}
    <wb-button
      variant="secondary"
      size="l"
      slot="footer"
      (click)="modalService.handleDiscard(false)"
    >
      Cancel
    </wb-button>
    <wb-button
      variant="primary"
      size="l"
      slot="footer"
      (click)="modalService.handleDiscard(true)"
    >
      Start test job
    </wb-button>
  </wb-modal-level>
</wb-modal>
