import {Component} from '@angular/core';
import {VpackagereleaseService} from 'src/app/package/services/vpackagerelease.service';
import {HeaderTitleService} from 'src/app/services/header/header-title.service';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {Format, ValidationService, ValidationStatus,} from '../../../services/validation/validation.service';
import {ReleaseType} from '@vtp/vtpcap-client-ts';
import {PacmanService} from 'src/app/services/pacman/pacman.service';

enum FormGroup {
  name = 'name',
  variantInfo = 'variantInfo',
  releaseType = 'releaseType',
  modelSeries = 'modelSeries',
}

@Component({
  selector: 'app-vpackage-release-base-data',
  templateUrl: './vpackage-release-base-data.component.html',
  styleUrl: './vpackage-release-base-data.component.css',
})
export class VpackageReleaseBaseDataComponent {
  title = 'Create new vECU package release';
  description = '';
  VariantInfos?: any;
  softwareUuids?: any = ['test'];
  modelSeries: any;
  modelSeriesList: string[] = [];
  modelSeriesSelected : string = '';

  variantInfo: string = '';
  release: string = '';
  formGroup = FormGroup;
  format = Format;
  validationStatus = ValidationStatus;

  ecuPackageReleases: any[] = [];
  releaseOptions: string[] = [
    'release_candidate',
    'release-candidate',
    'beta',
    'release',
  ];

  constructor(
    public vPackageReleaseService: VpackagereleaseService,
    private breadcrumbService: BreadcrumbsService,
    private headerService: HeaderTitleService,
    private capabilitiesService: CapabilitiesService,
    private notificationService: NotificationService,
    public validationService: ValidationService,
    private pacmanService: PacmanService
  ) {
  }

  spinner: boolean = false;

  ngOnInit() {
    this.initValidation();
    this.pacmanService
      .getHardwarePackageReleases(
        this.vPackageReleaseService.packageOverview!.ecu_package_link!
      )
      .subscribe({
        next: (response) => {
          this.ecuPackageReleases = response[0].releaseInformation;
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });
    if (this.vPackageReleaseService.packageReleasePost!.release_type) {
      this.release = this.vPackageReleaseService.packageReleasePost!.release_type!.replace("_", "-")
    } else {
      this.release = ReleaseType.Beta;
    }
    if (this.vPackageReleaseService.packageType == 'vECUPKG') {
      this.capabilitiesService
        .getEcuOfTopologyInModelSeries(
          this.vPackageReleaseService.getModelSeries(),
          this.vPackageReleaseService.getDiagnosticName(),
          true
        )
        .subscribe({
          next: (response) => {
            this.VariantInfos = response.data.variant_info;
          },
          error: (error) => {
            this.notificationService.handleError(error);
          },
        });
        this.vPackageReleaseService.packageReleasePost!.application_instances!.use_selected_manifest = true;
    }
    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    const breadcrumb = {
      label: 'Create new release',
      route: '/',
    };
    if (this.breadcrumbService.findIndexByLabel(breadcrumb.label) > -1) {
      this.breadcrumbService.removeAboveIndex(
        this.breadcrumbService.findIndexByLabel(breadcrumb.label)
      );
    } else {
      this.breadcrumbService.breadcrumbs.push(breadcrumb);
    }
    if (this.vPackageReleaseService.packageOverview?.model_series) {
      for (let index = 0; index < this.vPackageReleaseService.packageOverview?.model_series.length; index++) {
        this.modelSeriesList.push(this.vPackageReleaseService.packageOverview?.model_series[index].name)
        
      }
    }
    if (this.vPackageReleaseService.packageReleasePost.model_series) {
      this.modelSeriesSelected = this.vPackageReleaseService.packageReleasePost.model_series[0].name;
    }
  }

  initValidation(): void {
    this.validationService.initialize(FormGroup);
    if (!this.vPackageReleaseService.packageReleasePost.application_instances) {
      this.validationService.setInputFieldTouched('variantInfo', true);
    }
  }

  onVariantInfoInputChange(event: any): void {
    this.variantInfo = event.detail.value;
    if (this.VariantInfos.includes(this.variantInfo)) {
      this.vPackageReleaseService.packageReleasePost!.variant_info = this.variantInfo;
    }
  }

  onVariantInfoSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.variantInfo = event.detail.value.value;
        this.vPackageReleaseService.packageReleasePost!.variant_info =
          event.detail.value.value;
      } else {
        this.variantInfo = '';
        this.vPackageReleaseService.packageReleasePost!.variant_info =
          undefined;
      }
    }
  }

  onHardwareLinkInputChange(event: any): void {
  }

  onEcuReleaseLinkSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageReleaseService.packageReleasePost!.ecu_package_release_link =
          event.detail.value.value;
      } else {
        this.vPackageReleaseService.packageReleasePost!.ecu_package_release_link =
          '';
      }
    } else {
      this.vPackageReleaseService.packageReleasePost!.ecu_package_release_link =
        '';
    }
  }

  onSoftwareUuidInputChange(event: any): void {
    this.variantInfo = event.detail.value;
    if (this.VariantInfos.includes(this.variantInfo)) {
      this.vPackageReleaseService.packageReleasePost!.variant_info =
        this.variantInfo;
    }
  }

  onSoftwareUuidSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.softwareUuids = event.detail.value.value;
        this.vPackageReleaseService.packageReleasePost!.application_instances!.sw_uuids =
          event.detail.value.value;
      } else {
        this.softwareUuids = [];
        this.vPackageReleaseService.packageReleasePost!.application_instances!.sw_uuids =
          undefined;
      }
    }
  }

  onReleaseTypeInputChange(event: any): void {
    this.release = event.detail.value;
    if (this.releaseOptions.includes(this.release)) {
      this.vPackageReleaseService.packageReleasePost!.release_type = <
        ReleaseType
        >this.release.replace('-', '_');
    }
  }

  onReleaseTypeSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.vPackageReleaseService.packageReleasePost!.release_type =
          event.detail.value.value;
        this.release = event.detail.value.value;
      } else {
        this.release = "";
      }
    }
  }

  onReleaseModelSeriesInputChange(event: any): void {
    this.modelSeriesSelected = event.detail.value;
    if (this.modelSeriesList.includes(this.modelSeriesSelected)) {
      this.vPackageReleaseService.packageReleasePost!.model_series = [
        {
          'name': this.modelSeriesSelected
        }
      ]
        
    }else{
      this.vPackageReleaseService.packageReleasePost!.model_series = []
    }
  }

  onReleaseModelSeriesSelectChange(event: any): void {
    if (event) {
      if (event.detail.value) {
        this.modelSeriesSelected = event.detail.value.value;
        if (this.modelSeriesList.includes(this.modelSeriesSelected)) {
          this.vPackageReleaseService.packageReleasePost!.model_series = [
            {
              'name': this.modelSeriesSelected
            }
          ]
            
        }
      } else {
        this.vPackageReleaseService.packageReleasePost!.model_series = []
      }
    }
  }
}
