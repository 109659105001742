<wb-modal
  variant="prompt"
  close-on-backdrop-click
  close-on-escape-key
  scroll-lock
>
  <wb-modal-level>
    <wb-heading size="xs">
      {{ modalService.title }}
    </wb-heading>
    {{ modalService.description }}
    <wb-button
      variant="secondary"
      size="l"
      slot="footer"
      (click)="modalService.handleDiscard(false)"
    >
      Cancel
    </wb-button>
    <wb-button
      variant="primary"
      size="l"
      slot="footer"
      (click)="modalService.handleDiscard(true)"
    >
      Discard & leave
    </wb-button>
  </wb-modal-level>
</wb-modal>
