import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { ValidationModule } from '../components/validation/validation.module';
import { CustomGridsModule } from '../components/custom-grids/custom-grids.module';
import { VirtualRemoteTestBenchJobFlowBaseDataComponent } from './components/remote-testbench-job-flow/remote-testbench-job-flow-base-data/remote-testbench-job-flow-base-data.component';
import { VirtualRemoteTestBenchJobFlowSummaryComponent } from './components/remote-testbench-job-flow/remote-testbench-job-flow-summary/remote-testbench-job-flow-summary.component';
import { VirtualRemoteTestBenchJobFlowTestComponent } from './components/remote-testbench-job-flow/remote-testbench-job-flow-test/remote-testbench-job-flow-test.component';
import { VirtualRemoteTestBenchJobFlowTestDefinitionComponent } from './components/remote-testbench-job-flow/remote-testbench-job-flow-test-definition/remote-testbench-job-flow-test-definition.component';
import { VirtualRemoteTestBenchFropSettingsComponent } from './components/remote-testbench-job-flow/remote-testbench-job-flow-test-definition/remote-testbench-frop-settings/remote-testbench-frop-settings.component';
import { VirtualRemoteTestBenchJobOverviewComponent } from './components/remote-testbench-job-overview/remote-testbench-job-overview.component';
import { VirtualRemoteTestBenchJobFlowComponent } from './components/remote-testbench-job-flow/remote-testbench-job-flow.component';
import { VirtualRemoteTestBenchJobBropComponentSettingsComponent } from './components/remote-testbench-job-flow/remote-testbench-job-flow-test-definition/remote-testbench-job-brop/remote-testbench-job-brop.component';




@NgModule({
  declarations: [
    VirtualRemoteTestBenchJobFlowBaseDataComponent,
    VirtualRemoteTestBenchJobFlowSummaryComponent,
    VirtualRemoteTestBenchJobFlowTestComponent,
    VirtualRemoteTestBenchJobFlowTestDefinitionComponent,
    VirtualRemoteTestBenchFropSettingsComponent,
    VirtualRemoteTestBenchJobOverviewComponent,
    VirtualRemoteTestBenchJobFlowComponent,
    VirtualRemoteTestBenchJobBropComponentSettingsComponent
    
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    AgGridModule,
    ValidationModule,
    CustomGridsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VirtualRemoteTestBenchModule { }
