<wb-grid [fullWidth]="true">
  <wb-grid-row class="spacing-s">
    <wb-grid-col mq6="5">
      <wb-heading size="xs" class="spacing-xs">Base data</wb-heading>
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="this.vPackageService.modelSeries"
          [name]="this.formGroup.modelSeries"
          ngDefaultControl
          required
          #modelSeries="ngModel"
          [error]="
            this.validationService.isMultiSelectInvalid(
              this.formGroup.modelSeries,
              this.modelSeries,
              this.vPackageService.modelSeries
            )
          "
          (wbcomboboxinput)="onModelSeriesInputChange($event)"
          selection-count-suffix="model series selected"
        >
          <label slot="label">Model series*</label>
        </wb-combobox-input>
        <wb-combobox-list
          variant="multi"
          show-chip-list-row="false"
          no-items-selected-text="No model series selected"
          no-items-found-text="No model series found"
          (wbcomboboxlistselectionchange)="onModelSeriesSelectChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let series of this.vPackageService.modelSeriesList"
            value="{{ series.name }}"
            [selected]="isModelSeriesSelected(series.name)"
          >{{ series.name }}
          </wb-combobox-list-item>
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="model series"
            [type]="format.MultiSelect"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.modelSeries,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.modelSeries,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="typeInput"
          name="type"
          ngDefaultControl
          required
          #type="ngModel"
          [error]="
            this.validationService.isComboBoxInValid(
              this.formGroup.type,
              this.type,
              typeInput,
              getTypeOptions()
            )
          "
          (wbcomboboxinput)="onTypeInputChange($event)"
        >
          <label slot="label">Package type*</label>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No type selected"
          no-items-found-text="No types found"
          (wbcomboboxlistselectionchange)="onTypeSelectChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let type of vPackageService.typeList"
            value="{{ type }}"
          >{{ vPackageService.packageTypeMap[type] }}
          </wb-combobox-list-item>
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="package type"
            [type]="format.Pick"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.type,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.type,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
      <wb-input
        as="input"
        [(ngModel)]="this.vPackageService.packageDataPost!.name"
        [name]="this.formGroup.packageName"
        ngDefaultControl
        inputmode="text"
        class="spacing-xs"
        required
        #packageName="ngModel"
        [error]="
          validationService.isFieldInvalid(
            this.formGroup.packageName,
            packageName,
            this.vPackageService.packageDataPost!.name,
            format.Name
          )
        "
      >
        <span slot="label">Package name*</span>
        <wb-input-action slot="actions" *ngIf="vPackageService.packageDataPost?.provider=='infosim'">
          <wb-tooltip position="top">
            <wb-badge variant="warning" class="infosim">
              <wb-icon name="bds/exclamation-mark/24"></wb-icon>
            </wb-badge>
            <div slot="content">For an infosim package please make sure to chose the package name identically to the
              infosim package!
            </div>
          </wb-tooltip>
        </wb-input-action>
        <div slot="message">
          <app-validation-notification
            name="Package Name"
            [type]="format.None"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.packageName,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.packageName,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
          <app-validation-notification
            name="Package Name"
            [type]="format.Name"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.packageName,
                validationStatus.Length
              ) &&
              validationService.getFormStatus(
                this.formGroup.packageName,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-input>
      <wb-radio-group>
        <wb-checkbox
          (wbchange)="onInfosimChange($event)"
          class="spacing-xs"
          ngDefaultControl
          name="infosim"
        >
          Infosim package (optional)
        </wb-checkbox>
      </wb-radio-group>
      <wb-combobox class="spacing-xs" *ngIf="this.vPackageService.packageDataPost!.type == 'vECUPKG'">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="vPackageService.simFramework"
          name="simulationFramework"
          ngDefaultControl
          required
          #simulationFramework="ngModel"
          [error]="
            this.validationService.isComboBoxInValid(
              this.formGroup.simulationFramework,
              this.simulationFramework,
              vPackageService.simFramework,
              frameworks
            )
          "
          (wbcomboboxinput)="onFrameworkInputChange($event)"
          [disabled]="this.vPackageService.packageDataPost!.provider == 'infosim'"
        >
          <label slot="label">Simulation Framework*</label>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No type selected"
          no-items-found-text="No types found"
          (wbcomboboxlistselectionchange)="onFrameworkSelectChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let framework of frameworks"
            value="{{ framework }}"
            [selected]="framework === vPackageService.simFramework"
          >{{ framework }}
          </wb-combobox-list-item>
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="simulation framework"
            [type]="format.Pick"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.simulationFramework,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.simulationFramework,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>

    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
