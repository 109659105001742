<wb-tab-content name="contents" slot="content" [selected]="selectedTabId == 'contents'" style="padding: 0;">
  <app-custom-grid
    [rowData]="
        vPackageService.packageOverview.type == 'vECUPKG'
          ? applications
          : vPackageService.packageOverview.packages || []
      "
    [columnDefs]="
        vPackageService.packageOverview.type == 'vECUPKG'
          ? columnDefsApps
          : columnDefs
      "
    [defualtColDef]="defaultColDef"
  >
  </app-custom-grid>
</wb-tab-content>
<wb-tab-content name="info" slot="content" [selected]="selectedTabId == 'info'" style="padding: 0;">
  <wb-grid [fullWidth]="true">
    <wb-grid-row>
      <wb-grid-col mq6="12">
        <wb-heading class="spacing-xs" size="s" tag="div">
          Package information
        </wb-heading>
      </wb-grid-col>

      <wb-grid-col mq6="3">
        <wb-text class="spacing-bot-16" size="m" tag="div"> Name:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> ID:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> Type:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"
                 *ngIf="this.vPackageService.packageOverview.ecu_package_link">
          ECU package link:
        </wb-text>
        <wb-text
          class="spacing-bot-16"
          size="m"
          tag="div"
          *ngIf="this.vPackageService.packageOverview.packages?.length"
        >
          Contained vPackages:
        </wb-text>
        <wb-text
          class="spacing-bot-16"
          size="m"
          tag="div"
          *ngIf="this.vPackageService.packageOverviewAppCount"
        >
          Contained applications:
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          Created by:
        </wb-text>
        <wb-text size="m" tag="div">
          Created at:
        </wb-text>
      </wb-grid-col>
      <wb-grid-col mq6="3">
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.vPackageService.packageOverview.name }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.vPackageService.packageOverview.id }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.vPackageService.packageOverview.type }}
        </wb-text>
        <div>
          <div data-on="main" class="spacing-bot-16">
            <wb-link
              href="/package-management/packages/{{this.vPackageService.packageOverview.ecu_package_link}}/overview/content"
              variant="standalone" size="l">
              {{ this.vPackageService.packageOverview.ecu_package_link }}
            </wb-link>
          </div>
        </div>
        <wb-text
          class="spacing-bot-16"
          size="m"
          tag="div"
          *ngIf="this.vPackageService.packageOverview.packages?.length"
        >
          {{ this.vPackageService.packageOverview.packages?.length }}
        </wb-text>
        <wb-text
          class="spacing-bot-16"
          size="m"
          tag="div"
          *ngIf="this.vPackageService.packageOverviewAppCount"
        >
          {{ this.vPackageService.packageOverviewAppCount }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{ this.vPackageService.packageOverview.created_by }}
        </wb-text>
        <wb-text size="m" tag="div">
          {{ this.vPackageService.packageOverview.created_at }}
        </wb-text>
      </wb-grid-col>
    </wb-grid-row>
    <wb-grid-row *ngIf="vPackageService.packageOverview.type == 'vECUPKG'">
      <wb-grid-col mq6="12">
        <wb-heading class="spacing-bot-24 spacing-top-xs" size="s" tag="div">
          Application instance
        </wb-heading>
      </wb-grid-col>

      <wb-grid-col mq6="3">
        <wb-text class="spacing-bot-16" size="m" tag="div">
          Diagnostic name:
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> NCD name:</wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div"> Level:</wb-text>
      </wb-grid-col>
      <wb-grid-col mq6="3">
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{
            this.vPackageService.packageOverview.application_instances
              ?.diagnostic_name
          }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{
            this.vPackageService.packageOverview.application_instances
              ?.ncd_name
          }}
        </wb-text>
        <wb-text class="spacing-bot-16" size="m" tag="div">
          {{
            this.vPackageService.levelMap[this.vPackageService.packageOverview.application_instances ? this.vPackageService.packageOverview.application_instances.vecu_level : "lvl1"]
          }}
        </wb-text>
      </wb-grid-col>
    </wb-grid-row>
  </wb-grid>
</wb-tab-content>
<wb-tab-content name="releases" slot="content" [selected]="selectedTabId == 'releases'" style="padding: 0;">
  <app-vpackage-release-grid
    [rowData]="releaseData"
    [masterDetail]="true"
    [columnDefs]="
        vPackageService.packageOverview.type == 'vECUPKG'
          ? columnDefvECUReleases
          : columnDefPackageReleases
      "
    [detailCellRendererParams]="
        vPackageService.packageOverview.type == 'vECUPKG'
          ? detailCellRendererParamVECUReleases
          : detailCellRendererParamPackageReleases
      "
  ></app-vpackage-release-grid>
</wb-tab-content>
<wb-tab-content name="permissions" slot="content" [selected]="selectedTabId == 'permissions'" style="padding: 0;">
  <mbos-permissions
    domain="vtesting-packages"
    [resourceId]="this.vPackageService.packageOverview.id"
  ></mbos-permissions>
</wb-tab-content>
