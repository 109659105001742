import {Component, OnDestroy, OnInit} from '@angular/core';
import {Route, Router} from '@angular/router';
import {VpackagereleaseService} from '../package/services/vpackagerelease.service';
import {HeaderTitleService} from '../services/header/header-title.service';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {NotificationService} from '../services/notification/notification.service';
import {Subscription} from 'rxjs';
import {ModalService} from '../services/modal/modal.service';
import {VpackageService} from "../package/services/vpackage.service";

@Component({
  selector: 'app-release',
  templateUrl: './package-release.component.html',
  styleUrls: ['./package-release.component.css'],
})
export class PackageReleaseComponent implements OnInit, OnDestroy {
  title = 'Create new vECU package release';
  description =
    'Create a new vECU package release. Enter some basic data and add vECU applications or vECU packages to your new vECU package.';
  primaryButtonText = 'Next';
  secondaryButtonText = '';
  tertiaryButtonText = 'Cancel';

  currentStep: number = 1;
  isInfosim: boolean = this.vPackageReleaseService.packageOverview?.provider == 'infosim';

  routerConfig: Route[] = [];
  routeSubscription: Subscription = this.router.events.subscribe(
    () => (this.routerConfig = this.router.config)
  );

  discardModalTitle: string = `Discard create vECU package release?`;
  discardModalDescription: string =
    'You will lose all unsaved changes made to the data.';
  discardModalSubscription: Subscription = this.modalService
    .confirmDiscard()
    .subscribe((discard) => {
      if (discard) {
        this.vPackageReleaseService.clearAll();
        this.router.navigate(['vpackages']);
      }
    });

  constructor(
    private router: Router,
    private modalService: ModalService,
    public headerService: HeaderTitleService,
    public breadcrumbService: BreadcrumbsService,
    public vPackageReleaseService: VpackagereleaseService,
    public notificationService: NotificationService,
    private vPackageService: VpackageService
  ) {
  }

  ngOnInit() {
    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    this.initHeaderActions();
    this.initBreadcrumbs();
  }

  private initHeaderActions() {
    this.headerService.resetHeaderActions();
    this.headerService.primaryButtonText = this.primaryButtonText;
    this.headerService.secondaryButtonText = this.secondaryButtonText;
    this.headerService.tertiaryButtonText = this.tertiaryButtonText;

    this.headerService.secondaryClick$.subscribe(() => {
      this.backStep();
    });
    this.headerService.tertiaryClick$.subscribe(() => {
      this.cancel();
    });

    setTimeout(() => this.setPrimaryClick(), 500);
  }

  private initBreadcrumbs() {
    if (this.vPackageService.packageOverview.name) {
      this.breadcrumbService.setBreadcrumbs([
        {
          label: 'vECU packages',
          route: '/vpackages',
        },
        {
          label: this.vPackageService.packageOverview.name!,
          route: '/vpackages/vpackage/' + this.vPackageService.packageOverview.id + '/overview',
        },
        {label: 'Create new release', route: ''}
      ])
    } else {
      this.router.navigate(['vpackages']);
    }
  }

  setPrimaryClick(): void {
    this.headerService.primaryClick$.subscribe(() => {
      this.nextStep();
    });
  }

  nextStep(): void {
    this.currentStep++;
    this.headerService.secondaryButtonText = 'Back';
    if (this.currentStep != 4) {
      this.router.navigate(['vpackagerelease', this.findPath()]);
    }
  }

  backStep(): void {
    this.currentStep--;
    if (this.currentStep == 2) {
      this.headerService.clearPrimaryClickFunction();
      this.headerService.primaryButtonText = this.primaryButtonText;
      this.headerService.primaryClick$.subscribe(() => {
        this.nextStep();
      });
    }
    this.headerService.secondaryButtonText = this.currentStep === 1 ? '' : 'Back';
    this.router.navigate(['vpackagerelease', this.findPath()]);
  }


  findPath(): string {
    const route = this.routerConfig.find(
      (route) => route.path === 'vpackagerelease'
    );

    return (
      route?.children?.find(
        (child) => child.data?.['step'] === this.currentStep
      )?.path || ''
    );
  }

  ngOnDestroy(): void {
    // this.vPackageReleaseService.clearAll(); //vtp-1649
    this.discardModalSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  cancel(): void {
    this.modalService.initializeCustomModal(
      'discard',
      this.discardModalTitle,
      this.discardModalDescription
    );
  }
}
