<wb-grid [fullWidth]="true">
  <wb-grid-row class="spacing-s">
    <wb-grid-col mq6="5">
      <wb-heading size="xs" class="spacing-xs">Base data</wb-heading>
      <wb-input
        as="input"
        [(ngModel)]="this.vPackageReleaseService.packageReleasePost!.name"
        name="name"
        ngDefaultControl
        inputmode="text"
        required
        #name="ngModel"
        [error]="
          validationService.isFieldInvalid(
            formGroup.name,
            name,
            this.vPackageReleaseService.packageReleasePost!.name,
            format.isInputAlphaNumericUnderscoreFormatReleasepackage
          )
        "
        class="spacing-xs"
      >
        <span slot="label">Package release name*</span>
        <div slot="message">
          <app-validation-notification
            name="Release name"
            [type]="format.None"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.name,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.name,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
          <app-validation-notification
            name="Release name"
            [type]="format.isInputAlphaNumericUnderscoreFormatReleasepackage"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.name,
                validationStatus.Regex
              ) &&
              validationService.getFormStatus(
                this.formGroup.name,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-input>
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="release"
          name="releaseType"
          ngDefaultControl
          selection-count-suffix="groups selected"
          required
          #releaseType="ngModel"
          [error]="this.validationService.isComboBoxInValid(
            this.formGroup.releaseType,
            releaseType,
            this.release,
            this.releaseOptions)"
          (wbcomboboxinput)="onReleaseTypeInputChange($event)"
        >
          <label slot="label">Release type*</label>
        </wb-combobox-input>
        <wb-combobox-list
          (wbcomboboxlistselectionchange)="onReleaseTypeSelectChange($event)"
        >
          <wb-combobox-list-item value="beta">beta</wb-combobox-list-item>
          <wb-combobox-list-item value="release_candidate">
            release-candidate
          </wb-combobox-list-item>
          <wb-combobox-list-item value="release">release</wb-combobox-list-item>
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="Release type"
            [type]="format.Pick"
            *ngIf="
                    validationService.getFormStatus(this.formGroup.releaseType, validationStatus.Invalid) &&
                    validationService.getFormStatus(this.formGroup.releaseType, validationStatus.Touched)">
          </app-validation-notification>
        </div>
      </wb-combobox>
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="modelSeriesSelected"
          name="modelSeries"
          ngDefaultControl
          required
          #modelSeries="ngModel"
          [error]="this.validationService.isComboBoxInValid(
            this.formGroup.modelSeries,
            modelSeries,
            this.modelSeriesSelected,
            this.modelSeriesList)"
          (wbcomboboxinput)="onReleaseModelSeriesInputChange($event)"
        >
          <label slot="label">Model Series</label>
        </wb-combobox-input>
        <wb-combobox-list
          (wbcomboboxlistselectionchange)="onReleaseModelSeriesSelectChange($event)"
        >
        <wb-combobox-list-item
            *ngFor="let line of this.vPackageReleaseService.packageOverview?.model_series"
            [value]="line.name"
          >
            {{ line.name }}
          </wb-combobox-list-item>
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="Model Series"
            [type]="format.Pick"
            *ngIf="
                    validationService.getFormStatus(this.formGroup.modelSeries, validationStatus.Invalid) &&
                    validationService.getFormStatus(this.formGroup.modelSeries, validationStatus.Touched)">
          </app-validation-notification>
        </div>
      </wb-combobox>
      <wb-combobox class="spacing-xs" *ngIf="this.vPackageReleaseService.packageReleasePost.application_instances">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="
            this.vPackageReleaseService.packageReleasePost!.variant_info
          "
          name="Variant_Info"
          ngDefaultControl
          required
          #VariantInfo="ngModel"
          [error]="this.validationService.isComboBoxInValid(
              this.formGroup.variantInfo,
              VariantInfo,
              this.vPackageReleaseService.packageReleasePost!.variant_info!,
              this.VariantInfos
            )
          "
          (wbcomboboxinput)="onVariantInfoInputChange($event)"
        >
          <label slot="label">Diagnostic variant*</label>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No diagnostic variant info selected"
          no-items-found-text="No diagnostic variant info found"
          (wbcomboboxlistselectionchange)="onVariantInfoSelectChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let variant_info of VariantInfos"
            value="{{ variant_info }}"
          >{{ variant_info }}
          </wb-combobox-list-item
          >
        </wb-combobox-list>
        <div slot="message">
          <app-validation-notification
            name="Diagnostic variant info"
            [type]="format.Pick"
            *ngIf="
              validationService.getFormStatus(
                this.formGroup.variantInfo,
                validationStatus.Invalid
              ) &&
              validationService.getFormStatus(
                this.formGroup.variantInfo,
                validationStatus.Touched
              )
            "
          >
          </app-validation-notification>
        </div>
      </wb-combobox>
      <wb-combobox class="spacing-xs">
        <wb-combobox-input
          slot="input"
          [(ngModel)]="
            this.vPackageReleaseService.packageReleasePost!.ecu_package_release_link
          "
          name="hardwarelink"
          ngDefaultControl
          #hardwarelink="ngModel"
          (wbcomboboxinput)="onHardwareLinkInputChange($event)"
        >
          <label slot="label">ECU package release link (optional) </label>
        </wb-combobox-input>
        <wb-combobox-list
          no-items-selected-text="No hardware link selected"
          no-items-found-text="No hardware link found"
          (wbcomboboxlistselectionchange)="onEcuReleaseLinkSelectChange($event)"
        >
          <wb-combobox-list-item
            *ngFor="let ecuPackageRelease of ecuPackageReleases"
            value="{{ ecuPackageRelease.id }}"
          >{{ ecuPackageRelease.name }}
          </wb-combobox-list-item
          >
        </wb-combobox-list>
      </wb-combobox>
    </wb-grid-col>
  </wb-grid-row>
</wb-grid>
