<div *ngIf="vPackageService.spinner">
  <wb-spinner class="centered-element" theme="light" style="width: 128px; height: 128px"></wb-spinner>
</div>
<wb-grid [fullWidth]="true" *ngIf="!vPackageService.spinner">
  <main>
    <wb-stepper [preventInteraction]="true">
      <wb-step
        #step1
        aria-label="Step 1: Base data"
        [status]="currentStep > 1 ? 'done' : ''"
      >Base data
      </wb-step
      >
      <wb-step
        #step2
        aria-label="Step 2: Packages"
        [status]="currentStep > 2 ? 'done' : ''"
      >Packages
      </wb-step
      >
      <wb-step
        #step3
        aria-label="Step 3: Application"
        [status]="currentStep > 3 ? 'done' : ''"
      >Applications
      </wb-step
      >
      <wb-step
        #step4
        aria-label="Step 4: Summary"
        [status]="currentStep != 4 ? 'disabled' : ''"
      >Summary
      </wb-step
      >
    </wb-stepper>
    <router-outlet></router-outlet>
  </main>
</wb-grid>
