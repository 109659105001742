<wb-tab-content
  name="my-vecus"
  slot="content"
  [selected]="selectedTabId == 'my-vecus'"
  style="padding: 0"
>
  <app-grid-by-user
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
  ></app-grid-by-user>
</wb-tab-content>
<wb-tab-content name="all-vecus" slot="content" [selected]="selectedTabId == 'all-vecus'" style="padding: 0">
  <app-custom-grid
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
  >
  </app-custom-grid>
</wb-tab-content>
