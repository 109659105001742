import {VtestingJobService} from 'src/app/test-job/services/vtesting-job.service';
import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderTitleService} from '../../../services/header/header-title.service';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {CellClickedEvent, ColDef, GetDataPath, ICellRendererParams, ValueGetterParams,} from 'ag-grid-community';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {CustomCellRendererComponent} from '../../../components/row-actions/row-actions/row-actions.component';
import {NotificationService} from 'src/app/services/notification/notification.service';
import * as moment from 'moment';
import {CoSimulationBundleGet, SimulationBundleGet,} from '@vtp/vtpcap-client-ts';
import {Subscription} from 'rxjs';
import {SimulationBuilderService} from '../../services/simulation-builder.service';

@Component({
  selector: 'app-simulation-overview',
  templateUrl: './simulation-overview.component.html',
  styleUrl: './simulation-overview.component.css',
})
export class SimulationOverviewComponent implements OnDestroy {
  delete() {
    throw new Error('Method not implemented.');
  }

  title = 'Simulations overview';
  description =
    'The simulation builder enables you to configure complex simulations for system testing.';
  primaryButtonText = 'Create simulation';
  buttonStatus: boolean = false;
  buttonDeleteStatus: boolean = true;
  deleteStatus: boolean = false;
  isAreaChecked: boolean = false;
  checkedStatus: boolean = false;
  EditMode: boolean = false;
  SimulationBundleName: string = '';
  deleteSelectedData: string[] = [];
  rowData?: any[];
  data: string[] = [];
  SimulationBundles: string[] = [];

  selectedTabId!: string;
  subscription!: Subscription;

  constructor(
    private router: Router,
    private capabilitiesService: CapabilitiesService,
    public headerService: HeaderTitleService,
    public breadcrumbService: BreadcrumbsService,
    private notificationService: NotificationService,
    private vTestingJobService: VtestingJobService,
    private simulationBuilderService: SimulationBuilderService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnDestroy() {
    this.breadcrumbService.breadcrumbs.pop();
    this.headerService.setTabs([]);
    this.subscription.unsubscribe();
  }

  public columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 3,
      cellRenderer: (params: ICellRendererParams) => {
        if (params.data.type != 'Co-Sim' && params.data.id.length > 1) {
          return `&emsp; <wb-link variant="standalone" (click)="">${params.value}</wb-link>`;
        } else {
          return `<wb-link variant="standalone" (click)="">${params.value}</wb-link>`;
        }
      },
      onCellClicked: (event: CellClickedEvent) => this.onCellClicked(event),
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      flex: 2,
      valueGetter: this.ValueGetter,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
    },
    {
      field: 'model_series',
      headerName: 'Model series',
      flex: 2,
    },
    {
      field: 'release_information.count',
      headerName: 'Number of releases',
      flex: 2,
      comparator: (valueA, valueB) => valueA - valueB,
    },
    {
      field: 'release_information.latest.name',
      headerName: 'Newest release',
      flex: 2,
    },

    {
      field: '',
      headerName: 'Actions',
      sortable: false,
      filter: false,
      flex: 1,
      cellRenderer: CustomCellRendererComponent,
      cellRendererParams: (params: ICellRendererParams) => ({
        deleteCallback: () => this.onDeleteClicked(params.data), // Pass a callback function to handle delete
      }),
    },
  ];

  ValueGetter(params: ValueGetterParams) {
    return moment(params.data.created_at)
      .utc(true)
      .format('DD-MM-YYYY HH:mm:ss');
  }

  public defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
  };

  public getDataPath: GetDataPath = (data: any) => {
    return data.id;
  };

  public autoGroupColumnDef: ColDef = {
    headerName: ' ',
    field: 'id',
    flex: 4,
    cellRendererParams: {suppressCount: true},
    resizable: true,
    sortable: false,
    filter: false,
    suppressMovable: true,
    maxWidth: 40,
    menuTabs: [],
    valueFormatter: () => '',
  };

  onCellClicked(event: CellClickedEvent): void {
    this.simulationBuilderService.simulationId = '';
    this.simulationBuilderService.simulationOverview = undefined;
    this.simulationBuilderService.simulationId = event.data.id[event.data.id.length - 1];
    this.simulationBuilderService.simulationOverview = event.data;
    this.showSimulation(
      this.simulationBuilderService.simulationId!,
      event.data.type
    );
  }

  showSimulation(id: string, type: string): void {
    if (type == 'Co-Sim') {
      this.router.navigate([`co-simulation/${id}/overview`]);
    } else {
      this.router.navigate([`simulation/${id}/overview`]);
    }
  }

  getNewRows(): void {
    this.capabilitiesService.getSimulationBundleResolved().subscribe({
      next: (simulationBundles: any) => {
        this.capabilitiesService.getCoSimulationBundles().subscribe({
          next: (coSimulationBundles: any) => {
            this.rowData = [];
            coSimulationBundles.data.items.forEach(
              (coSimulationBundle: CoSimulationBundleGet) => {
                let rowDataItem = {
                  id: [coSimulationBundle.id],
                  name: coSimulationBundle.name,
                  created_at: coSimulationBundle.created_at,
                  created_by: coSimulationBundle.created_by,
                  type: 'Co-Sim',
                  model_series: '-',
                  release_information: {
                    count: coSimulationBundle.revision,
                    latest: {
                      name: coSimulationBundle.latest_revision,
                    },
                  },
                };
                this.rowData?.push(rowDataItem);
                coSimulationBundle.simulation_bundle_ids.forEach(
                  (simulationBundleId: string) => {
                    let matchingSimulationBundle =
                      simulationBundles.data.items.find(
                        (simulationBundle: SimulationBundleGet) =>
                          simulationBundle.id == simulationBundleId
                      );
                    if (matchingSimulationBundle && matchingSimulationBundle.id) {
                      this.rowData?.push({
                        id: [coSimulationBundle.id, matchingSimulationBundle.id],
                        name: matchingSimulationBundle.name,
                        created_at: matchingSimulationBundle.created_at,
                        created_by: coSimulationBundle.created_by,
                        type: 'Sim',
                        model_series: matchingSimulationBundle.model_series,
                        release_information: {
                          count: matchingSimulationBundle.revision,
                          latest: {
                            name: matchingSimulationBundle.latest_revision,
                          },
                        },
                      });
                    }
                    simulationBundles.data.items =
                      simulationBundles.data.items.filter(
                        (sb: SimulationBundleGet) =>
                          sb != matchingSimulationBundle
                      );
                  }
                );
              }
            );
          },
          error: (error) => {
            this.notificationService.handleError(error);
          },
        });
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  onDeleteClicked(data: any): void {
    this.deleteSelectedData.push(data.id);
    this.SimulationBundleName = data.name;
    this.buttonStatus = true;
    this.data = data;
    this.deleteSelectedData = [];

    setTimeout(() => this.getNewRows(), 500);
  }

  ngOnInit(): void {
    this.headerService.setTabs([
      {label: 'My simulations', id: 'my-simulations'},
      {label: 'All simulations', id: 'all-simulations'},
    ]);
    if (this.activatedRoute.snapshot.queryParamMap.get('tab')) {
      this.headerService.setSelectedTab({label: '', id: this.activatedRoute.snapshot.queryParamMap.get('tab')!})
    }
    this.subscription = this.headerService.selectedTab$.subscribe(
      (tab) => {
        this.breadcrumbService.removeAboveIndex(0);
        this.breadcrumbService.breadcrumbs.push({
          label: (tab == 'all-simulations' ? 'All' : 'My') + ' simulations',
          route: ''
        });
        this.selectedTabId = tab
      }
    );
    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    this.initHeaderActions();
    this.initBreadcrumbs();
    this.getSimulationBundlesList();
    this.getNewRows();
  }

  private initHeaderActions() {
    this.headerService.resetHeaderActions();
    this.headerService.primaryButtonText = this.primaryButtonText;
    setTimeout(() => this.setPrimaryClick(), 500);
  }

  private initBreadcrumbs() {
    let tab = this.activatedRoute.snapshot.queryParamMap.get('tab');

    this.breadcrumbService.setBreadcrumbs([{label: 'Simulations', route: '/simulations'}])
    if (tab) {
      this.breadcrumbService.breadcrumbs.push({
        label: (tab == 'all-simulations' ? 'All' : 'My') + ' simulations',
        route: ''
      });
    } else {
      this.breadcrumbService.breadcrumbs.push({
        label: 'My simulations',
        route: ''
      });
    }
  }

  setPrimaryClick(): void {
    this.headerService.primaryClick$.subscribe(() => {
      this.vTestingJobService.setEditMode(this.EditMode);
      this.startProcess(); // Call the onNextClick function when the event is triggered
    });
  }

  checkBoxArea(event: any): void {
    if (event.target.checked) {
      this.buttonDeleteStatus = true;
      this.isAreaChecked = false;
      this.checkedStatus = true;
    } else {
      this.buttonDeleteStatus = false;
      this.isAreaChecked = true;
      this.checkedStatus = false;
    }
  }

  deleteSimulationBundleResolved(data: any): void {
    this.buttonStatus = false;
    this.buttonDeleteStatus = true;

    data.type == "Sim" ? this.deleteSimulationBundle(data) : this.deleteCoSimulationBundle(data)

    setTimeout(() => this.getNewRows(), 500);
  }

  cancelVEcuTestJob(): void {
    this.buttonStatus = false;
    this.buttonDeleteStatus = true;
  }

  closeModal(): void {
    this.buttonStatus = false;
    this.buttonDeleteStatus = true;
  }

  deleteSimulationBundle(simulation_bundle: any): void {
    this.deleteStatus = true;
    this.SimulationBundles = this.SimulationBundles.filter(
      (j) => j !== simulation_bundle
    );
    this.capabilitiesService
      .deleteSimulationBundle(simulation_bundle.id)
      .subscribe({
        next: (response) => {
          this.notificationService.notifySuccess = true;
          this.notificationService.notificationSuccesMessage = response.message;
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });
    this.deleteStatus = false;
  }

  deleteCoSimulationBundle(coSimulationBundle: any): void {
    this.capabilitiesService
      .deleteCoSimulationBundle(coSimulationBundle.id)
      .subscribe({
        next: (response) => {
          this.notificationService.notifySuccess = true;
          this.notificationService.notificationSuccesMessage = response.message;
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });
  }

  getSimulationBundlesList(): void {
    this.capabilitiesService.getSimulationBundleResolved().subscribe({
      next: (element) => {
        element.data.items.forEach((item: any) =>
          this.SimulationBundles.push(item)
        );
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  startProcess(): void {
    this.router.navigate(['simulation/general']);
  }
}
