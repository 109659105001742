import {saveAs} from 'file-saver';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {
  BropService,
  Configuration as vtpcapConf,
  CoSimulationBundlePost,
  CoSimulationBundlesService,
  EcuService,
  FropService,
  FropType,
  ModelSeriesService,
  NCDReleasesService,
  NetworkService,
  PackagePost,
  PackageReleasePost,
  PackageReleasesService,
  PackagesService,
  SimulationBundleGet,
  SimulationBundlePost,
  SimulationBundlesService,
  ToolService,
  V1FropFropIdMatchPackagesGet200Response,
  V1PackagesPackageIdResolvedGet200Response,
  V1SimulationBundlesGet200Response,
} from '@vtp/vtpcap-client-ts';

import {NotificationService} from '../notification/notification.service';
import {V1FropGet200Response} from '@vtp/vtpcap-client-ts/model/v1FropGet200Response';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CapabilitiesService {
  apiUrl: string = '/api/vtpcap/' + environment.stage;

  constructor(
    private http: HttpClient,
    private capabilitiesEcuService: EcuService,
    private capabilitiesPackage: PackagesService,
    private capabilitiesPackageReleases: PackageReleasesService,
    private capabilitiesBrop: BropService,
    private fropService: FropService,
    private simulationBundleService: SimulationBundlesService,
    private coSimulationBundleService: CoSimulationBundlesService,
    private notificationService: NotificationService,
    private capabilitiesModelSeries: ModelSeriesService,
    private capabilitiesNetworkService: NetworkService,
    private capabiliesTools: ToolService,
    private ncdReleaseService: NCDReleasesService
  ) {
    //NILS
    const capConf = new vtpcapConf();
    capConf.basePath = this.apiUrl;
    this.capabilitiesEcuService.configuration = capConf;
    this.capabilitiesPackage.configuration = capConf;
    this.capabilitiesPackageReleases.configuration = capConf;
    this.capabilitiesBrop.configuration = capConf;
    this.fropService.configuration = capConf;
    this.capabilitiesNetworkService.configuration = capConf;
    this.capabilitiesModelSeries.configuration = capConf;
    this.simulationBundleService.configuration = capConf;
    this.coSimulationBundleService.configuration = capConf;
    this.capabiliesTools.configuration = capConf;
    this.ncdReleaseService.configuration = capConf;
  }

  getModelSeries(): Observable<any> {
    return this.capabilitiesModelSeries.v1ModelSeriesGet();
  }

  getEcus(modelSeries: string[]): Observable<any> {
    return this.capabilitiesPackage.v1PackagesGet(undefined, modelSeries);
  }

  getVPackagesForContents(modelSeries: string): Observable<any> {
    return this.capabilitiesPackage.v1PackagesGet('vECUPKG', [modelSeries]);
  }

  getDiagnosticNames(modelSeries: string[]): Observable<any> {
    return this.capabilitiesEcuService.v1EcusGet(modelSeries);
  }

  getAllVPackages() {
    return this.capabilitiesPackage.v1PackagesResolvedGet();
  }

  getAllVPackagesForModelseries(modelSeries: string[]) {
    return this.capabilitiesPackage.v1PackagesResolvedGet(undefined, modelSeries);
  }

  getSpecificPackage(packageId: string): Observable<any> {
    return this.capabilitiesPackage.v1PackagesPackageIdResolvedGet(packageId);
  }

  getTestTypes(modelSeries: string): Observable<any> {
    return this.capabilitiesBrop.v1BropGet([modelSeries]);
  }

  /** GET: Get dependenceis of the test type in the model series  */
  getSpecificTestType(modelSeries: string, testType: string): Observable<any> {
    return this.capabilitiesBrop.v1ModelSeriesModelSeriesBropTestTypeGet(
      modelSeries,
      testType
    );
  }

  getPackageReleases(modelSeries: string, packageId: string): Observable<any> {
    return this.capabilitiesPackageReleases.v1PackageReleasesResolvedGet(
      [modelSeries],
      packageId
    );
    // return this.capabilitiesPackageReleases.v1PackageReleasesGet([modelSeries], packageId);
  }

  getPackageReleasesSementicVersion(modelSeries: string, packageId: string): Observable<any> {
    return this.capabilitiesPackageReleases.v1PackageReleasesGet(
      [modelSeries],
      packageId
    );
    // return this.capabilitiesPackageReleases.v1PackageReleasesGet([modelSeries], packageId);
  }

  getResolvedPackageRelease(releaseId: string): Observable<any> {
    return this.capabilitiesPackageReleases.v1PackageReleasesPackageReleaseIdResolvedGet(releaseId);
  }

  getSpecificPackageRelease(packageReleaseId: string): Observable<any> {
    return this.capabilitiesPackageReleases.v1PackageReleasesPackageReleaseIdGet(
      packageReleaseId
    );
  }

  /* The catchError() operator intercepts an Observable that failed. The operator then passes the error to the error handling function. */

  postSimulationBundle(
    modelSeries: string,
    bundle: SimulationBundlePost
  ): Observable<any> {
    return this.simulationBundleService.v1SimulationBundlesPost(bundle);
  }

  /** GET: Get Simulation Bundle*/
  getSimulationBundle(simulation_bundle_id: any): Observable<any> {
    return this.simulationBundleService.v1SimulationBundlesSimulationBundleIdGet(
      simulation_bundle_id
    );
  }

  /** GET: Get Simulation Bundle resovled*/
  getSimulationBundleResolved(): Observable<any> {
    return this.simulationBundleService.v1SimulationBundlesResolvedGet();
  }

  /** DELETE: Delete Simulation Bundle */
  deleteSimulationBundle(simulation_bundle_id: any): Observable<any> {
    return this.simulationBundleService.v1SimulationBundlesSimulationBundleIdDelete(
      simulation_bundle_id,
      undefined,
      'application/json'
    );
  }

  putSimulationBundle(
    id: string,
    bundle: SimulationBundleGet
  ): Observable<any> {
    return this.simulationBundleService.v1SimulationBundlesSimulationBundleIdPut(
      id,
      bundle
    );
  }

  /** GET: returns a actual Package of the model series*/
  getPackageOfModelSeries(
    modelSeries: string,
    packageId: string
  ): Observable<any> {
    return this.capabilitiesPackage.v1PackagesPackageIdResolvedGet(packageId);
  }

  getEcuOfTopology(modelseries: string[], diagnosticName: string[]): Observable<any> {
    return this.capabilitiesEcuService.v1EcusGet(modelseries, diagnosticName);
  }

  /** GET: returns the ecu of the actual topology in the model series*/
  getEcuOfTopologyInModelSeries(
    modelSeries: string,
    diagnosticName: string,
    manifests: boolean
  ): Observable<any> {
    return this.capabilitiesEcuService.v1ModelSeriesModelSeriesEcusDiagnosticNameGet(
      modelSeries,
      diagnosticName,
      manifests
    );
  }

  /** GET: Get Tool List*/
  GetToolList(): Observable<any> {
    return this.capabiliesTools.v1ToolsGet();
  }

  /** GET: Get Tool */
  v1ToolsNameGet(name: any): Observable<any> {
    return this.capabiliesTools.v1ToolsNameGet(name);
  }

  postPackage(vPackage: PackagePost): Observable<any> {
    return this.capabilitiesPackage.v1PackagesPost(vPackage);
  }

  postPackageRelease(vPackageRelease: PackageReleasePost): Observable<any> {
    return this.capabilitiesPackageReleases.v1PackageReleasesPost(
      vPackageRelease
    );
  }

  /** GET:get the actual package of the model series*/

  getvPackage(id: any): Observable<any> {
    return this.capabilitiesPackage.v1PackagesPackageIdGet(id);
  }

  getVPackageResolved(
    id: any
  ): Observable<V1PackagesPackageIdResolvedGet200Response> {
    return this.capabilitiesPackage.v1PackagesPackageIdResolvedGet(id);
  }

  /*  PUT update the actual package of the model series */
  UpdatePackage(vPackageId: any, packagePut: any): Observable<any> {
    return this.capabilitiesPackage.v1PackagesPackageIdPut(
      vPackageId,
      packagePut
    );
  }

  uploadFile(file: FormData, url: string): Observable<any> {
    return this.http.post(url, file);
  }

  // *************************************DOWNLOAD DEFINITION*************************************
  downLoadFile(url: string, fileName: string) {
    this.http
      .get(url, {
        responseType: 'blob',
      })
      .subscribe({
        next: (res) => {
          saveAs(res, fileName + '.zip');
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });
  }

  getEventChainListOfModelSeries(
    modelSeries: string,
    fropId: string | undefined,
    type: FropType | undefined
  ): Observable<V1FropGet200Response> {
    return this.fropService.v1FropGet([modelSeries], fropId, type);
  }

  getFropById(modelSeries: string, fropId: string): Observable<any> {
    return this.fropService.v1ModelSeriesModelSeriesFropFropIdGet(
      modelSeries,
      fropId
    );
  }

  getMatchingPackagesOfEventChainList(
    fropId: string,
    modelSeries: string[]
  ): Observable<V1FropFropIdMatchPackagesGet200Response> {
    return this.fropService.v1FropFropIdMatchPackagesGet(fropId, modelSeries);
  }

  getEventChainListOfPackage(
    packageId: string
  ): Observable<V1FropGet200Response> {
    return this.fropService.v1PackagesPackageIdMatchFropGet(packageId);
  }

  getSimulationBundleListByPackageId(
    packageId: string
  ): Observable<V1SimulationBundlesGet200Response> {
    return this.simulationBundleService.v1SimulationBundlesGet(
      undefined,
      packageId
    );
  }

  postCoSimulationBundle(
    coSimulationBundle: CoSimulationBundlePost
  ): Observable<any> {
    return this.coSimulationBundleService.v1CoSimulationBundlesPost(
      coSimulationBundle
    );
  }

  getCoSimulationBundles(): Observable<any> {
    return this.coSimulationBundleService.v1CoSimulationBundlesGet();
  }

  deleteCoSimulationBundle(id: string): Observable<any> {
    return this.coSimulationBundleService.v1CoSimulationBundlesCoSimulationBundleIdDelete(id, undefined, 'application/json');
  }

  getNcdReleases(modelseries: string): Observable<any> {
    return this.ncdReleaseService.v1NcdReleasesGet([modelseries]);
  }

  getCoSimulationBundle(id: any): Observable<any> {
    return this.coSimulationBundleService.v1CoSimulationBundlesCoSimulationBundleIdGet(id);
  }

  getSimulationBundleById(id: string): Observable<any> {
    return this.simulationBundleService.v1SimulationBundlesSimulationBundleIdGet(id);
  }
}
