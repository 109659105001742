import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ColDef, ICellRendererParams, ValueFormatterParams} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {HeaderTitleService} from 'src/app/services/header/header-title.service';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {SimulationBuilderService} from 'src/app/simulation-builder/services/simulation-builder.service';

@Component({
  selector: 'app-simulation-bundle-detail',
  templateUrl: './simulation-bundle-detail.component.html',
  styleUrl: './simulation-bundle-detail.component.css',
})
export class SimulationBundleDetailComponent implements OnDestroy {
  constructor(
    private headerService: HeaderTitleService,
    public simulationBuilderService: SimulationBuilderService,
    private capabilitiesService: CapabilitiesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbsService,
    private notificationService: NotificationService
  ) {
    this.routerSubscription$ = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  simId: string | null = '';
  title: any;
  description: string = '';
  primaryButtonText: string = '';
  networks: string[] = [];
  testGroups: any[] = [];
  subFunctions: any[] = [];
  plantModels: any[] = [];

  selectedTabId!: string;
  subscription!: Subscription;
  routerSubscription$!: Subscription;

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    suppressMovable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
  };

  plantModelsColumnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
    }
  ];

  public columnDefsGroup: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 12,
    },
  ];

  simulatedEcuColumnDefs: ColDef[] = [
    {
      field: 'diagnostic_name',
      headerName: 'Diagnostic name',
      flex: 4,
    },
    {
      field: 'ncd_name',
      headerName: 'NCD name',
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
      flex: 3,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 3,
    },
    {
      field: 'networks',
      headerName: 'Network',
      flex: 3,
      valueFormatter: this.networksFormatter
    }
  ];

  ngOnDestroy() {
    this.headerService.setTabs([]);
    this.subscription.unsubscribe();
    this.routerSubscription$.unsubscribe();
  }

  private initializePlantModelGrid(simulationBundle: any) {
    let simulationBundleData;
    let simulationBundleType: 'Physic' | 'Generic';

    if (simulationBundle.plant_models && simulationBundle.plant_models.physics) {
      simulationBundleData = simulationBundle.plant_models.physics;
      simulationBundleType = "Physic";
    } else if (simulationBundle.plant_models && simulationBundle.plant_models.generics) {
      simulationBundleData = simulationBundle.plant_models.generics;
      simulationBundleType = "Generic"
    } else return;


    simulationBundleData.forEach((item: any) =>{
      if (this.plantModels.length == 0 || this.plantModels.findIndex(newPlantmodels => newPlantmodels.name == item.file_name && newPlantmodels.simulationFramework == item.simulationFramework) == -1) {
        this.plantModels.push({

          name: item.file_name,
          simulationFramework: item.simulation_framework,
          type: simulationBundleType!
        })
      } 
    } );
  }

  networksFormatter(params: ValueFormatterParams): string {
    return params.value.map((network: any) => network.name).join();
  }

  init() {
    this.headerService.setTabs([
      {label: 'Information', id: 'info'},
      {label: 'Permissions', id: 'permissions'},
    ]);
    if (this.activatedRoute.snapshot.queryParamMap.get('tab')) {
      this.headerService.setSelectedTab({label: '', id: this.activatedRoute.snapshot.queryParamMap.get('tab')!})
    }
    this.subscription = this.headerService.selectedTab$.subscribe(
      (tab) => (this.selectedTabId = tab)
    );
    this.simId = this.activatedRoute.snapshot.paramMap.get('id');
    this.capabilitiesService
      .getSimulationBundleById(this.activatedRoute.snapshot.paramMap.get('id')!)
      .subscribe({
        next: (response: any) => {
          this.simulationBuilderService.simulationOverview = response.data;
          this.simulationBuilderService.modelSeries =
            response.data.model_series;
        },
        complete: () => {
          this.prepareOverview();
          this.networks =
            this.simulationBuilderService.simulationOverview.test_environment.configuration.networks.map(
              (net: any) => net.name
            );
          if (
            this.simulationBuilderService.simulationOverview.test_environment
              .configuration.test_groups
          ) {
            this.testGroups =
              this.simulationBuilderService.simulationOverview.test_environment.configuration.test_groups.map(
                (group: any) => {
                  return {name: group};
                }
              );
          }

          if (
            this.simulationBuilderService.simulationOverview.test_environment
              .configuration.sub_functions
          ) {
            this.subFunctions =
              this.simulationBuilderService.simulationOverview.test_environment.configuration.sub_functions.map(
                (sf: any) => sf.sub_function
              );
          }
        },
        error: (error: any) => {
          this.notificationService.handleError(error);
          this.router.navigate(['/simulations'])
        }
      });
  }

  prepareOverview(): void {
    this.initializePlantModelGrid(this.simulationBuilderService.simulationOverview)
    this.title = this.simulationBuilderService.simulationOverview.name!;
    this.description =
      'Simulation | ' +
      this.simulationBuilderService.simulationOverview.model_series;

    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    this.headerService.primaryButtonText = this.primaryButtonText;
    const breadcrumb = {
      label: this.simulationBuilderService.simulationOverview.name!,
      route: '/simulation/' + this.simulationBuilderService.simulationOverview.id + '/overview',
    };
    if (this.breadcrumbService.breadcrumbs.length == 0) {
      this.breadcrumbService.breadcrumbs.push({
        label: 'Simulations',
        route: '/simulations',
      });
    }
    if (this.breadcrumbService.findIndexByLabel(breadcrumb.label) > -1) {
      this.breadcrumbService.removeAboveIndex(
        this.breadcrumbService.findIndexByLabel(breadcrumb.label)
      );
    } else {
      this.breadcrumbService.breadcrumbs.push(breadcrumb);
    }
    let tab = this.activatedRoute.snapshot.queryParamMap.get('tab');

    this.breadcrumbService.breadcrumbs.push(
      {
        label: tab == 'permissions' ? 'Permissions' : 'Information',
        route: ''
      }
    )
  }
}
