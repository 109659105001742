import {VirtualRemoteTestBenchVtestingJobService} from '../../services/remote-testbench-vtesting-job.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {Component, OnDestroy,} from '@angular/core';
import {DatePipe} from '@angular/common';

import {
  ColDef,
  GetDetailRowDataParams,
  ICellRendererParams,
  IDetailCellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import * as moment from 'moment';

import {HeaderTitleService} from 'src/app/services/header/header-title.service';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {
  ButtonCellRendererComponent
} from 'src/app/components/custom-cell-renderers/button-cell-renderer/button-cell-renderer.component';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {MyInfoIconComponent} from 'src/app/components/my-info-icon/my-info-icon.component';
import {GetVtestingJobRunJob} from '../../interfaces/VtestingJobRun/getVtestingJobRunJob';
import {GetVTestingJobRunDto} from '@vtp/vtpcfg-client-ts';
import {
  ButtonDetailCellRendererComponent
} from 'src/app/components/custom-cell-renderers/button-detail-cell-renderer/button-detail-cell-renderer.component';
import {ConfigurationService} from '../../../services/configuration/configuration.service';
import {Subscription} from 'rxjs';
import {ModalService} from '../../../services/modal/modal.service';

@Component({
  selector: 'app-remote-testbench-job-overview',
  // standalone: true,
  // imports: [],
  templateUrl: './remote-testbench-job-overview.component.html',
  styleUrl: './remote-testbench-job-overview.component.css'
})

export class VirtualRemoteTestBenchJobOverviewComponent implements ICellRendererAngularComp, OnDestroy {
  title = 'Remote Testbench Job';
  description =
    'A remote test bench is an instance where the user can manually test the SUT. The required tools and configurations housing the vECUs and plant models will be available on the created test bench in order to excecute the simulations.';
  primaryButtonText = 'Start Remote Testbench job';
  secondaryButtonText = 'Edit';
  tertiaryButtonText = 'Back';
  JobRunReportUrl: any[] = [];
  value: any;
  duration: any;
  testjobId: any;
  testJob: any[] = [];
  startedTime: any[] = [];
  finishedTime: any[] = [];
  myJobRunIdArray: any[] = [];
  index: any = 0;
  foundedIndex: any;
  current_stage: any;
  showJobDefinitionDownloadNotification = false;
  showTestDefinitionDownloadNotification = false;
  formatedDateObject = {};
  formateDateOnCreatedAt: any;
  detectedTimeZone: any;
  text: any;
  formattedDate: any;
  id: any;
  EditMode: boolean = false;
  testdefrunidarray: string[] = [];
  fetchRowData: any;

  packageName: string = '-';

  selectedTabId!: string;
  tabSubscription!: Subscription;
  routerSubscription$!: Subscription;
  private gridApi: any;

  modalSubscription: Subscription = this.modalService
    .confirmDiscard()
    .subscribe((discard) => {
      if (discard) {
        this.VirtualRemoteTestBenchVtestingJobService.runVTestingJob(this.testJob[0].id);
      }
    });

  constructor(
    protected VirtualRemoteTestBenchVtestingJobService: VirtualRemoteTestBenchVtestingJobService,
    private activedRoute: ActivatedRoute,
    private capabilityservice: CapabilitiesService,
    private configurationService: ConfigurationService,
    private router: Router,
    public headerService: HeaderTitleService,
    public breadcrumbService: BreadcrumbsService,
    public notificationService: NotificationService,
    public modalService: ModalService,
    private activatedRoute: ActivatedRoute
  ) {
    this.routerSubscription$ = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  agInit(params: ICellRendererParams): void {
    this.value = params.data.items.test_definition_runs.status;
    throw new Error('Method not implemented.');
  }

  refresh(): boolean {
    return false;
  }

  public columnDefs: ColDef[] = [
    {
      /*   field: 'started_at', */
      headerName: 'Date',
      cellRenderer: 'agGroupCellRenderer',
      /*      valueFormatter: this.dateFormatter, */
      valueGetter: this.ValueGetter,
      flex: 3
    },
    {
      field: 'id',
      /*  wrapText: true, */
      autoHeight: true,
      flex: 4,
      /* rowGroup: true */
      headerName: 'Job run ID',
    },
    // {
    //   field: 'duration',
    //   flex: 2,
    //   headerName: 'Duration',
    // },

    {
      field: 'status',
      flex: 2,
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === 'CANCELED') {
          /*   this.onCellClicked(this.getJobRunReport(params)); */
          return `<wb-tag variant="error">${params.value}</wb-tag>

          `;
        } else if (params.value === 'CREATED') {
          return `<wb-tag variant="primary">${params.value}</wb-tag>`;
        } else if (params.value === 'QUEUED') {
          return `<wb-tag variant="tertiary">${params.value}</wb-tag>`;
        } else if (params.value === 'RUNNING') {
          return `<wb-tag variant="warning">${params.value}</wb-tag>`;
        } else if (params.value === 'PROVISIONING') {
          return `<wb-tag variant="warning">${params.value}</wb-tag>`;
        }
        else if (params.value === 'PROVISIONED') {
          return `<wb-tag variant="success">${params.value}</wb-tag>`;
        }else if (params.value === 'STOPPED') {
          return `<wb-tag variant="warning">STOPPED</wb-tag>`;
        }else if (params.value === 'STARTED') {
          return `<wb-tag variant="success">STARTED</wb-tag>`;
        }
        params.value === 'FAILED';
        return `<wb-tag variant="error">${params.value}</wb-tag>
        `;
      },
    },
    {
      field: 'status_reason',
      headerName: 'Status reason',
      wrapText: true,
      autoHeight: true,
      sortable: false,
      flex: 3,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
    },
    // {
    //   field: 'current_stage',
    //   flex: 2,
    //   headerName: 'Current stage',
    //   cellRenderer: (params: ICellRendererParams) => {
    //     if (params.value === 0) {
    //       return '-';
    //     }
    //     return `${params.value}`;
    //   },
    // },
    {
      field: '',
      headerName: 'Actions',
      sortable: false,
      filter: false,
      menuTabs: [],
      maxWidth: 100,
      cellRenderer: ButtonCellRendererComponent,
      cellRendererParams: (params: ICellRendererParams) => ({
        cancelCallback: () => this.onCancelClickedEvent(params.data),
        downloadCallBack: () => this.onDownloadClickedEvent(params.data),
        // Pass a callback function to handle delete
      }),
    },
  ];

  ValueGetter(params: ValueGetterParams) {
    return moment(params.data.created_at)
      .utc(true)
      .format('DD-MM-YYYY HH:mm:ss');
  }

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  onGridReady(gridapi: any) {
    this.gridApi = gridapi;
  }

  refreshGridData(): void {
    const expandedRowIds: any[] = [];
    //Store the IDs of the expanded rows
    this.gridApi.forEachNode((node: any) => {
      if (node.expanded) {
        expandedRowIds.push(node.id);
      }
    });
    //fetch data
    this.fetchGridData(expandedRowIds);
    /*  this.gridApi.setRowData(this.fetchRowData); */
  }

  refreshRowsData(): void {
    // Ensure API is available
    if (!this.gridApi) {
    } else {
      this.refreshGridData();
    }
  }

  CancelJobRun(vtesting_job_id: any, vtesting_job_run_id: any): void {
    this.configurationService
      .cancelCurrentRunningVTestingJobRun(vtesting_job_id, vtesting_job_run_id)
      .subscribe({
        next: (response: any) => {
          this.notificationService.notifySuccess = true;
          this.notificationService.notificationSuccesMessage = response.message;
        },
        error: (error: any) => {
          this.notificationService.handleError(error);
        },
      });
    this.notificationService.notifySuccess = false;
  }

  terminateJobRun(vtesting_job_id: any, vtesting_job_run_id: any, instanceId: string): void {
    this.configurationService
      .terminateTestBench(vtesting_job_id, vtesting_job_run_id, instanceId)
      .subscribe({
        next: (response: any) => {
          this.notificationService.notifySuccess = true;
          this.notificationService.notificationSuccesMessage = response.message;
        },
        error: (error: any) => {
          this.notificationService.handleError(error);
        },
      });
    this.notificationService.notifySuccess = false;
  }

  startTestBench(vtesting_job_id: any, vtesting_job_run_id: any, instanceId: string): void {
    this.configurationService
      .startTestBench(vtesting_job_id, vtesting_job_run_id, instanceId)
      .subscribe({
        next: (response: any) => {
          this.notificationService.notifySuccess = true;
          this.notificationService.notificationSuccesMessage = response.message;
        },
        error: (error: any) => {
          this.notificationService.handleError(error);
        },
      });
    this.notificationService.notifySuccess = false;
  }

  stopTestBench(vtesting_job_id: any, vtesting_job_run_id: any, instanceId: string): void {
    this.configurationService
      .stopTestBench(vtesting_job_id, vtesting_job_run_id, instanceId)
      .subscribe({
        next: (response: any) => {
          this.notificationService.notifySuccess = true;
          this.notificationService.notificationSuccesMessage = response.message;
        },
        error: (error: any) => {
          this.notificationService.handleError(error);
        },
      });
    this.notificationService.notifySuccess = false;
  }

  onCancelClickedEvent(data: any): void {
    this.CancelJobRun(data.vtesting_job_id, data.id);
    setTimeout(() => this.refreshRowsData(), 500);
  }

  onTerminateClickedEvent(data: any): void {
    var instanceID = data.status_reason.split('(')[1];
    instanceID = instanceID.split('[')[0];
    this.terminateJobRun(data.test_results.job_id, data.test_results.run_id, instanceID);    
  }
  onstartClickedEvent(data: any): void {
    var instanceID = data.status_reason.split('(')[1];
    instanceID = instanceID.split('[')[0];
    this.startTestBench(data.test_results.job_id, data.test_results.run_id, instanceID);    
  }
  onstopClickedEvent(data: any): void {
    var instanceID = data.status_reason.split('(')[1];
    instanceID = instanceID.split('[')[0];
    this.stopTestBench(data.test_results.job_id, data.test_results.run_id, instanceID);    
  }

  onDownloadClickedEvent(data: any): void {
    this.getJobRunReport(this.testjobId, data.id);
    setTimeout(() => this.refreshRowsData(), 500);
  }

  onDownloadTestDefinitionClickedEvent(data: any): void {
    this.getTestDefRunReport(this.testjobId, data.id);
    setTimeout(() => this.refreshRowsData(), 500);
  }

  public detailCellRendererParams: any = {
    refreshStrategy: 'everything',

    detailGridOptions: {
      rowSelection: 'multiple',
      enableCellChangeFlash: true,

      columnDefs: [
        {
          field: 'name',
          headerName: 'Test definition name',
          flex: 2
        },
        // {
        //   field: 'duration',
        //   headerName: 'Test definition duration',
        //   flex: 2,
        // },
        {
          field: 'status',
          flex: 2,

          cellRenderer: (params: ICellRendererParams) => {
            if (params.value === 'CANCELED') {
              return `<wb-tag variant="error">${params.value}</wb-tag>`;
            } else if (params.value === 'CREATED') {
              return `<wb-tag variant="primary">${params.value}</wb-tag>`;
            } else if (params.value === 'QUEUED') {
              return `<wb-tag variant="tertiary">${params.value}</wb-tag>`;
            } else if (params.value === 'PROVISIONING') {
              return `<wb-tag variant="warning">${params.value}</wb-tag>`;
            }
            else if (params.value === 'RUNNING') {
              return `<wb-tag variant="warning">${params.value}</wb-tag>`;
            } else if (params.value === 'PROVISIONED') {
              return `<wb-tag variant="success">${params.value}</wb-tag>`;
            } else if (params.value === 'INTEGRATION') {
              return `<wb-tag variant="warning">${params.value}</wb-tag>`;
            } else if (params.value === 'WAITING_FOR_LICENSE') {
              return `<wb-tag variant="tertiary">WAITING FOR LICENSE</wb-tag>`;
            }else if (params.value === 'STOPPED') {
              return `<wb-tag variant="warning">STOPPED</wb-tag>`;
            }else if (params.value === 'STARTED') {
              return `<wb-tag variant="success">STARTED</wb-tag>`;
            }

            params.value === 'FAILED';
            return `<wb-tag variant="error">${params.value}</wb-tag>`;
          },
        },

        {
          field: 'status_reason',
          headerName: 'Status reason',
          sortable: false,
          wrapText: true,
          autoHeight: true,
          flex: 2,
          cellRenderer: (params: ICellRendererParams) => {
            return params.value ? params.value : '-';
          },
        },
        // {
        //   field: 'info',
        //   headerName: 'Information',
        //   sortable: false,
        //   cellRenderer: MyInfoIconComponent,
        //   flex: 2
        // },
        // {
        //   field: 'test_orchestration.execution_order',
        //   headerName: 'Stage',
        //   wrapText: true,
        //   autoHeight: true,
        //   flex: 1,
        //   cellRenderer: (params: ICellRendererParams) => {
        //     return params.value ? params.value : '-';
        //   },
        // },
        {
          field: 'status',
          headerName: 'Action',
          flex: 1,
          cellRenderer: ButtonDetailCellRendererComponent,
          cellRendererParams: (params: ICellRendererParams) => ({
            terminateCallback: () => this.onTerminateClickedEvent(params.data),
            startCallback: () => this.onstartClickedEvent(params.data),
            stopCallback: () => this.onstopClickedEvent(params.data)
            /*          cancelCallback: () => this.onCancelClickedEvent(params.data), */
            // downloadCallBack: () =>
            //   this.onDownloadTestDefinitionClickedEvent(params.data),
          }),
        },
      ],
      domLayout: 'autoHeight',
      defaultColDef: {
        resizable: true,
      },
    },
    getDetailRowData: (params: GetDetailRowDataParams) => {
      params.successCallback(params.data.test_definition_runs);
    },
  } as IDetailCellRendererParams<GetVTestingJobRunDto, GetVtestingJobRunJob>;

  public rowData!: GetVTestingJobRunDto[];

  fetchGridData(expandedRowIds?:any []) {
    this.configurationService.getAllJobRuns(this.testjobId).subscribe({
      next: (element: any) => {
        this.rowData = element.data.items;
        element.data.items.forEach((item: any) => {
          item.test_definition_runs.forEach((newItem: any) => {
            this.startedTime.push(newItem.started_at);
            this.finishedTime.push(newItem.finished_at);
            var jsonString = JSON.stringify(newItem.test_results);
            var jsonObject = JSON.parse(jsonString);
            jsonObject['job_id'] = item.vtesting_job_id;
            jsonObject['run_id'] = item.id;
            newItem.test_results = jsonObject;
          });
        });
        this.VirtualRemoteTestBenchVtestingJobService.setStartedTime(this.startedTime);
        this.VirtualRemoteTestBenchVtestingJobService.setFinishedTime(this.finishedTime);
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
      complete: () => {
        /*         return this.fetchRowData; */

        //Restore expanded rows
        this.gridApi.setRowData(this.rowData);
        setTimeout(() => {
          this.gridApi.forEachNode((node: any) => {
            if (expandedRowIds!.includes(node.id)) {
              node.setExpanded(true);
            }
          });
        }, 0);
      },
    });
  }

  manageLoadingSpinner(condition: boolean): void {
    if (condition) {
      this.VirtualRemoteTestBenchVtestingJobService.loadingSpinner = true;
      this.headerService.primaryDisabled = true;
      this.headerService.secondaryDisabled = true;
    } else {
      this.VirtualRemoteTestBenchVtestingJobService.loadingSpinner = false;
      this.headerService.primaryDisabled = false;
      this.headerService.secondaryDisabled = false;
    }
  }

  init(): void {
    this.headerService.setTabs([
      {label: 'Information', id: 'info'},
      {label: 'Permissions', id: 'permissions'},
    ]);
    if (this.activatedRoute.snapshot.queryParamMap.get('tab')) {
      this.headerService.setSelectedTab({label: '', id: this.activatedRoute.snapshot.queryParamMap.get('tab')!})
    }
    this.tabSubscription = this.headerService.selectedTab$.subscribe(
      // (tab) => (this.selectedTabId = tab)
      (tab) => {
        this.selectedTabId = tab;
        this.breadcrumbService.setBreadcrumbs([
          {
            label: 'Remote Testbench',
            route: '/remote-testbench',
          },
          {
            label: this.testJob[0].name,
            route: `/remote-testbench/remote-testbench-job/${this.testJob[0].id}/overview`,
          },
          {
            label: tab == 'info' ? "Information" : "Permissions",
            route: ''
          }
        ]);
      }
    );
    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    this.initHeaderActions();

    this.testjobId = this.activedRoute.snapshot.paramMap.get('id'); // currently Job ID from picked JOB
    this.VirtualRemoteTestBenchVtestingJobService.jobId = this.testjobId;
    this.manageLoadingSpinner(true);
    this.configurationService.getVTestingJob(this.testjobId).subscribe({
      next: (element) => {
        this.capabilityservice.getvPackage(element.data.test_definitions[0].test_object.package_id).subscribe({
          next: (response) => this.packageName = response.data.name,
          error: (error: any) => this.notificationService.handleError(error)
        })
        this.testJob.push(element.data);
        this.headerService.primaryButtonText = element.data.continuous ? '' : this.primaryButtonText;
        this.formateDateOnCreatedAt = moment(element.data.created_at).format(
          'YYYY-MM-DD hh:mm:ss'
        );

        this.formatedDateObject = {
          created_by: this.formateDateOnCreatedAt,
        };

        /*---------- date logic -----------*/
        this.detectedTimeZone =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
        let date = new Date(element.data.created_at);

        let output = date.toLocaleString('en-US', {
          timeZone: this.detectedTimeZone,
        });

        let datepipe: DatePipe = new DatePipe('en-US');
        this.formattedDate = datepipe.transform(output, 'dd-MM-YYYY HH:mm:ss');
      },
      complete: () => {
        this.initBreadCrumb();
        this.manageLoadingSpinner(false);
      },
      error: (error: any) => {
        this.notificationService.handleError(error);
        this.router.navigate(['/remote-testbench'])
      }
    });
    const date_started_at = new Date('2023-12-12T12:03:20.727663+00:00');
    const date_finished_at = new Date('2023-12-12T12:07:55.727663+00:00');
    let number1: number = Number(date_started_at);
    let number2: number = Number(date_finished_at);

    this.duration = number2 - number1;

    this.fetchGridData();
  }

  private initHeaderActions() {
    this.headerService.secondaryButtonText = this.secondaryButtonText;
    this.headerService.tertiaryButtonText = this.tertiaryButtonText;

    setTimeout(() => this.setPrimaryClick(), 500);
    this.headerService.secondaryClick$.subscribe(() => this.editGeneralData());
    this.headerService.tertiaryClick$.subscribe(() => this.backToOverview());
  }

  private initBreadCrumb() {
    let tab = this.activatedRoute.snapshot.queryParamMap.get('tab');
    this.breadcrumbService.setBreadcrumbs([
      {
        label: 'Remote Testbench',
        route: '/remote-testbench',
      },
      {
        label: this.testJob[0].name,
        route: `/remote-testbench/remote-testbench-job/${this.testJob[0].id}/overview`,
      }
    ]);
    if (tab) {
      this.breadcrumbService.breadcrumbs.push({
        label: tab == 'info' ? "Information" : "Permissions",
        route: ''
      });
    } else {
      this.breadcrumbService.breadcrumbs.push({
        label: 'Information',
        route: ''
      });
    }

  }

  ngOnDestroy() {
    this.headerService.setTabs([]);
    this.tabSubscription.unsubscribe();
    this.routerSubscription$.unsubscribe();
    this.modalSubscription.unsubscribe();
  }

  setPrimaryClick(): void {
    this.headerService.primaryClick$.subscribe(() => {
      this.startTestJobManually();
      this.refreshRowsData();
    });
  }

  loadData() {
    this.showJobDefinitionDownloadNotification = true;
  }

  loadTestDefData() {
    this.showTestDefinitionDownloadNotification = true;
  }

  getJobRunReport(testjobId: any, testdefrunid: any) {
    this.loadData();

    this.configurationService.getAllJobRuns(testjobId).subscribe({
      next: (element) => {
        element.data.items.forEach((item: any) => {
          this.myJobRunIdArray.push(item.id);
        });
        this.foundedIndex = this.myJobRunIdArray.indexOf(testdefrunid);
        this.myJobRunIdArray = [];
        this.configurationService
          .getJobRunReport(testjobId, element.data.items[this.foundedIndex].id)
          .subscribe({
            next: (Element) => {
              this.JobRunReportUrl = Element.data.url;
              this.onDownload(this.JobRunReportUrl);
              this.showJobDefinitionDownloadNotification = false;
            },
            error: (error) => {
              this.notificationService.handleError(error);
            },
          });
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  getTestDefRunReport(vtesting_job_id: any, test_definition_run_id: any) {
    this.loadTestDefData();
    this.configurationService.getAllJobRuns(vtesting_job_id).subscribe({
      next: (element) => {
        element.data.items.forEach((item: any) => {
          this.myJobRunIdArray.push(item.id);
          item.test_definition_runs.forEach((testdefitem: any) => {
            this.testdefrunidarray.push(testdefitem.id);
          });
        });

        element.data.items.forEach((item: any) => {
          item.test_definition_runs.forEach((testdefitem: any) => {
            if (testdefitem.id == test_definition_run_id) {
              this.id = this.myJobRunIdArray.indexOf(item.id);

              return this.id;
            } else if (testdefitem.id != test_definition_run_id) {
              return;
            }
          });
        });

        this.myJobRunIdArray = [];

        this.testdefrunidarray = [];

        this.configurationService
          .getTestDefRunReport(
            vtesting_job_id,
            element.data.items[this.id].id,
            test_definition_run_id
          )
          .subscribe({
            next: (element: any) => {
              let TestDefiRunReportUrl = element.data.url;

              this.onDownload(TestDefiRunReportUrl);
              this.showTestDefinitionDownloadNotification = false;
            },
            error: (error: any) => {
              this.notificationService.handleError(error);
            },
          });
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  onDownload(url: any) {
    this.capabilityservice.downLoadFile(
      url,
      moment().format('MM-DD-YYYYThh-mm-ss') +
      '-' +
      this.testJob[0].name +
      '-' +
      this.testJob[0].id
    );
  }

  editGeneralData(): void {
    this.EditMode = true;
    this.VirtualRemoteTestBenchVtestingJobService.setEditMode(this.EditMode);
    this.VirtualRemoteTestBenchVtestingJobService.setVtestingJobId(this.testjobId);
    this.router.navigate([`remote-testbench-job/general`]).then(
      () => {
        this.VirtualRemoteTestBenchVtestingJobService.setVtestingJobId(this.testjobId);
      },
      (err) => {
        this.notificationService.handleError(err);
      }
    );
  }

  private backToOverview() {
    this.router.navigate([`remote-testbench`]);
  }

  showPackage() {
    this.router.navigate(['vpackages/vpackage/' + this.testJob[0].test_definitions[0].test_object.package_id + '/overview'])
  }

  startTestJobManually() {
    this.EditMode = false;
    this.VirtualRemoteTestBenchVtestingJobService.setEditMode(this.EditMode);
    this.modalService.initializeCustomModal(
      'test-job-manually-run',
      'Start test job manually?',
      'You will start the test job manually.'
    );
    /*  this.refreshRowsData(); */
    /*  this.refreshRowsData(); */
  }
}

