import {VtestingJobService} from 'src/app/test-job/services/vtesting-job.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderTitleService} from '../../../services/header/header-title.service';
import {BreadcrumbsService} from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {CellClickedEvent, ColDef, ICellRendererParams,} from 'ag-grid-community';
import {CapabilitiesService} from 'src/app/services/capabilities/capabilities.service';
import {CustomCellRendererComponent} from '../../../components/row-actions/row-actions/row-actions.component';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {ConfigurationService} from "../../../services/configuration/configuration.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-app-job-overview',
  templateUrl: './test-jobs-overview.component.html',
  styleUrls: ['./test-jobs-overview.component.css'],
})
export class TestJobsOverviewComponent implements OnInit, OnDestroy {
  delete() {
    throw new Error('Method not implemented.');
  }

  title = 'vTesting jobs';
  description =
    'A vTesting job describes a testing campaign on different levels. A testing job can either be one-time or recurring. A one-time job is referencing a dedicated version of a system under test (SUT). A recurring job references the SUT itself and is continuously tested for every released version of it.';
  primaryButtonText = 'Create vTesting job';
  secondaryButtonText = '';
  tertiaryButtonText = '';
  buttonStatus: boolean = false;
  buttonDeleteStatus: boolean = true;
  vTestingJobs: any[] = [];
  isAreaChecked: boolean = false;
  checkedStatus: boolean = false;
  EditMode: boolean = false;
  vECUTestJobName: string = '';
  deleteSelectedData: string[] = [];
  rowData?: any[];
  filteredRowData?: any[] = [];
  data: string[] = [];

  selectedTabId!: string;
  subscription!: Subscription;

  constructor(
    private router: Router,
    private capabilitiesService: CapabilitiesService,
    private configurationService: ConfigurationService,
    public headerService: HeaderTitleService,
    public breadcrumbService: BreadcrumbsService,
    private notificationService: NotificationService,
    private vTestingJobService: VtestingJobService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  public columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      cellRenderer: (params: ICellRendererParams) => {
        return `<wb-link variant="standalone" (click)="">${params.value}</wb-link>`;
      },
      onCellClicked: (event: CellClickedEvent) => this.onCellClicked(event),
    },
    {
      field: 'basic_configuration.model_series',
      headerName: 'Model series',
      flex: 1,
    },
    {
      field: 'further_notes',
      headerName: 'Further notes',
      flex: 2,
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? params.value : '-';
      },
    },
    {
      field: '',
      headerName: 'Actions',
      sortable: false,
      filter: false,
      menuTabs: [],
      maxWidth: 100,
      cellRenderer: CustomCellRendererComponent,
      cellRendererParams: (params: ICellRendererParams) => ({
        deleteCallback: () => this.onDeleteClicked(params.data), // Pass a callback function to handle delete
      }),
    },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    suppressMovable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
  };
  public overlayNoRowsTemplate =
    '<span aria-live="polite" aria-atomic="true" style="padding: 10px; border: 2px solid #666; background: #55AA77;">This is a custom \'no rows\' overlay</span>';

  onCellClicked(event: CellClickedEvent): void {
    this.showJob(event.data);
  }

  getNewRows(): void {
    this.configurationService.getVTestingJobs().subscribe({
      next: (element: any) => {
        this.rowData = element.data.items;
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  onDeleteClicked(data: any): void {
    this.deleteSelectedData.push(data.name);
    this.vTestingJobService.setvECUTestJobName(this.deleteSelectedData[0]);
    this.vECUTestJobName = this.vTestingJobService.getvECUTestJobName();
    this.buttonStatus = true;
    this.data = data;
    this.deleteSelectedData = [];

    setTimeout(() => this.getNewRows(), 500);
  }

  fetchGridData() {
    this.configurationService.getVTestingJobs().subscribe({
      next: (element: any) => {
        this.rowData = element.data.items;
        this.filteredRowData = this.rowData;
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  ngOnInit(): void {
    this.headerService.setTabs([]);
    this.headerService.setTabs([
      {label: 'My vTesting job', id: 'my-jobs'},
      {label: 'All vTesting job', id: 'all-jobs'}
    ]);
    if (this.activatedRoute.snapshot.queryParamMap.get('tab')) {
      this.headerService.setSelectedTab({label: '', id: this.activatedRoute.snapshot.queryParamMap.get('tab')!})
    }
    this.subscription = this.headerService.selectedTab$.subscribe(tab => {
      this.breadcrumbService.removeAboveIndex(0);
      this.breadcrumbService.breadcrumbs.push({
        label: (tab == 'all-jobs' ? 'All' : 'My') + ' vTesting jobs',
        route: ''
      });
      this.selectedTabId = tab;
    });
    this.headerService.pageDescription = this.description;
    this.headerService.pageTitle = this.title;
    this.initHeaderActions();
    this.initBreadcrumbs();
    this.getVTestingJobs();
    this.fetchGridData();
  }

  private initHeaderActions() {
    this.headerService.resetHeaderActions();
    this.headerService.primaryButtonText = this.primaryButtonText;
    this.headerService.secondaryButtonText = this.secondaryButtonText;
    this.headerService.tertiaryButtonText = this.tertiaryButtonText;

    setTimeout(() => this.setPrimaryClick(), 500);
  }

  private initBreadcrumbs() {
    let tab = this.activatedRoute.snapshot.queryParamMap.get('tab');

    this.breadcrumbService.setBreadcrumbs([{label: 'vTesting jobs', route: '/vtesting-jobs'}]);
    if (tab) {
      this.breadcrumbService.breadcrumbs.push({
        label: (tab == 'all-jobs' ? 'All' : 'My') + ' vTesting jobs',
        route: ''
      });
    } else {
      this.breadcrumbService.breadcrumbs.push({
        label: 'My vTesting jobs',
        route: ''
      });
    }
  }

  ngOnDestroy() {
    this.headerService.setTabs([]);
    this.subscription.unsubscribe();
  }

  setPrimaryClick(): void {
    this.headerService.primaryClick$.subscribe(() => {
      this.vTestingJobService.setEditMode(this.EditMode);
      this.startProcess(); // Call the onNextClick function when the event is triggered
    });
  }

  checkBoxArea(event: any): void {
    if (event.target.checked) {
      this.buttonDeleteStatus = true;
      this.isAreaChecked = false;
      this.checkedStatus = true;
    } else {
      this.buttonDeleteStatus = false;
      this.isAreaChecked = true;
      this.checkedStatus = false;
    }
  }

  deleteVEcuTestJob(data: any): void {
    this.buttonStatus = false;
    this.buttonDeleteStatus = true;

    this.deleteVTestingJob(data);

    setTimeout(() => this.getNewRows(), 500);
  }

  cancelVEcuTestJob(): void {
    this.buttonStatus = false;
    this.buttonDeleteStatus = true;
  }

  closeModal(): void {
    this.buttonStatus = false;
    this.buttonDeleteStatus = true;
  }

  showJob(job: any): void {
    this.configurationService.getVTestingJob(job.id).subscribe({
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });

    this.router.navigate([`vtesting-jobs/vtesting-job/${job.id}/overview`]);
  }

  deleteVTestingJob(job: any): void {
    this.vTestingJobs = this.vTestingJobs.filter((j) => j !== job);
    this.configurationService.deleteVTestingJob(job.id).subscribe({
      next: (response) => {
        this.notificationService.notifySuccess = true;
        this.notificationService.notificationSuccesMessage = response.message;
        this.rowData = this.rowData?.filter((j) => j !== job);
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  getVTestingJobs(): void {
    this.configurationService.getVTestingJobs().subscribe({
      next: (element) => {
        element.data.items.forEach((item: any) => this.vTestingJobs.push(item));
      },
      error: (error) => {
        this.notificationService.handleError(error);
      },
    });
  }

  filterInputChange(event: any) {
      this.filteredRowData = this.rowData!.filter((node) =>
      node.name.trim().toLowerCase().includes(event.detail.value.trim().toLowerCase())
     );
  }
  
  startProcess(): void {
    this.router.navigate(['vtesting-job/general']);
  }
}
