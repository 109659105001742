<div *ngIf="!showDataTable">
  <wb-spinner
    class="centered-element"
    theme="light"
    style="width: 128px; height: 128px"
  ></wb-spinner>
</div>
<wb-grid [fullWidth]="true" *ngIf="showDataTable">
  <wb-grid-row>
    <wb-grid-col mq6="3" class="spacing-xs spacing-top-xs">
      <wb-heading size="xs">Release data</wb-heading>
      <wb-text size="l">Enter Package release information</wb-text>
    </wb-grid-col>
  </wb-grid-row>
  <app-custom-tree-selection-grid
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [getDataPath]="getDataPath"
    [mode]="'singleCategory'"
    (selectedRowData)="setSelectedReleases($event)"
  >
  </app-custom-tree-selection-grid>
  <!--
  <wb-grid-col
    mq6="4"
    *ngFor="
      let sa of this.vPackageService.packageOverview.packages;
      let i = index
    "
  >
    <wb-combobox class="spacing-xs">
      <wb-combobox-input slot="input" ngDefaultControl>
        <label slot="label"
        >Release for
          {{ this.vPackageService.packageOverview.packages![i].name }}*</label
        >
      </wb-combobox-input>
      <wb-combobox-list
        no-items-selected-text="No release selected"
        no-items-found-text="No release found"
      >
        <wb-combobox-list-item
          (wbcomboboxlistitemselectionchange)="
            onReleaseInputChange($event, i)
          "
          *ngFor="
            let release of releases[
              vPackageService.packageOverview.packages![i].id
            ]
          "
          [value]="release['id']"
        >{{ release["name"] }}
        </wb-combobox-list-item
        >
      </wb-combobox-list>
    </wb-combobox>
  </wb-grid-col>
  -->
</wb-grid>
