import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Route, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {HeaderTitleService} from "../../../services/header/header-title.service";
import {BreadcrumbsService} from "../../../services/breadcrumbs/breadcrumbs.service";
import {CapabilitiesService} from "../../../services/capabilities/capabilities.service";
import {NotificationService} from "../../../services/notification/notification.service";
import {VirtualRemoteTestBenchVtestingJobService} from "../../services/remote-testbench-vtesting-job.service";
import {ConfigurationService} from "../../../services/configuration/configuration.service";
import {ModalService} from "../../../services/modal/modal.service";
import { SubFunctionsItem } from '../../../../../node_modules/@vtp/vtpcap-client-ts/model/subFunctionsItem';

const MAX_STEPS = 3;

@Component({
  selector: 'app-remote-testbench-job-flow',
  // standalone: true,
  // imports: [],
  templateUrl: './remote-testbench-job-flow.component.html',
  styleUrl: './remote-testbench-job-flow.component.css'
})

export class VirtualRemoteTestBenchJobFlowComponent implements OnDestroy, OnInit {
  breadCrumb: { label: string, route: string } = {label: '', route: ''};
  primaryButtonText: string = 'Next';
  secondaryButtonText: string = 'Back';
  tertiaryButtonText: string = 'Cancel';
  currentStep: number = 1;
  routerConfig: Route[] = [];
  routeSubscription: Subscription = this.router.events.subscribe(() => this.routerConfig = this.router.config);

  discardModalTitle: string = `Discard ${(this.VirtualRemoteTestBenchVtestingJobService.getEditMode() ? "edit" : "create")} Remote Testbench ?`;
  discardModalDescription: string = "You will lose all unsaved changes made to the data.";
  discardModalSubscription: Subscription = this.modalService.confirmDiscard().subscribe(discard => {
    if (discard) {
      this.router.navigate(['remote-testbench']);
      this.VirtualRemoteTestBenchVtestingJobService.clearAll();
    }
  })

  @ViewChild('step1') step1Ref!: ElementRef;
  @ViewChild('step2') step2Ref!: ElementRef;
  @ViewChild('step3') step3Ref!: ElementRef;

  constructor(
    private router: Router,
    private headerService: HeaderTitleService,
    private breadCrumbService: BreadcrumbsService,
    private capabilitiesService: CapabilitiesService,
    private configurationService: ConfigurationService,
    private notificationService: NotificationService,
    private modalService: ModalService,
    public VirtualRemoteTestBenchVtestingJobService: VirtualRemoteTestBenchVtestingJobService
  ) {
  }

  ngOnInit() {
    this.setModeAndBreadcrumb();
    this.initHeaderActions();
    this.updateStatusBar();

    if (this.VirtualRemoteTestBenchVtestingJobService.getEditMode()) {
      this.configurationService.getVTestingJob(this.VirtualRemoteTestBenchVtestingJobService.getVtestingJobId()).subscribe({
        next: (testJob) => {
          this.VirtualRemoteTestBenchVtestingJobService.base_data = testJob.data;
          this.VirtualRemoteTestBenchVtestingJobService.setToggleStatus(!this.VirtualRemoteTestBenchVtestingJobService.base_data.test_definitions?.some((jobDefinition) => jobDefinition.test_object.package_release_id))
          this.VirtualRemoteTestBenchVtestingJobService.base_data.test_definitions?.forEach((testDefinition) => {
            this.VirtualRemoteTestBenchVtestingJobService.vtestingJobDefinitions.push(testDefinition);
            // testDefinition.test_scope.configuration.instance != "FROP" && this.capabilitiesService.getSimulationBundle(testDefinition.test_object.simulation_bundle_id).subscribe({
            //   next: (simulationBundle: any) => {
            //     this.VirtualRemoteTestBenchVtestingJobService.putSimulationBundles[testDefinition.id] = (simulationBundle.data);
            //     this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[testDefinition.id] = (simulationBundle.data);
            //   },
            //   error: (err) => {
            //     this.notificationService.handleError(err);
            //   },
            // });
            if ( testDefinition.test_scope.configuration.instance == "FROP") {
              if (testDefinition.test_object.co_simulation_bundle_id != null || testDefinition.test_object.co_simulation_bundle_id != undefined ) {
                this.capabilitiesService.getCoSimulationBundle(testDefinition.test_object.co_simulation_bundle_id).subscribe({
                  next: (simulationBundle: any) => {
                    this.VirtualRemoteTestBenchVtestingJobService.putSimulationBundles[testDefinition.id] = (simulationBundle.data);
                    this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[testDefinition.id] = (simulationBundle.data);                   
                    this.capabilitiesService.getSimulationBundle(simulationBundle.data.simulation_bundle_ids[0]).subscribe({
          
                      next: (simulationBundle1: any) => {
                        // simulationBundle.data.test_object.simulation_bundle_id = null;
                        simulationBundle.data.test_environment = simulationBundle1.data.test_environment;
                  
                        if (testDefinition.test_scope.configuration.system != null) {
                          simulationBundle.data.test_environment.test_type = "System";
                          simulationBundle.data.test_environment.configuration.system = testDefinition.test_scope.configuration.system;
                        }
                        if (testDefinition.test_scope.configuration.system != null && testDefinition.test_scope.configuration.functions!.length > 0) {
                          simulationBundle.data.test_environment.test_type = "Function";
                          simulationBundle.data.test_environment.configuration.function = testDefinition.test_scope.configuration.functions![0].name;
                        }
                        if (testDefinition.test_scope.configuration.system != null && testDefinition.test_scope.configuration.functions!.length > 0  && testDefinition.test_scope.configuration.functions![0].sub_functions!.length > 0 ) {
                          simulationBundle.data.test_environment.test_type = "Sub_Function";
                          if (simulationBundle.data.test_environment.configuration.subfunctions == null && this.VirtualRemoteTestBenchVtestingJobService.simulationBundles[testDefinition.id].test_environment.configuration.subfunctions == undefined) {
                            simulationBundle.data.test_environment.configuration.subfunctions = [];
                          }
                          for (let index = 0; index < testDefinition.test_scope.configuration.functions![0].sub_functions!.length; index++) {
                            const element = testDefinition.test_scope.configuration.functions![0].sub_functions![index];
                            var subfunction1: SubFunctionsItem = {}
                            subfunction1.subfunction = element.name;
                            simulationBundle.data.test_environment.configuration.subfunctions!.push(subfunction1)
                          }
                        }
                      },
                      error: (err) => {
                        this.notificationService.handleError(err);
                      },
                    });    
                  },
      
                  error: (err) => {
                    this.notificationService.handleError(err);
                  },
                });
              }
            }
          });
        },
        error: (error) => {
          this.notificationService.handleError(error);
        },
      });
    }
  }

  private initHeaderActions() {
    this.headerService.resetHeaderActions();
    this.headerService.primaryButtonText = this.primaryButtonText;
    this.headerService.secondaryButtonText = this.currentStep == 1 ? '' : 'Back';
    this.headerService.tertiaryButtonText = this.tertiaryButtonText;

    this.headerService.primaryClick$.subscribe(() => this.nextStep());
    this.headerService.secondaryClick$.subscribe(() => this.backOneStep());
    this.headerService.tertiaryClick$.subscribe(() => this.cancelCreateVTestingJob());
  }

  ngOnDestroy() {
    this.VirtualRemoteTestBenchVtestingJobService.clearAll();
    this.routeSubscription.unsubscribe();
    this.discardModalSubscription.unsubscribe();
  }

  setModeAndBreadcrumb(): void {
    this.VirtualRemoteTestBenchVtestingJobService.loadingSpinner = false;
    this.headerService.pageTitle = (this.VirtualRemoteTestBenchVtestingJobService.getEditMode() ? 'Edit' : 'Create') + ' Remote Testbench';
    this.headerService.pageDescription = 'A remote test bench is an instance where the user can manually test the SUT. The required tools and configurations housing the vECUs and plant models will be available on the created test bench in order to excecute the simulations.';
    this.breadCrumbService.setBreadcrumbs([
      {label: 'Remote Testbench', route: '/remote-testbench'},
      {
        label: (this.VirtualRemoteTestBenchVtestingJobService.getEditMode() ? 'Edit' : 'Create') + ' Remote Testbench',
        route: ''
      }
    ]);
  }

  nextStep(): void {
    this.headerService.primaryDisabled = true;
    if (this.currentStep < MAX_STEPS) {
      this.VirtualRemoteTestBenchVtestingJobService.triggerNextClick();
      this.currentStep++;
      this.headerService.primaryButtonText = this.currentStep < MAX_STEPS ? 'Next' : 'Finish';
      this.router.navigate(['remote-testbench-job', this.findPath()]);
      this.headerService.pageTitle = (this.VirtualRemoteTestBenchVtestingJobService.getEditMode() ? 'Edit' : 'Create') + ' Remote Testbench';
      this.updateStatusBar();
    } else {
      this.finish();
    }
  }

  findPath(): string {
    const route = this.routerConfig.find(route => route.path === 'remote-testbench-job');

    return (route?.children?.find(child => child.data?.['step'] === this.currentStep)?.path) || '';
  }

  finish(): void {
    const testJobId = this.VirtualRemoteTestBenchVtestingJobService.getVtestingJobId();

    this.VirtualRemoteTestBenchVtestingJobService.loadingSpinner = true;
    if (this.VirtualRemoteTestBenchVtestingJobService.getEditMode()) {
      this.VirtualRemoteTestBenchVtestingJobService.base_data.test_definitions = this.VirtualRemoteTestBenchVtestingJobService.getVtestingJobDefinitions();
      this.VirtualRemoteTestBenchVtestingJobService.putAllSimulationBundles(testJobId);
    } else {
      this.VirtualRemoteTestBenchVtestingJobService.postAllSimulationBundles();
    }
  }

  backOneStep(): void {
    this.headerService.primaryDisabled = true;
    this.currentStep--;
    this.updateStatusBar();
    this.headerService.secondaryButtonText = this.currentStep === 1 ? '' : 'Back';
    if (this.currentStep === 2) {
      this.headerService.clearPrimaryClickFunction();
      this.headerService.primaryButtonText = 'Next';
      this.headerService.primaryClick$.subscribe(() => this.nextStep());
    }
    this.router.navigate(['remote-testbench-job', this.findPath()]);
    this.headerService.pageTitle = (this.VirtualRemoteTestBenchVtestingJobService.getEditMode() ? 'Edit' : 'Create') + ' Remote Testbench Job';
  }

  updateStatusBar(): void {
    if (this.step1Ref && this.step2Ref && this.step3Ref)
      [this.step1Ref, this.step2Ref, this.step3Ref].forEach((step, index) =>
        step.nativeElement.status = index < this.currentStep - 1 ? 'done' : index === this.currentStep - 1 ? '' : 'disabled');
  }

  cancelCreateVTestingJob(): void {
    this.modalService.initializeCustomModal("discard", this.discardModalTitle, this.discardModalDescription);
  }
}
